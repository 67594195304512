import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { htmlStylesBorderLessonParts, htmlStylesBorderRadiusLessonParts, htmlStylesFontFamilyLessonParts, htmlStylesFontStyleLessonParts, htmlStylesFontWeightLessonParts, htmlStylesTextAlignmentLessonParts, htmlStylesTextDecorationLessonParts } from '../htmlIntro/htmlStylesLesson';
import { htmlDivsLessonParts } from '../htmlIntro/htmlDivLesson';
import { htmlImagesLessonParts } from '../htmlIntro/htmlImagesLesson';
import { htmlListsLessonParts } from '../htmlIntro/htmlListsLesson';
import { htmlTablesLessonParts } from '../htmlIntro/htmlTableLesson2';
import { htmlTextFormattingsLessonParts } from '../htmlIntro/htmlTextFormattingLesson';
import { htmlFormsLessonParts } from '../htmlIntro/htmlFormsLesson';
import { htmlCssLessonParts } from '../htmlIntro/htmlCssLesson';
import { cssBackgroundLessonParts } from '../css/cssBackgroundLesson';
import { cssMarginAndPaddingLessonParts } from '../css/cssMarginAndPaddingLesson';
import { cssHeightandWidthLessonParts } from '../css/cssHeightAndWidthLesson';
import { cssLinksLessonParts } from '../css/cssLinksLesson';
import { htmlAudioLessonParts } from '../htmlIntro/htmlAudioLesson';
import { htmlVoidElementLessonParts } from '../htmlIntro/htmlVoidElementLesson';
import { htmlMetadataLessonParts } from '../htmlIntro/htmlMetadataLesson';
import { htmlIframeLessonParts } from '../htmlIntro/htmlIframeLesson';
import { htmlCodesLessonParts } from '../htmlIntro/htmlCodesLesson';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import * as bs from 'bootstrap'
import { cssDropDownLessonParts } from '../css/cssDropdowns';
import { cssLengthUnitsLessonParts } from '../css/cssLengthUnits';
import { cssLineHeightLessonParts } from '../css/cssLineHeight';
import { htmlDjangoLessonParts, htmlWikipediaLessonParts } from '../htmlIntro/htmlWikipediaLesson';
import { cssColorsLessonParts, cssDisplayLessonParts, cssPositionLessonParts, htmlAnchorLessonParts, htmlAttributesLessonParts, htmlButtonLessonParts, htmlElementsLessonParts, htmlHeadingsLessonParts, htmlIntroLessonParts, htmlParagraphsLessonParts, htmlStylesBackgroundColorLessonParts, htmlStylesLessonParts, htmlStylesTextSizeLessonParts } from '../htmlLessons';


export const HTML_LESSONS = [{
    lessonId: "htmlIntroLesson",
    lessonName: "html intro",
    lessonParts: htmlIntroLessonParts 
}, {
    lessonId: "htmlElements",
    lessonName: "html elements",
    lessonParts: htmlElementsLessonParts
}, {
    lessonId: "htmlAttributes",
    lessonName: "html attributes",
    lessonParts: htmlAttributesLessonParts
}, {
    lessonId: "htmlHeadings",
    lessonName: "html headings",
    lessonParts: htmlHeadingsLessonParts
}, {
    lessonId: "htmlStyles",
    lessonName: "html styles",
    lessonParts: htmlStylesLessonParts
}, {
    lessonId: "htmlStylesTextSize",
    lessonName: "html styles - text size",
    lessonParts: htmlStylesTextSizeLessonParts
}, {
    lessonId: "htmlStylesBackgroundColor",
    lessonName: "html styles - background color",
    lessonParts: htmlStylesBackgroundColorLessonParts
}, {
    lessonId: "htmlStylesBorder",
    lessonName: "html styles - border",
    lessonParts: htmlStylesBorderLessonParts
}, {
    lessonId: "htmlStylesBorderRadius",
    lessonName: "html styles - border radius",
    lessonParts: htmlStylesBorderRadiusLessonParts
}, {
    lessonId: "htmlStylesTextAlignment",
    lessonName: "html styles - text alignment",
    lessonParts: htmlStylesTextAlignmentLessonParts
}, {
    lessonId: "htmlStylesFontWeight",
    lessonName: "html styles - font weight",
    lessonParts: htmlStylesFontWeightLessonParts
}, {
    lessonId: "htmlStylesTextDecoration",
    lessonName: "html styles - text decoration",
    lessonParts: htmlStylesTextDecorationLessonParts
}, {
    lessonId: "htmlStylesFontStyle",
    lessonName: "html styles - font style",
    lessonParts: htmlStylesFontStyleLessonParts
}, {
    lessonId: "htmlStylesFontFamily",
    lessonName: "html styles - font family",
    lessonParts: htmlStylesFontFamilyLessonParts
}, {
    lessonId: "htmlParagraphs",
    lessonName: "html paragraphs",
    lessonParts: htmlParagraphsLessonParts
}, {
    lessonId: "htmlButton",
    lessonName: "html buttons",
    lessonParts: htmlButtonLessonParts
}, {
    lessonId: "htmlTextFormattings",
    lessonName: "html text formatting",
    lessonParts: htmlTextFormattingsLessonParts
}, {
    lessonId: "htmlAnchor",
    lessonName: "html anchor",
    lessonParts: htmlAnchorLessonParts
}, {
    lessonId: "htmlImages",
    lessonName: "html images",
    lessonParts: htmlImagesLessonParts
}, {
    lessonId: "htmlTables",
    lessonName: "html tables",
    lessonParts: htmlTablesLessonParts
}, {
    lessonId: "htmlLists",
    lessonName: "html lists",
    lessonParts: htmlListsLessonParts
}, {
    lessonId: "htmlDivs",
    lessonName: "html divs",
    lessonParts: htmlDivsLessonParts
}, {
    lessonId: "htmlCss",
    lessonName: "html css",
    lessonParts: htmlCssLessonParts
}, {
    lessonId: "htmlForms",
    lessonName: "html forms",
    lessonParts: htmlFormsLessonParts
}, {
    lessonId: "cssBackground",
    lessonName: "css background",
    lessonParts: cssBackgroundLessonParts
}, {
    lessonId: "cssMarginAndPadding",
    lessonName: "css margin and padding",
    lessonParts: cssMarginAndPaddingLessonParts
}, {
    lessonId: "cssHeightandWidth",
    lessonName: "css height and width",
    lessonParts: cssHeightandWidthLessonParts
}, {
    lessonId: "cssPosition",
    lessonName: "css position",
    lessonParts: cssPositionLessonParts
}, {
    lessonId: "cssColors",
    lessonName: "css colors",
    lessonParts: cssColorsLessonParts
}, {
    lessonId: "cssDisplay",
    lessonName: "css display",
    lessonParts: cssDisplayLessonParts
}, {
    lessonId: "cssLinks",
    lessonName: "css links",
    lessonParts: cssLinksLessonParts
}, {
    lessonId: "htmlAudio",
    lessonName: "html audio",
    lessonParts: htmlAudioLessonParts
}, {
    lessonId: "htmlVoidElement",
    lessonName: "html void elements",
    lessonParts: htmlVoidElementLessonParts
}, {
    lessonId: "htmlMetadata",
    lessonName: "html metadata",
    lessonParts: htmlMetadataLessonParts
}, {
    lessonId: "htmlIframe",
    lessonName: "html iframes",
    lessonParts: htmlIframeLessonParts
}, {
    lessonId: "htmlCodes",
    lessonName: "html codes",
    lessonParts: htmlCodesLessonParts
}, {
    lessonId: "cssDropDown",
    lessonName: "dropdowns",
    lessonParts: cssDropDownLessonParts
}, {
    lessonId: "cssLengthUnits",
    lessonName: "length units",
    lessonParts: cssLengthUnitsLessonParts
}, {
    lessonId: "cssLineHeight",
    lessonName: "line height",
    lessonParts: cssLineHeightLessonParts
}, {
    lessonId: "htmlWikipedia",
    lessonName: "html exercise 1",
    lessonParts: htmlWikipediaLessonParts
}, {
    lessonId: "htmlDj",
    lessonName: "html exercise 2",
    lessonParts: htmlDjangoLessonParts
}
//  {
//     lessonId: "htmlSemanticElements",
//     lessonName: "html semantic elements",
//     lessonParts: htmlSemanticElementsLessonParts
// }
]



class HtmlLanding extends Component {
    constructor(props) {
        super(props)
        this.selectedOptions = new Set()
    }

    componentDidMount = () => {
        this.getLessonStatusIfNeeded()
    }

    componentDidUpdate = (prevProps) => {
        // if (prevProps.lessonId !== this.props.lessonId) {
        //     this.getLessonStatusIfNeeded()
        // }
        if (prevProps.userId !== this.props.userId) {
            this.props.dispatchGetLessonHistoryForUserIdSagaAction(this.props.userId)
        }
    }

    getLessonStatusIfNeeded = () => {
        const { userId } = this.props
        if (userId) {
            this.props.dispatchGetLessonHistoryForUserIdSagaAction(userId)
        }
        this.props.dispatchGetUsersSagaAction()
    }

    render = () => {
        const { lessonId, lessonStatus, lessonHistoryForUserId, users } = this.props
        const { userId } = this.props

        const lessonLinksTableItems = HTML_LESSONS.map(lesson => {
            const history = (lessonHistoryForUserId[userId] || {})[lesson.lessonId]

            const doneTimestampMillis = history?.update_timestamp_millis || 0
            const doneDateString = doneTimestampMillis > 0 ? new Date(doneTimestampMillis).toLocaleString("en-IN"): ""
            return (<tr key={lesson.lessonId}>
                <td><a href={`/html/${lesson.lessonId}`}>{lesson.lessonName}</a></td>
                <td>
                    {history && history?.totalExercises ?
                        <span>{`${history?.doneExercises} / ${history?.totalExercises}`}</span> : null}
                    {history &&
                    ((
                        history?.totalExercises !== 0 &&
                        history?.totalExercises === history?.doneExercises
                    ) || (
                        history?.totalExercises === 0 &&
                        history?.lesson_status == "done"
                    )) ? 
                    <span className='ms-2'>
                        <FontAwesomeIcon icon={faCheckCircle} color={"green"}/>
                        <div className="d-inline-block" style={{marginLeft: "20px"}}>{doneDateString}</div>
                    </span>:null}
                </td>
            </tr>)
        })
        const lessonRoutes = HTML_LESSONS.map((lesson, index) => {
            return (<Route path={`/${lesson.lessonId}`}>
                <HtmlLesson lesson={lesson} homeLink="/html"
                    nextLessonLink={HTML_LESSONS[index+1]?.lessonId ? `/html/${HTML_LESSONS[index+1]?.lessonId}`: null}/>
            </Route>)
        })
        // return (
        //     <BrowserRouter basename="/html">
        //     <Switch>
        //         {lessonRoutes}
        //         <Route path="/">
        //             {/* <PromHeader></PromHeader> */}
        //             <table className="table mt-5">
        //                 <thead>
        //                     <tr>
        //                         <th scope="col">Lesson</th>
        //                         <th scope="col">Status</th>
        //                     </tr>
        //                 </thead>
        //                 <tbody>
        //                     {lessonLinksTableItems}
        //                 </tbody>
        //             </table>
        //     </Route>
        //     </Switch>
        //     </BrowserRouter>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        lessonStatus: state.common.lessonStatuses[ownProps.lessonId || ""],
        lessonHistory: state.common.lessonHistories[ownProps.lessonId || ""],
        lessonHistoryForUserId: state.common.lessonHistoryForUserId,
        users: state.common.users,
        email: state.common.user?.email,
        userId: state.common.user?.userId,
    };
};

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(HtmlLanding)