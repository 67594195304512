import React from 'react';
import { HtmlAnswerQuizLessonPart } from '../../../util/lessonPart';
import { getExerciseWithExerciseId } from '../../../exercises/exercise';

export const HtmlTablesLessonPart1 = () => {
    return (<div className="container-fluid">
        <h3>Tables</h3>
        {/* <HtmlLessonReaderComponent lessonPartContent={htmlTableLessonPartContent}></HtmlLessonReaderComponent> */}
    </div>)
}

export const HtmlTablesLessonPart2 = () => {
    return (<div className="container-fluid">
        <h3>Styling a table</h3>
        {/* <HtmlLessonReaderComponent lessonPartContent={htmlTableLessonPartContent2}></HtmlLessonReaderComponent> */}
    </div>)
}


export const htmlTablesLessonParts = [{
    component: HtmlTablesLessonPart1 
}, {
    component: HtmlTablesLessonPart2,
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-table-1")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-table-2")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-table-3")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-table-4")
    }
}]
