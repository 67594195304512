import React from 'react';
import { HtmlAnswerQuizLessonPart } from '../../../util/lessonPart';
import { getExerciseWithExerciseId } from '../../../exercises/exercise';

export const CssLinksLessonPart1 = () => {
    return (<div className="container-fluid">
        <h3>Links</h3>
        <ul className="mt-3">
            <li>Links or anchor elements can be styled similar to other html elements.</li>
            <li>In addition to regular styling, links also have additional states which can also
                be styled using css properties.</li>
            <li>For each css class say, <code>link1</code>, there are states, <code>link1:visited</code>, <code>
            link1:hover</code>, <code>link1:active</code> that
                can be styled like below.</li>
        </ul>
        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <head>
        <style>
            .link1 {
                color: red;
                border: 3px solid grey;
                font-size: 24px;
                margin-top: 30px;
                padding: 20px;
                position: absolute;
            }
            .link1:visited {
                background-color: green;
                font-size: 24px;
                margin-top: 30px;
                padding: 20px;
            }
            .link1:hover {
                background-color: blue;
                font-size: 24px;
                margin-top: 30px;
                padding: 20px;
            }
            .link1:active {
                background-color: pink;
                font-size: 24px;
                margin-top: 30px;
                padding: 20px;
            }
        </style>
    </head>
    <body>
        <div>
            <a class="link1" target="_blank" href="https://google.com">
                Google
            </a>
        </div>
    </body>
</html>`}
            height={"500px"}
            showOutput={true}
        ></HtmlEditor>
    </div>)
}

export const CssStateElementsLessonPart1 = () => {
    return (<div className="container-fluid">
        <h3>State elements</h3>
        <ul className="mt-3">
            <li>Like links, all html elements can be styled differently depending on their state, like when user
                 hovers on them, when user clicks on them etc.</li>
            <li>These additional styles are specified in the <code>class:state</code> css style class, where <code>class</code> refers to the
                class of the element, <code>state</code> refers to the state of the element.</li>
            <li>For example, the style of a <code>div</code> with style class <code>div1</code>, when mouse hovers 
            on it is specified in <code>div1:hover</code>.</li>
        </ul>
        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <head>
        <style>
            .div1 {
                width: 300px;
                height: 100px;
                border: 3px solid red;
                font-size: 18px;
                margin: 30px;
                position: absolute;
                text-align: center
            }
            .div1:hover {
                background-color: green;
                font-size: 24px;
                color: white;
            }
        </style>
    </head>
    <body>
        <div class="div1">
            <h1>Heading</h1>
        </div>
    </body>
</html>`}
            height={"500px"}
            showOutput={true}
        ></HtmlEditor>
    </div>)
}


export const CssStateElementsLessonPart2 = () => {
    return (<div className="container-fluid">
        <h3>State elements</h3>
        <ul className="mt-3">
            <li>We can use css state elements to implement things like dropdowns in css</li>
            <li>In the below example, changing the style property <code>display</code> of another <code>div</code> element
                we can make it visible when user hovers over the first <code>div</code>.</li>
        </ul>
        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <head>
        <style>
            .div1 {
                margin: 30px;
                position: relative;
                text-align: center;
                display: inline-block;
            }
            .div2 {
                display: none;
                position: absolute;
                z-index: 1;
            }

            .div1:hover .div2 {
                display: block;
            }

            .button1 {
                width: 100px;
            }

            .option1:hover {
                background-color: grey;
            }
        </style>
    </head>
    <body>
        <div class="div1">
            <button class="button1">Button</button>
            <div class="div2">
                <p class="option1">Option 1</p>
                <p class="option1">Option 2</p>
                <p class="option1">Option 3</p>
                <p class="option1">Option 4</p>
            </div>
        </div>
    </body>
</html>`}
            height={"500px"}
            showOutput={true}
        ></HtmlEditor>
    </div>)
}

export const cssLinksLessonParts = [{
    component: CssLinksLessonPart1
}, {
    component: CssStateElementsLessonPart1
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-css-links-1")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-css-links-2")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-css-links-3")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-css-links-4")
    }
}]
