import { JS_EXERCISES } from "./jsExercises"
import { ALL_EXERCISES } from "./pythonExercises"
import { HTML_EXERCISES } from "./html/htmlExercises"


export const getExerciseWithExerciseId = (exerciseId) => {
    const exercise = HTML_EXERCISES.find(exercise => exercise.exerciseId === exerciseId)
    if (!exercise) {
        console.log(`Could not find exercise with execise id ${exerciseId}`)
        return null
    }
    return exercise
}