import styles from "./levelComponent.module.scss";
import color from "../../../styles/colors.module.scss";
import { getCourseLevelString } from "../../util/utils";

export const LevelComponent = (props) => {
  //   const { level = "Beginner" } = props;
  const { type = "" } = props;
  const level = getCourseLevelString(props.level)
  const levelsList = ["Beginner", "Intermediate", "Advanced"];
  const levelIndex = levelsList?.indexOf(level);
  return (
    <div className={type === "inverse"? styles.levelComponentInverse :styles.levelComponent}>
      <div className={styles.levelStatusContainer}>
        {levelsList?.map((level, index) => (
          <div
            key={level}
            className={type=="inverse"?styles.levelStatusInverse:styles.levelStatus}
            style={{
              background: levelIndex >= index ? type === "inverse" ? color.darkbackgroundcolor : color.white : "transparent",
              height: `${0.3 * (index + 1)}rem`,
            }}
          ></div>
        ))}
      </div>
      <span>{level}</span>
    </div>
  );
};
