import styles from "./buttonComponent.module.scss";
import color from "../../../styles/colors.module.scss";
export default function ButtonComponent(props) {
  const { type = "", children, size = "medium", onClick } = props;
  const getButtonBackgroundColor = () => {
    if (type === "primary") {
      return color.primarybutton;
    }
    if (type === "dark") {
      return color.darkbackgroundcolor;
    }
    return "";
  };
  const getButtonSize = () => {
    if (size === "medium") {
      return "0.594rem 1rem";
    }
    if (size === "small") {
      return "0.25rem 0.75rem";
    }
  };
  const getColor = () => {
    if (type === "primary" || type === "dark") {
      return color.primarybuttontextcolor;
    }
    return "";
  };
  const getBorder = () => {
    if (type === "inverse") {
      return "1px solid #000000";
    }
    return "";
  };

  return (
    <div
    role="button"
    tabIndex={0}
      onClick={onClick}
      className={styles.buttonComponent}
      style={{
        backgroundColor: getButtonBackgroundColor(),
        padding: getButtonSize(),
        color: getColor(),
        border: getBorder(),
      }}
      {...props}
    >
      {children}
    </div>
  );
}
