import React from 'react';
import { HtmlAnswerQuizLessonPart } from '../../../util/lessonPart';
import { getExerciseWithExerciseId } from '../../../exercises/exercise';

export const HtmlFormsLessonPartNew = () => {
    return (<div className="container-fluid">
        <h3>Forms</h3>
        {/* <HtmlLessonReaderComponent lessonPartContent={htmlFormsLessonPartContent}></HtmlLessonReaderComponent> */}
    </div>)
}

export const HtmlFormsLessonPart1 = () => {
    return (<div className="container-fluid">
        <h3>Form</h3>
        <ul className="mt-3">
            <li>Forms are used to collect data from user.</li>
        </ul>

        <p>A simple form would look like this. It has input text boxes to collect first name and last name data of a user and a submit button.
        </p>
        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <body>
        <form action="/action_link">
            <label for="first_name">First name:</label><br>
            <input type="text" id="first_name" name="first_name"></input><br>
            <label for="last_name">Last name:</label><br>
            <input type="text" id="last_name" name="last_name"></input><br><br>
            <input type="submit" value="Submit">
        </form>
    </body>
</html>`}
            height={"600px"}
            showOutput={true}
        ></HtmlEditor>
        <p></p>
    </div>)
}

export const HtmlFormsLessonPart2 = () => {
    return (<div className="container-fluid">
        <h3>Form</h3>
        <ul className="mt-3">
            <li>Forms are defined using the tag <code>form</code>.</li>
            <li>Inside a <code>form</code> element, user inputs are specified using <code>input</code> html elements.
                Each <code>input</code> element can correspondingly have a label element that specifies the
                user readable text explanation about the input.</li>
            <li>Each <code>input</code> element has an attribute <code>id</code> that is used to identify the input.</li>
            <li>The <code>for</code> attribute on the label element points to the <code>id</code> of the input element
                 to which the label corresponds to.</li>
            <li>Each input element has a <code>type</code> attribute that defines the type of the input.
                Some of the valid input types are <code>text</code>, <code>checkbox</code>, <code>
                number</code>, <code>password</code>, <code>radio</code>.</li>
        </ul>
        <p>The data that user enters in a form needs to be sent to server.
        It's generally sent using javascript, so we will only focus on rendering the forms here.</p>

        <p>You can see a form with different input types below.
        </p>
        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <body>
        <form action="/action_link">
            <label for="text_input">Text input</label><br>
            <input type="text" id="text_input" name="text_input"><br>
            <label for="number_input">Number input</label><br>
            <input type="number" id="number_input" name="number_input"><br>
            <label for="radio_input">Radio input</label><br>
            <input type="radio" id="radio_input" name="radio_input"><br>
            <label for="file_input">File input</label><br>
            <input type="file" id="file_input" name="file_input"><br>
            <label for="date_input">Date input</label><br>
            <input type="date" id="date_input" name="date_input"><br>
            <label for="checkbox_input">checkbox input</label><br>
            <input type="checkbox" id="checkbox_input" name="checkbox_input"><br>
        </form>
    </body>
</html>`}
            height={"600px"}
            showOutput={true}
        ></HtmlEditor>
        <p></p>
    </div>)
}


export const htmlFormsLessonParts = [{
    component: HtmlFormsLessonPartNew 
// }, {
//     component: HtmlFormsLessonPart2,
}, {
  component: HtmlAnswerQuizLessonPart,
  props: {
      exercise: getExerciseWithExerciseId("html-form-1")
  }
}, {
  component: HtmlAnswerQuizLessonPart,
  props: {
      exercise: getExerciseWithExerciseId("html-form-2")
  }
}, {
  component: HtmlAnswerQuizLessonPart,
  props: {
      exercise: getExerciseWithExerciseId("html-form-3")
  }
}, {
  component: HtmlAnswerQuizLessonPart,
  props: {
      exercise: getExerciseWithExerciseId("html-form-4")
  }
}]
