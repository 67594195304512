import { FeaturesInfoComponent } from "../FeaturesInfoComponent/featuresInfoComponent";
import { GradientTextComponent } from "../GradientTextComponent/gradientTextComponent";
import styles from "./featuresListPanel.module.scss";
const featuresInformation = [
    {
      title: () => (
        <>
          <GradientTextComponent>Engaging</GradientTextComponent> Coding
          Environment
        </>
      ),
      subText: "Engaging, Interactive and Adaptive",
      detailInfoPoints: [
        "Experience the thrill of coding with our state-of-the-art interactive coding environment.",
        "Each line of code you write is instantly evaluated, offering immediate feedback.",
        `This dynamic setup is ideal for experimenting, debugging, and understanding the impact of each 
        code snippet, accelerating your learning and coding proficiency.`,
        "Fully featured online tool to write and debug code.",
        "Write and run code online without any time taking local software setup.",
      ],
      imageUrl:
        "https://storage.googleapis.com/38492734832public/web/images/engaging_coding_environment.jpg",
      direction: "right",
      show: true,
    },
    {
      title: () => (
        <>
          <GradientTextComponent>Interactive</GradientTextComponent> Lessons
        </>
      ),
      subText: "Learning without losing focus",
      detailInfoPoints: [
        `Interactive gamified lessons to enable steady learning without losing focus.
         We want to make learning a fun and interesting activity.`,
        "Lessons designed to start from zero in each topic. ",
        "Fully featured online tool to write code and debug while following a lesson.",
      ],
      imageUrl:
        "https://storage.googleapis.com/38492734832public/web/images/interactive_lessons.jpg",
      direction: "left",
      show: true,
    },
  {
    title: () => (
      <>
        <GradientTextComponent>Instant</GradientTextComponent> Feedback and
        Guidance
      </>
    ),
    subText: "Immediate Insight, Better Code",
    detailInfoPoints: [
      "Our platform doesn't just passively teach; it actively guides.",
      "As you code, receive instant feedback on your work. This immediate response helps you quickly understand mistakes, learn best practices, and refine your coding skills on the spot.",
      "It's like having a mentor over your shoulder, ensuring you stay on the right track.",
    ],
    imageUrl:
      "https://storage.googleapis.com/38492734832public/web/images/instant_feedback_and_guidance.jpg",
    direction: "right",
    show: true,
  },
  {
    title: () => (
      <>
        <GradientTextComponent>AI-Powered</GradientTextComponent> Personalized
        Learning
      </>
    ),
    subText: "Your Personalized Learning Ally",
    detailInfoPoints: [
      "Harness the power of AI for a learning experience that adapts to you.",
      "Our AI algorithms analyze your progress, strengths, and areas for improvement, customizing the course content to suit your individual learning pace and style.",
      "This personalized approach ensures that your learning journey is as effective and efficient as possible.",
    ],
    imageUrl:
      "https://storage.googleapis.com/38492734832public/web/images/ai_powered_personalized_learning.jpg",
    direction: "left",
    show: true,
  },
  {
    title: () => (
      <>
        <GradientTextComponent>Practical</GradientTextComponent> Coding
        Challenges
      </>
    ),
    subText: "Real Skills Through Real Challenges",
    detailInfoPoints: [
      "Put your skills to the test with practical coding challenges designed to simulate real-world scenarios.",
      "These challenges not only reinforce your learning but also enhance your problem-solving and critical thinking skills.",
      "Tackling these tasks will prepare you for the kinds of challenges you'll face in a professional coding environment.",
    ],
    imageUrl:
      "https://storage.googleapis.com/38492734832public/web/images/practical_coding_challenges.jpg",
    direction: "right",
    show: true,
  },
  {
    title: () => (
      <>
        <GradientTextComponent>Interactive</GradientTextComponent> Quizzes and
        Exercises
      </>
    ),

    subText: "Engaging Exercises for Deep Learning",
    detailInfoPoints: [
      "Solidify your learning with interactive quizzes and exercises.",
      "These are not just assessments but learning tools in themselves, designed to reinforce concepts and ensure retention.",
      "Each quiz and exercise is an opportunity to apply what you've learned in a controlled, supportive environment.",
    ],
    imageUrl:
      "https://storage.googleapis.com/38492734832public/web/images/interactive_quizzes_and_exercises.jpg",
    direction: "left",
    show: true,
  },
];
export const FeaturesListPanelComponent = () => {
  // const { t } = useTranslation();
  return (
    <div className={styles.featuresListPanelComponent}>
      {featuresInformation?.map(
        (info) =>
          info?.show && (
            <FeaturesInfoComponent
              key={info?.title}
              TitleComponent={info?.title}
              subText={info?.subText}
              featureInfo={info?.detailInfoPoints}
              imageUrl={info?.imageUrl}
              infoSide={info?.direction}
            />
          )
      )}
    </div>
  );
};
