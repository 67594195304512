import axios from 'axios';

const promAxiosInternal = axios.create({
    // baseURL: 'http://localhost:4356',
    headers: {
        common: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        },
    },
    timeout: process.env.HTTP_CALL_TIMEOUT,
});
promAxiosInternal.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log({response})
    return response;
}, function (error) {
    console.log(error)
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const requestUrl = error?.config?.url || ""
    const currentUrl = window.location.href || "/"
    // console.log({error, requestUrl, currentUrl})
    if (error?.response?.status === 401 &&
        !currentUrl.includes("/app/login") &&
        !requestUrl.includes("/api/v1/checkLogin")) {
        window.location.href = `/app/login?continueUrl=${currentUrl}`
    }
    return Promise.reject(error);
});

export const promAxios = promAxiosInternal
