import React from 'react';
import { HtmlAnswerQuizLessonPart } from '../../../util/lessonPart';
import { getExerciseWithExerciseId } from '../../../exercises/exercise';

export const CssLengthUnitsLessonPart = () => {
    return (<div className="container-fluid">
        <h3>CSS Length Units</h3>
        {/* <HtmlLessonReaderComponent lessonPartContent={htmlCssLengthsLessonPartContent}></HtmlLessonReaderComponent> */}
    </div>)
}


export const cssLengthUnitsLessonParts = [{
    component: CssLengthUnitsLessonPart
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("css-length-units-1")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("css-length-units-2")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("css-length-units-3")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("css-length-units-4")
    }
}]
