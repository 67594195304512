import { PureComponent } from "react"
import React from 'react';

import { QuestionTextComponent, promReduxConnect } from "../utils";

import * as bs from 'bootstrap'
import '../../../../node_modules/flexlayout-react/style/light.css'
import { CODE_EVENT_TYPE_CURSOR, CODE_EVENT_TYPE_INIT, CODE_EVENT_TYPE_SELECTION,
     CODE_EVENT_TYPE_TEXT } from "../pythonEditor";
import { ProjectEditorComponent } from "./projectEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import "../../dashboard/course.css"
import { resetExerciseSaga, saveCourseTaskSaga } from "../../../store/common.saga";
import * as FlexLayout from 'flexlayout-react'

class ContextMenu extends PureComponent {
    render() {
        const { contextMenuItems, contextMenuXPos, contextMenuYPos, treeNode } = this.props;
        const elements = []
        contextMenuItems.forEach(menuItemText => {
            elements.push(<li key={menuItemText} onClick={() => this.props.onContextMenuItemClick(menuItemText, treeNode)}>
                <button class="dropdown-item" style={{lineHeight: "normal"}}>{menuItemText}</button>
            </li>)
        })

        return (<div class="dropdown" style={{ position: "absolute", top: contextMenuYPos, left: contextMenuXPos }}>
            <ul class="dropdown-menu dropdown-menu-dark" style={{display: "block", padding: "0px"}}>
                {elements}
            </ul>
        </div>)
    }
}


class ProjectExerciseViewerComponentInternal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            openTreeNodes: new Set(),
            outputWebSocketReadyState: 0,
            httpRequestMethod: "GET",
            httpRequestBody: "",
            editedProcessConfig: {},
            currentProcesses: {},
            currentFilePath: null,
            // layoutModel: {}
            layoutModel: FlexLayout.Model.fromJson({
                global: {
                    rootOrientationVertical: true,
                    tabSetEnableTabStrip: false
                },
                borders: [],
                layout: {
                }
            })
        }
    }
    questionRef= React.createRef(null)
    resizerRef=React.createRef(null)
    setLayout = (height) => {
        console.log({height})
        this.setState({layoutModel: FlexLayout.Model.fromJson({
            global: { rootOrientationVertical: true,
                tabSetEnableTabStrip: false,
                rootOrientationVertical: false
            },
            borders: [],
            layout: {
                type: "row",
                weight: 100,
                children: [{
                    type: "tabset",
                    weight: 25,
                    children: [{
                            type: "tab",
                            name: "",
                            component: "questionHTML",
                            // tabsetClassName: "questionHtmlTabSetClass",
                            // contentClassName: "questionHtmlTabSetClass2",
                            enableClose: false,
                            enableDrag: false
                    }]
                }, {
                    type: "tabset",
                    weight: 75,
                    children: [{
                        type: "tab",
                        name: "",
                        component: "projectEditor",
                        enableClose: false,
                        enableDrag: false
                    }]
                }]
            }
        })})
    }

    componentDidMount = () => {
        this.setLayout()
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.exercise !== prevProps.exercise) {
            this.setState({
                currentProcesses: {}, currentFilePath: null,
            })
            this.questionRef.current.style.height='max-content';
            this.initialHeight=0
        }
    }

    componentWillUnmount() {
    }

    // componentWillUpdate(nextProps, nextState) {
    //     const stateKeys = new Set();
    //     const propsKeys = new Set();
    //     Object.keys(this.props).forEach(key => propsKeys.add(key));
    //     Object.keys(nextProps).forEach(key => propsKeys.add(key));
    //     Object.keys(this.state || {}).forEach(key => stateKeys.add(key));
    //     Object.keys(nextState || {}).forEach(key => stateKeys.add(key));

    //     const getDiff = (keys, object1, object2) => {
    //         const diffValues = [];
    //         const keysArray = [...keys];
    //         keysArray.filter(key => object1[key] !== object2[key]).forEach(
    //             key => diffValues.push([key, object1[key], object2[key]]));
    //         return [...diffValues];
    //     };

    //     console.log(getDiff(propsKeys, this.props, nextProps),
    //         getDiff(stateKeys, this.state || {}, nextState || {}));
    // }

    onSaveExercise = (exerciseData) => {
        const { courseId, exercise, userId } = this.props
        const { exerciseId } = exercise || {}

        console.log("onSaveExercise")
        this.props.dispatchFunctionAction(
            saveCourseTaskSaga, {
            courseId, taskId: exerciseId,
            courseTaskData: {
                ...exerciseData,
        }})
    }

    onSaveProjectFiles = (filesToBeSaved) => {
        const currentFiles = [...this.props.exerciseTask?.files]
        filesToBeSaved.forEach(f => {
            const existingFile = currentFiles.find(f1 => f1.filePath === f.filePath)
            if (existingFile) {
                existingFile.fileText = f.fileText
            } else {
                currentFiles.push(f)
            }
        })
        this.onSaveExercise({
            files: currentFiles
        })
    }

    onDeleteProjectFile = (filePath) => {
        const { exerciseTask } = this.props
        this.onSaveExercise({
            files: exerciseTask?.files?.filter(f => f.filePath !== filePath)
        })
    }
    
    onResetProjectProcesses = () => {
        const { courseId, exercise, userId } = this.props
        const { exerciseId } = exercise || {}

        this.props.dispatchOnResetProjectProcesses(courseId, exerciseId)
    }

    onRenameProjectFile = (parentPath, oldFileName, newFileName, isFolder) => {
        const { exerciseTask } = this.props
        let newFiles = [...exerciseTask?.files]
        if (!isFolder) {
            let oldFilePath = `${parentPath}/${oldFileName}`
            let newFilePath = `${parentPath}/${newFileName}`
            if (parentPath === "") {
                oldFilePath = oldFileName
                newFilePath = newFileName
            }
            const fileText = exerciseTask?.files?.find(f => f.filePath === oldFilePath)?.fileText || ""
            newFiles = newFiles.map(f => {
                if (f.filePath === oldFilePath) {
                    return {
                        filePath: newFilePath,
                        fileText: fileText
                    }
                } else {
                    return f
                }
            })
        } else {
            let oldFolderPath = `${parentPath}/${oldFileName}/`
            let newFolderPath = `${parentPath}/${newFileName}/`
            if (parentPath === "") {
                oldFolderPath = `${oldFileName}/`
                newFolderPath = `${newFileName}/`
            }

            newFiles = newFiles.map(f => {
                if (f.filePath === oldFolderPath) {
                    return {
                        filePath: newFolderPath,
                    }
                } else if (f.filePath.startsWith(oldFolderPath)) {
                    const oldFilePath = f.filePath
                    let newFilePath = newFolderPath + oldFilePath.split(
                        oldFolderPath).slice(1).join(oldFolderPath)
                    let fileText = null
                    if (!oldFilePath.endsWith("/")) {
                        fileText = f.fileText || ""
                    }
                    return {
                        filePath: newFilePath,
                        fileText
                    }
                } else {
                    return f
                }
            })
        }
        this.onSaveExercise({
            files: newFiles
        })
    }

    onValidateProject = ({stdin, stdout, stderr}) => {
        const { courseId, exercise, userId } = this.props
        const { exerciseId } = exercise || {}
        const { exerciseTask } = this.props


        const projectFilesForWebsocket = {}
        exerciseTask?.files?.forEach((f) => {
            projectFilesForWebsocket[f.filePath] = f.fileText
        })

        console.log("Validating project", {
            messageType: "validateProject",
            userId: userId,
            exercise: exercise,
            projectId: this.getProjectId(),
            projectFilesForWebsocket: projectFilesForWebsocket,
            exerciseTask,
            stdin, stdout, stderr
        })

        this.outputWebSocket.send(JSON.stringify({
            messageType: "validateProject",
            userId: userId,
            exercise: exercise,
            projectId: this.getProjectId(),
            projectFilesForWebsocket: projectFilesForWebsocket,
            exerciseTask,
            stdin, stdout, stderr
        }))
    }

    getProjectId = () => {
        const { exercise, userId } = this.props
        const { exerciseId } = exercise || {}
        return `${exerciseId}-${userId}`
    }

    onResetExercise = () => {
        const { courseId } = this.props
        const { exercise } = this.props
        const { exerciseId } = exercise || {}
        this.props.dispatchFunctionAction(resetExerciseSaga, { courseId, exerciseId })
    }

    onWebsocketConnect = (ws) => {
        this.outputWebSocket = ws
    }

    onValidationResultsReceived = (validationResults) => {
        const { courseId, exercise, userId } = this.props
        const { exerciseId } = exercise || {}
        console.log("onValidationResultsReceived", validationResults)
        this.props.dispatchFunctionAction(
            saveCourseTaskSaga, {
                courseId, taskId: exerciseId,
                courseTaskData: {
                    validationResults,
                    taskStatus: validationResults?.overallStatus || "failed"
                }})
    }

    getQuestionPartsStatus = () => {
        const { exerciseTask, exercise } = this.props
        const { exerciseId } = exercise || {}

        console.log({exerciseTask})

        if (exerciseId && exerciseTask) {
            return exerciseTask.exerciseQuestionPartsStatus || {}
        }

        return {}
    }

    onProjectFilesChanged = (files) => {
        if (this.props.onExerciseTaskProgressChanged) {
            this.props.onExerciseTaskProgressChanged({files})
        }
    }

    onQuestionPartResize = (height) => {
        // this.setLayout(height)
    }
    
    layoutFactory = (node) => {
        const {exercise, exerciseTask, userId, courseId} = this.props
        const { exerciseId, questionParts, exerciseType } = exercise || {};

        if (node.getComponent() == "questionHTML") {
                return (
                    <QuestionTextComponent exercise={exercise} exerciseTask={exerciseTask}
                        onResize={this.onQuestionPartResize} />
                )}
        else if (node.getComponent()=="projectEditor") {
                return (
                    <ProjectEditorComponent
                        projectId={this.getProjectId()}
                        exerciseId={exerciseId}
                        projectType={exerciseType}
                        files={exerciseTask?.files}
                        userId={this.props.userId}
                        onProjectFilesChanged={this.onProjectFilesChanged}
                        onSaveProjectFiles={this.onSaveProjectFiles}
                        onAddProjectFiles={this.onSaveProjectFiles}
                        onDeleteProjectFile={this.onDeleteProjectFile}
                        onResetProjectProcesses={this.onResetProjectProcesses}
                        onRenameProjectFile={this.onRenameProjectFile}
                        onValidateProject={this.onValidateProject}
                        onValidationResultsReceived={this.onValidationResultsReceived}
                        onResetProject={this.onResetExercise}
                        processes={exerciseTask?.processes}
                        onWebsocketConnect={this.onWebsocketConnect}
                        taskStatus={exerciseTask?.taskStatus}
                        height={"100%"}
                    />
                );}
    }
    
    render = () => {
        return (
        <div className="flexlayout-container">
            <FlexLayout.Layout
                model={this.state.layoutModel}
                factory={this.layoutFactory}
            />
        </div>
    )}

    
    handleResizing=(e)=>{
        if(e.buttons===1 ){
            const offsetHeight = this.questionRef?.current?.offsetHeight
            const movementY = offsetHeight+e.movementY
            if(movementY>=30 && movementY<=this.initialHeight+1){
                this.questionRef.current.style.height=`${movementY}px`
                this.questionRef.current.style.overflow="hidden"
                this.resizerRef.current.style.opacity=1;
                document.body.style.userSelect="none"
                document.body.style.cursor="row-resize"
            }
        }
    }
    handleOnResizerMouseDown = (e)=>{
        if(!this.initialHeight){
            this.initialHeight=this.questionRef?.current?.offsetHeight
        }
        document.addEventListener('mousemove',this.handleResizing)
        document.addEventListener('mouseleave',this.handleResizingStop)
        document.addEventListener('mouseup',this.handleResizingStop)
      
    }

    handleResizingStop=(e)=>{
        document.body.style.userSelect="unset"
        document.body.style.pointerEvents="unset"
        this.questionRef.current.style.overflowY="auto"
        this.resizerRef.current.style.opacity=0;

        document.removeEventListener('mousemove',this.handleResizing)
        document.removeEventListener('mouseleave',this.handleResizingStop)
        document.removeEventListener('mouseup',this.handleResizingStop)
        document.body.style.cursor="default"
    }
    
    render3 = () => {
        const {exercise, exerciseTask, userId, courseId} = this.props
        const { exerciseId, questionParts, exerciseType } = exercise || {};
        return (
            <div className="w-100 d-flex flex-column" style={{
                minHeight:"calc(100vh - 49px)" // Excluding the height of header + 1px from the section.
            }}>
                <div ref={this.questionRef} style={{
                    height:'max-content',
                    maxHeight:"30vh",
                    overflowY: "auto",
                }}>
                    <QuestionTextComponent exercise={exercise} exerciseTask={exerciseTask}
                        onResize={this.onQuestionPartResize} />
                </div>
                {/* Divder element */}
                <div
                    className="resizer-panel"
                    ref={this.resizerRef}
                    onMouseDown={this.handleOnResizerMouseDown}
                />
                <div className="flex-grow-1" style={{
                    minHeight:"50vh",
                }}>
                    <ProjectEditorComponent
                        projectId={this.getProjectId()}
                        exerciseId={exerciseId}
                        projectType={exerciseType}
                        files={exerciseTask?.files}
                        userId={this.props.userId}
                        onProjectFilesChanged={this.onProjectFilesChanged}
                        onSaveProjectFiles={this.onSaveProjectFiles}
                        onAddProjectFiles={this.onSaveProjectFiles}
                        onDeleteProjectFile={this.onDeleteProjectFile}
                        onResetProjectProcesses={this.onResetProjectProcesses}
                        onRenameProjectFile={this.onRenameProjectFile}
                        onValidateProject={this.onValidateProject}
                        onValidationResultsReceived={this.onValidationResultsReceived}
                        onResetProject={this.onResetExercise}
                        processes={exerciseTask?.processes}
                        onWebsocketConnect={this.onWebsocketConnect}
                        taskStatus={exerciseTask?.taskStatus}
                        height={"100%"}
                    />
                </div>
            </div>
        )
    }
    
    render2 = () => {
        const {exercise, exerciseTask, userId, courseId} = this.props
        const { exerciseId, questionParts, exerciseType } = exercise || {};
        return (
            <div className="w-100 d-flex flex-row" style={{
                // minHeight:"calc(100vh - 49px)" // Excluding the height of header + 1px from the section.
            }}>
                <div
                //  ref={this.questionRef}
                  style={{
                      flex: "20%"
                    // height:'max-content',
                    // maxHeight:"30vh",
                    // overflowY: "auto",
                }}>
                    <QuestionTextComponent exercise={exercise} exerciseTask={exerciseTask}
                        onResize={this.onQuestionPartResize} />
                </div>
                {/* Divder element */}
                {/* <div
                    className="resizer-panel"
                    ref={this.resizerRef}
                    onMouseDown={this.handleOnResizerMouseDown}
                /> */}
                <div className="flex-grow-1" style={{
                      flex: "80%"
                    // minHeight:"50vh",
                }}>
                    <ProjectEditorComponent
                        projectId={this.getProjectId()}
                        exerciseId={exerciseId}
                        projectType={exerciseType}
                        files={exerciseTask?.files}
                        userId={this.props.userId}
                        onProjectFilesChanged={this.onProjectFilesChanged}
                        onSaveProjectFiles={this.onSaveProjectFiles}
                        onAddProjectFiles={this.onSaveProjectFiles}
                        onDeleteProjectFile={this.onDeleteProjectFile}
                        onResetProjectProcesses={this.onResetProjectProcesses}
                        onRenameProjectFile={this.onRenameProjectFile}
                        onValidateProject={this.onValidateProject}
                        onValidationResultsReceived={this.onValidationResultsReceived}
                        onResetProject={this.onResetExercise}
                        processes={exerciseTask?.processes}
                        onWebsocketConnect={this.onWebsocketConnect}
                        taskStatus={exerciseTask?.taskStatus}
                        height={"100%"}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        email: state.common.user?.email,
        userId: state.common.user?.userId,
    };
};

export const ProjectExerciseViewerComponent = promReduxConnect(
    ProjectExerciseViewerComponentInternal,
    mapStateToProps
)