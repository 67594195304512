import React from 'react';
import { HtmlAnswerQuizLessonPart } from '../../../util/lessonPart';
import { getExerciseWithExerciseId } from '../../../exercises/exercise';

export const CssHeightandWidthLessonPart1 = () => {
    return (<div className="container-fluid">
        <h3>Height and width</h3>
        <ul className="mt-3">
            <li>CSS <code>height</code> and <code>width</code> are used to specify the height and width of the html element.</li>
            <li>Most html elements take the amount of space needed for the contents inside it.</li>
            <li>We can use height and width properties to define a fixed dimensions for the element.</li>
            <li><code>height</code> and <code>width</code> take values in pixels, similar to borders.</li>
        </ul>
        <p>
            See the below example where we set the height and width of buttons and headings.
        </p>

        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <head>
        <style>
            .button1 {
                background-color:red;
                border:3px solid green;
                color:white;
                height: 50px;
                width: 100px
            }
            .heading1 {
                background-color:blue;
                border:3px solid green;
                color:white;
                height: 100px;
                width: 200px;
                text-align: center
            }
            .div1 {
                background-color:grey;
                border:3px solid green;
                color:white
            }
        </style>
    </head>
    <body>
        <div class="div1">
            <h1 class="heading1">Heading</h1>
            <button class="button1">
                Button
            </button>
        </div>
    </body>
</html>`}
            height={"400px"}
            showOutput={true}
        ></HtmlEditor>
    </div>)
}
export const CssHeightandWidthLessonPart2 = () => {
    return (<div className="container-fluid">
        <ul className="mt-3">
            <li><code>height</code> and <code>width</code> can also be specified as a percentage of parent element's height and width.</li>
        </ul>
        <p>
            See the below example where we set the height and width of a heading as a percent of parent div's height and width.
            Try changing the percentage values and see how the dimensions change.
        </p>

        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <head>
        <style>
            .button1 {
                background-color:red;
                border:3px solid green;
                color:white;
                height: 50px;
                width: 100px
            }
            .heading1 {
                background-color:blue;
                border:3px solid green;
                color:white;
                height: 50%;
                width: 30%
                text-align: center
            }
            .div1 {
                background-color:grey;
                border:3px solid green;
                color:white
            }
        </style>
    </head>
    <body>
        <div class="div1">
            <h1 class="heading1">Heading</h1>
            <button class="button1">
                Button
            </button>
        </div>
    </body>
</html>`}
            height={"400px"}
            showOutput={true}
        ></HtmlEditor>
    </div>)
}

export const cssHeightandWidthLessonParts = [{
    component: CssHeightandWidthLessonPart1
},{
    component: CssHeightandWidthLessonPart2
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-css-height-width-1")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-css-height-width-2")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-css-height-width-3")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-css-height-width-4")
    }
}]
