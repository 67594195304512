import React from 'react';
import htmlStylesBorderLessonContent from "../../../../lessonJsons/htmlStylesBorderLessonContent.json"
import htmlStylesTextAlignmentLessonContent from "../../../../lessonJsons/htmlStylesTextAlignmentLessonContent.json"
import htmlStylesFontWeightLessonContent from "../../../../lessonJsons/htmlStylesFontWeightLessonContent.json"
import { HtmlLessonReader2 } from '../../../util/htmlLessonRecorder/htmlLessonRecorder2/htmlLessonReader';
import { HtmlAnswerQuizLessonPart } from '../../../util/lessonPart';
import { getExerciseWithExerciseId } from '../../../exercises/exercise';


export const HtmlStylesLessonPart1 = () => {
    return (<div className="container-fluid">
        <h3>Html styles</h3>
        <p>
        </p>
        <ul className="mt-3">
            <li>We will learn about styling various html elements. We will use html header element <code>{"<h1>"}</code>.
            But it is similar with other for other html elements like paragraphs, anchor elements.</li>
            <li>Styles are specified using the <code>style</code> attribute.</li>
            <li>The value of the style attribute is a list of key value pairs separated by semi colon <code>;</code>.</li> 
            <li>Each key value is specified in the <code>key:value</code> format.</li> 
            <li>Below is an example of specifying font size (style key <code>font-size</code>) to be 24 pixels and
                text color(style key <code>color</code>) to be red, of a heading element using <code>style</code> attribute.</li>
        </ul>

        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <body>
        <h1 style="font-size:24px;color:red">Napoleon Bonaparte</h1>
    </body>
</html>`}
            height={"200px"}
            showOutput={true}
        ></HtmlEditor>

    </div>)
}

export const HtmlStylesLessonPartColor = () => {
    return (<div className="container-fluid">
        <h3>Text color</h3>
        <ul className="mt-3">
            <li>Font color is specified using <code>color</code> style property.</li>
            <li>The value of <code>color</code> property is a color name, like red, blue, green, brown, black etc.</li> 
            <li><code>color</code> property can also have other types of values, which we will learn later.</li> 
            <li>See <a href="https://htmlcolorcodes.com/tutorials/html-text-color/" target="_blank" rel="noreferrer">this</a>&nbsp;
             for more information on color property.</li>
            <li>Below is an example specifying different colors for same heading.</li>
        </ul>
        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <body>
        <h1 style="font-size:24px;color:red">Napoleon Bonaparte</h1>
        <h1 style="font-size:24px;color:blue">Napoleon Bonaparte</h1>
        <h1 style="font-size:24px;color:yellow">Napoleon Bonaparte</h1>
        <h1 style="font-size:24px;color:black">Napoleon Bonaparte</h1>
    </body>
</html>`}
            height={"380px"}
            showOutput={true}
        ></HtmlEditor>
    </div>)
}

export const HtmlStylesLessonPartTextSize = () => {
    return (<div className="container-fluid">
        <h3>Text size</h3>
        <ul className="mt-3">
            <li>Text size is specified using <code>font-size</code> style property.</li>
            <li>The value of size property is number of pixels with px appended at the end, for ex: <code>14px</code>,
            <code> 26px</code> etc</li> 
            <li><code>font-size</code> property can also have other types of values, which we will learn later.</li>
            <li>Below is an example specifying different font size for same heading.</li>
        </ul>
        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <body>
        <h1 style="font-size:14px;color:red">Napoleon Bonaparte</h1>
        <h1 style="font-size:24px;color:red">Napoleon Bonaparte</h1>
        <h1 style="font-size:10px;color:red">Napoleon Bonaparte</h1>
        <h1 style="font-size:50px;color:red">Napoleon Bonaparte</h1>
    </body>
</html>`}
            height={"380px"}
            showOutput={true}
        ></HtmlEditor>
    </div>)
}

export const HtmlStylesLessonPartBackgroundColor = () => {
    return (<div className="container-fluid">
        <h3>Background color</h3>
        <ul className="mt-3">
            <li>Background color is specified using <code>background-color</code> style property.</li>
            <li>The value of <code>background-color</code> property is a color name, like red, blue, green, brown, black etc.</li> 
            <li><code>background-color</code> property can also have other types of values, which we will learn later.</li>
            <li>Below is an example specifying different background colors for same heading. </li>
        </ul>
        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <body>
        <h1 style="font-size:24px;background-color:red">Napoleon Bonaparte</h1>
        <h1 style="font-size:24px;background-color:blue">Napoleon Bonaparte</h1>
        <h1 style="font-size:24px;background-color:yellow">Napoleon Bonaparte</h1>
        <h1 style="font-size:24px;background-color:green">Napoleon Bonaparte</h1>
    </body>
</html>`}
            height={"380px"}
            showOutput={true}
        ></HtmlEditor>
    </div>)
}

export const HtmlStylesLessonPartBorderRadius = () => {
    return (<div className="container-fluid">
        <h3>Border Radius</h3>
        <ul className="mt-3">
            <li><code>border-radius</code> is used to specify the radius of the four corners of the border.</li>
        </ul>
        <p>See below example to set different border radius for headings</p>
        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <body>
    <h3 style="border:3px solid red; border-radius: 3px">Napoleon Bonaparte</h3>
    <h3 style="border:3px solid red; border-radius: 10px">Napoleon Bonaparte</h3>
    </body>
</html>`}
            height={"380px"}
            showOutput={true}
        ></HtmlEditor>
    </div>)
}

export const HtmlStylesLessonPartTextDecoration = () => {
    return (<div className="container-fluid">
        <h3>Text decoration</h3>
        <ul className="mt-3">
            <li>Text decodation is used to decorate the text, for example to draw an underline or overline.</li>
            <li><code>text-decoration</code> property is used to decorate the text. It can take one of the possible values <code>
                overline</code>, <code>line-through</code>, <code>underline</code>.</li> 
            <li>Below is an example to see different examples of <code>text-decoration</code> style property.</li>
        </ul>
        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <body>
    <body>
        <h3 style="text-decoration:overline">Earth is the third planet from the Sun and the only astronomical object known to harbor life.</h3>
        <h3 style="text-decoration:line-through">Earth is the third planet from the Sun and the only astronomical object known to harbor life.</h3>
        <h3 style="text-decoration:underline">Earth is the third planet from the Sun and the only astronomical object known to harbor life.</h3>
        <h3 style="font-weight:lighter">Earth is the third planet from the Sun and the only astronomical object known to harbor life.</h3>
    </body>
</html>`}
            height={"380px"}
            showOutput={true}
        ></HtmlEditor>
    </div>)
}

export const HtmlStylesLessonPartFontStyle = () => {
    return (<div className="container-fluid">
        <h3>Font style</h3>
        <ul className="mt-3">
            <li><code>font-style</code> property is used to set the italic/oblique properies of the text.</li>
            <li>It can take one of the possible values <code>
                normal</code>, <code>italic</code>, <code>oblique</code>.</li> 
            <li>Below is an example to see different examples of <code>font-style</code> style property.</li>
        </ul>
        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <body>
    <body>
        <h3 style="text-decoration:overline;font-style:italic">Earth is the third planet from the Sun and the only astronomical object known to harbor life.</h3>
        <h3 style="text-decoration:line-through;font-style:normal">Earth is the third planet from the Sun and the only astronomical object known to harbor life.</h3>
        <h3 style="text-decoration:underline;font-style:oblique">Earth is the third planet from the Sun and the only astronomical object known to harbor life.</h3>
        <h3 style="font-weight:lighter;font-style:italic">Earth is the third planet from the Sun and the only astronomical object known to harbor life.</h3>
    </body>
</html>`}
            height={"380px"}
            showOutput={true}
        ></HtmlEditor>
    </div>)
}

export const HtmlStylesLessonPartFontFamily = () => {
    return (<div className="container-fluid">
        <h3>Font family</h3>
        <ul className="mt-3">
            <li><code>font-family</code> property is used to set the font of the text.</li>
            <li>The value of <code>font-family</code> should be the desired font of the text,
             for example "Arial", "Helvetica", "sans-serif", "monospace".</li> 
            <li>Below is an example to see different examples of <code>font-family</code> style property.</li>
        </ul>
        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <body>
    <body>
        <h3 style="font-family:Arial;">Earth is the third planet from the Sun and the only astronomical object known to harbor life.</h3>
        <h3 style="font-family:monospace;">Earth is the third planet from the Sun and the only astronomical object known to harbor life.</h3>
        <h3 style="font-family:sans;">Earth is the third planet from the Sun and the only astronomical object known to harbor life.</h3>
        <h3 style="font-family:Times New Roman;">Earth is the third planet from the Sun and the only astronomical object known to harbor life.</h3>
    </body>
</html>`}
            height={"380px"}
            showOutput={true}
        ></HtmlEditor>
    </div>)
}


export const htmlStylesBorderLessonParts = [{
    component: HtmlLessonReader2,
    props: {
        lessonContent: htmlStylesBorderLessonContent,
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-border-1")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-border-2")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-border-3")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-border-4")
    }
}]
export const htmlStylesBorderRadiusLessonParts = [{
    component: HtmlStylesLessonPartBorderRadius
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-border-radius-1")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-border-radius-2")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-border-radius-3")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-border-radius-4")
    }
}]
export const htmlStylesTextAlignmentLessonParts = [{
    component: HtmlLessonReader2,
    props: {
        lessonContent: htmlStylesTextAlignmentLessonContent,
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-text-alignment-1")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-text-alignment-2")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-text-alignment-3")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-text-alignment-4")
    }
}]

export const htmlStylesFontWeightLessonParts = [{
    component: HtmlLessonReader2,
    props: {
        lessonContent: htmlStylesFontWeightLessonContent,
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-font-weight-1")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-font-weight-2")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-font-weight-3")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-font-weight-4")
    }
}]

export const htmlStylesTextDecorationLessonParts = [{
    component: HtmlStylesLessonPartTextDecoration
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-text-decoration-1")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-text-decoration-2")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-text-decoration-3")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-text-decoration-4")
    }
}]

export const htmlStylesFontStyleLessonParts = [{
    component: HtmlStylesLessonPartFontStyle
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-font-style-1")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-font-style-2")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-font-style-3")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-font-style-4")
    }
}]

export const htmlStylesFontFamilyLessonParts = [{
    component: HtmlStylesLessonPartFontFamily
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-font-family-1")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-font-family-2")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-font-family-3")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-font-family-4")
    }
}]
