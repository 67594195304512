import React from 'react';
import { getComponentForQuestionId } from '../../../util/lessonPart';


export const HtmlVoidElementLessonPart1 = () => {
    return (<div className="container-fluid">
        <h3>void elements</h3>
        <ul className="mt-3">
            <li>void elements don't have content or more elements inside them.</li>
            <li>void elements don't have or need not have closing tag.</li>
            <li>They are usually written with a special syntax <code>{"<tag_name/>"}</code></li>
            <li><code>img</code>, <code>br</code>, <code>input</code> are some of the void elements.</li>
        </ul>

        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <head>
        <style>
            .div1 {
                margin: 20px;
            }
        </style>
    </head>
    <body>
        <div class="div1">
            This is a div element. <br/> The br element here has introduced a line break
        </div>
        <div class="div1">
            <img width="100px" height="100px" src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/97/The_Earth_seen_from_Apollo_17.jpg/599px-The_Earth_seen_from_Apollo_17.jpg">
                This is a image element which doesn't have a closing tag.
        </div>
        <div class="div1">
            <input type="text"/> This text input element has no closing tag.
        </div>
    </body>
</html>`}
            height={"400px"}
            showOutput={true}
        ></HtmlEditor>
    </div>)
}


export const htmlVoidElementLessonParts = [{
    component: HtmlVoidElementLessonPart1 
}, getComponentForQuestionId("void-elements")]
