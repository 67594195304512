"use client";
import { useNavigate } from "react-router-dom";
import styles from "./footer.module.scss";

import { useState } from "react";

const FooterColumnItem = (props) => {
    const { title, urlsList = [], value, isString } = props;
    const [expandFooter, setExpandFooter] = useState(false);
    const navigate = useNavigate();
    const onHandleRedirection = (url) => {
        navigate(url);
        // window.open(url);
    };

    const isTabletMobile =
        typeof window !== "undefined" && window?.innerWidth <= 1023;

    const onToggleFooter = () => {
        if (isTabletMobile) {
            setExpandFooter(!expandFooter);
        }
    };
    return (
        <div className={styles.footerColumn}>
            <div className={styles.footerColumnTitle}>
                <div className={styles.title} onClick={onToggleFooter}>
                    {title}
                </div>
                <div onClick={onToggleFooter} className={styles.footerItemExpandButton}>
                    {expandFooter ? "-" : "+"}
                </div>
            </div>
            <div
                className={
                    expandFooter
                        ? styles.footerColumnItemsMobile
                        : styles.footerColumnItems
                }
            >
                {isString ? (
                    <>{value}</>
                ) : (
                    <>
                        {urlsList?.map(
                            (item) =>
                                item?.show && (
                                    <div
                                        className={styles.item}
                                        key={item?.title}
                                        onClick={() => {
                                            onHandleRedirection(item?.url);
                                        }}
                                    >
                                        {item?.title}
                                    </div>
                                )
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export const FooterComponent = () => {
    return (
        <footer className={styles.footerContainer}>
            <FooterColumnItem
                title={"Address"}
                isString
                value={
                    "4th floor, Above Jata Fertility Center, Street no. 5, Janardhan Hills, Gachibowli, Hyderabad, India - 500032"
                }
            />

            <FooterColumnItem
                title={"Internships"}
                urlsList={[
                    {
                        title: "Python Developer Internship",
                        url: "/app/internships/python-developer-internship",
                        show: true,
                    },
                ]}
            />

            <FooterColumnItem
                title={"Plovi"}
                urlsList={[
                    {
                        title: "Support",
                        url: "/app/help",
                        show: true,
                    },
                    {
                        title: "Privacy Policy",
                        url: "/app/privacy-policy",
                        show: true,
                    },
                    {
                        title: "Terms of Service",
                        url: "/app/tos",
                        show: true,
                    },


                ]}
            />

            {/* <FooterColumnItem
        title={"Social"}
        urlsList={[
          {
            title: "LinkedIn",
            url: "https://www.google.com/",
            show: true,
          },
          {
            title: "Instagram",
            url: "https://www.google.com/",
            show: true,
          },
          {
            title: "Facebook",
            url: "https://www.google.com/",
            show: true,
          },

          {
            title: "Twitter",
            url: "https://www.google.com/",
            show: true,
          },
        ]}
      /> */}
        </footer>
    );
};
