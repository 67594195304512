import React from 'react';
import { HtmlAnswerQuizLessonPart } from '../../../util/lessonPart';
import { getExerciseWithExerciseId } from '../../../exercises/exercise';


export const HtmlIframeLessonPart1 = () => {
    return (<div className="container-fluid">
        <h3>Iframe</h3>
        <ul className="mt-3">
            <li><code>iframe</code> element is used to display another html document inside a html document.</li>
        </ul>

        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <head>
        <style>
            .iframe1 {
                width: 500px;
                height: 300px;
            }
        </style>
    </head>
    <body>
        <iframe class="iframe1" src="https://en.wikipedia.org/wiki/Main_Page" title="Wikipedia"></iframe>
    </body>
</html>`}
            height={"400px"}
            showOutput={true}
        ></HtmlEditor>
    </div>)
}


export const htmlIframeLessonParts = [{
    component: HtmlIframeLessonPart1 
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-iframe-1")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-iframe-2")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-iframe-3")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-iframe-4")
    }
}]
