import { getHashCode } from '../components/util/utils';


const INITIAL_STATE = {
    loginStatus: "notChecked",
    allCoursesTasks: { },
    allCourses: {},
    lessonStatuses: { },
    lessonHistories: { },
    lessonHistoryForUserId: {},
    users: [],
    timeStats: [],
    selectedDate: null,
    exerciseHistory: [],
    codeRuns: {},
    projects: {},
    httpApiRuns: {},
    validateProjectResults: {},
    allLessonContents: {},
    allExams: {},
    allExercises: {},
    orders: {},
    allBatches: {},
    allSubscriptions: null,
    loginApiAreInvalidCredentials: false,
    loginApiHasServerError: false
};

export const reduxStateUpdateLoginStatus = (state, { loginStatus, user}) => {
    return {
        ...state,
        loginStatus: loginStatus,
        user: user
    };
}

export const reduxStateUpdateLogout = (state, { }) => {
    return {
        ...state,
        loginStatus: "notLoggedIn",
        user: null,
        allSubscriptions: null,
        orders: {},
        allCoursesTasks: { },
    };
}

export const reduxStateUpdateLoginApiCredentials = (state, payload) => {
    return {
        ...state,
        loginApiAreInvalidCredentials: payload.loginApiAreInvalidCredentials,
        loginApiHasServerError: payload.loginApiHasServerError
    }
}

export const reduxStateUpdateExercise = (state, payload) => {
    return {
        ...state,
        allExercises: {
            ...state.allExercises,
            ...payload
        }
    }
}
export const reduxStateUpdateExamExam = (state, payload) => {
    return {
        ...state,
        allExams: {
            ...state.allExams,
            ...payload
        }
    }
}
export const reduxStateUpdateLessonContent = (state, payload) => {
    return {
        ...state,
        allLessonContents: {
            ...state.allLessonContents,
            ...payload
        }
    }
}

export const reduxStateUpdateMyOrders = (state, {orders}) => {
    const newState = {...state}
    newState.orders = {}
    orders.forEach(order => {
        newState.orders[order?.orderId] = order
    })
    return newState
}

export const reduxStateUpdateCourses = (state, payload) => {
    return {
        ...state,
        allCourses: {
            ...state.allCourses,
            ...payload.courses
        }
    }
}
export const reduxStateUpdateBatches = (state, payload) => {
    return {
        ...state,
        allBatches: {
            ...state.allBatches,
            ...payload.batches
        }
    }
}

export const reduxStateUpdateSubscriptions = (state, payload) => {
    return {
        ...state,
        allSubscriptions: payload.subscriptions
    }
}

export const reduxStateUpdateCourseTask = (state, { courseId, taskId, courseTask }) => {
    const newState = {...state}
    if (!newState.allCoursesTasks) {
        newState.allCoursesTasks = {}
    }
    if (!newState.allCoursesTasks[courseId]) {
        newState.allCoursesTasks[courseId] = {}
    }
    newState.allCoursesTasks[courseId] = {
        ...newState.allCoursesTasks[courseId],
        [taskId]: {
            ...newState.allCoursesTasks[courseId]?.[taskId],
            ...courseTask
        }
    }
    newState.allCoursesTasks = {...newState.allCoursesTasks}
    return newState
}

export const reduxStateUpdateAddOrder = (state, { order }) => {
    const newState = {...state}
    newState.orders[order.orderId] = order
    newState.orders = {...newState.orders}
    return newState
}

export const reduxStateUpdateCourseTasks = (state, { courseId, tasks }) => {
    const newState = {...state}
    if (!newState.allCoursesTasks) {
        newState.allCoursesTasks = {}
    }
    if (!newState.allCoursesTasks[courseId]) {
        newState.allCoursesTasks[courseId] = {}
    }
    tasks.forEach(task => {
        if (task?.courseId !== courseId) {
            return
        }
        newState.allCoursesTasks[courseId] = {
            ...newState.allCoursesTasks[courseId],
            [task.taskId]: {
                ...newState.allCoursesTasks[courseId]?.[task.taskId],
                ...task
            }
        }
    })
    newState.allCoursesTasks = {...newState.allCoursesTasks}
    return newState
}

export const reduxStateUpdateRunHttpApi = (state, { runId, httpResponseStatus, httpResponseBody }) => {
    const newState = {...state}
    newState.httpApiRuns = {
        ...newState.httpApiRuns,
        [runId]: {httpResponseStatus, httpResponseBody, httpResponseTimestampMillis: new Date().getTime()}
    }
    return newState
}

export const reduxStateUpdateCodeRunOutput = (state, { runId, runOutput }) => {
    const newState = {...state}
    newState.codeRuns[runId] = runOutput
    newState.codeRuns = {...newState.codeRuns}
    return newState
}
export const reduxStateUpdateValidateProjectResults = (state, { runId, runOutput }) => {
    const newState = {...state}
    newState.validateProjectResults[runId] = runOutput
    newState.validateProjectResults = {...newState.validateProjectResults}
    return newState
}

// export const registeredReducerFunctions = new Set()

const commonReducer = (state, action) => {
    
    if (!state) {
        state = INITIAL_STATE
    }
    const registeredReducerFunctions = [
        reduxStateUpdateLoginStatus, reduxStateUpdateLogout,
        reduxStateUpdateExercise, reduxStateUpdateExamExam, reduxStateUpdateLessonContent,
        reduxStateUpdateMyOrders, reduxStateUpdateCourses, reduxStateUpdateBatches,
        reduxStateUpdateSubscriptions, reduxStateUpdateCourseTask, reduxStateUpdateAddOrder,
        reduxStateUpdateCourseTasks, reduxStateUpdateRunHttpApi,
        reduxStateUpdateCodeRunOutput, reduxStateUpdateValidateProjectResults, reduxStateUpdateLoginApiCredentials
    ]
    const reducerFunction = registeredReducerFunctions.find(f => action.type === getHashCode(f.toString()))
    // const reducerFunction = [...registeredReducerFunctions].find(f => action.type === getHashCode(f.toString()))
    if (reducerFunction) {
        return reducerFunction(state, action.payload)
    }
    return state
};

export default commonReducer;
