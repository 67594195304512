import { applyMiddleware,  createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { watchRequests} from './common.saga';

import { combineReducers } from 'redux';
import { enableBatching } from 'redux-batched-actions';
import commonReducer from './common.reducer';



const rootReducer = enableBatching(combineReducers({
    common: commonReducer,
}));


const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

// if (process.env.NODE_ENV === 'development') {
//     const logger = createLogger({
//         collapsed: true,
//         duration: true,
//     });
//     middlewares.push(logger);
// }

const store = createStore(rootReducer, applyMiddleware(...middlewares));
sagaMiddleware.run(watchRequests);

export default store;