import React from 'react';
import { HtmlAnswerQuizLessonPart } from '../../../util/lessonPart';
import { getExerciseWithExerciseId } from '../../../exercises/exercise';


export const HtmlCodesLessonPart1 = () => {
    return (<div className="container-fluid">
        <h3>Html Codes</h3>
        <ul className="mt-3">
            <li>Some special characters in a html document are reserved for html syntax, like <code>{"<, >, /"}</code> etc.</li>
            <li>If these characters are written as they are they will disturb the html syntax of the document.</li>
            <li>So there are special codes defined to display these special characters in a document.</li>
            <li>All special codes usually start with <code>{"&"}</code> and end with <code>
                {";"}</code>. For example <code>{"&lt;"}</code> defines <code>{"<"}</code>.</li>
            <li>See the below document and observe the special codes starting with
            <code>{" &"}</code> and ending with <code>{";"}</code> are displayed in the output.</li>
        </ul>

        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <head>
    </head>
    <body>
        <p>&lt; is less than symbol </p>
        <p>&gt; is greater than symbol </p>
        <p>&amp; is ampersand symbol </p>
        <p>&quot; is double quotation symbol </p>
        <p>&apos; is cent symbol </p>
        <p>&cent; is less than symbol </p>
        <p>&pound; is pound symbol </p>
        <p>&euro; is euro symbol </p>
        <p>&copy; is copyright symbol </p>
        <p>&reg; is trademark symbol </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; just adds spaces </p>
    </body>
</html>`}
            height={"400px"}
            showOutput={true}
        ></HtmlEditor>
        <p>As you may have observed, if there are more than one consecutive spaces provided in a html document, it is still
displayed as a single space in the output. We have to use the special code <code>{"&nbsp;"}</code> to display more
 than one consecutive white spaces.</p>
    </div>)
}


export const htmlCodesLessonParts = [{
    component: HtmlCodesLessonPart1 ,
    lessonPartId: "HtmlCodesLessonPart1"
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-code-1")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-code-2")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-code-3")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-code-4")
    }
}]
