export const dj1Html = `<html lang="en"><head>
<meta charset="utf-8">
<meta http-equiv="X-UA-Compatible" content="IE=edge">
<meta name="viewport" content="width=device-width, initial-scale=1">
<meta name="ROBOTS" content="ALL">
<meta name="MSSmartTagsPreventParsing" content="true">
<meta name="Copyright" content="Django Software Foundation">
<meta name="keywords" content="Python, Django, framework, open-source">
<meta name="description" content="">


<!-- Favicons -->
<link rel="apple-touch-icon" href="https://static.djangoproject.com/img/icon-touch.e4872c4da341.png">
<link rel="icon" sizes="192x192" href="https://static.djangoproject.com/img/icon-touch.e4872c4da341.png">
<link rel="shortcut icon" href="https://static.djangoproject.com/img/favicon.6dbf28c0650e.ico">
<meta name="msapplication-TileColor" content="#113228">
<meta name="msapplication-TileImage" content="https://static.djangoproject.com/img/icon-tile.b01ac0ef9f67.png">

<title>The web framework for perfectionists with deadlines | Django</title>

<link rel="stylesheet" href="https://static.djangoproject.com/css/output.f3605340f42d.css">    
</head>

<body id="homepage" class="homepage">

<div role="banner" id="top">
<div class="container">
<a class="logo" href="https://www.djangoproject.com/">Django</a>
<p class="meta">The web framework for perfectionists with deadlines.</p>
<div role="navigation">
  <ul>
    <li>
      <a href="https://www.djangoproject.com/start/overview/">Overview</a>
    </li>
    <li>
      <a href="https://www.djangoproject.com/download/">Download</a>
    </li>
    <li>
      <a href="https://docs.djangoproject.com/">Documentation</a>
    </li>
    <li>
      <a href="https://www.djangoproject.com/weblog/">News</a>
    </li>
    <li>
      <a href="https://www.djangoproject.com/community/">Community</a>
    </li>
    <li>
      <a href="https://github.com/django/django" target="_blank" rel="noopener">Code</a>
    </li>
    <li>
      <a href="https://code.djangoproject.com/">Issues</a>
    </li>
    <li>
      <a href="https://www.djangoproject.com/foundation/">About</a>
    </li>
    <li>
      <a href="https://www.djangoproject.com/fundraising/">♥ Donate</a>
    </li>
  </ul>
</div>
</div>
</div>







 
 







  





</body></html>`