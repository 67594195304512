export const wiki2Html = ` <html class="client-nojs" lang="en" dir="ltr"><head>
	<style>.mw-footer {
		margin-left: 10em;
		margin-top: 0;
		padding: 0.75em;
	}.mw-footer {
		padding: 1.25em;
	}.mw-footer {
		direction: ltr;
	}
		.mw-body, #mw-data-after-content {
			margin-left: 10em;
		}
		.mw-content-ltr ul, .mw-content-rtl .mw-content-ltr ul {
    margin: 0.3em 0 0 1.6em;
    padding: 0;
}.vector-search-box {
    float: left;
    margin-right: 0.5em;
    margin-left: 0.5em;
}.vector-search-box {
    margin-right: 1em;
}.printfooter, .client-nojs #t-print {
    display: none;
}.mw-body, #mw-head-base, #left-navigation, #mw-data-after-content, .mw-footer {
    margin-left: 11em;
}
/* input[type="search" i] {
    appearance: auto;
    box-sizing: border-box;
    padding: 1px 2px;
} */
.vector-search-box-input {
    background-color: rgba(255,255,255,0.5);
    color: #000000;
    width: 100%;
    height: 2.15384615em;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #a2a9b1;
    border-radius: 2px;
    padding: 5px 2.15384615em 5px 0.4em;
    box-shadow: inset 0 0 0 1px transparent;
    font-family: inherit;
    font-size: 0.8125em;
    direction: ltr;
    -webkit-transition: border-color 250ms,box-shadow 250ms;
    transition: border-color 250ms,box-shadow 250ms;
    -webkit-appearance: none;
    -moz-appearance: textfield;
}
#p-logo a {
    background-position: center center;
    background-repeat: no-repeat;
    display: block;
    width: 10em;
    height: 160px;
    text-decoration: none;
}
.mw-wiki-logo {
    background-image: url(https://en.m.wikipedia.org/static/images/project-logos/enwiki.png);
}
a {
    text-decoration: none;
    color: #0645ad;
    background: none;
}
#mw-panel {
    padding-left: 0.5em;
}
#mw-panel {
    position: absolute;
    top: 0;
    width: 10em;
    left: 0;
}
#mw-head {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
}
#mw-data-after-content, .mw-footer {
    margin-left: 11em;
}
.vector-menu-portal .vector-menu-content li {
    margin: 0;
    padding: 0.25em 0;
    font-size: 0.75em;
    line-height: 1.125em;
    word-wrap: break-word;
}
li {
    text-align: -webkit-match-parent;
}
.vector-menu-portal .vector-menu-content ul {
    list-style: none none;
    margin: 0;
    padding-top: 0.3em;
}
.mw-portlet ul {
    list-style: none none;
    margin: 0;
}
ul {
    list-style-image: url(https://en.wikipedia.org/w/skins/Vector/resources/common/images/bullet-icon.svg?d4515);
}.vector-menu-portal {
    margin: 0 0.6em 0 0.7em;
    padding: 0.25em 0;
    direction: ltr;
}.vector-menu-portal .vector-menu-content li {
    margin: 0;
    padding: 0.25em 0;
    font-size: 0.75em;
    line-height: 1.125em;
    word-wrap: break-word;
}.vector-menu-portal .vector-menu-content ul {
    list-style: none none;
    margin: 0;
    padding-top: 0.3em;
}.mw-portlet ul {
    list-style: none none;
    margin: 0;
}.vector-menu-portal .vector-menu-content {
    margin-left: 0.5em;
    padding-top: 0;
}.vector-menu-portal {
    margin: 0 0.6em 0 0.7em;
    padding: 0.25em 0;
    direction: ltr;
}#p-logo a {
    background-position: center center;
    background-repeat: no-repeat;
    display: block;
    width: 10em;
    height: 160px;
    text-decoration: none;
}#p-logo {
    width: 10em;
    height: 160px;
    margin-bottom: 1em;
}#mw-panel {
    padding-left: 0.5em;
}#mw-head {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
}html, body {
    font-family: sans-serif;
}#p-personal {
    position: absolute;
    top: 6px;
    right: 0.75em;
    z-index: 100;
}#p-personal {
    right: 1em;
}#left-navigation {
    float: left;
    margin-left: 10em;
    margin-top: 2.5em;
    margin-bottom: -2.5em;
}#right-navigation {
    float: right;
    margin-top: 2.5em;
}#mw-panel {
    position: absolute;
    top: 0;
    width: 10em;
    left: 0;
}#p-logo {
    width: 10em;
    height: 160px;
    margin-bottom: 1em;
}.vector-menu-portal {
    margin: 0 0.6em 0 0.7em;
    padding: 0.25em 0;
    direction: ltr;
}.vector-menu-portal .vector-menu-content li {
    margin: 0;
    padding: 0.25em 0;
    font-size: 0.75em;
    line-height: 1.125em;
    word-wrap: break-word;
}li {
    text-align: -webkit-match-parent;
}li {
    display: list-item;
    text-align: -webkit-match-parent;
}ul {
    margin: 0.3em 0 0 1.6em;
    padding: 0;
}
.mw-wiki-logo {
    background-image: url(https://en.m.wikipedia.org/static/images/project-logos/enwiki.png);
}a {
    text-decoration: none;
    color: #0645ad;
    background: none;
}#p-personal li {
    float: left;
    margin-left: 0.75em;
    padding-top: 0.5em;
    line-height: 1.16666667;
}.vector-user-menu-legacy li {
    font-size: 0.75em;
}.mw-portlet ul {
    list-style: none none;
    margin: 0;
}li {
    margin-bottom: 0.1em;
}li {
    display: list-item;
    text-align: -webkit-match-parent;
}.vector-user-menu-legacy .vector-menu-content-list {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: baseline;
}
#p-personal ul {
    padding-left: 10em;
}h1, h2, h3, h4, h5, h6 {
    color: #000;
    margin: 0;
    padding-top: 0.5em;
    padding-bottom: 0.17em;
    overflow: hidden;
}h3, h4, h5 {
    margin-bottom: 0.3em;
}h3 {
    font-size: 128%;
}#mw-head {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
}#left-navigation {
    float: left;
    margin-left: 10em;
    margin-top: 2.5em;
    margin-bottom: -2.5em;
}#right-navigation {
    float: right;
    margin-top: 2.5em;
}.vector-menu-tabs, .vector-menu-tabs a, #mw-head .vector-menu-dropdown h3 {
    background-image: url(https://en.wikipedia.org/w/skins/Vector/resources/common/images/tab-separator.png?09d4b);
    background-image: linear-gradient(to bottom,rgba(167,215,249,0) 0,#a7d7f9 100%);
    background-repeat: no-repeat;
    background-size: 1px 100%;
}.vector-menu-tabs {
    background-position: left bottom;
    float: left;
    height: 2.5em;
    padding-left: 1px;
}nav {
    display: block;
}.vector-menu-tabs h3 {
    display: none;
}.vector-user-menu-legacy #pt-anonuserpage, .vector-user-menu-legacy #pt-userpage a {
    background-position: left 0.33333333em;
    background-image: url(https://en.wikipedia.org/w/skins/Vector/resources/skins.vector.styles.legacy/images/user-avatar.svg?b7f58);
    background-repeat: no-repeat;
    background-size: 1.16666667em 1.16666667em;
    padding-top: 0.5em;
    padding-left: 1.5em;
}.vector-menu-tabs {
    background-position: left bottom;
    float: left;
    height: 2.5em;
    padding-left: 1px;
}nav {
    display: block;
}.vector-menu-tabs h3 {
    display: none;
}.vector-menu-tabs ul {
    float: left;
    height: 100%;
    list-style: none none;
    margin: 0;
    padding: 0;
}.vector-menu-tabs .selected {
    background: #ffffff;
}.vector-menu-tabs li {
    background-image: url(https://en.wikipedia.org/w/skins/Vector/resources/common/images/tab-normal-fade.png?1cc52);
    background-image: linear-gradient(to top,#77c1f6 0,#e8f2f8 1px,#ffffff 100%);
    background-position: left bottom;
    background-repeat: repeat-x;
    float: left;
    display: block;
    height: 100%;
    margin: 0;
    padding: 0;
    line-height: 1.125em;
    white-space: nowrap;
}.vector-menu-tabs ul {
    float: left;
    height: 100%;
    list-style: none none;
    margin: 0;
    padding: 0;
}.mw-portlet ul {
    list-style: none none;
    margin: 0;
}.vector-menu-tabs .selected a, .vector-menu-tabs .selected a:visited {
    color: #202122;
    text-decoration: none;
}.vector-menu-tabs li a {
    background-position: right bottom;
    color: #0645ad;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    float: left;
    height: 3.07692308em;
    position: relative;
    padding-top: 1.25em;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 0.8125em;
    cursor: pointer;
}.vector-menu-tabs, .vector-menu-tabs a, #mw-head .vector-menu-dropdown h3 {
    background-image: url(https://en.wikipedia.org/w/skins/Vector/resources/common/images/tab-separator.png?09d4b);
    background-image: linear-gradient(to bottom,rgba(167,215,249,0) 0,#a7d7f9 100%);
    background-repeat: no-repeat;
    background-size: 1px 100%;
}.vector-menu-tabs li {
    background-image: url(https://en.wikipedia.org/w/skins/Vector/resources/common/images/tab-normal-fade.png?1cc52);
    background-image: linear-gradient(to top,#77c1f6 0,#e8f2f8 1px,#ffffff 100%);
    background-position: left bottom;
    background-repeat: repeat-x;
    float: left;
    display: block;
    height: 100%;
    margin: 0;
    padding: 0;
    line-height: 1.125em;
    white-space: nowrap;
}.vector-menu-tabs ul {
    float: left;
    height: 100%;
    list-style: none none;
    margin: 0;
    padding: 0;
}:not(:checked) > .vector-menu-checkbox {
    display: block;
}.vector-menu-checkbox {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: none;
}button, input, optgroup, select, textarea {
    margin: 0;
}
input[type="checkbox" i] {
    background-color: initial;
    cursor: default;
    appearance: auto;
    box-sizing: border-box;
    margin: 3px 3px 3px 4px;
    padding: initial;
    border: initial;
}.vector-menu-dropdown {
    direction: ltr;
    float: left;
    cursor: pointer;
    position: relative;
    line-height: 1.125em;
}
.vector-menu-dropdown h3:after {
    content: '';
    background-image: url(https://en.wikipedia.org/w/skins/Vector/resources/common/images/arrow-down.svg?9426f);
    background-position: 100% 50%;
    background-repeat: no-repeat;
    position: absolute;
    top: 0.76923077em;
    right: 8px;
    bottom: 0;
    width: 1.23076923em;
    opacity: 0.84;
}.vector-menu-dropdown {
    direction: ltr;
    float: left;
    cursor: pointer;
    position: relative;
    line-height: 1.125em;
}.vector-menu-dropdown h3 {
    color: #54595d;
    position: relative;
    display: block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}.vector-menu-dropdown-noicon h3 {
    padding-top: 1.25em;
    padding-left: 8px;
    padding-right: 1.84615385em;
    font-size: 0.8125em;
    font-weight: normal;
}#mw-head .vector-menu-dropdown h3 {
    background-position: right bottom;
    float: left;
    height: 3.07692308em;
    margin: 0 -1px 0 0;
    padding-right: 1.84615385em;
}.vector-menu-tabs, .vector-menu-tabs a, #mw-head .vector-menu-dropdown h3 {
    background-image: url(https://en.wikipedia.org/w/skins/Vector/resources/common/images/tab-separator.png?09d4b);
    background-image: linear-gradient(to bottom,rgba(167,215,249,0) 0,#a7d7f9 100%);
    background-repeat: no-repeat;
    background-size: 1px 100%;
}.vector-menu-dropdown .vector-menu-content {
    background-color: #ffffff;
    list-style: none none;
    min-width: 100%;
    position: absolute;
    top: 2.5em;
    left: -1px;
    margin: 0;
    border: 1px solid #a2a9b1;
    border-top-width: 0;
    padding: 0;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%);
    text-align: left;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 100ms;
    transition: opacity 100ms;
    z-index: 3;
}button, input, optgroup, select, textarea {
    margin: 0;
}button, input, optgroup, select, textarea {
    margin: 0;
}.searchButton {
    background-color: transparent;
    position: absolute;
    top: 1px;
    bottom: 1px;
    right: 1px;
    min-width: 28px;
    width: 2.15384615em;
    border: 0;
    padding: 0;
    cursor: pointer;
    font-size: 0.8125em;
    direction: ltr;
    text-indent: -99999px;
    white-space: nowrap;
    overflow: hidden;
    z-index: 1;
}.searchButton[name='go'] {
    background: no-repeat center / 1.23076923em url(https://en.wikipedia.org/w/skins/Vector/resources/common/images/search.svg?bbf78);
    opacity: 0.67;
}.vector-search-box-inner {
    min-width: 5em;
    width: 13.2em;
    width: 20vw;
    max-width: 20em;
}.vector-search-box-inner {
    position: relative;
    height: 100%;
}form {
    border: 0;
    margin: 0;
}.vector-search-box form {
    margin: 0.5em 0 0;
}.vector-search-box h3 {
    display: block;
    position: absolute !important;
    clip: rect(1px,1px,1px,1px);
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    overflow: hidden;
}.searchButton[name='go'] {
    background: no-repeat center / 1.23076923em url(https://en.wikipedia.org/w/skins/Vector/resources/common/images/search.svg?bbf78);
    opacity: 0.67;
}.mw-parser-output #mp-welcome {
    font-size: 162%;
    padding: 0.1em;
}.mw-parser-output #mp-welcomecount {
    margin: 0.4em;
    width: 22em;
    text-align: center;
}.mw-parser-output #mp-topbanner, .mw-parser-output .mp-h2, .mw-parser-output #mp-left {
    color: #000;
}.mw-parser-output #mp-topbanner {
    position: relative;
    box-sizing: border-box;
    min-width: 47em;
    border-color: #ddd;
    background-color: #f9f9f9;
    white-space: nowrap;
}.mw-content-ltr {
    direction: ltr;
}.vector-body {
    font-size: 0.875em;
    font-size: calc(1em * 0.875);
    line-height: 1.6;
}.mw-body, .parsoid-body {
    background-color: #ffffff;
    color: #202122;
    direction: ltr;
    padding: 1em;
}b {
    font-weight: bold;
}ul {
    list-style-image: url(https://en.wikipedia.org/w/skins/Vector/resources/common/images/bullet-icon.svg?d4515);
}.mw-parser-output #mp-topbanner, .mw-parser-output .mp-h2, .mw-parser-output #mp-left {
    color: #000;
}.mw-parser-output #mp-upper {
    width: 100%;
    margin-top: 4px;
    margin-bottom: 0;
    border-spacing: 0;
    border-collapse: separate;
}table {
    font-size: 100%;
}table {
    border-collapse: separate;
    text-indent: initial;
    border-spacing: 2px;
}.mw-parser-output #mp-upper {
    width: 100%;
    margin-top: 4px;
    margin-bottom: 0;
    border-spacing: 0;
    border-collapse: separate;
}.mw-parser-output #mp-topbanner, .mw-parser-output .mp-h2, .mw-parser-output #mp-left {
    color: #000;
}ul {
    list-style-image: url(https://en.wikipedia.org/w/skins/Vector/resources/common/images/bullet-icon.svg?d4515);
}.mw-parser-output div.hlist.inline ul, .mw-parser-output div.hlist.inline li, .mw-parser-output div.hlist.inline {
    display: inline;
}.hlist dd, .hlist dt, .hlist li {
    margin: 0;
    display: inline;
}li {
    margin-bottom: 0.1em;
}#footer-info li {
    line-height: 1.4;
}.mw-footer li {
    color: #202122;
    margin: 0;
    padding: 0.5em 0;
    font-size: 0.75em;
}.mw-footer ul {
    list-style: none none;
    margin: 0;
    padding: 0;
}.mw-footer {
    direction: ltr;
}h1, h2, h3, h4, h5, h6 {
    color: #000;
    margin: 0;
    padding-top: 0.5em;
    padding-bottom: 0.17em;
    overflow: hidden;
}h1, h2 {
    margin-bottom: 0.6em;
    border-bottom: 1px solid #a2a9b1;
}h2 {
    font-size: 150%;
    font-weight: normal;
}#mw-navigation h2 {
    position: absolute;
    top: -9999px;
}.mw-footer {
    direction: ltr;
}.mw-footer ul {
    list-style: none none;
    margin: 0;
    padding: 0;
}ul {
    list-style-image: url(https://en.wikipedia.org/w/skins/Vector/resources/common/images/bullet-icon.svg?d4515);
}#footer-info li {
    line-height: 1.4;
}.mw-footer li {
    color: #202122;
    margin: 0;
    padding: 0.5em 0;
    font-size: 0.75em;
}li {
    display: list-item;
    text-align: -webkit-match-parent;
}#footer-places li {
    float: left;
    margin-right: 1em;
    line-height: 2;
}img {
    border: 0;
    vertical-align: middle;
}img {
    border: 0;
}#footer-icons li {
    float: left;
    margin-left: 0.5em;
    line-height: 2;
    text-align: right;
}#footer-icons {
    float: right;
}.mw-parser-output .wikipedia-languages ul a {
    white-space: nowrap;
}.mw-parser-output a.external {
    color: #36b;
}.mw-parser-output a.extiw, .mw-parser-output a.external {
    color: #3366bb;
}ul ul {
    list-style-type: circle;
}ul {
    list-style-image: url(https://en.wikipedia.org/w/skins/Vector/resources/common/images/bullet-icon.svg?d4515);
}.mw-parser-output .wikipedia-languages>ul {
    list-style: none;
    text-align: center;
    clear: both;
}.mw-content-ltr {
    direction: ltr;
}.mw-body {
    padding: 1.25em 1.5em 1.5em 1.5em;
}.mw-body {
    margin-top: -1px;
    border: 1px solid #a7d7f9;
    border-right-width: 0;
}body {
    background-color: #f6f6f6;
    overflow-y: scroll;
}html, body {
    height: 100%;
}body {
    margin: 0;
}html {
    font-size: 100%;
}#mw-page-base {
    background-color: #fbfbfb;
    background-image: linear-gradient(to bottom,#ffffff 50%,#f6f6f6 100%);
    background-position: bottom left;
    height: 5em;
}.emptyPortlet {
    display: none;
}.page-Main_Page #deleteconfirm, .page-Main_Page #t-cite, .page-Main_Page #footer-info-lastmod, .action-view.page-Main_Page #siteSub, .action-view.page-Main_Page #contentSub, .action-view.page-Main_Page #contentSub2, .action-view.page-Main_Page .firstHeading {
    display: none !important;
}body.skin-vector-legacy #siteSub {
    font-size: 92%;
}.mw-jump-link:not(:focus) {
    display: block;
    position: absolute !important;
    clip: rect(1px,1px,1px,1px);
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    overflow: hidden;
}.vector-user-menu-legacy #pt-anonuserpage {
    color: #54595d;
}#p-personal li {
    float: left;
    margin-left: 0.75em;
    padding-top: 0.5em;
    line-height: 1.16666667;
}.vector-user-menu-legacy li {
    font-size: 0.75em;
}.searchButton[name='go'] {
    background: no-repeat center / 1.23076923em url(https://en.wikipedia.org/w/skins/Vector/resources/common/images/search.svg?bbf78);
    opacity: 0.67;
}.searchButton {
    background-color: transparent;
    position: absolute;
    top: 1px;
    bottom: 1px;
    right: 1px;
    min-width: 28px;
    width: 2.15384615em;
    border: 0;
    padding: 0;
    cursor: pointer;
    font-size: 0.8125em;
    direction: ltr;
    text-indent: -99999px;
    white-space: nowrap;
    overflow: hidden;
    z-index: 1;
}button, input, optgroup, select, textarea {
    margin: 0;
}
#p-logo + .mw-portlet h3 {
    display: none;
}.mw-portlet h3 {
    display: none;
}.vector-menu-portal h3 {
    display: block;
    background-image: url(https://en.wikipedia.org/w/skins/Vector/resources/common/images/portal-separator.png?4ab04);
    background-image: linear-gradient(to right,rgba(200,204,209,0) 0,#c8ccd1 33%,#c8ccd1 66%,rgba(200,204,209,0) 100%);
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    color: #54595d;
    margin: 0.5em 0 0 0.66666667em;
    border: 0;
    padding: 0.25em 0;
    font-size: 0.75em;
    font-weight: normal;
    cursor: default;
}.flaggedrevs_draft_synced, .flaggedrevs_stable_synced, #t-upload, .mw-special-Book #coll-downloadbox {
    display: none;
}ul {
    list-style-image: url(https://en.m.wikipedia.org/w/skins/Vector/resources/common/images/bullet-icon.svg?d4515);
}ul ul {
    list-style-type: circle;
}.mw-parser-output .wikipedia-languages>ul {
    list-style: none;
    text-align: center;
    clear: both;
}.mw-parser-output .mp-h2, body.skin-timeless .mw-parser-output .mp-h2 {
    margin: 0.5em;
    padding: 0.2em 0.4em;
    font-size: 120%;
    font-weight: bold;
    font-family: inherit;
}.mw-parser-output #mp-topbanner, .mw-parser-output .mp-h2, .mw-parser-output #mp-left {
    color: #000;
}.vector-body h2 {
    margin-top: 1em;
    font-size: 1.5em;
}.mw-body h1, .mw-body-content h1, .mw-body-content h2 {
    margin-bottom: 0.25em;
    padding: 0;
    font-family: 'Linux Libertine','Georgia','Times',serif;
    line-height: 1.3;
}h2 {
    font-size: 150%;
    font-weight: normal;
}.mw-parser-output #mp-upper {
    width: 100%;
    margin-top: 4px;
    margin-bottom: 0;
    border-spacing: 0;
    border-collapse: separate;
}.vector-body p {
    margin: 0.5em 0;
}.mw-parser-output #mp-portals {
    position: absolute;
    right: -1em;
    top: 50%;
    margin-top: -2.4em;
    width: 38%;
    min-width: 25em;
    font-size: 95%;
}ul {
    list-style-image: url(https://en.m.wikipedia.org/w/skins/Vector/resources/common/images/bullet-icon.svg?d4515);
}ul {
    list-style-type: disc;
}.mw-parser-output #mp-topbanner, .mw-parser-output .mp-h2, .mw-parser-output #mp-left {
    color: #000;
}.mw-parser-output #mp-topbanner {
    position: relative;
    box-sizing: border-box;
    min-width: 47em;
    border-color: #ddd;
    background-color: #f9f9f9;
    white-space: nowrap;
}.mw-parser-output #mp-portals li {
    position: absolute;
    left: 0;
    top: 0;
}li {
    margin-bottom: 0.1em;
}li {
    display: list-item;
    text-align: -webkit-match-parent;
}ul {
    list-style-image: url(https://en.m.wikipedia.org/w/skins/Vector/resources/common/images/bullet-icon.svg?d4515);
}ul {
    list-style-type: disc;
}.mw-parser-output div.hlist.inline ul, .mw-parser-output div.hlist.inline li, .mw-parser-output div.hlist.inline {
    display: inline;
}.hlist dd, .hlist dt, .hlist li {
    margin: 0;
    display: inline;
}.mw-parser-output div.hlist.inline ul, .mw-parser-output div.hlist.inline li, .mw-parser-output div.hlist.inline {
    display: inline;
}.hlist dl, .hlist ol, .hlist ul {
    margin: 0;
    padding: 0;
}ul {
    margin: 0.3em 0 0 1.6em;
    padding: 0;
}ul {
    list-style-image: url(https://en.m.wikipedia.org/w/skins/Vector/resources/common/images/bullet-icon.svg?d4515);
}.hlist dd:after, .hlist li:after {
    content: " · ";
    font-weight: bold;
}.hlist dd:last-child:after, .hlist dt:last-child:after, .hlist li:last-child:after {
    content: none;
}.mw-content-ltr .thumbcaption {
    text-align: left;
}.thumbcaption {
    border: 0;
    font-size: 94%;
}.thumbcaption {
    text-align: left;
    line-height: 1.4em;
    padding: 3px;
}div.thumbinner {
    border: 1px solid #c8ccd1;
    background-color: #f8f9fa;
    font-size: 94%;
}div.thumbinner {
    padding: 3px;
    text-align: center;
    overflow: hidden;
}.mw-parser-output #mp-topbanner, .mw-parser-output .mp-h2, .mw-parser-output #mp-left {
    color: #000;
}
	</style>
<meta charset="UTF-8">
<title>Wikipedia, the free encyclopedia</title>
<!-- <link rel="stylesheet" href="https://en.wikipedia.org/w/load.php?lang=en&amp;modules=ext.uls.interlanguage%7Cext.visualEditor.desktopArticleTarget.noscript%7Cext.wikimediaBadges%7Cskins.vector.styles.legacy&amp;only=styles&amp;skin=vector"/>
<meta name="ResourceLoaderDynamicStyles" content=""/>
<link rel="stylesheet" href="https://en.wikipedia.org/w/load.php?lang=en&amp;modules=site.styles&amp;only=styles&amp;skin=vector"/> -->
<meta name="generator" content="MediaWiki 1.38.0-wmf.17">
<meta name="referrer" content="origin">
<meta name="referrer" content="origin-when-crossorigin">
<meta name="referrer" content="origin-when-cross-origin">
<meta name="format-detection" content="telephone=no">
<meta property="og:title" content="Wikipedia, the free encyclopedia">
<meta property="og:type" content="website">
<link rel="preconnect" href="https://upload.wikimedia.org">
<link rel="alternate" media="only screen and (max-width: 720px)" href="https://en.m.wikipedia.org/wiki/Main_Page">
<link rel="alternate" type="application/atom+xml" title="Wikipedia picture of the day feed" href="https://en.wikipedia.org/w/api.php?action=featuredfeed&amp;feed=potd&amp;feedformat=atom">
<link rel="alternate" type="application/atom+xml" title="Wikipedia featured articles feed" href="https://en.wikipedia.org/w/api.php?action=featuredfeed&amp;feed=featured&amp;feedformat=atom">
<link rel="alternate" type="application/atom+xml" title="Wikipedia &quot;On this day...&quot; feed" href="https://en.wikipedia.org/w/api.php?action=featuredfeed&amp;feed=onthisday&amp;feedformat=atom">
<link rel="apple-touch-icon" href="https://en.wikipedia.org/static/apple-touch/wikipedia.png">
<link rel="shortcut icon" href="https://en.wikipedia.org/static/favicon/wikipedia.ico">
<link rel="search" type="application/opensearchdescription+xml" href="https://en.wikipedia.org/w/opensearch_desc.php" title="Wikipedia (en)">
<link rel="EditURI" type="application/rsd+xml" href="https://en.wikipedia.org/w/api.php?action=rsd">
<link rel="license" href="https://creativecommons.org/licenses/by-sa/3.0/">
<link rel="canonical" href="https://en.wikipedia.org/wiki/Main_Page">
<link rel="dns-prefetch" href="https://meta.wikimedia.org">
<link rel="dns-prefetch" href="https://login.wikimedia.org">
</head>
<body class="mediawiki ltr sitedir-ltr mw-hide-empty-elt ns-0 ns-subject page-Main_Page rootpage-Main_Page skin-vector action-view skin-vector-legacy"><div id="mw-page-base" class="noprint"></div>
<div id="mw-head-base" class="noprint"></div>

<div id="mw-data-after-content">
	<div class="read-more-container"></div>
</div>

<div id="mw-navigation">
	<h2>Navigation menu</h2>
	<div id="mw-head">
		<nav id="p-personal" class="mw-portlet mw-portlet-personal vector-user-menu-legacy vector-menu" aria-labelledby="p-personal-label" role="navigation">
	<h3 id="p-personal-label" aria-label="" class="vector-menu-heading">
		<span class="vector-menu-heading-label">Personal tools</span>
	</h3>
	<div class="vector-menu-content">
		
		<ul class="vector-menu-content-list"><li id="pt-anonuserpage" class="mw-list-item"><span>Not logged in</span></li><li id="pt-anontalk" class="mw-list-item"><a href="https://en.wikipedia.org/wiki/Special:MyTalk" title="Discussion about edits from this IP address [n]" accesskey="n"><span>Talk</span></a></li><li id="pt-anoncontribs" class="mw-list-item"><a href="https://en.wikipedia.org/wiki/Special:MyContributions" title="A list of edits made from this IP address [y]" accesskey="y"><span>Contributions</span></a></li><li id="pt-createaccount" class="mw-list-item"><a href="https://en.wikipedia.org/w/index.php?title=Special:CreateAccount&amp;returnto=Main+Page" title="You are encouraged to create an account and log in; however, it is not mandatory"><span>Create account</span></a></li><li id="pt-login" class="mw-list-item"><a href="https://en.wikipedia.org/w/index.php?title=Special:UserLogin&amp;returnto=Main+Page" title="You're encouraged to log in; however, it's not mandatory. [o]" accesskey="o"><span>Log in</span></a></li></ul>
		
	</div>
</nav>


		<div id="right-navigation">
			<nav id="p-views" class="mw-portlet mw-portlet-views vector-menu vector-menu-tabs" aria-labelledby="p-views-label" role="navigation">
	<h3 id="p-views-label" aria-label="" class="vector-menu-heading">
		<span class="vector-menu-heading-label">Views</span>
	</h3>
	<div class="vector-menu-content">
		
		<ul class="vector-menu-content-list"><li id="ca-view" class="selected mw-list-item"><a href="https://en.wikipedia.org/wiki/Main_Page"><span>Read</span></a></li><li id="ca-viewsource" class="mw-list-item"><a href="https://en.wikipedia.org/w/index.php?title=Main_Page&amp;action=edit" title="This page is protected.
You can view its source [e]" accesskey="e"><span>View source</span></a></li><li id="ca-history" class="mw-list-item"><a href="https://en.wikipedia.org/w/index.php?title=Main_Page&amp;action=history" title="Past revisions of this page [h]" accesskey="h"><span>View history</span></a></li></ul>
		
	</div>
</nav>

			<nav id="p-cactions" class="mw-portlet mw-portlet-cactions emptyPortlet vector-menu-dropdown-noicon vector-menu vector-menu-dropdown" aria-labelledby="p-cactions-label" role="navigation" title="More options">
	<input type="checkbox" id="p-cactions-checkbox" role="button" aria-haspopup="true" data-event-name="ui.dropdown-p-cactions" class="vector-menu-checkbox" aria-labelledby="p-cactions-label">
	<h3 id="p-cactions-label" aria-label="" class="vector-menu-heading">
		<span class="vector-menu-heading-label">More</span>
			<span class="vector-menu-checkbox-expanded">expanded</span>
			<span class="vector-menu-checkbox-collapsed">collapsed</span>
	</h3>
	<div class="vector-menu-content">
		
		<ul class="vector-menu-content-list"></ul>
		
	</div>
</nav>

			<div id="p-search" role="search" class=" vector-search-box">
	<div>
			<h3>
				<label for="searchInput">Search</label>
			</h3>
		<form action="/w/index.php" id="searchform" class="vector-search-box-form">
			<div id="simpleSearch" class="vector-search-box-inner" data-search-loc="header-navigation">
				<input class="vector-search-box-input" type="search" name="search" placeholder="Search Wikipedia" aria-label="Search Wikipedia" autocapitalize="sentences" title="Search Wikipedia [f]" accesskey="f" id="searchInput">
				<input type="hidden" name="title" value="Special:Search">
				<input id="mw-searchButton" class="searchButton mw-fallbackSearchButton" type="submit" name="fulltext" title="Search Wikipedia for this text" value="Search">
				<input id="searchButton" class="searchButton" type="submit" name="go" title="Go to a page with this exact name if it exists" value="Go">
			</div>
		</form>
	</div>
</div>

		</div>
	</div>
	


</div>


</body></html>`