import { PureComponent } from "react"

class PloviIconInternal extends PureComponent {
    render = () => {
        return <svg width={`${this.props.width || 153}`}
                // height={`${56*((this.props.width || 153)/153)}`}
                viewBox="0 0 153 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 30.216C20 26.76 19.1893 24.0933 17.568 22.216C15.9467 20.296 13.8347 19.336 11.232
                19.336C9.78133 19.336 8.62933 19.4 7.776 19.528C6.96533 19.656 6.32533 19.8053 5.856 
                19.976V38.728C6.45333 39.2827 7.328 39.7947 8.48 40.264C9.632 40.7333 10.8267 40.968 
                12.064 40.968C13.472 40.968 14.6667 40.6907 15.648 40.136C16.672 39.5813 17.504 38.8347
                18.144 37.896C18.784 36.9147 19.2533 35.784 19.552 34.504C19.8507 33.1813 20 31.752 
                20 30.216ZM25.44 30.216C25.44 32.4773 25.1413 34.568 24.544 36.488C23.9893 38.3653 
                23.1573 39.9867 22.048 41.352C20.9813 42.7173 19.6587 43.784 18.08 44.552C16.5013 
                45.32 14.7093 45.704 12.704 45.704C11.1253 45.704 9.73867 45.512 8.544 45.128C7.34933
                44.744 6.45333 44.3173 5.856 43.848V55.56H0.544V16.2C1.696 15.8587 3.168 15.5173
                4.96 15.176C6.79467 14.8347 8.88533 14.664 11.232 14.664C13.3653 14.664 15.3067 
                15.0267 17.056 15.752C18.8053 16.4773 20.2987 17.5227 21.536 18.888C22.7733 20.2107
                23.7333 21.832 24.416 23.752C25.0987 25.672 25.44 27.8267 25.44 30.216ZM49.632
                45.704C47.7547 45.704 46.1973 45.448 44.96 44.936C43.7227 44.424 42.7413 43.656 
                42.016 42.632C41.2907 41.608 40.7787 40.3493 40.48 38.856C40.1813 37.32 40.032 
                35.5493 40.032 33.544V5.448H31.456V0.967999H45.28V33.544C45.28 35.0373 45.3653
                36.2533 45.536 37.192C45.7493 38.1307 46.048 38.8987 46.432 39.496C46.8587
                40.0507 47.3707 40.4347 47.968 40.648C48.5653 40.8613 49.2693 40.968 50.08 
                40.968C51.3173 40.968 52.4693 40.8187 53.536 40.52C54.6027 40.2213 55.4347 39.9013
                56.032 39.56L56.8 44.04C56.544 44.168 56.1813 44.3387 55.712 44.552C55.2427 
                44.7227 54.688 44.8933 54.048 45.064C53.408 45.2347 52.704 45.384 51.936 45.512C51.2107
                45.64 50.4427 45.704 49.632 45.704ZM89.44 30.152C89.44 32.4987 89.0987
                34.632 88.416 36.552C87.776 38.4293 86.8587 40.0507 85.664 41.416C84.4693 
                42.7813 83.04 43.848 81.376 44.616C79.712 45.3413 77.8987 45.704 75.936 
                45.704C74.016 45.704 72.224 45.3413 70.56 44.616C68.9387 43.848 67.5307
                42.7813 66.336 41.416C65.1413 40.0507 64.2027 38.4293 63.52 36.552C62.88 34.632
                62.56 32.4987 62.56 30.152C62.56 27.8053 62.88 25.672 63.52 23.752C64.2027
                21.832 65.1413 20.2107 66.336 18.888C67.5307 17.5227 68.9387 16.4773
                70.56 15.752C72.224 14.984 74.016 14.6 75.936 14.6C77.8987 14.6
                79.712 14.984 81.376 15.752C83.04 16.4773 84.4693 17.5227 85.664 18.888C86.8587 
                20.2107 87.776 21.832 88.416 23.752C89.0987 25.672 89.44 27.8053 89.44
                30.152ZM84 30.152C84 26.7813 83.2747 24.136 81.824 22.216C80.3733 20.2533
                78.4107 19.272 75.936 19.272C73.504 19.272 71.5627 20.2533 70.112
                22.216C68.704 24.136 68 26.7813 68 30.152C68 33.48 68.704 36.1253 70.112
                38.088C71.5627 40.008 73.504 40.968 75.936 40.968C78.4107 40.968 80.3733 40.008
                81.824 38.088C83.2747 36.1253 84 33.48 84 30.152ZM122.08 15.304C121.312
                18.0773 120.437 20.808 119.456 23.496C118.475 26.1413 117.472 28.744
                116.448 31.304C115.424 33.8213 114.379 36.232 113.312 38.536C112.245
                40.84 111.221 42.9947 110.24 45H105.44C103.435 40.9893 101.408 36.424
                99.36 31.304C97.312 26.184 95.4987 20.8507 93.92 15.304H99.68C100.192 
                17.1813 100.768 19.1653 101.408 21.256C102.091 23.3467 102.795 25.4373
                103.52 27.528C104.245 29.6187 104.971 31.6453 105.696 33.608C106.464
                35.528 107.211 37.256 107.936 38.792C108.661 37.256 109.408 35.528
                110.176 33.608C110.987 31.6453 111.776 29.6187 112.544 27.528C113.312
                25.4373 114.037 23.3467 114.72 21.256C115.445 19.1653 116.064 17.1813
                116.576 15.304H122.08ZM137.376 10.696C136.267 10.696 135.307 10.312 
                134.496 9.544C133.685 8.776 133.28 7.73066 133.28 6.408C133.28 5.08533 
                133.685 4.04 134.496 3.272C135.307 2.504 136.267 2.12 137.376 2.12C138.528
                2.12 139.488 2.504 140.256 3.272C141.067 4.04 141.472 5.08533 141.472
                6.408C141.472 7.73066 141.067 8.776 140.256 9.544C139.488 10.312 
                138.528 10.696 137.376 10.696ZM136.032 19.72H127.456V15.304H141.28V33.544C141.28 
                36.488 141.685 38.472 142.496 39.496C143.307 40.4773 144.523 40.968 146.144 40.968C147.381
                40.968 148.512 40.8187 149.536 40.52C150.603 40.2213 151.435 39.9013 152.032 39.56L152.8
                44.04C152.544 44.168 152.181 44.3387 151.712 44.552C151.243 44.7227 150.688 44.8933 
                150.048 45.064C149.451 45.2347 148.768 45.384 148 45.512C147.275 45.64 146.507 45.704 
                145.696 45.704C143.819 45.704 142.261 45.448 141.024 44.936C139.787 44.424 138.784
                43.656 138.016 42.632C137.291 41.608 136.779 40.3493 136.48 38.856C136.181 37.32
                136.032 35.5493 136.032 33.544V19.72Z" fill={this.props.color || "black"}/>
        </svg>
    }

}
export const PloviIcon = PloviIconInternal