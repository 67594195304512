export const htmlTable4Text = `<div style="font-family: arial;">
<h2>Physical characteristics</h2>
<p>The Moon is a very slightly <a href="/wiki/Scalene_ellipsoid"  >scalene ellipsoid</a> due to tidal stretching, with its long axis displaced 30° from facing the Earth, due to gravitational anomalies from impact basins. Its shape is more elongated than current tidal forces can account for. This 'fossil bulge' indicates that the Moon solidified when it orbited at half its current distance to the Earth, and that it is now too cold for its shape to adjust to its orbit.
</p>
<h3>Internal structure</h3>
<p style="font-style: italic;" >Main article: <a href="/wiki/Internal_structure_of_the_Moon" >Internal structure of the Moon</a></p>
<table  style="margin-left:1em;text-align:center;float: right;border:1px solid grey;border-collapse:collapse;background-color: #eaecf0;">
<caption>Lunar surface chemical composition
</caption>
<tbody><tr style="border:1px solid grey;">
<th rowspan="2" valign="top" style="border:1px solid grey;">Compound</th>
<th rowspan="2" valign="top" style="border:1px solid grey;">Formula</th>
<th colspan="2" style="border:1px solid grey;">Composition
</th></tr>
<tr style="border:1px solid grey;">
<th style="font-size:smaller">Maria</th>
<th style="font-size:smaller">Highlands
</th></tr>
<tr style="border:1px solid grey;">
<td style="text-align:left"><a href="/wiki/Silicon_dioxide" >silica</a></td>
<td style="border:1px solid grey;">SiO<sub>2</sub></td>
<td style="border:1px solid grey;">45.4%</td>
<td style="border:1px solid grey;">45.5%
</td></tr>
<tr style="border:1px solid grey;">
<td style="text-align:left"><a href="/wiki/Aluminium_oxide" >alumina</a></td>
<td style="border:1px solid grey;">Al<sub>2</sub>O<sub>3</sub></td>
<td style="border:1px solid grey;">14.9%</td>
<td style="border:1px solid grey;">24.0%
</td></tr>
<tr style="border:1px solid grey;">
<td style="text-align:left"><a href="/wiki/Calcium_oxide" >lime</a></td>
<td style="border:1px solid grey;">CaO</td>
<td style="border:1px solid grey;">11.8%</td>
<td style="border:1px solid grey;">15.9%
</td></tr>
<tr style="border:1px solid grey;">
<td style="text-align:left"><a href="/wiki/Iron(II)_oxide" >iron(II) oxide</a></td>
<td style="border:1px solid grey;">FeO</td>
<td style="border:1px solid grey;">14.1%</td>
<td style="border:1px solid grey;">5.9%
</td></tr>
<tr style="border:1px solid grey;">
<td style="text-align:left"><a href="/wiki/Magnesium_oxide" >magnesia</a></td>
<td style="border:1px solid grey;">MgO</td>
<td style="border:1px solid grey;">9.2%</td>
<td style="border:1px solid grey;">7.5%
</td></tr>
<tr style="border:1px solid grey;">
<td style="text-align:left"><a href="/wiki/Titanium_dioxide" >titanium dioxide</a></td>
<td style="border:1px solid grey;">TiO<sub>2</sub></td>
<td style="border:1px solid grey;">3.9%</td>
<td style="border:1px solid grey;">0.6%
</td></tr>
<tr style="border:1px solid grey;">
<td style="text-align:left"><a href="/wiki/Sodium_oxide" >sodium oxide</a></td>
<td style="border:1px solid grey;">Na<sub>2</sub>O</td>
<td style="border:1px solid grey;">0.6%</td>
<td style="border:1px solid grey;">0.6%
</td></tr>
<tr style="border:1px solid grey;">
<th colspan="2">&#160;</th>
<th>99.9%</th>
<th>100.0%
</th></tr></tbody></table>
<p>The Moon is a <a href="/wiki/Planetary_differentiation" >differentiated</a> body in <a href="/wiki/Hydrostatic_equilibrium" >hydrostatic equilibrium</a>. It has a <a href="/wiki/Geochemistry" >geochemically</a> distinct <a href="/wiki/Crust_(geology)" >crust</a>, <a href="/wiki/Mantle_(geology)" >mantle</a>, and <a href="/wiki/Planetary_core" >core</a>. The Moon has a solid iron-rich inner core with a radius possibly as small as 240 kilometres (150&#160;mi) and a fluid outer core primarily made of liquid iron with a radius of roughly 300 kilometres (190&#160;mi). Around the core is a partially molten boundary layer with a radius of about 500 kilometres (310&#160;mi). This structure is thought to have developed through the <a href="/wiki/Fractional_crystallization_(geology)" >fractional crystallization</a> of a global magma ocean shortly after the Moon's formation 4.5&#160;billion years ago.
</p><p>Crystallization of this magma ocean would have created a <a href="/wiki/Mafic" >mafic</a> mantle from the <a href="/wiki/Precipitation_(chemistry)" >precipitation</a> and sinking of the minerals <a href="/wiki/Olivine" >olivine</a>, <a href="/wiki/Clinopyroxene"  >clinopyroxene</a>, and <a href="/wiki/Orthopyroxene"  >orthopyroxene</a>; after about three-quarters of the magma ocean had crystallised, lower-density <a href="/wiki/Plagioclase" >plagioclase</a> minerals could form and float into a crust atop. The final liquids to crystallise would have been initially sandwiched between the crust and mantle, with a high abundance of <a href="/wiki/Compatibility_(geochemistry)" >incompatible</a> and heat-producing elements. Consistent with this perspective, geochemical mapping made from orbit suggests a crust of mostly <a href="/wiki/Anorthosite" >anorthosite</a>. The <a href="/wiki/Moon_rock" >Moon rock</a> samples of the flood lavas that erupted onto the surface from partial melting in the mantle confirm the mafic mantle composition, which is more iron-rich than that of Earth. The crust is on average about 50 kilometres (31&#160;mi) thick.
</p><p>The Moon is the second-densest satellite in the Solar System, after <a href="/wiki/Io_(moon)" >Io</a>. However, the inner core of the Moon is small, with a radius of about 350 kilometres (220&#160;mi) or less, around 20% of the radius of the Moon. Its composition is not well understood, but is probably metallic iron alloyed with a small amount of sulfur and nickel; analyses of the Moon's time-variable rotation suggest that it is at least partly molten. The pressure at the lunar core is estimated to be 5&#160;<a href="/wiki/Pascal_(unit)" >GPa</a>.
</p>
<h4>Magnetic field</h4>
<p role="note" style="font-style: italic;">Main article: <a href="/wiki/Magnetic_field_of_the_Moon" >Magnetic field of the Moon</a></p>
<p>The Moon has an external <a href="/wiki/Magnetic_field" >magnetic field</a> of generally less than 0.2 <a href="/wiki/Tesla_(unit)" >nanoteslas</a>, or less than one hundred thousandth <a href="/wiki/Earth%27s_magnetic_field" >that of Earth</a>. The Moon does not currently have a global <a href="/wiki/Dipole" >dipolar</a> magnetic field and only has crustal magnetization likely acquired early in its history when a dynamo was still operating. However, early in its history, 4 billion years ago, its magnetic field strength was likely close to that of Earth today. This early dynamo field apparently expired by about one billion years ago, after the lunar core had completely crystallized. Theoretically, some of the remnant magnetization may originate from transient magnetic fields generated during large impacts through the expansion of plasma clouds. These clouds are generated during large impacts in an ambient magnetic field. This is supported by the location of the largest crustal magnetizations situated near the <a href="/wiki/Antipodes" >antipodes</a> of the giant impact basins.
</p>
</div>`