export const dj5Html = `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="ROBOTS" content="ALL" />
    <meta name="MSSmartTagsPreventParsing" content="true" />
    <meta name="Copyright" content="Django Software Foundation" />
    <meta name="keywords" content="Python, Django, framework, open-source" />
    <meta name="description" content="" />

    
    <!-- Favicons -->
    <link rel="apple-touch-icon" href="https://static.djangoproject.com/img/icon-touch.e4872c4da341.png">
    <link rel="icon" sizes="192x192" href="https://static.djangoproject.com/img/icon-touch.e4872c4da341.png">
    <link rel="shortcut icon" href="https://static.djangoproject.com/img/favicon.6dbf28c0650e.ico">
    <meta name="msapplication-TileColor" content="#113228">
    <meta name="msapplication-TileImage" content="https://static.djangoproject.com/img/icon-tile.b01ac0ef9f67.png">

    <title>The web framework for perfectionists with deadlines | Django</title>

    <link rel="stylesheet" href="https://static.djangoproject.com/css/output.f3605340f42d.css" >    
  </head>

  <body id="homepage" class="homepage">

    <div role="banner" id="top">
  <div class="container">
    <a class="logo" href="https://www.djangoproject.com/">Django</a>
    <p class="meta">The web framework for perfectionists with deadlines.</p>
    <div role="navigation">
      <ul>
        <li>
          <a href="https://www.djangoproject.com/start/overview/">Overview</a>
        </li>
        <li>
          <a href="https://www.djangoproject.com/download/">Download</a>
        </li>
        <li>
          <a href="https://docs.djangoproject.com/">Documentation</a>
        </li>
        <li>
          <a href="https://www.djangoproject.com/weblog/">News</a>
        </li>
        <li>
          <a href="https://www.djangoproject.com/community/">Community</a>
        </li>
        <li>
          <a href="https://github.com/django/django" target="_blank" rel="noopener">Code</a>
        </li>
        <li>
          <a href="https://code.djangoproject.com/">Issues</a>
        </li>
        <li>
          <a href="https://www.djangoproject.com/foundation/">About</a>
        </li>
        <li>
          <a href="https://www.djangoproject.com/fundraising/">&#9829; Donate</a>
        </li>
      </ul>
    </div>
  </div>
</div>


    <div class="copy-banner">
      <div class="container ">
        
  <h1 class="visuallyhidden">Django</h1>
    <p>
        <em>Django makes it easier to build better web apps more quickly and with less code.</em>
    </p>
    <p>
        <a href="https://www.djangoproject.com/start/" class="cta">Get started with Django</a>
    </p>

      </div>
    </div>
    <div id="billboard"></div>

    <div class="container sidebar-right">
      <div role="main">

        
          
        

        
    <div class="section">
        <h1>Meet Django</h1>
        <p>
          Django is a high-level Python web framework that encourages rapid development and clean, pragmatic design.
          Built by experienced developers, it takes care of much of the hassle of web development, so you can focus
          on writing your app without needing to reinvent the wheel. It’s free and open source.
        </p>
        <dl class="list-features">
          <dt><i class="icon icon-bolt"></i> Ridiculously fast.</dt>
          <dd>
            <p>Django was designed to help developers take applications from concept to completion as quickly as possible.</p>
          </dd>

          <dt><i class="icon icon-lock"></i> Reassuringly secure.</dt>
          <dd>
            <p>Django takes security seriously and helps developers avoid many common security mistakes.</p>
          </dd>

          <dt><i class="icon icon-dashboard"></i> Exceedingly scalable.</dt>
          <dd>
            <p>Some of the busiest sites on the web leverage Django’s ability to quickly and flexibly scale.</p>
          </dd>
        </dl>

        <a href="https://www.djangoproject.com/start/overview/" class="cta outline">Learn more about Django</a>
    </div>

    

    <div class="section">
    <h1>Stay in the loop</h1>
    <p>Subscribe to one of our mailing lists to stay up to date with everything in the Django community:</p>
    <div class="layout-2col">
  <div class="col form-email">
    <h3><a href="https://groups.google.com/group/django-users">Using Django</a></h3>
    <p class="meta">Get help with Django and follow announcements.</p>
    <form class="form-input" action="https://groups.google.com/group/django-users/boxsubscribe">
      <input type="email" name="email" placeholder="Enter email">
      <button type="submit">
        <i class="icon icon-envelope-o"></i>
        <span class="visuallyhidden">Subscribe</span>
      </button>
    </form>
    <p class="meta">
      You can also subscribe by sending an email to
      <a href="mailto:django-users+subscribe@googlegroups.com">
      django-users+subscribe@googlegroups.com</a> and following the
      instructions that will be sent to you.
    </p>
  </div>
  <div class="col form-email last-child">
    <h3><a href="https://groups.google.com/group/django-developers">Contributing to Django</a></h3>
    <p class="meta">Contribute to the development of Django itself.</p>
    <form class="form-input" action="https://groups.google.com/group/django-developers/boxsubscribe">
      <input type="email" name="email" placeholder="Enter email">
      <button type="submit">
        <i class="icon icon-envelope-o"></i>
        <span class="visuallyhidden">Subscribe</span>
      </button>
    </form>
    <p class="meta">
      Before asking a question about how to contribute, read
      <a href="https://docs.djangoproject.com/en/dev/internals/contributing/">
      Contributing to Django</a>. Many frequently asked questions are answered there.
    </p>
    <hr>
    <p class="meta">
      You can also subscribe by sending an email to
      <a href="mailto:django-developers+subscribe@googlegroups.com">
      django-developers+subscribe@googlegroups.com</a> and following the
      instructions that will be sent to you.
    </p>
  </div>

</div>
<p>
    We have a few other specialized lists (announce, i18n, ...). You can
    find more information about them in our
    <a href="https://docs.djangoproject.com/en/dev/internals/mailing-lists/">
    mailing list documentation</a>.
  </p>

    </div>

    <!-- END #content-secondary -->

        <a href="#top" class="backtotop"><i class="icon icon-chevron-up"></i> Back to Top</a>
      </div>

      
  <div role="complementary">
      <a href="https://www.djangoproject.com/download/" class="cta">
          Download <em>latest release: 4.0.1</em>
      </a>
      <a href="https://docs.djangoproject.com/" class="link-readmore">Django documentation</a>

      


  <div class="fundraising-sidebar">
    <h2>Support Django!</h2>

    <div class="small-heart">
      <img src="https://static.djangoproject.com/img/fundraising-heart.cd6bb84ffd33.svg" alt="Support Django!" />
    </div>

    <div class="small-cta">
      <ul class="list-links-small">
        <li><a href="https://www.djangoproject.com/fundraising/">
          Axo Finans donated to the Django Software Foundation to
          support Django development. Donate today!
        </a></li>
      </ul>
    </div>
  </div>



      <h3>Latest news</h3>
      <ul class="list-news">

    <li>
  <h4>
    <a href="https://www.djangoproject.com/weblog/2022/jan/21/announcing-djangocon-europe-2022/">Announcing DjangoCon Europe 2022</a>
  </h4>
  
    We are happy to announce DjangoCon Europe 2022 will take place in Porto, Portugal
hopefully! Let us explain, and believe us that there is a lot to explain.

DjangoCon Europe is hosted annually by an independent volunteer team. This volunteer
team submits a proposal to DSF, which will then select the most suitable one from all the
proposals. We knew from the start it would be daunting for a new team to submit for 2022, in
these uncertain times. So, we pledged our availability to host another DjangoCon, but only if
there was no other team submitting a proposal.
  
  <span class="meta">
    Posted by <strong>David Vaz &amp; Miguel Magalhaes</strong> on January 21, 2022
  </span>
  
  
</li>


    <li>
  <h4>
    <a href="https://www.djangoproject.com/weblog/2022/jan/04/security-releases/">Django security releases issued: 4.0.1, 3.2.11, and 2.2.26</a>
  </h4>
  
    <p>Django 4.0.1, 3.2.11, and 2.2.26 fix three security issues.</p>

  
  <span class="meta">
    Posted by <strong>Carlton Gibson</strong> on January 4, 2022
  </span>
  
  
</li>


</ul>

      <a href="https://www.djangoproject.com/weblog/" class="link-readmore">More news</a>

      <h3>New to Django?</h3>
      <ul class="list-links-small docs-list">
          <li><a href="https://docs.djangoproject.com/en/stable/intro/install/">Installation guide</a></li>
          <li><a href="https://docs.djangoproject.com/en/stable/intro/tutorial01/">Write your first Django app</a></li>
      </ul>
      <a href="https://www.djangoproject.com/start/" class="link-readmore">Getting started with Django</a>

      <h3>The power of Django</h3>
      <ul class="list-links-small docs-list">
          <li><a href="https://docs.djangoproject.com/en/stable/topics/db/models/">Object-relational mapper</a></li>
          <li><a href="https://docs.djangoproject.com/en/stable/intro/tutorial02/">Automatic admin interface</a></li>
          <li><a href="https://docs.djangoproject.com/en/stable/topics/templates/">Robust template system</a></li>
          <li><a href="https://docs.djangoproject.com/en/stable/topics/i18n/">Quick internationalization</a></li>
      </ul>
      <a href="https://www.djangoproject.com/start/overview/" class="link-readmore">Explore more features</a>

      <h3>Get involved</h3>
      <dl class="list-links-small">
         <dt><a href="irc://irc.libera.chat/django">#django IRC channel</a></dt>
          <dd>
            Chat with other Django users
          </dd>

          <dt><a href="https://code.djangoproject.com/">Ticket system</a></dt>
          <dd>
            Report bugs and make feature requests
          </dd>
      </dl>
      <a href="https://www.djangoproject.com/community/" class="link-readmore">Inside the Django community</a>

      <h3>The Django Software Foundation</h3>
      <dl class="list-links-small">
        <dt><a href="/foundation/">About the Foundation</a></dt>
        <dd>
          Our non-profit supports the project
        </dd>

        <dt><a href="/foundation/donate/">Support Django</a></dt>
        <dd>
          Your contribution makes Django stronger
        </dd>

        <dt><a href="/contact/foundation/">Contact the Django Software Foundation</a></dt>
        <dd></dd>
      </dl>
      <a href="/foundation/" class="link-readmore">More about the DSF</a>
      
  </div>


      

    </div>

     
     

    
    
  


    
      
<div role="contentinfo">
  <div class="subfooter">
    <div class="container">
      <h1 class="visuallyhidden">Django Links</h1>

      <div class="col learn">
        <h2>Learn More</h2>
        <ul>
          <li><a href="https://www.djangoproject.com/start/overview/">About Django</a></li>
          
          <li><a href="https://www.djangoproject.com/start/">Getting Started with Django</a></li>
          <li><a href="https://docs.djangoproject.com/en/dev/internals/organization/">Team Organization</a></li>
          <li><a href="https://www.djangoproject.com/foundation/">Django Software Foundation</a></li>
          <li><a href="https://www.djangoproject.com/conduct/">Code of Conduct</a></li>
          <li><a href="https://www.djangoproject.com/diversity/">Diversity Statement</a></li>
        </ul>
      </div>

      <div class="col involved">
        <h2>Get Involved</h2>
        <ul>
          <li><a href="https://www.djangoproject.com/community/">Join a Group</a></li>
          <li><a href="https://docs.djangoproject.com/en/dev/internals/contributing/">Contribute to Django</a></li>
          <li><a href="https://docs.djangoproject.com/en/dev/internals/contributing/bugs-and-features/">Submit a Bug</a></li>
          <li><a href="https://docs.djangoproject.com/en/dev/internals/security/#reporting-security-issues">Report a Security Issue</a></li>
        </ul>
      </div>

      <div class="col follow last-child">
        <h2>Follow Us</h2>
        <ul>
          <li><a href="https://github.com/django">GitHub</a></li>
          <li><a href="https://twitter.com/djangoproject">Twitter</a></li>
          <li><a href="https://www.djangoproject.com/rss/weblog/">News RSS</a></li>
          <li><a href="https://groups.google.com/forum/#!forum/django-users">Django Users Mailing List</a></li>
        </ul>
      </div>

      <div class="col follow last-child">
        <h2>Support Us</h2>
        <ul>
          <li><a href="https://www.djangoproject.com/fundraising/">Sponsor Django</a></li>
          <li><a href="https://django.threadless.com/" target="_blank">Official merchandise store</a></li>
          <li><a href="/foundation/donate/#amazon-smile">Amazon Smile</a></li>
          <li><a href="/foundation/donate/#benevity-giving">Benevity Workplace Giving Program</a></li>
        </ul>
      </div>

    </div>
  </div>

  <div class="footer">
    <div class="container">
      <div class="footer-logo">
        <a class="logo" href="https://www.djangoproject.com/">Django</a>
      </div>
      <ul class="thanks">
        <li>
          <span>Hosting by</span> <a class="rackspace" href="https://www.rackspace.com">Rackspace</a>
        </li>
        <li class="design"><span>Design by</span> <a class="threespot" href="https://www.threespot.com">Threespot</a> <span class="ampersand">&amp;</span> <a class="andrevv" href="http://andrevv.com/">andrevv</a></li>
      </ul>
      <p class="copyright">&copy; 2005-2022
        <a href="https://www.djangoproject.com/foundation/"> Django Software
        Foundation</a> and individual contributors. Django is a
        <a href="https://www.djangoproject.com/trademarks/">registered
        trademark</a> of the Django Software Foundation.
      </p>
    </div>
  </div>

</div>

  </body>
</html>

`