import React from 'react';
import { getExerciseWithExerciseId } from '../../../exercises/exercise';
import { HtmlAnswerQuizLessonPart } from '../../../util/lessonPart';


export const CssMarginAndPaddingLessonPart1 = () => {
    return (<div className="container-fluid">
        <h3>Margin</h3>
        {/* <HtmlLessonReaderComponent lessonPartContent={htmlCssMarginLessonPartContent}></HtmlLessonReaderComponent> */}
    </div>)
}
export const CssMarginAndPaddingLessonPart2 = () => {
    return (<div className="container-fluid">
        <h3>Padding</h3>
        {/* <HtmlLessonReaderComponent lessonPartContent={htmlCssPaddingLessonPartContent}></HtmlLessonReaderComponent> */}
    </div>)
}

// export const CssMarginAndPaddingLessonPart1 = () => {
//     return (<div className="container-fluid">
//         <h3>Margin and padding</h3>
//         <ul className="mt-3">
//             <li><code>margin</code> is used to set the space between a html element and the surrounding elements.</li>
//             <li><code>padding</code> is used to set the space between the html element's border and the content inside it.</li>
//             <li><code>border</code> lies between the space defined by margin and padding.</li>
//         </ul>
//         <p>
//             See the below example with html elements with margin and one with padding. Try changing
//              the values of margin and padding and see how the output changes.
//         </p>

//         <HtmlEditor
//             initialHtmlText={`
// <!DOCTYPE html>
// <html>
//     <head>
//         <style>
//             .button1 {
//                 background-color:blue;
//                 border:3px solid green;
//                 color:white;
//                 margin: 10px;
//                 padding: 20px
//             }
//             .div1 {
//                 background-color:blue;
//                 border:3px solid green;
//                 color:white;
//                 width: 200px;
//                 height: 200px
//             }
//         </style>
//     </head>
//     <body>
//         <div class="div1">
//             <button class="button1">
//                 Button
//             </button>
//         </div>
//     </body>
// </html>`}
//             height={"400px"}
//             showOutput={true}
//         ></HtmlEditor>
//     </div>)
// }

// export const CssMarginAndPaddingLessonPart2 = () => {
//     return (<div className="container-fluid">
//         <h3>Margin</h3>
//         <ul className="mt-3">
//             <li>If a single value is specified for <code>margin</code> property, it is used to set the margin on all sides.</li>
//             <li>You can also give 4 different values for the top, right, bottom, left margins.</li>
//         </ul>
//         <p>
//             See the below example where the button has different margin values for different sides.
//             Try changing the 4 values and see how it affects the margin on all sides.
//         </p>

//         <HtmlEditor
//             initialHtmlText={`
// <!DOCTYPE html>
// <html>
//     <head>
//         <style>
//             .button1 {
//                 background-color:blue;
//                 border:3px solid green;
//                 color:white;
//                 margin: 20px 10px 30px 40px;
//                 padding: 20px
//             }
//             .div1 {
//                 background-color:blue;
//                 border:3px solid green;
//                 color:white;
//                 width: 200px;
//                 height: 200px
//             }
//         </style>
//     </head>
//     <body>
//         <div class="div1">
//             <button class="button1">
//                 Button
//             </button>
//         </div>
//     </body>
// </html>`}
//             height={"400px"}
//             showOutput={true}
//         ></HtmlEditor>
//         <ul className="mt-3">
//             <li>Additionally, margin can also be specified as four different properties <code>margin-top</code>, <code>margin-right</code>,
//                 <code> margin-bottom</code>, <code>margin-left</code>.
//             </li>
//         </ul>
//         <p>
//             See the below example where the button has different margin values for different sides specified using
//             different margin properties.
//             Try changing the 4 values and see how it affects the margin on all sides.
//         </p>

//         <HtmlEditor
//             initialHtmlText={`
// <!DOCTYPE html>
// <html>
//     <head>
//         <style>
//             .button1 {
//                 background-color:blue;
//                 border:3px solid green;
//                 color:white;
//                 margin-top: 20px;
//                 margin-right: 10px;
//                 margin-bottom: 30px;
//                 margin-left:40px;
//                 padding: 20px
//             }
//             .div1 {
//                 background-color:blue;
//                 border:3px solid green;
//                 color:white;
//                 width: 200px;
//                 height: 200px
//             }
//         </style>
//     </head>
//     <body>
//         <div class="div1">
//             <button class="button1">
//                 Button
//             </button>
//         </div>
//     </body>
// </html>`}
//             height={"400px"}
//             showOutput={true}
//         ></HtmlEditor>
//     </div>)
// }

export const CssMarginAndPaddingLessonPart3 = () => {
    return (<div className="container-fluid">
        <h3>Padding</h3>
        <ul className="mt-3">
            <li>Padding follows similar semantics as margin.</li>
            <li>If a single value is specified for <code>padding</code> property, it is used to set the padding on all sides.</li>
            <li>You can also give 4 different values for the top, right, bottom, left padding.</li>
        </ul>
        <p>
            See the below example where the button has different padding values for different sides.
            Try changing the 4 values and see how it affects the padding on all sides.
        </p>

        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <head>
        <style>
            .button1 {
                background-color:blue;
                border:3px solid green;
                color:white;
                padding: 20px 10px 30px 40px;
                margin: 20px
            }
            .div1 {
                background-color:blue;
                border:3px solid green;
                color:white;
                width: 200px;
                height: 200px
            }
        </style>
    </head>
    <body>
        <div class="div1">
            <button class="button1">
                Button
            </button>
        </div>
    </body>
</html>`}
            height={"400px"}
            showOutput={true}
        ></HtmlEditor>
        <ul className="mt-3">
            <li>Additionally, padding can also be specified as four different properties <code>padding-top</code>,
            <code> padding-right</code>,
            <code>padding-bottom</code>, <code>padding-left</code>.
            </li>
        </ul>
        <p>
            See the below example where the button has different padding values for different sides specified using
            different padding properties.
            Try changing the 4 values and see how it affects the padding on all sides.
        </p>

        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <head>
        <style>
            .button1 {
                background-color:blue;
                border:3px solid green;
                color:white;
                padding-top: 20px;
                padding-right: 10px;
                padding-bottom: 30px;
                padding-left:40px;
                margin: 20px
            }
            .div1 {
                background-color:blue;
                border:3px solid green;
                color:white;
                width: 200px;
                height: 200px
            }
        </style>
    </head>
    <body>
        <div class="div1">
            <button class="button1">
                Button
            </button>
        </div>
    </body>
</html>`}
            height={"400px"}
            showOutput={true}
        ></HtmlEditor>
    </div>)
}

export const cssMarginAndPaddingLessonParts = [{
    component: CssMarginAndPaddingLessonPart1
// }, {
//     component: CssMarginAndPaddingLessonPart2
}, {
    component: CssMarginAndPaddingLessonPart2
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-css-margin-padding-1")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-css-margin-padding-2")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-css-margin-padding-3")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-css-margin-padding-4")
    }
}]
