"use client";
// import { Trans } from "react-i18next";
import styles from "./featuresInfoComponent.module.scss";
import GradientTextComponent from "../GradientTextComponent/gradientTextComponent";
export const FeaturesInfoComponent = (props) => {
  const {
    infoSide = "right",
    TitleComponent,
    subText,
    imageUrl,
    featureInfo = [],
  } = props;
  return (
    <div className={styles.featureInfoComponent}>
      <div className={styles.featureInfoHeader}>
        <div className={styles.featureInfoTitle}>
          {<TitleComponent />}
          {/* <Trans
            defaults={title}
            components={{ gradient: <GradientTextComponent /> }}
          /> */}
        </div>
        <div className={styles.featureInfoSubText}>{subText}</div>
      </div>
      <div
        className={`${
          infoSide === "left"
            ? styles.featureInfoDetails
            : styles.featureInfoDetailsReverse
        }`}
      >
        <div className={styles.featureInfoDetailsLeft}>
          <img
            className={styles.featureInfoDetailImage}
            src={imageUrl}
            alt={"info"}
          />
        </div>
        <div className={styles.featureInfoDetailsRight}>
          {featureInfo?.map((info, index) => (
            <div key={index}>{info}</div>
          ))}
        </div>
      </div>
    </div>
  );
};
