import "./callUsAt.scss";
export const CallUsAt = () => {
  const contactNumber = "00000000";
  const onContactClick = () => {
    // window.open(`tel:${contactNumber}`);
  };
  return (
    <div className="call-us-container" onClick={onContactClick}>
      <div className="call-icon">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 15.5C18.8 15.5 17.5 15.3 16.4 14.9H16.1C15.8 14.9 15.6 15 15.4 15.2L13.2 17.4C10.4 15.9 8 13.6 6.6 10.8L8.8 8.6C9.1 8.3 9.2 7.9 9 7.6C8.7 6.5 8.5 5.2 8.5 4C8.5 3.5 8 3 7.5 3H4C3.5 3 3 3.5 3 4C3 13.4 10.6 21 20 21C20.5 21 21 20.5 21 20V16.5C21 16 20.5 15.5 20 15.5ZM5 5H6.5C6.6 5.9 6.8 6.8 7 7.6L5.8 8.8C5.4 7.6 5.1 6.3 5 5ZM19 19C17.7 18.9 16.4 18.6 15.2 18.2L16.4 17C17.2 17.2 18.1 17.4 19 17.4V19Z"
            fill="#7349F2"
          />
        </svg>
      </div>
      <div className="text-container">
        <div className={"title"}>Do you have questions for us?</div>
        <div className="call-us-number"> Whatsapp us at +91-9515656894</div>
      </div>
    </div>
  );
};
