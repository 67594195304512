export const dj3Html = `<html lang="en"><head>
<meta charset="utf-8">
<meta http-equiv="X-UA-Compatible" content="IE=edge">
<meta name="viewport" content="width=device-width, initial-scale=1">
<meta name="ROBOTS" content="ALL">
<meta name="MSSmartTagsPreventParsing" content="true">
<meta name="Copyright" content="Django Software Foundation">
<meta name="keywords" content="Python, Django, framework, open-source">
<meta name="description" content="">


<!-- Favicons -->
<link rel="apple-touch-icon" href="https://static.djangoproject.com/img/icon-touch.e4872c4da341.png">
<link rel="icon" sizes="192x192" href="https://static.djangoproject.com/img/icon-touch.e4872c4da341.png">
<link rel="shortcut icon" href="https://static.djangoproject.com/img/favicon.6dbf28c0650e.ico">
<meta name="msapplication-TileColor" content="#113228">
<meta name="msapplication-TileImage" content="https://static.djangoproject.com/img/icon-tile.b01ac0ef9f67.png">

<title>The web framework for perfectionists with deadlines | Django</title>

<link rel="stylesheet" href="https://static.djangoproject.com/css/output.f3605340f42d.css">    
</head>

<body id="homepage" class="homepage">

<div role="banner" id="top">
<div class="container">
<a class="logo" href="https://www.djangoproject.com/">Django</a>
<p class="meta">The web framework for perfectionists with deadlines.</p>
<div role="navigation">
  <ul>
    <li>
      <a href="https://www.djangoproject.com/start/overview/">Overview</a>
    </li>
    <li>
      <a href="https://www.djangoproject.com/download/">Download</a>
    </li>
    <li>
      <a href="https://docs.djangoproject.com/">Documentation</a>
    </li>
    <li>
      <a href="https://www.djangoproject.com/weblog/">News</a>
    </li>
    <li>
      <a href="https://www.djangoproject.com/community/">Community</a>
    </li>
    <li>
      <a href="https://github.com/django/django" target="_blank" rel="noopener">Code</a>
    </li>
    <li>
      <a href="https://code.djangoproject.com/">Issues</a>
    </li>
    <li>
      <a href="https://www.djangoproject.com/foundation/">About</a>
    </li>
    <li>
      <a href="https://www.djangoproject.com/fundraising/">♥ Donate</a>
    </li>
  </ul>
</div>
</div>
</div>


<div class="copy-banner">
  <div class="container ">
    
<h1 class="visuallyhidden">Django</h1>
<p>
    <em>Django makes it easier to build better web apps more quickly and with less code.</em>
</p>
<p>
    <a href="https://www.djangoproject.com/start/" class="cta">Get started with Django</a>
</p>

  </div>
</div>
<div id="billboard"></div>

<div class="container sidebar-right">
  <div role="main">

    
      
    

    
<div class="section">
    <h1>Meet Django</h1>
    <p>
      Django is a high-level Python web framework that encourages rapid development and clean, pragmatic design.
      Built by experienced developers, it takes care of much of the hassle of web development, so you can focus
      on writing your app without needing to reinvent the wheel. It’s free and open source.
    </p>
    <dl class="list-features">
      <dt><i class="icon icon-bolt"></i> Ridiculously fast.</dt>
      <dd>
        <p>Django was designed to help developers take applications from concept to completion as quickly as possible.</p>
      </dd>

      <dt><i class="icon icon-lock"></i> Reassuringly secure.</dt>
      <dd>
        <p>Django takes security seriously and helps developers avoid many common security mistakes.</p>
      </dd>

      <dt><i class="icon icon-dashboard"></i> Exceedingly scalable.</dt>
      <dd>
        <p>Some of the busiest sites on the web leverage Django’s ability to quickly and flexibly scale.</p>
      </dd>
    </dl>

    <a href="https://www.djangoproject.com/start/overview/" class="cta outline">Learn more about Django</a>
</div>



<div class="section">
<h1>Stay in the loop</h1>
<p>Subscribe to one of our mailing lists to stay up to date with everything in the Django community:</p>
<div class="layout-2col">
<div class="col form-email">
<h3><a href="https://groups.google.com/group/django-users">Using Django</a></h3>
<p class="meta">Get help with Django and follow announcements.</p>
<form class="form-input" action="https://groups.google.com/group/django-users/boxsubscribe">
  <input type="email" name="email" placeholder="Enter email">
  <button type="submit">
    <i class="icon icon-envelope-o"></i>
    <span class="visuallyhidden">Subscribe</span>
  </button>
</form>
<p class="meta">
  You can also subscribe by sending an email to
  <a href="mailto:django-users+subscribe@googlegroups.com">
  django-users+subscribe@googlegroups.com</a> and following the
  instructions that will be sent to you.
</p>
</div>
<div class="col form-email last-child">
<h3><a href="https://groups.google.com/group/django-developers">Contributing to Django</a></h3>
<p class="meta">Contribute to the development of Django itself.</p>
<form class="form-input" action="https://groups.google.com/group/django-developers/boxsubscribe">
  <input type="email" name="email" placeholder="Enter email">
  <button type="submit">
    <i class="icon icon-envelope-o"></i>
    <span class="visuallyhidden">Subscribe</span>
  </button>
</form>
<p class="meta">
  Before asking a question about how to contribute, read
  <a href="https://docs.djangoproject.com/en/dev/internals/contributing/">
  Contributing to Django</a>. Many frequently asked questions are answered there.
</p>
<hr>
<p class="meta">
  You can also subscribe by sending an email to
  <a href="mailto:django-developers+subscribe@googlegroups.com">
  django-developers+subscribe@googlegroups.com</a> and following the
  instructions that will be sent to you.
</p>
</div>

</div>
<p>
We have a few other specialized lists (announce, i18n, ...). You can
find more information about them in our
<a href="https://docs.djangoproject.com/en/dev/internals/mailing-lists/">
mailing list documentation</a>.
</p>

</div>

<!-- END #content-secondary -->

    <a href="#top" class="backtotop"><i class="icon icon-chevron-up"></i> Back to Top</a>
  </div>

  



  

</div>

 
 







  





</body></html>`