export const HTML_QUESTIONS = [{
    questionId: "tag-for-audio",
    questionType: "multipleChoiceQuestion",
    question: "Which tag is used to include audio in a webpage?",
    options: {
        option1: "<audiovideo>",
        option2: "<audio>",
        option3: "<htmlaudio>",
        option4: "<canvas>",
    },
    correctOption: "option2"
}, {
    questionId: "audio-formats",
    questionType: "multiple-choice-multiple-answer",
    question: "What are the supported audio formats for html audio tag?",
    options: {
        option1: "audio/ogg",
        option2: "audio/mpeg",
        option3: "audio/wav",
        option4: "audio/mp3",
        option5: "audio/3gp",
        option6: "audio/jpeg",
    },
    correctOptions: ["option1", "option2", "option3"]
}, {
    questionId: "tag-for-audio-sources",
    questionType: "multipleChoiceQuestion",
    question: "Which tag is used to define sources of audio in a webpage?",
    options: {
        option1: "<audiosource>",
        option2: "<source>",
        option3: "<audiovideosource>",
        option4: "<audio>",
    },
    correctOption: "option3"
}, {
    questionId: "attribute-for-audio-controls",
    questionType: "multipleChoiceQuestion",
    question: "Which of the following attribute adds audio controls like play/pause?",
    options: {
        option1: "audiocontrols",
        option2: "controls",
        option3: "playcontrols",
        option4: "source",
    },
    correctOption: "option3"
}, {
    questionId: "attribute-for-audio-repeat",
    questionType: "multipleChoiceQuestion",
    question: "Setting which attribute would run the audio repeatedly when page opens?",
    options: {
        option1: "repeat",
        option2: "loop",
        option3: "continous",
        option4: "unstoppable",
    },
    correctOption: "option2"
}, {
    questionId: "void-elements",
    questionType: "multiple-choice-multiple-answer",
    question: "which of the following is true about void elements?",
    options: {
        option1: "They do not need to have closing tags",
        option2: "<img> is a void element",
        option3: "<audio> is a void element",
        option4: "<hr> is not a void element",
        option5: "<p> is not a void element",
    },
    correctOptions: ["option1", "option2", "option5"]
}]