import React from 'react';
import { HtmlAnswerQuizLessonPart } from '../../../util/lessonPart';
import { getExerciseWithExerciseId } from '../../../exercises/exercise';

export const HtmlTextFormattingsLessonPart1 = () => {
    return (<div className="container-fluid">
        <h3>Html text formatting</h3>
        <ul className="mt-3">
            <li>Text formatting elements are used to format text inside html elements.</li>
            <li><code>{"<sup>"}</code>, <code>{"<b>"}</code>, <code>{"<em>"}</code>, <code>{"<sub>"}</code>,
             <code>{"<strong>"}</code>, <code>{"<em>"}</code>, <code>{"<b>"}</code>, <code>{"<i>"}</code> are
              some of the important tags to format html text.</li>
        </ul>

        <p>Below is html using different text formatting tags. 
        </p>
        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <body>
        <p>The shape of Earth is nearly <sup>spherical</sup>.</p>
        <p>The shape of Earth is nearly <b>spherical</b>.</p>
        <p>The shape of Earth is nearly <em>spherical</em>.</p>
        <p>The shape of Earth is nearly <sub>spherical</sub>.</p>
        <p>The shape of Earth is nearly <strong>spherical</strong>.</p>
        <p>The shape of Earth is nearly <em>spherical</em>.</p>
        <p>The shape of Earth is nearly <b>spherical</b>.</p>
        <p>The shape of Earth is nearly <i>spherical</i>.</p>
    </body>
</html>`}
            height={"400px"}
            showOutput={true}
        ></HtmlEditor>
    </div>)
}


export const htmlTextFormattingsLessonParts = [{
    component: HtmlTextFormattingsLessonPart1 
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-text-formatting-1")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-text-formatting-2")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-text-formatting-3")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-text-formatting-4")
    }
}]
