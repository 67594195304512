import { PureComponent } from "react"
import React from 'react';

import Editor from "@monaco-editor/react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons'
import { faCompressArrowsAlt } from '@fortawesome/free-solid-svg-icons'
import * as monaco from "monaco-editor"
import "../dashboard/course.css"
import { saveCourseTaskSaga } from "../../store/common.saga";
import { promReduxConnect } from "./utils";


export const getHighlightRangeForHighlightIndices = (highlightStartIndex, highlightEndIndex, text) => {
    let startLineNumber = -1
    let startColumnNumber = -1
    let endLineNumber = -1
    let endColumnNumber = -1
    let lineNumber = 1
    let columnNumber = 1
    for (let i = 0; i <= (text || "").length; i++) {
        if (highlightStartIndex === i) {
            startLineNumber = lineNumber
            startColumnNumber = columnNumber
        }
        if (highlightEndIndex === i) {
            endLineNumber = lineNumber
            endColumnNumber = columnNumber
        }
        if (text.charAt(i) === "\n") {
            lineNumber = lineNumber + 1
            columnNumber = 1
        } else {
            columnNumber = columnNumber + 1
        }
    }
    return { startLineNumber, startColumnNumber, endLineNumber, endColumnNumber }
}


export const getHighlightIndicesforRange = (startLineNumber, startColumnNumber, endLineNumber, endColumnNumber, text) => {
    let lineNumber = 1
    let columnNumber = 1
    let highlightStartIndex = -1
    let highlightEndIndex = -1
    for (let i = 0; i <= (text || "").length; i++) {
        if (lineNumber === startLineNumber && columnNumber === startColumnNumber) {
            highlightStartIndex = i
        }
        if (lineNumber === endLineNumber && columnNumber === endColumnNumber) {
            highlightEndIndex = i
        }
        if (text.charAt(i) === "\n") {
            lineNumber = lineNumber + 1
            columnNumber = 1
        } else {
            columnNumber = columnNumber + 1
        }
    }
    return { highlightStartIndex, highlightEndIndex }
}
class HtmlEditorInternal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            editedHtmlText: null,
            outputTabName: "output",
            isOutputExpanded: false
        }
        this.monacoRef = React.createRef()
        this.monacoEditor = React.createRef()
        this.startTimestampMillis = null
    }

    componentDidMount = () => {
        // const { expectedOutputHtml } = this.props
        // if (expectedOutputHtml) {
        //     this.getSavedExerciseIfNeeded()
        // }
        this.saveInterval = setInterval(this.saveCodetext, 10*1000)
    }

    componentDidUpdate = (prevProps) => {
        const { exerciseId, initialCodeText, highlightBlocks, savedCodeText } = this.props
        // const codeText = this.getCodeText()
        console.log({exerciseId, initialCodeText})
        if (exerciseId !== prevProps.exerciseId || initialCodeText !== prevProps.initialCodeText ||
            highlightBlocks !== prevProps.highlightBlocks) {
            // this.setState({runId: null})
            this.setState({editedHtmlText: null}, () => {
                this.updateEditor()
            })
            // this.updateEditor()
        }
        // let shouldUpdateEditor = false
        // if (this.props.initialCodeText !== prevProps.initialCodeText || 
        //     this.lastRenderedCodeText !== codeText ||
        //     this.props.highlightBlocks !== prevProps.highlightBlocks) {
        //     shouldUpdateEditor = true
        // }

        // const updateEditorIfRequired = () => {
        //     if (shouldUpdateEditor) {
        //         this.updateEditor()
        //     }
        // }

        // if (exerciseId !== prevProps.exerciseId) {
        //     this.setState({editedHtmlText: null}, updateEditorIfRequired)
        //     this.getSavedExerciseIfNeeded()
        //     this.startTimestampMillis = performance.now()
        // }
        // if (initialCodeText !== prevProps.initialCodeText) {
        //     this.setState({editedHtmlText: null}, updateEditorIfRequired)
        // }
        // if (highlightBlocks !== prevProps.highlightBlocks) {
        //     this.setState({editedHtmlText: null}, updateEditorIfRequired)
        // }
        
        // if (prevProps.userId !== this.props.userId) {
        //     this.setState({editedHtmlText: null}, () => {
        //         this.getSavedExerciseIfNeeded()
        //         if (this.monacoEditor.updateOptions) {
        //             this.monacoEditor.updateOptions({
        //                 readOnly: false
        //             })
        //         }
        //     })
        // }

        this.lastRenderedInitialHtmlText = this.props.initialCodeText
        this.lastRenderedEditedHtmlText = this.props.editedHtmlText
        this.lastRenderedCurrentEditorText = this.getCurrentEditorText()
        this.lastRenderedCodeText = this.getCodeText()
    }

    componentWillUnmount() {
        if (this.saveInterval) {
            clearInterval(this.saveInterval)
        }
    }

    // componentWillUpdate(nextProps, nextState) {
    //     const stateKeys = new Set();
    //     const propsKeys = new Set();
    //     Object.keys(this.props).forEach(key => propsKeys.add(key));
    //     Object.keys(nextProps).forEach(key => propsKeys.add(key));
    //     Object.keys(this.state || {}).forEach(key => stateKeys.add(key));
    //     Object.keys(nextState || {}).forEach(key => stateKeys.add(key));

    //     const getDiff = (keys, object1, object2) => {
    //         const diffValues = [];
    //         const keysArray = [...keys];
    //         keysArray.filter(key => object1[key] !== object2[key]).forEach(key => diffValues.push([key, object1[key], object2[key]]));
    //         return [...diffValues];
    //     };

    //     console.log(getDiff(propsKeys, this.props, nextProps), getDiff(stateKeys, this.state || {}, nextState || {}));
    // }

    toggleOutputExpandMode = () => {
        this.setState({isOutputExpanded: !this.state.isOutputExpanded})
    }

    // getSavedExerciseIfNeeded = () => {
    //     const { isExerciseLoaded, courseId, exerciseId } = this.props
    //     if (exerciseId && !isExerciseLoaded) {
    //         this.props.dispatchGetExerciseSagaAction(courseId, exerciseId)
    //     }
    // }

    updateEditor = () => {
        if (!this.monacoEditor || !this.monacoEditor.setValue) {
            return
        }
        this.monacoEditor.setValue(this.getCurrentEditorText())
        this.updateDecorations()
    }

    updateDecorations = () => {
        if (!this.monacoEditor || !this.monacoEditor.setValue) {
            return
        }
        const { editedHtmlText } = this.state
        if (editedHtmlText) {
            this.monacoEditor.deltaDecorations([], []);
        } else {
            const highlightBlocks = this.props.highlightBlocks || []
            const decorations = []
            highlightBlocks.forEach(highlightBlock => {
                const { highlightStartIndex, highlightEndIndex } = highlightBlock
                if (highlightStartIndex < 0 || highlightEndIndex < 0 || highlightStartIndex === highlightEndIndex) {
                    return
                }
                const { startLineNumber, startColumnNumber, endLineNumber, endColumnNumber } = getHighlightRangeForHighlightIndices(
                    highlightStartIndex, highlightEndIndex, this.getCurrentEditorText())
                decorations.push({ 
                    range: new monaco.Range(startLineNumber, startColumnNumber, endLineNumber, endColumnNumber),
                    options: {
                        inlineClassName: 'myInlineDecoration'
                    }
                })
            })
            this.monacoEditor.deltaDecorations([], decorations);
        }
    }

    getCurrentEditorText = () => {
        const { initialCodeText, } = this.props
        const { editedHtmlText } = this.state
        const codeText = this.getCodeText()
        // if (!codeText && codeText !== "") {
        //     return null
        // }
        return editedHtmlText || codeText || initialCodeText || ""
        // return editedHtmlText || initialHtmlText || ""
    }

    getCodeText = () => {
        return this.props.savedCodeText
        const { exercises, courseId, exerciseId } = this.props
        console.log({ exercises, courseId, exerciseId })
        return getCodeText(exercises, courseId, exerciseId)
    }

    getExerciseLastUpdatedMillis = () => {
        const { exercises, exerciseId, courseId } = this.props
        const { userId } = this.props
        if (!exerciseId) {
            return 0
        }

        if (exerciseId && exercises && exercises[courseId] && exercises[courseId][exerciseId]) {
            return exercises[courseId][exerciseId].updateTimestampMillis
        }
        return 0
    }

    onEditorChange = (editorText, event) => {
        console.log({editorText})
        this.setState({editedHtmlText: editorText}, () => {
            this.updateDecorations()
        })
        if (this.props.onEditorTextChange) {
            this.props.onEditorTextChange(editorText)
        }
    }

    onEditorMount = (editor, monaco) => {
        this.monacoEditor = editor
        this.monacoEditor.updateOptions({
            minimap: {
                enabled: false
            },
            wordWrap: "on",
            readOnly: false
        })
        this.monacoEditor.onDidChangeCursorSelection(this.onDidChangeCursorSelection)
        this.updateEditor()
    }

    onDidChangeCursorSelection = (event) => {
        const selections = this.monacoEditor.getSelections() || []
        if (selections.length < 1) {
            return
        }
        const selection = selections[0]
        const { startLineNumber, startColumn: startColumnNumber, endLineNumber, endColumn: endColumnNumber } = selection
        const { highlightStartIndex, highlightEndIndex } = getHighlightIndicesforRange(
            startLineNumber, startColumnNumber, endLineNumber, endColumnNumber, this.getCurrentEditorText())
        if (this.props.onSelectionChanged && highlightStartIndex !== highlightEndIndex) {
            this.props.onSelectionChanged({ highlightStartIndex, highlightEndIndex })
        }
    }

    setToOutout = () => {
        this.setState({outputTabName: "output"})
    }

    setToExpectedOutout = () => {
        this.setState({outputTabName: "expectedOutput"})
    }

    saveCodetext = () => {
        const { courseId, exerciseId } = this.props
        const editedText = this.getCurrentEditorText()
        console.log({a: this.getCodeText(), editedText})
        if (this.getCodeText() === editedText) {
            return
        }
        if (!exerciseId) {
            return
        }
        // return
        this.props.dispatchFunctionAction(
            saveCourseTaskSaga, {
                courseId, taskId: exerciseId,
                courseTaskData: { files: [{filePath: "main.html", fileText: editedText}]}
            })
    }

    render = () => {
        const { expectedOutputHtml, exerciseId, userId } = this.props
        const height = this.props.height || "150px"
        let htmlTextInputCardClass = "col-12 card"
        if (this.props.showOutput) {
            htmlTextInputCardClass = "col-6 card"
        }
        const currentEditorText = this.getCurrentEditorText()
        const lastUpdateTimestampMillis = this.getExerciseLastUpdatedMillis()

        console.log({currentEditorText})
        return (<div className="card">
            {currentEditorText !== null ?
            <div className="card-body">
            <div className="container-fluid">
                <div className="row">
                    <div className={htmlTextInputCardClass}>
                        <div className="card-body">
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <div className="linkdiv nav-link active" aria-current="page">HTML</div>
                                </li>
                                {/* {expectedOutputHtml ? */}
                                    <li className="nav-item ms-auto">
                                        {this.props.exerciseId || ""}
                                    </li>
                                {/* } */}
                                <li className="nav-item ms-auto">
                                </li>
                                {exerciseId && <li className="nav-item ms-auto">
                                    <div style={{lineHeight: "normal", fontSize: "10px"}}>
                                        {lastUpdateTimestampMillis > 0 ? new Date(lastUpdateTimestampMillis).toLocaleString("en-IN"): ""}
                                    </div>
                                    {this.getCodeText() !== this.getCurrentEditorText() ?
                                        <button className="btn btn-primary ml-2" onClick={this.saveCodetext}>Save</button>:
                                        <button className="btn btn-success ml-2" disabled>Saved</button>}
                                </li>}
                            </ul>
                            <Editor
                                height={height}
                                defaultLanguage="html"
                                // defaultValue={this.getCurrentEditorText()}
                                onChange={this.onEditorChange}
                                onMount={this.onEditorMount}
                            />
                        </div>
                    </div>
                    {this.props.showOutput ?
                    <div className={this.state.isOutputExpanded ? " expanded-output card": "col-6 card"}>
                        <div className="card-body d-flex flex-column">
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    {this.state.outputTabName === "output" ?
                                        <div className="linkdiv nav-link active" aria-current="page"
                                         onClick={this.setToOutout}>Output</div> :
                                        <div className="linkdiv nav-link" aria-current="page"
                                         onClick={this.setToOutout}>Output</div>
                                    }
                                </li>
                                {exerciseId ? <li className="nav-item">
                                    {this.state.outputTabName === "expectedOutput" ?
                                        <div className="linkdiv nav-link active"
                                            onClick={this.setToExpectedOutout}>Expected Output</div> :
                                        <div className="linkdiv nav-link"
                                            onClick={this.setToExpectedOutout}>Expected Output</div>
                                    }
                                </li>: null}
                                <li className="nav-item ms-auto">
                                    <button className="btn btn-primary" onClick={this.toggleOutputExpandMode}>
                                        {!this.state.isOutputExpanded  ?
                                        <FontAwesomeIcon icon={faExpandArrowsAlt} />:
                                        <FontAwesomeIcon icon={faCompressArrowsAlt} />}
                                    </button>
                                </li>
                            </ul>
                            {/* <div className="flex-fill d-flex align-items-stretch"> */}
                                {/* <div className="col-12"> */}
                            {this.state.outputTabName === "output" ? 
                                <iframe className="flex-fill" srcDoc={this.getCurrentEditorText()} title="output"></iframe> :
                                <iframe className="flex-fill" srcDoc={expectedOutputHtml} title="output"></iframe>
                            }
                                {/* </div> */}
                            {/* </div> */}
                        </div>
                    </div>: null}
                </div>
            </div>
            </div>: null}
        </div>)
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        exercises: state.common.exercises,
        email: state.common.user?.email,
        userId: state.common.user?.userId,
    };
};

const mapDispatchToProps = dispatch => ({
});

export const HtmlEditor = promReduxConnect(HtmlEditorInternal, mapStateToProps)