import React from 'react';
import { HtmlAnswerQuizLessonPart } from '../../../util/lessonPart';
import { getExerciseWithExerciseId } from '../../../exercises/exercise';

export const CssDropdownLessonPart = () => {
    return (<div className="container-fluid">
        <h3>Dropdowns</h3>
        {/* <HtmlLessonReaderComponent lessonPartContent={htmlCssDropdownLessonPartContent}></HtmlLessonReaderComponent> */}
    </div>)
}


export const cssDropDownLessonParts = [{
    component: CssDropdownLessonPart
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-css-dropdown-1")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-css-dropdown-2")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-css-dropdown-3")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-css-dropdown-4")
    }
}]
