export const TASK_TYPE_EXERCISE_PYTHON_FILE = "pythonFileExercise"
export const TASK_TYPE_EXERCISE_HTML_FILE = "htmlFileExercise"
export const TASK_TYPE_EXERCISE_JS_FILE = "jsFileExercise"
export const TASK_TYPE_EXERCISE_PYTHON_MODULE = "pythonModuleExercise"
export const TASK_TYPE_EXERCISE_PYTHON_DJANGO = "pythonDjangoExercise"
export const TASK_TYPE_EXERCISE_MYSQL_SCRIPT = "mysqlScriptExercise"
export const TASK_TYPE_EXERCISE_MYSQL_SHELL= "mysqlShellExercise"
export const TASK_TYPE_EXERCISE_BASH_SHELL= "bashShellExercise"
export const TASK_TYPE_EXERCISE_PYTHON_DJANGO_MYSQL = "pythonDjangoMysqlExercise"
export const TASK_TYPE_EXERCISE_JS_REACT = "jsReactExercise"
export const TASK_TYPE_EXERCISE_JS_REACT_DJANGO_MYSQL = "jsReactDjangoMysqlExercise"

export const TASK_TYPE_EXERCISE_MULTIPLE_CHOICE_QUESTION = "multipleChoiceQuestion"

export const TASK_TYPE_MODULE_EXAM = "exam"

export const TASK_TYPE_LESSON_PYTHON_FILE = "pythonFileLesson"
export const TASK_TYPE_LESSON_HTML_FILE = "htmlFileLesson"
export const TASK_TYPE_LESSON_JS_FILE = "jsFileLesson"
export const TASK_TYPE_LESSON_PYTHON_MODULE = "pythonModuleLesson"
export const TASK_TYPE_LESSON_PYTHON_DJANGO = "pythonDjangoLesson"
export const TASK_TYPE_LESSON_MYSQL_SCRIPT = "mysqlScriptLesson"
export const TASK_TYPE_LESSON_MYSQL_SHELL = "mysqlShellLesson"
export const TASK_TYPE_LESSON_BASH_SHELL = "bashShellLesson"
export const TASK_TYPE_LESSON_PYTHON_DJANGO_MYSQL = "pythonDjangoMysqlLesson"
export const TASK_TYPE_LESSON_JS_REACT = "jsReactLesson"
export const TASK_TYPE_LESSON_JS_REACT_DJANGO_MYSQL = "jsReactDjangoMysqlLesson"

export const PROCESS_TYPE_PYTHON = "python"
export const PROCESS_TYPE_DJANGO = "django"
export const PROCESS_TYPE_MYSQL_SERVER = "mysqlserver"
export const PROCESS_TYPE_MYSQL_CLIENT = "mysqlscript"
export const PROCESS_TYPE_REACT = "react"

