import React from 'react';
import { HtmlAnswerQuizLessonPart } from '../../../util/lessonPart';
import { getExerciseWithExerciseId } from '../../../exercises/exercise';

export const HtmlImagesLessonPart1 = () => {
    return (<div className="container-fluid">
        <h3>Images</h3>
        <ul className="mt-3">
            <li>Images are displayed using <code>img</code> tag.</li>
            <li><code>src</code> attribute on <code>img</code> tag is used to specify the source of the image to be displayed.</li>
            <li>Images are uploaded to a webserver before being added to webpages.</li>
            <li>For now use the image at the source address 
            <code> https://upload.wikimedia.org/wikipedia/commons/thumb/5/5d/Napoleon_-_2.jpg/800px-Napoleon_-_2.jpg </code>
            in all our examples.</li>
        </ul>

        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <body>
        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5d/Napoleon_-_2.jpg/800px-Napoleon_-_2.jpg">
        </img>
    </body>
</html>`}
            height={"400px"}
            showOutput={true}
        ></HtmlEditor>
    </div>)
}

export const HtmlImagesLessonPart2 = () => {
    return (<div className="container-fluid">
        <ul className="mt-3">
            <li>Style properties <code>width</code> and <code>height</code> can be used to display the image at the size we need.</li>
        </ul>

        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <body>
        <img
            style="width:130px" 
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5d/Napoleon_-_2.jpg/800px-Napoleon_-_2.jpg">
        </img>
        <img
            style="height:140px" 
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5d/Napoleon_-_2.jpg/800px-Napoleon_-_2.jpg">
        </img>
    </body>
</html>`}
            height={"400px"}
            showOutput={true}
        ></HtmlEditor>
    </div>)
}

export const HtmlImagesLessonPart3 = () => {
    return (<div className="container-fluid">
        <ul className="mt-3">
            <li><code>float</code> style property is used to float the image to the left or right of the screen.</li>
        </ul>

        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <body>
        <img
            style="width:130px;float:right" 
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5d/Napoleon_-_2.jpg/800px-Napoleon_-_2.jpg">
        </img>
        <img
            style="height:140px;float:left" 
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5d/Napoleon_-_2.jpg/800px-Napoleon_-_2.jpg">
        </img>
    </body>
</html>`}
            height={"400px"}
            showOutput={true}
        ></HtmlEditor>
    </div>)
}


export const htmlImagesLessonParts = [{
    component: HtmlImagesLessonPart1 
}, {
    component: HtmlImagesLessonPart2,
}, {
    component: HtmlImagesLessonPart3,
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-image-1")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-image-2")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-image-3")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-image-4")
    }
}]
