import { PureComponent } from "react"
import React from 'react';

import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faAngleRight, faArrowRotateLeft, faArrowUpRightFromSquare, faBan, faCaretSquareRight, faCheckCircle, faCog, faExpandArrowsAlt, faFile, faFolder, faFolderOpen, faLightbulb, faSave, faTerminal, faTimesCircle, faWindowMaximize } from '@fortawesome/free-solid-svg-icons'

import * as bs from 'bootstrap'
import "../../dashboard/course.css"





class WebBrowserComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.iframeRef = React.createRef();
    }

    componentDidUpdate = (prevProps) => {
    }

    onRefresh = () => {
        const { httpUrl, isServerRunning } = this.props
        if (!isServerRunning) {
            return
        }
        if (this.iframeRef) {
            this.iframeRef.current.contentWindow.location.replace(httpUrl)
        }
    }

    onOpenInNewTab = () => {
        const { httpUrl, isServerRunning } = this.props
        if (!isServerRunning) {
            return
        }
        window.open(httpUrl, '_blank');
    }

    render = () => {
        const { httpUrl, isServerRunning } = this.props
        if (!isServerRunning) {
            return null
        }
        return <div className="d-flex h-100 w-100 flex-column">
            <div className="d-flex">
                <input type="text" className="form-control d-inline-block flex-grow-1" id="httpUrl"
                    style={{height: "25px", width: "unset"}}
                    placeholder=""
                    disabled={true}
                    value={httpUrl}/>
                <button className="consoleButton" onClick={this.onRefresh}>
                    <FontAwesomeIcon icon={faArrowRotateLeft}/>
                </button>
                <button className="consoleButton" onClick={this.onOpenInNewTab}>
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare}/>
                </button>
            </div>
            <div className="position-relative flex-grow-1">
                <iframe src={httpUrl}
                    ref={this.iframeRef}
                    style={{
                        height: "100%",
                        width: "100%"
                    }}/>
            </div>
        </div>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
    };
};

const mapDispatchToProps = dispatch => ({
});

export const WebBrowser = connect(
    mapStateToProps,
    mapDispatchToProps,
)(WebBrowserComponent)