import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Component, PureComponent } from "react"
import { connect } from "react-redux"
import { HTML_QUESTIONS } from "../exercises/html/htmlQuestions"
import Editor from "@monaco-editor/react"
import { getHtmlString, shuffle } from "./utils"
import { ProjectExerciseViewerComponent } from "./projectEditor/projectExerciseViewer"
import { JsEditor } from "./jsEditor"
import "../dashboard/course.css"
import { HtmlEditor } from "./htmlEditor"

const VALIDATION_STATE_UNTOUCHED = "VALIDATION_STATE_UNTOUCHED"
const VALIDATION_STATE_CORRECT = "VALIDATION_STATE_CORRECT"
const VALIDATION_STATE_INCORRECT = "VALIDATION_STATE_INCORRECT"

export const getValidationMessage = (validationState) => {
    if (validationState === VALIDATION_STATE_CORRECT) {
        return "Correct answer"
    } else if (validationState === VALIDATION_STATE_INCORRECT) {
        return "Incorrect answer"
    }
    return ""
}

export class SingleAnswerQuizLessonPart extends Component {
    constructor(props) {
        super(props)
        this.state= {
            selectedOption: null,
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.exerciseId !== this.props.exerciseId) {
            this.setState({
                selectedOption: null,
            })
        }
        if (prevProps.shuffleSeed !== this.props.shuffleSeed) {
            this.setState({
                selectedOption: null,
            })
        }
    }

    onQuizOptionChanged = (quizOptionKey, event) => {
        this.setState({selectedOption: quizOptionKey})
        const selectedOptions = new Set()
        selectedOptions.add(quizOptionKey)
        this.props.onSelectedOptionsChanged(selectedOptions)
    }

    render = () => {
        const { options, shuffleSeed } = this.props
        const { selectedOption } = this.state
        if (!options) {
            return null
        }
        const quizOptionElements = shuffle(Object.keys(options).map(quizOptionKey => {
            return (<div className="form-check" key={quizOptionKey}>
                <input className="form-check-input" 
                    checked={selectedOption === quizOptionKey}
                    type="radio" name="flexRadioDefault" id={"flexRadioDefault"+quizOptionKey}
                    onChange={(event) => this.onQuizOptionChanged(quizOptionKey, event)}/>
                <label className="form-check-label" htmlFor={"flexRadioDefault"+quizOptionKey}
                    dangerouslySetInnerHTML={{__html: getHtmlString(options[quizOptionKey].text)}}>
                </label>
            </div>)
        }), shuffleSeed)
        return (<div>
            <div className="card">
                <div className="card-body">
                    {quizOptionElements}
                </div>
            </div>
            
        </div>)
    }
}

export class MultipleCorrectAnswerQuizLessonPart extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedOptions: new Set(),
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.exerciseId !== this.props.exerciseId) {
            this.setState({
                selectedOptions: new Set(),
            })
        }
        if (prevProps.shuffleSeed !== this.props.shuffleSeed) {
            this.setState({
                selectedOptions: new Set(),
            })
        }
    }

    onQuizOptionChanged = (quizOptionKey, event) => {
        const newSelectedOptions = new Set([...this.state.selectedOptions])
        if (event.target.checked) {
            newSelectedOptions.add(quizOptionKey)
        } else {
            newSelectedOptions.delete(quizOptionKey)
        }
        this.setState({selectedOptions: newSelectedOptions})
        this.props.onSelectedOptionsChanged(newSelectedOptions)
    }

    render = () => {
        const { options, shuffleSeed } = this.props
        const { selectedOptions } = this.state
        if (!options) {
            return null
        }
        const quizOptionElements = shuffle(Object.keys(options).map(quizOptionKey => {
            return (<div className="form-check" key={quizOptionKey} style={{border: "1px solid gray"}}>
                <input className="form-check-input" type="checkbox" checked={
                    selectedOptions.has(quizOptionKey)}
                    id={"flexCheckDefault"+quizOptionKey}
                    onChange={(event) => this.onQuizOptionChanged(quizOptionKey, event)}/>
                <label className="form-check-label" htmlFor={"flexCheckDefault"+quizOptionKey}
                    dangerouslySetInnerHTML={{__html: getHtmlString(options[quizOptionKey].text)}}>
                    {/* {getHtmlString(options[quizOptionKey].text)} */}
                </label>
            </div>)
        }), shuffleSeed)
        return (<div>
            <div className="card">
                <div className="card-body">
                    {quizOptionElements}
                </div>
            </div>
        </div>)
    }
}

export class HtmlAnswerQuizLessonPart extends PureComponent {
    constructor(props) {
        super(props)
        this.selectedOptions = new Set()
    }

    render = () => {
        const { exercise } = this.props
        const { initialCodeText, expectedOutputHtml, exerciseId, questionText, questionHtml } = exercise || {}

        console.log({exercise})
        // if (!initialHtmlText) {
        //     return null
        // }
        return (<div className="card">
            <div className="card-body">
                <div style={{maxHeight: "400px", overflow: "scroll"}} dangerouslySetInnerHTML={{
                    __html: questionHtml || questionText || "Write html to get the expected output."}}></div>
                {/* <h5 className="card-title">{questionText || "Write html to get the expected output."}</h5> */}
                <HtmlEditor
                    initialCodeText={initialCodeText}
                    expectedOutputHtml={expectedOutputHtml}
                    height={"450px"}
                    exerciseId={exerciseId}
                    showOutput={true}>
                </HtmlEditor>
            </div>
        </div>)
    }
}

class MultipleChoiceAnswerLessonPartComponent extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            selectedOptions: new Set(),
            shuffleSeed: Math.random(),
        }
    }
    
    componentDidMount = () => {
        const { exercise, courseId } = this.props
        const { exerciseId } = exercise || {}
        this.props.dispatchGetExerciseSagaAction(courseId, exerciseId)
    }

    componentDidUpdate = (prevProps) => {
        const { exercise, courseId } = this.props
        const { exerciseId } = exercise || {}
        if (prevProps?.exercise?.exerciseId !== this.props?.exercise?.exerciseId) {
            this.setState({
                shuffleSeed: Math.random()
            })
            this.props.dispatchGetExerciseSagaAction(courseId, exerciseId)
            this.selectedOptions = null
        }
    }

    onSelectedOptionsChanged = (selectedOptions) => {
        this.selectedOptions = selectedOptions
    }

    getExerciseLastUpdatedMillis = () => {
    }

    getExerciseStatus = () => {
    }

    saveValidationStatus = () => {
        const { exercise, courseId } = this.props
        const { exerciseId, correctOptions } = exercise || {}

        if (!exerciseId) {
            return
        }

        const selectedOptions = this.selectedOptions || new Set()

        let validateStatus = false
        if (correctOptions.length === selectedOptions.size) {
            let i = 0
            for (i = 0; i < correctOptions.length; i++) {
                if (!selectedOptions.has(`${correctOptions[i]}`)) {
                    break
                }
            }
            
            if (correctOptions.length === i) {
                validateStatus = true
            }
        }
        this.props.dispatchSaveExerciseSagaAction(
            {courseId, exerciseId, exerciseStatus: validateStatus ? "passed": "failed"})
        this.setState({shuffleSeed: Math.random()})
    }


    render = () => {
        const { exercise } = this.props
        const lastUpdateTimestampMillis = this.getExerciseLastUpdatedMillis()
        const exerciseStatus = this.getExerciseStatus()
        const { exerciseId, questionType, correctOptions, options, questionHtml, questionCodeText } = exercise || {}

        const { shuffleSeed } = this.state
        if (!correctOptions) {
            return null
        }

        return (<div>
            <div className="nav-item ms-auto">
                <div style={{
                    lineHeight: "normal",
                    fontSize: "10px",
                    color: exerciseStatus === "passed" ? "green": "red"
                }}>
                    {lastUpdateTimestampMillis > 0 ? new Date(lastUpdateTimestampMillis).toLocaleString("en-IN"): ""}
                </div>
                <button className="btn btn-primary ml-2" onClick={this.saveValidationStatus}>Check</button>
                {exerciseStatus === "valid" ?
                <FontAwesomeIcon icon={faCheckCircle} color={"green"}
                    size={"lg"}
                    style={{marginLeft: "10px"}}/>:
                <FontAwesomeIcon icon={faTimesCircle} color={"red"}
                    size={"lg"}
                    style={{marginLeft: "10px"}}/>}
                <span style={{marginLeft: "10px"}}>{exerciseId}</span>
            </div>

            <div dangerouslySetInnerHTML={{__html: questionHtml}}></div>
            {questionCodeText ?
            <Editor
                height={"300px"}
                defaultLanguage={questionCodeText.language}
                value={questionCodeText.codeText}
                options={{
                    readOnly: true,
                    minimap: {
                        enabled: false
                    }
                }}
            />: null
            }
            {questionType == "multipleChoiceQuestion" ?
            <SingleAnswerQuizLessonPart
                options={options}
                shuffleSeed={shuffleSeed}
                exerciseId={exerciseId}
                onSelectedOptionsChanged={this.onSelectedOptionsChanged}>
            </SingleAnswerQuizLessonPart>:
            <MultipleCorrectAnswerQuizLessonPart
                options={options}
                shuffleSeed={shuffleSeed}
                exerciseId={exerciseId}
                onSelectedOptionsChanged={this.onSelectedOptionsChanged}>
            </MultipleCorrectAnswerQuizLessonPart>}
        </div>)



    }
}

const mapStateToPropsMultipleChoiceAnswerLessonPartComponent = (state, ownProps) => {
    return {
        email: state.common.user?.email,
        userId: state.common.user?.userId,
        codeRuns: state.common.codeRuns
    };
};

const mapDispatchToPropsMultipleChoiceAnswerLessonPartComponent = dispatch => ({
    dispatchGetExerciseSagaAction: (courseId, exerciseId) => dispatch(
        getExerciseSagaAction(courseId, exerciseId)),
    dispatchSaveExerciseSagaAction: (courseId, exerciseId, exerciseData) => dispatch(saveCourseTaskSagaAction(
        courseId, exerciseId, exerciseData)),
});

export const MultipleChoiceAnswerLessonPart = connect(
    mapStateToPropsMultipleChoiceAnswerLessonPartComponent,
    mapDispatchToPropsMultipleChoiceAnswerLessonPartComponent,
)(MultipleChoiceAnswerLessonPartComponent)


export class JsAnswerQuizLessonPart extends Component {
    constructor(props) {
        super(props)
        this.selectedOptions = new Set()
    }

    render = () => {
        const { exercise } = this.props
        const { initialCodeText, expectedOutputText, exerciseId, questionHtml } = exercise || {}
        // if (!initialCodeText && initialCodeText !== "") {
        //     return null
        // }
        return (<div className="card">
            <div className="card-body">
                <div style={{maxHeight: "400px", overflow: "scroll"}} dangerouslySetInnerHTML={{__html: questionHtml}}></div>
                <JsEditor
                    initialCodeText={initialCodeText || ""}
                    expectedOutputText={expectedOutputText}
                    height={"450px"}
                    exerciseId={exerciseId}
                    showOutput={true}>
                </JsEditor>
            </div>
        </div>)
    }
}


class ProjectAnswerQuizLessonPartComponent extends PureComponent {
    constructor(props) {
        super(props)
        this.selectedOptions = new Set()
    }

    getQuestionPartsStatus = () => {
        const { exerciseTask, exercise } = this.props
        const { exerciseId } = exercise || {}

        if (exerciseId && exerciseTask) {
            return exerciseTask.exerciseQuestionPartsStatus || {}
        }

        return {}
    }

    render = () => {
        const { exercise, userId, courseId, exerciseTask } = this.props
        const { expectedOutputText, exerciseId,
             questionHtml, questionParts, exerciseType } = exercise || {}

        console.log(this.props)

        const questionPartsElements = []
        const questionPartsStatus = this.getQuestionPartsStatus()
        if (questionParts && questionParts.length > 0) {
            for (let i = 0; i < questionParts.length; i++) {
                const questionPartId = questionParts[i].questionPartId
                questionPartsElements.push(<div className="d-flex">
                    <div className="me-2">{i + 1}</div>
                    <div>
                    {questionPartsStatus[questionPartId]?.question_part_status === "valid" ?
                        <FontAwesomeIcon icon={faCheckCircle} color={"green"} size={"lg"}
                            style={{marginRight: "5px"}}/>:
                        questionPartsStatus[questionPartId]?.question_part_status === "invalid" ?
                        <FontAwesomeIcon icon={faTimesCircle} color={"red"} size={"lg"}
                            style={{marginRight: "5px"}}/>: null}
                    </div>

                    <div className="flex-grow-1" dangerouslySetInnerHTML={{
                        __html: questionParts[i].html || "" }}>
                    </div>
                </div>)
            }
        }

        return (<div className="d-flex flex-column">
            <div style={{maxHeight: "400px", overflowY: "scroll"}}>
                <div dangerouslySetInnerHTML={{__html: questionHtml}}></div>
                {questionPartsElements.length > 0 ?
                <div>{questionPartsElements}</div>
                :null}
                {/* <h5 className="card-title" dangerouslySetInnerHTML={{__html: questionHtml}}></h5> */}
            </div>

            <div className="flex-grow-1">
            <ProjectExerciseViewerComponent
                // initialCodeText={initialCodeText}
                // expectedOutputText={expectedOutputText}
                projectId={`${exerciseId}-${userId}`}
                exerciseId={exerciseId}
                courseId={courseId}
                projectType={exerciseType}
                exerciseTask={exerciseTask}
                height={"800px"}
                // showOutput={true}
                >
            </ProjectExerciseViewerComponent>
            </div>
        </div>)
    }
}

const mapStateToPropsProjectAnswerQuizLessonPartComponent = (state, ownProps) => {
    return {
        email: state.common.user?.email,
        userId: state.common.user?.userId,
    };
};

const mapDispatchToPropsProjectAnswerQuizLessonPartComponent = dispatch => ({
});

export const ProjectAnswerQuizLessonPart = connect(
    mapStateToPropsProjectAnswerQuizLessonPartComponent,
    mapDispatchToPropsProjectAnswerQuizLessonPartComponent,
)(ProjectAnswerQuizLessonPartComponent)

export const getComponentForQuestionId = (questionId, lessionPartId) => {
    const question = HTML_QUESTIONS.find(q => q.questionId === questionId)
    return {
        component: question.questionType === "multipleChoiceQuestion" ?
            SingleAnswerQuizLessonPart: MultipleCorrectAnswerQuizLessonPart,
        needsValidation: true,
        lessionPartId,
        props: {
            questionId: questionId,
            quizQuestion: question.question,
            quizOptions: question.options,
            correctOptions: question.correctOptions,
            correctOption: question.correctOption,
        }
    }
}
