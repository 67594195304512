import React, { Component, PureComponent } from 'react'
import { promAxios } from '../../../store/promAxios'
import ButtonComponent from '../ButtonComponent/buttonComponent'

export class ContactUsMessageComponent extends PureComponent {
    constructor(props) {
    super(props);
    this.state = {
      messageData: null,
      errorFetchContactUsMessage: null
        }
    }

    //This is for sending API to get the messages
    clickHandler = (e) => {
    e.preventDefault()
    promAxios.get('/api/v1/contactUsMessages')
    .then (response => {
        this.setState({ 
        messageData: response.data, 
        errorFetchContactUsMessage: null })
    })
    .catch (error => {
        this.setState({ 
        messageData: [], 
        errorFetchContactUsMessage: "Failed to fetch contact us messages" });
    })
    }

    render() {
    const { messageData, errorFetchContactUsMessage } = this.state;
    return (
        <>
        <h4>Contact Us Message log</h4>
        <ButtonComponent type="primary" onClick={this.clickHandler}>Display Messages</ButtonComponent>
        {errorFetchContactUsMessage && <div>Error: {errorFetchContactUsMessage}</div>}
        {messageData && (
        <div className="table-responsive">
            <table className="table align-middle">
            <thead>
                <tr>
                <th className="align-top">Serial No</th>
                <th className="align-top">Name</th>
                <th className="align-top">Email</th>
                <th className="align-top">Mobile Number</th>
                <th className="align-top">IP Address</th>
                <th className="align-top">Message</th>
                </tr>
            </thead>
            <tbody>
                {messageData.map((message, index) => (
                <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{message.name}</td>
                <td>{message.email}</td>
                <td>{message.phoneNumber}</td>
                <td>{message.ipAddress}</td>
                <td>{message.messageText}</td>
                </tr>
                ))}
            </tbody>
            </table>
        </div>)}
        </>
        );
    }
}
