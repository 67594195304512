import { dj1Html } from "./html/dj/dj1.html"
import { dj2Html } from "./html/dj/dj2.html"
import { dj3Html } from "./html/dj/dj3.html"
import { dj4Html } from "./html/dj/dj4.html"
import { dj5Html } from "./html/dj/dj5.html"
import { htmlCssExerciseText2, htmlCssExerciseText3 } from "./html/htmlCssExerciseText"
import { htmlDivExerciseText1, htmlDivExerciseText2, htmlDivExerciseText3, htmlDivExerciseText4 } from "./html/htmlDivExerciseText"
import { htmlTable4Text } from "./html/htmlTable4"
import { htmlTextFormatting4Text } from "./html/htmlTable4 copy"
import { wiki1Html } from "./html/wiki/wiki1.html"
import { wiki10Html } from "./html/wiki/wiki10.html"
import { wiki11Html } from "./html/wiki/wiki11.html"
import { wiki2Html } from "./html/wiki/wiki2.html"
import { wiki3Html } from "./html/wiki/wiki3.html"
import { wiki4Html } from "./html/wiki/wiki4.html"
import { wiki5Html } from "./html/wiki/wiki5.html"
import { wiki6Html } from "./html/wiki/wiki6.html"
import { wiki7Html } from "./html/wiki/wiki7.html"
import { wiki8Html } from "./html/wiki/wiki8.html"
import { wiki9Html } from "./html/wiki/wiki9.html"

export const HTML_EXERCISES = [{
    exerciseId: "html-color-1",
    initialHtmlText: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1>\n"+
        "      Napoleon Bonaparte\n"+
        "    </h1>\n"+
        "  </body>\n"+
        "</html>",
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1 style=\"color:red\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h1>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-color-2",
    initialHtmlText: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1>\n"+
        "      Napoleon Bonaparte\n"+
        "    </h1>\n"+
        "  </body>\n"+
        "</html>",
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1 style=\"color:blue\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h1>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-color-3",
    initialHtmlText:
        "<html>\n"+
        "  <body>\n"+
        "    <h1>\n"+
        "      Napoleon Bonaparte\n"+
        "    </h1>\n"+
        "  </body>\n"+
        "</html>",
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1 style=\"color:pink\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h1>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-color-4",
    questionText: "Use colors green, magenta, purple and indigo and write html to get the expected output.",
    initialHtmlText: 
        "<html>\n"+
        "  <body>\n"+
        "    <h3>\n"+
        "      Napoleon Bonaparte\n"+
        "    </h3>\n"+
        "  </body>\n"+
        "</html>",
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h3 style=\"color:green\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h3>\n"+
        "    <h3 style=\"color:magenta\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h3>\n"+
        "    <h3 style=\"color:purple\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h3>\n"+
        "    <h3 style=\"color:indigo\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h3>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-text-size-1",
    initialHtmlText: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1>\n"+
        "      Napoleon Bonaparte\n"+
        "    </h1>\n"+
        "  </body>\n"+
        "</html>",
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1 style=\"font-size: 13px\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h1>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-text-size-2",
    initialHtmlText: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1>\n"+
        "      Napoleon Bonaparte\n"+
        "    </h1>\n"+
        "  </body>\n"+
        "</html>",
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1 style=\"font-size: 27px\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h1>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-text-size-3",
    initialHtmlText: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1>\n"+
        "      Napoleon Bonaparte\n"+
        "    </h1>\n"+
        "  </body>\n"+
        "</html>",
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1 style=\"font-size:18px;color:blue\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h1>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-text-size-4",
    questionText: "Use colors green, magenta, purple and indigo and write html to get the expected output.",
    initialHtmlText: 
        "<html>\n"+
        "  <body>\n"+
        "    <h3>\n"+
        "      Napoleon Bonaparte\n"+
        "    </h3>\n"+
        "  </body>\n"+
        "</html>",
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h3 style=\"font-size:12px;color:green\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h3>\n"+
        "    <h3 style=\"font-size:18px;color:magenta\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h3>\n"+
        "    <h3 style=\"font-size:24px;color:purple\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h3>\n"+
        "    <h3 style=\"font-size:32px;color:indigo\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h3>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-background-color-1",
    initialHtmlText: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1>\n"+
        "      Napoleon Bonaparte\n"+
        "    </h1>\n"+
        "  </body>\n"+
        "</html>",
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1 style=\"background-color:red\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h1>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-background-color-2",
    initialHtmlText: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1>\n"+
        "      Napoleon Bonaparte\n"+
        "    </h1>\n"+
        "  </body>\n"+
        "</html>",
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1 style=\"background-color:blue\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h1>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-background-color-3",
    initialHtmlText:
        "<html>\n"+
        "  <body>\n"+
        "    <h1>\n"+
        "      Napoleon Bonaparte\n"+
        "    </h1>\n"+
        "  </body>\n"+
        "</html>",
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1 style=\"background-color:pink\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h1>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-background-color-4",
    questionText: "Use colors green, magenta, purple and indigo and write html to get the expected output.",
    initialHtmlText: 
        "<html>\n"+
        "  <body>\n"+
        "    <h3>\n"+
        "      Napoleon Bonaparte\n"+
        "    </h3>\n"+
        "  </body>\n"+
        "</html>",
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h3 style=\"background-color:green\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h3>\n"+
        "    <h3 style=\"background-color:magenta\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h3>\n"+
        "    <h3 style=\"background-color:purple\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h3>\n"+
        "    <h3 style=\"background-color:indigo\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h3>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-border-1",
    initialHtmlText: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1>\n"+
        "      Napoleon Bonaparte\n"+
        "    </h1>\n"+
        "  </body>\n"+
        "</html>",
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1 style=\"border:4px solid red\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h1>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-border-2",
    initialHtmlText: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1>\n"+
        "      Napoleon Bonaparte\n"+
        "    </h1>\n"+
        "  </body>\n"+
        "</html>",
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1 style=\"border:2px dotted green\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h1>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-border-3",
    initialHtmlText:
        "<html>\n"+
        "  <body>\n"+
        "    <h1>\n"+
        "      Napoleon Bonaparte\n"+
        "    </h1>\n"+
        "  </body>\n"+
        "</html>",
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1 style=\"border:4px dashed pink\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h1>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-border-4",
    initialHtmlText: 
        "<html>\n"+
        "  <body>\n"+
        "    <h3>\n"+
        "      Napoleon Bonaparte\n"+
        "    </h3>\n"+
        "  </body>\n"+
        "</html>",
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h3 style=\"border:4px dashed pink\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h3>\n"+
        "    <h3 style=\"border:4px dashed magenta\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h3>\n"+
        "    <h3 style=\"border:4px dashed purple\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h3>\n"+
        "    <h3 style=\"border:4px dashed indigo\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h3>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-border-radius-1",
    initialHtmlText: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1>\n"+
        "      Napoleon Bonaparte\n"+
        "    </h1>\n"+
        "  </body>\n"+
        "</html>",
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1 style=\"border:4px solid red; border-radius: 8px\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h1>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-border-radius-2",
    initialHtmlText: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1>\n"+
        "      Napoleon Bonaparte\n"+
        "    </h1>\n"+
        "  </body>\n"+
        "</html>",
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1 style=\"border:2px dotted green; border-radius: 10px\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h1>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-border-radius-3",
    initialHtmlText:
        "<html>\n"+
        "  <body>\n"+
        "    <h1>\n"+
        "      Napoleon Bonaparte\n"+
        "    </h1>\n"+
        "  </body>\n"+
        "</html>",
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1 style=\"border:4px dashed blue; border-radius: 30px\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h1>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-border-radius-4",
    initialHtmlText: 
        "<html>\n"+
        "  <body>\n"+
        "    <h3>\n"+
        "      Napoleon Bonaparte\n"+
        "    </h3>\n"+
        "  </body>\n"+
        "</html>",
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h3 style=\"border:4px solid pink; border-radius: 30px\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h3>\n"+
        "    <h3 style=\"border:4px dashed magenta; border-radius: 3px\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h3>\n"+
        "    <h3 style=\"border:4px solid purple; border-radius: 10px\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h3>\n"+
        "    <h3 style=\"border:4px dashed indigo; border-radius: 20px\">\n"+
        "      Napoleon Bonaparte\n"+
        "    </h3>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-text-alignment-1",
    initialHtmlText:`
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h3 style=\"border:2px solid red;text-align:left\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-text-alignment-2",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h3 style=\"border:2px solid red;text-align:right\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-text-alignment-3",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h3 style=\"border:2px solid red;text-align:center\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-text-alignment-4",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h3 style=\"border:2px solid red;text-align:right\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "    <h3 style=\"border:2px solid red;text-align:center\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "    <h3 style=\"border:2px solid red;text-align:left\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "    <h3 style=\"border:2px solid red;text-align:justify\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-font-weight-1",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h3 style=\"border:2px solid red;text-align:left;font-weight:bold\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-font-weight-2",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h3 style=\"border:2px solid red;text-align:right;font-weight:bolder\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-font-weight-3",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h3 style=\"border:2px solid red;text-align:center;font-weight:light\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-font-weight-4",
initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h3 style=\"border:2px solid red;text-align:right;font-weight:bold\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "    <h3 style=\"border:2px solid red;text-align:justify;font-weight:bolder\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "    <h3 style=\"border:2px solid red;text-align:left;font-weight:light\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "    <h3 style=\"border:2px solid red;text-align:center;font-weight:normal\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-text-decoration-1",
initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h3 style=\"border:2px solid red;text-align:left;text-decoration:underline\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-text-decoration-2",
initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h3 style=\"border:2px solid red;text-align:right;text-decoration:overline\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-text-decoration-3",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h3 style=\"border:2px solid red;text-align:center;text-decoration:line-through\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-text-decoration-4",
initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h3 style=\"border:2px solid red;text-align:justify;font-weight:bolder;text-decoration:line-through\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "    <h3 style=\"border:2px solid red;text-align:right;font-weight:normal;text-decoration:underline\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "    <h3 style=\"border:2px solid red;text-align:left;font-weight:light;text-decoration:overline\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "    <h3 style=\"border:2px solid red;text-align:center;font-weight:bold\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-font-style-1",
initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h3 style=\"border:2px solid red;text-align:left;text-decoration:underline;font-style:italic\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-font-style-2",
initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h3 style=\"border:2px dashed solid blue;text-align:right;text-decoration:overline;font-style:oblique\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-font-style-3",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h3 style=\"border:2px solid green;text-align:center;text-decoration:line-through;font-style:italic\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-font-style-4",
initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h3 style=\"border:2px solid green;text-align:right;font-weight:bold;text-decoration:underline;font-style:italic\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "    <h3 style=\"border:2px dotted red;text-align:justify;font-weight:bolder;text-decoration:line-through;font-style:normal\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "    <h3 style=\"border:2px dashed blue;text-align:left;font-weight:light;text-decoration:overline;font-style:italic\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "    <h3 style=\"border:2px solid red;text-align:center;font-weight:normal;font-style:oblique\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-font-family-1",
initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h3 style=\"border:2px solid red;text-align:left;text-decoration:underline;font-style:italic;font-family:monospace\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-font-family-2",
initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h3 style=\"font-family:Arial;border:2px dashed solid blue;text-align:right;text-decoration:overline;font-style:oblique\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-font-family-3",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h3 style=\"font-family:sans;border:2px solid green;text-align:center;text-decoration:line-through;font-style:italic\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-font-family-4",
initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h3 style=\"font-family:serif;border:2px solid green;text-align:right;font-weight:bold;text-decoration:underline;font-style:italic\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "    <h3 style=\"font-family:sans;border:2px dotted red;text-align:justify;font-weight:bolder;text-decoration:line-through;font-style:normal\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "    <h3 style=\"font-family:times new roman;border:2px dashed blue;text-align:left;font-weight:light;text-decoration:overline;font-style:italic\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "    <h3 style=\"font-family:helvetica;border:2px solid red;text-align:center;font-weight:normal;font-style:oblique\">\n"+
        "      The Moon is a relatively large, terrestrial, planet-like natural satellite, with a diameter about one-quarter of Earth's\n"+
        "    </h3>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-paragraph-1",
    initialHtmlText: 
        "<html>\n"+
        "  <body>\n"+
        "    <p>\n"+
        "    </p>\n"+
        "  </body>\n"+
        "</html>",
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <p>\n"+
        "      Earth is the third planet from the Sun and the only astronomical object known to harbor life."+
        " About 29.2% of Earth's surface is land consisting of continents and islands. The remaining 70.8% is covered"+
        " with water, mostly by oceans, seas, gulfs, and other salt-water bodies, but also by lakes, rivers, and other"+
        " freshwater, which together constitute the hydrosphere. Much of Earth's polar regions are covered in ice.\n"+
        "    </p>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-paragraph-2",
    initialHtmlText: 
        "<html>\n"+
        "  <body>\n"+
        "    <p>\n"+
        "    </p>\n"+
        "  </body>\n"+
        "</html>",
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <p style=\"color:blue;font-family:monospace;font-size:16px;font-weight:bold;text-align:right;text-decoration:underline;\">\n"+
        "      Earth is the third planet from the Sun and the only astronomical object known to harbor life."+
        " About 29.2% of Earth's surface is land consisting of continents and islands. The remaining 70.8% is covered"+
        " with water, mostly by oceans, seas, gulfs, and other salt-water bodies, but also by lakes, rivers, and other"+
        " freshwater, which together constitute the hydrosphere. Much of Earth's polar regions are covered in ice.\n"+
        "    </p>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-paragraph-3",
initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1 style=\"font-weight:bold;text-decoration:underline;text-align:center\">Earth</h1>\n"+
        "    <p style=\"color:grey;font-family:monospace;font-size:13px;text-align:justify\">\n"+
        "      Earth is the third planet from the Sun and the only astronomical object known to harbor life."+
        " About 29.2% of Earth's surface is land consisting of continents and islands. The remaining 70.8% is covered"+
        " with water, mostly by oceans, seas, gulfs, and other salt-water bodies, but also by lakes, rivers, and other"+
        " freshwater, which together constitute the hydrosphere. Much of Earth's polar regions are covered in ice.\n"+
        "    </p>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-paragraph-4",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1 style=\"font-weight:bold;text-decoration:underline;text-align:center\">Earth</h1>\n"+
        "    <h3 style=\"font-weight:bold;text-decoration:underline;\">Formation</h1>\n"+
        "    <p style=\"color:grey;font-family:arial;font-size:13px;text-align:justify\">\n"+
        "      The bodies in the Solar System formed and evolved with the Sun. In theory, a solar nebula partitions a volume out of a molecular cloud by gravitational collapse, which begins to spin and flatten into a circumstellar disk, and then the planets grow out of that disk with the Sun.\n"+
        "    </p>\n"+
        "    <h3 style=\"font-weight:bold;text-decoration:underline;\">Geological history</h1>\n"+
        "    <p style=\"color:grey;font-family:arial;font-size:13px;text-align:justify\">\n"+
        "      Earth's atmosphere and oceans were formed by volcanic activity and outgassing. Water vapor from these sources condensed into the oceans, augmented by water and ice from asteroids, protoplanets, and comets.\n"+
        "    </p>\n"+
        "    <h3 style=\"font-weight:bold;text-decoration:underline;\">Origin of life and evolution</h1>\n"+
        "    <p style=\"color:grey;font-family:arial;font-size:13px;text-align:justify\">\n"+
        "      Chemical reactions led to the first self-replicating molecules about four billion years ago. A half billion years later, the last common ancestor of all current life arose.\n"+
        "    </p>\n"+
        "    <h3 style=\"font-weight:bold;text-decoration:underline;\">Future</h1>\n"+
        "    <p style=\"color:grey;font-family:arial;font-size:13px;text-align:justify\">\n"+
        "      The Sun will evolve to become a red giant in about 5 billion years. Models predict that the Sun will expand to roughly 1 AU, about 250 times its present radius. Earth's fate is less clear. As a red giant, the Sun will lose roughly 30% of its mass, so, without tidal effects, Earth will move to an orbit 1.7 AU from the Sun when the star reaches its maximum radius.\n"+
        "    </p>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-text-formatting-1",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <p>\n"+
        "      Earth is the <em>third</em> planet from the Sun.\n"+
        "    </p>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-text-formatting-2",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <p>\n"+
        "      Earth is the <strong>third</strong> planet from the Sun.\n"+
        "    </p>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-text-formatting-3",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <p>\n"+
        "      Earth is the third planet from the Sun<sup>[1][2]</sup>. It's atmosphere is composed mostly of N<sub>2</sub>,"+
        " O<sub>2</sub>\n"+
        "    </p>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-text-formatting-4",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: htmlTextFormatting4Text
}, {
    exerciseId: "html-list-1",
    initialHtmlText: 
        "<html>\n"+
        "  <body>\n"+
        "    <p>\n"+
        "    </p>\n"+
        "  </body>\n"+
        "</html>",
    expectedOutputHtml: `
<ul>
    <li>Ginger</li>
    <li>Brinjal</li>
    <li>Potato</li>
    <li>Tomato</li>
</ul>
`
}, {
    exerciseId: "html-list-2",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: `
    <ol>
        <li>Ginger</li>
        <li>Brinjal</li>
        <li>Potato</li>
        <li>Tomato</li>
    </ol>
`
}, {
    exerciseId: "html-list-3",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: `<ol>
    <li>Chapter One
      <ul>
        <li>Section One</li>
        <li>Section Two </li>
        <li>Section Three </li>
      </ul>
    </li>
    <li>Chapter Two</li>
    <li>Chapter Three  </li>
  </ol>
`
}, {
    exerciseId: "html-list-4",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: `
    <p>List of Indian States with their capital</p>  
    <ol>  
        <li>Delhi  
            <ul>  
                <li>NewDelhi</li>  
            </ul>  
        </li>  
        <li>Haryana  
            <ul>  
                <li>Chandigarh</li>  
            </ul>  
        </li>  
        <li>Gujarat  
            <ul>  
                <li>Gandhinagar</li>  
            </ul>  
        </li>  
        <li>Rajasthan   
            <ul>  
                <li>Jaipur</li>  
            </ul>  
        </li>  
        <li>Maharashtra  
            <ul>  
                <li>Mumbai</li>  
            </ul>  
        </li>  
        <li>Uttarpradesh  
            <ul>  
                <li>Lucknow</li></ul>  
        </li>  
    </ol>  
    `
}, {
    exerciseId: "html-anchor-1",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1 style=\"font-weight:bold;text-decoration:underline;text-align:center\">Earth</h1>\n"+
        "    <p style=\"color:grey;font-family:arial;font-size:13px;text-align:justify\">\n"+
        "      <a href=\"https://en.wikipedia.org/wiki/Earth\">Earth</a>"+
        " is the third planet from the Sun and the only astronomical object known to harbor life.\n"+
        "    </p>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-anchor-2",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1 style=\"font-weight:bold;text-decoration:underline;text-align:center\">Earth</h1>\n"+
        "    <p style=\"color:blue;font-family:monospace;font-size:16px;text-align:center\">\n"+
        "      <a href=\"https://en.wikipedia.org/wiki/Earth\">Earth</a>"+
        " is the third <a href=\"https://en.wikipedia.org/wiki/Planet\">planet</a>"+
        " from the <a href=\"https://en.wikipedia.org/wiki/Sun\">Sun</a>"+
        " and the only <a href=\"https://en.wikipedia.org/wiki/Astronomical_object\">astronomical object</a> known to harbor life.\n"+
        "    </p>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-anchor-3",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1 style=\"font-weight:bold;text-decoration:underline;text-align:center\">Earth</h1>\n"+
        "    <h3 style=\"font-weight:bold;text-decoration:underline;\">Geological history</h1>\n"+
        "    <p style=\"font-family:arial;font-size:16px;font-style:italic\">Main article: <a href=\"https://en.wikipedia.org/wiki/Geological_history_of_Earth\">Geological history of Earth</a></p>"+
        "    <p style=\"color:grey;font-family:monospace;font-size:14px;text-align:justify\">\n"+
        "      <a href=\"https://en.wikipedia.org/wiki/Atmosphere_of_Earth\">Earth's atmosphere</a> and"+
        " <a href=\"https://en.wikipedia.org/wiki/Ocean\">oceans</a> "+
        " were formed by <a href=\"https://en.wikipedia.org/wiki/Volcano\">volcanic activity</a>"+
        " and <a href=\"https://en.wikipedia.org/wiki/Outgassing\">outgassing</a>. Water vapor from these"+
        " sources <a href=\"https://en.wikipedia.org/wiki/Origin_of_water_on_Earth\">condensed</a> into the oceans,"+
        " augmented by water and ice from <a href=\"https://en.wikipedia.org/wiki/Asteroid\">asteroids</a>,"+
        " <a href=\"https://en.wikipedia.org/wiki/Protoplanet\">protoplanets</a>, and"+
        " <a href=\"https://en.wikipedia.org/wiki/Comet\">comets</a>."+
        " Sufficient water to fill the oceans may have been on Earth since it formed. In this model,"+
        " atmospheric <a href=\"https://en.wikipedia.org/wiki/Greenhouse_gas\">greenhouse gases</a>"+
        " kept the oceans from freezing when the newly forming Sun"+
        " <a href=\"https://en.wikipedia.org/wiki/Faint_young_Sun_paradox\">had only 70%</a> of its"+
        " current <a href=\"https://en.wikipedia.org/wiki/Solar_luminosity\">luminosity</a>. By"+
        " 3.5 Ga, <a href=\"https://en.wikipedia.org/wiki/Earth%27s_magnetic_field\">Earth's magnetic field</a> was"+
        " established, which helped prevent the atmosphere from being stripped away by"+
        " the <a href=\"https://en.wikipedia.org/wiki/Solar_wind\">solar wind</a>.\n"+
        "    </p>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-anchor-4",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1 style=\"text-align:center;text-decoration:underline\">Earth-Moon system</h1>\n"+
        "    <p style=\"font-style:italic;\">Main articles: \n"+
        "      <a href=\"https://en.wikipedia.orghttps://en.wikipedia.org/wiki/Orbit_of_the_Moon\">Orbit of the Moon</a>\n"+
        "      and <a href=\"https://en.wikipedia.orghttps://en.wikipedia.org/wiki/Satellite_system_(astronomy)\">\n"+
        "        Satellite system (astronomy)</a>\n"+
        "    </p>\n"+
        "    <p style=\"font-style:italic;\">See also: <a href=\"https://en.wikipedia.orghttps://en.wikipedia.org/wiki/Claimed_moons_of_Earth\">\n"+
        "      Claimed moons of Earth</a>, <a href=\"https://en.wikipedia.orghttps://en.wikipedia.org/wiki/Quasi-satellite\">\n"+
        "        Quasi-satellite</a>, <a href=\"https://en.wikipedia.orghttps://en.wikipedia.org/wiki/Co-orbital_moon\">Co-orbital moon</a>,\n"+
        "         <a href=\"https://en.wikipedia.orghttps://en.wikipedia.org/wiki/Co-orbital_configuration\">Co-orbital configuration</a>,\n"+
        "          and <a href=\"https://en.wikipedia.orghttps://en.wikipedia.org/wiki/Temporary_satellite\">Temporary satellite</a>\n"+
        "    </p>\n"+
        "    <h3>Moon</h3>\n"+
        "    <p style=\"font-style:italic;\">Main articles: <a href=\"https://en.wikipedia.orghttps://en.wikipedia.org/wiki/Moon\">Moon</a> \n"+
        "      and <a href=\"https://en.wikipedia.orghttps://en.wikipedia.org/wiki/Lunar_theory\">Lunar theory</a>\n"+
        "    </p>\n"+
        "    <p>The Moon is a relatively large, <a href=\"https://en.wikipedia.org/wiki/Terrestrial_planet\">terrestrial</a>,\n"+
        "      planet-like <a href=\"https://en.wikipedia.org/wiki/Natural_satellite\">natural satellite</a>,\n"+
        "       with a diameter about one-quarter of Earth's. It is the largest moon in the Solar System relative\n"+
        "        to the size of its planet, although <a href=\"https://en.wikipedia.org/wiki/Charon_(moon)\">Charon</a>\n"+
        "        is larger relative to the <a href=\"https://en.wikipedia.org/wiki/Dwarf_planet\">dwarf planet</a>\n"+
        "        <a href=\"https://en.wikipedia.org/wiki/Pluto\">Pluto</a>. The natural satellites of other planets are\n"+
        "        also referred to as moons, after Earth's. The most widely accepted theory of the Moon's origin, the\n"+
        "        <a href=\"https://en.wikipedia.org/wiki/Giant-impact_hypothesis\">giant-impact hypothesis</a>, states that\n"+
        "        it formed from the collision of a Mars-size protoplanet called Theia with the early Earth.\n"+
        "        This hypothesis explains (among other things) the Moon's relative lack of iron and volatile elements and the\n"+
        "        fact that its composition is nearly identical to that of Earth's crust.\n"+
        "    </p>\n"+
        "    <p>The gravitational attraction between Earth and the Moon causes <a href=\"https://en.wikipedia.org/wiki/Tide\">tides</a>\n"+
        "       on Earth. The same effect on the Moon has led to its <a href=\"https://en.wikipedia.org/wiki/Tidal_locking\">tidal\n"+
        "        locking</a>: its rotation period is the same as the time it takes to orbit Earth. As a result,\n"+
        "        it always presents the same face to the planet. As the Moon orbits Earth, different parts of its\n"+
        "        face are illuminated by the Sun, leading to the <a href=\"https://en.wikipedia.org/wiki/Lunar_phase\">lunar phases</a>.\n"+
        "    </p>\n"+
        "    <p>The Moon may have dramatically affected the development of life by moderating the planet's climate.\n"+
        "      <a href=\"https://en.wikipedia.org/wiki/Paleontology\">Paleontological</a> evidence and computer simulations show that\n"+
        "      Earth's axial tilt is stabilized by tidal interactions with the Moon. Some theorists think that\n"+
        "      without this stabilization against the <a href=\"https://en.wikipedia.org/wiki/Torque\">torques</a> applied by the\n"+
        "      Sun and planets to Earth's equatorial bulge, the rotational axis might be chaotically unstable, exhibiting large\n"+
        "      changes over millions of years, as is the case for Mars, though this is disputed.\n"+
        "    </p>\n"+
        "    <p>Viewed from Earth, the Moon is just far enough away to have almost the same apparent-sized disk as the Sun.\n"+
        "      The <a href=\"https://en.wikipedia.org/wiki/Angular_size\">angular size</a> (or\n"+
        "      <a href=\"https://en.wikipedia.org/wiki/Solid_angle\">solid angle</a>) of these two bodies match because,\n"+
        "      although the Sun's diameter is about 400 times as large as the Moon's, it is also 400 times more distant.\n"+
        "      This allows total and annular solar eclipses to occur on Earth.\n"+
        "    </p>\n"+
        "    <h3>Asteroids and artificial satellites</h3>\n"+
        "    <p style=\"font-style:italic;\">Main article: <a href=\"https://en.wikipedia.org/wiki/Near-Earth_object\">Near-Earth object</a></p>\n"+
        "    <p>Earth's <a href=\"https://en.wikipedia.org/wiki/Co-orbital_configuration\">co-orbital asteroids</a>\n"+
        "      population consists of <a href=\"https://en.wikipedia.org/wiki/Quasi-satellite\">quasi-satellites</a>, objects with a\n"+
        "      <a href=\"https://en.wikipedia.org/wiki/Horseshoe_orbit\">horseshoe orbit</a> and \n"+
        "      <a href=\"https://en.wikipedia.org/wiki/Trojan_(celestial_body)\">trojans</a>. There are\n"+
        "       at least five quasi-satellites, including <a href=\"https://en.wikipedia.org/wiki/469219_Kamo%CA%BBoalewa\">469219 Kamoʻoalewa</a>.\n"+
        "        A <a href=\"https://en.wikipedia.org/wiki/Earth_trojan\">trojan asteroid</a> companion,\n"+
        "         <a href=\"https://en.wikipedia.org/wiki/2010_TK7\">2010 TK, is <a href=\"https://en.wikipedia.org/wiki/Libration\">\n"+
        "          librating</a> around the leading <a href=\"https://en.wikipedia.org/wiki/Lagrangian_point\">Lagrange triangular point</a>, L4,\n"+
        "           in <a href=\"https://en.wikipedia.org/wiki/Earth%27s_orbit\">Earth's orbit</a> around the Sun.\n"+
        "       The tiny <a href=\"https://en.wikipedia.org/wiki/Near-Earth_asteroid\">near-Earth asteroid</a>\n"+
        "        <a href=\"https://en.wikipedia.org/wiki/2006_RH120\">2006 RH 120</a> makes close approaches to the \n"+
        "        Earth–Moon system roughly every twenty years. During these approaches, it can orbit Earth for brief \n"+
        "        periods of time.\n"+
        "    </p>\n"+
        "    <p>As of April, 2020, there are 2,666 operational, human-made <a href=\"https://en.wikipedia.org/wiki/Satellite\">\n"+
        "      satellites</a> orbiting Earth. There are also inoperative satellites, including \n"+
        "      <a href=\"https://en.wikipedia.org/wiki/Vanguard_1\">Vanguard 1</a>, the oldest satellite currently in orbit, \n"+
        "      and over 16,000 pieces of tracked <a href=\"https://en.wikipedia.org/wiki/Space_debris\">space debris</a>. \n"+
        "      Earth's largest artificial satellite is the <a href=\"https://en.wikipedia.org/wiki/International_Space_Station\">\n"+
        "        International Space Station</a>.\n"+
        "    </p>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-image-1",
    questionText: `Use the image at the link
 https://upload.wikimedia.org/wikipedia/commons/thumb/9/97/The_Earth_seen_from_Apollo_17.jpg/1024px-The_Earth_seen_from_Apollo_17.jpg
to get the expected output.
 `,
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1 style=\"font-weight:bold;text-decoration:underline;text-align:center\">Earth</h1>\n"+
        "    <img style=\"width:200px\""+
        "      src=\"https://upload.wikimedia.org/wikipedia/commons/thumb/9/97/The_Earth_seen_from_Apollo_17.jpg/1024px-The_Earth_seen_from_Apollo_17.jpg\">\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-image-2",
    questionText: `Use the image at the link
 https://upload.wikimedia.org/wikipedia/commons/thumb/9/97/The_Earth_seen_from_Apollo_17.jpg/1024px-The_Earth_seen_from_Apollo_17.jpg
to get the expected output.
 `,
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1 style=\"font-weight:bold;text-decoration:underline;text-align:center\">Earth</h1>\n"+
        "    <p style=\"color:brown;font-family:arial;font-size:16px\">\n"+
        "    <img style=\"width:200px\""+
        "      src=\"https://upload.wikimedia.org/wikipedia/commons/thumb/9/97/The_Earth_seen_from_Apollo_17.jpg/1024px-The_Earth_seen_from_Apollo_17.jpg\">\n"+
        "      The <a href=\"https://en.wikipedia.org/wiki/Atmospheric_pressure\">atmospheric pressure</a> at Earth's "+
        "<a href=\"https://en.wikipedia.org/wiki/Sea_level\">sea level</a> averages 101.325 kPa"+
        " with a <a href=\"https://en.wikipedia.org/wiki/Scale_height\">scale height</a> of about 8.5 km (5.3 mi)."+
        "    </p>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-image-3",
    questionText: `Use the image at the link
 https://upload.wikimedia.org/wikipedia/commons/thumb/9/97/The_Earth_seen_from_Apollo_17.jpg/1024px-The_Earth_seen_from_Apollo_17.jpg
to get the expected output.
 `,
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1 style=\"font-weight:bold;text-decoration:underline;text-align:left\">Earth</h1>\n"+
        "    <p style=\"color:brown;font-family:arial;font-size:16px\">\n"+
        "    <img style=\"width:150px;float:right\""+
        "      src=\"https://upload.wikimedia.org/wikipedia/commons/thumb/9/97/The_Earth_seen_from_Apollo_17.jpg/1024px-The_Earth_seen_from_Apollo_17.jpg\">\n"+
        "      Above the troposphere, the atmosphere is usually divided into the "+
        "<a href=\"https://en.wikipedia.org/wiki/Stratosphere\">stratosphere</a>,"+
        " <a href=\"https://en.wikipedia.org/wiki/Mesosphere\">mesosphere</a>, "+
        "and <a href=\"https://en.wikipedia.org/wiki/Thermosphere\">thermosphere</a>."+
        "    </p>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-image-4",
    questionText: `Use the image at the link
 https://upload.wikimedia.org/wikipedia/commons/thumb/9/97/The_Earth_seen_from_Apollo_17.jpg/1024px-The_Earth_seen_from_Apollo_17.jpg
to get the expected output.
 `,
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "    <h1 style=\"font-weight:bold;text-decoration:underline;text-align:right\">Earth</h1>\n"+
        "    <p style=\"color:brown;font-family:arial;font-size:16px\">\n"+
        "    <img style=\"width:150px;float:left\""+
        "      src=\"https://upload.wikimedia.org/wikipedia/commons/thumb/9/97/The_Earth_seen_from_Apollo_17.jpg/1024px-The_Earth_seen_from_Apollo_17.jpg\">\n"+
        "      Above the troposphere, the atmosphere is usually divided into the "+
        "<a href=\"https://en.wikipedia.org/wiki/Stratosphere\">stratosphere</a>,"+
        " <a href=\"https://en.wikipedia.org/wiki/Mesosphere\">mesosphere</a>, "+
        "and <a href=\"https://en.wikipedia.org/wiki/Thermosphere\">thermosphere</a>."+
        "    </p>\n"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-table-1",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>"+
        "  <body>"+
        "    <h2 style=\"font-family:arial\">Land use in 2015 as a percentage of ice-free land surface"+
        "    </h2>"+
        "    <table style=\"float:left;font-family:arial\">"+
        "      <tr><th>Land use</th><th>Percentage</th></tr>"+
        "      <tr><td>Cropland</td><td style=\"text-align:center\">12–14%</td></tr>"+
        "      <tr><td>Pastures</td><td style=\"text-align:center\">30–47%</td></tr>"+
        "      <tr><td>Human-used forests</td><td style=\"text-align:center\">16–27%</td></tr>"+
        "      <tr><td>Infrastructure</td><td style=\"text-align:center\">1%</td></tr>"+
        "      <tr><td>Unused land</td><td style=\"text-align:center\">24–31%</td></tr>"+
        "    </table>"+
        "  </body>"+
        "</html>"
}, {
    exerciseId: "html-table-2",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>"+
        "<body style=\"font-family:arial\">"+
            "<table style=\"border:1px solid grey;border-collapse:collapse;font-family:arial\">"+
            "<tr>"+
                "<td colspan=\"2\" style=\"font-size: smaller; text-align: center;border:1px solid grey\">"+
                "<h3><a href=\"https://en.wikipedia.org/wiki/List_of_tectonic_plates\">Earth's major plates</a></h3>"+
                "</td>"+
            "<tr>"+
            "<td colspan=\"2\" style=\"text-align: center;border:1px solid grey\"><a href=\"https://upload.wikimedia.org/wikipedia/commons/thumb/3/3b/Tectonic_plates_%28empty%29.svg/681px-Tectonic_plates_%28empty%29.svg.png\">"+
                "<img"+
                " style=\"width:250px\" "+
                "src=\"https://upload.wikimedia.org/wikipedia/commons/thumb/3/3b/Tectonic_plates_%28empty%29.svg/681px-Tectonic_plates_%28empty%29.svg.png\""+
                ">"+
                "</a>"+
            "</td></tr>"+
            "<tr>"+
            "<th style=\"text-align: center;border:1px solid grey\">Plate name"+
            "</th>"+
            "<th style=\"text-align: center;border:1px solid grey\">Area<br><p style=\"font-size: smaller;\">10<sup>6</sup>&nbsp;km<sup>2</sup></p>"+
            "</th></tr>"+
            "<tr>"+
            "<td style=\"border:1px solid grey\"><a href=\"https://en.wikipedia.org/wiki/Pacific_Plate\">Pacific Plate</a></td>"+
            "<td style=\"text-align: center;border:1px solid grey\">103.3"+
            "</td></tr>"+
            "<tr>"+
            "<td style=\"border:1px solid grey\">"+
                "<a href=\"https://en.wikipedia.org/wiki/African_Plate\">African Plate</a></td>"+
            "<td style=\"text-align: center;border:1px solid grey\">78.0"+
            "</td></tr>"+
            "<tr>"+
            "<td style=\"border:1px solid grey\"><a href=\"https://en.wikipedia.org/wiki/North_American_Plate\">North American Plate</a></td>"+
            "<td style=\"text-align: center;border:1px solid grey\">75.9"+
            "</td></tr>"+
            "<tr>"+
            "<td style=\"border:1px solid grey\"><a href=\"https://en.wikipedia.org/wiki/Eurasian_Plate\">Eurasian Plate</a></td>"+
            "<td style=\"text-align: center;border:1px solid grey\">67.8"+
            "</td></tr>"+
            "<tr>"+
            "<td style=\"border:1px solid grey\"><a href=\"https://en.wikipedia.org/wiki/Antarctic_Plate\">Antarctic Plate</a></td>"+
            "<td style=\"text-align: center;border:1px solid grey\">60.9"+
            "</td></tr>"+
            "<tr>"+
            "<td style=\"border:1px solid grey\"><a href=\"https://en.wikipedia.org/wiki/Indo-Australian_Plate\">Indo-Australian Plate</a></td>"+
            "<td style=\"text-align: center;border:1px solid grey\">47.2"+
            "</td></tr>"+
            "<tr>"+
            "<td style=\"border:1px solid grey\"><a href=\"https://en.wikipedia.org/wiki/South_American_Plate\">South American Plate</a></td>"+
            "<td style=\"text-align: center;border:1px solid grey\">43.6"+
            "</td></tr>"+
            "</table>"+
        "</body>"+
        "</html>"
}, {
    exerciseId: "html-table-3",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: 
        "<html>\n"+
        "  <body>\n"+
        "<table  border=\"2\" style=\"border:1px solid grey;border-collapse:collapse;font-family:arial\">"+
"<thead><tr>"+
"<th scope=\"col\" style=\"width:8%;\" role=\"columnheader button\" >Year"+
"</th>"+
"<th scope=\"col\" style=\"width:30%;\" role=\"columnheader button\" >Director(s)"+
"</th>"+
"<th scope=\"col\" style=\"width:30%;\" role=\"columnheader button\" >Film"+
"</th></tr></thead><tbody>"+
"<tr>"+
"<th scope=\"row\" rowspan=\"5\" style=\"text-align:center;\"><a href=\"https://en.wikipedia.org/wiki/2010_in_film\" >2010</a><br>"+
"<a href=\"https://en.wikipedia.org/wiki/83rd_Academy_Awards\" >(83rd)</a>"+
"</th>"+
"<td style=\"background:#FAEB86;\"><b><a href=\"https://en.wikipedia.org/wiki/Tom_Hooper\" >Tom Hooper</a></b>"+
"</td>"+
"<td style=\"background:#FAEB86;\"><b><i><a href=\"https://en.wikipedia.org/wiki/The_King%27s_Speech\" >The King's Speech</a>"+
"</i></b>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Darren_Aronofsky\" >Darren Aronofsky</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/Black_Swan_(film)\" >Black Swan</a></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Coen_brothers\" >Coen Brothers</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/True_Grit_(2010_film)\" >True Grit</a></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/David_Fincher\" >David Fincher</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/The_Social_Network\" >The Social Network</a></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/David_O._Russell\" >David O. Russell</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/The_Fighter\" >The Fighter</a></i>"+
"</td></tr>"+
"<tr>"+
"<th scope=\"row\" rowspan=\"5\" style=\"text-align:center;\"><a href=\"https://en.wikipedia.org/wiki/2011_in_film\" >2011</a><br><a href=\"https://en.wikipedia.org/wiki/84th_Academy_Awards\" >(84th)</a>"+
"</th>"+
"<td style=\"background:#FAEB86;\"><b><a href=\"https://en.wikipedia.org/wiki/Michel_Hazanavicius\" >Michel Hazanavicius</a></b>"+
"</td>"+
"<td style=\"background:#FAEB86;\"><b><i><a href=\"https://en.wikipedia.org/wiki/The_Artist_(film)\" >The Artist</a> </i></b>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Woody_Allen\" >Woody Allen</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/Midnight_in_Paris\" >Midnight in Paris</a></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Terrence_Malick\" >Terrence Malick</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/The_Tree_of_Life_(film)\" >The Tree of Life</a></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Alexander_Payne\" >Alexander Payne</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/The_Descendants\" >The Descendants</a></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Martin_Scorsese\" >Martin Scorsese</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/Hugo_(film)\" >Hugo</a></i>"+
"</td></tr>"+
"<tr>"+
"<th scope=\"row\" rowspan=\"5\" style=\"text-align:center;\"><a href=\"https://en.wikipedia.org/wiki/2012_in_film\" >2012</a><br><a href=\"https://en.wikipedia.org/wiki/85th_Academy_Awards\" >(85th)</a>"+
"</th>"+
"<td style=\"background:#FAEB86;\"><b><a href=\"https://en.wikipedia.org/wiki/Ang_Lee\" >Ang Lee</a></b>"+
"</td>"+
"<td style=\"background:#FAEB86;\"><i><b><a href=\"https://en.wikipedia.org/wiki/Life_of_Pi_(film)\" >Life of Pi</a></b></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Michael_Haneke\" >Michael Haneke</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/Amour_(2012_film)\" >Amour</a></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/David_O._Russell\" >David O. Russell</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/Silver_Linings_Playbook\" >Silver Linings Playbook</a></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Steven_Spielberg\" >Steven Spielberg</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/Lincoln_(film)\" >Lincoln</a></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Benh_Zeitlin\" >Benh Zeitlin</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/Beasts_of_the_Southern_Wild\" >Beasts of the Southern Wild</a></i>"+
"</td></tr>"+
"<tr>"+
"<th scope=\"row\" rowspan=\"5\" style=\"text-align:center;\"><a href=\"https://en.wikipedia.org/wiki/2013_in_film\" >2013</a><br><a href=\"https://en.wikipedia.org/wiki/86th_Academy_Awards\" >(86th)</a>"+
"</th>"+
"<td style=\"background:#FAEB86;\"><b><a href=\"https://en.wikipedia.org/wiki/Alfonso_Cuar%C3%B3n\" >Alfonso Cuarón</a></b>"+
"</td>"+
"<td style=\"background:#FAEB86;\"><i><b><a href=\"https://en.wikipedia.org/wiki/Gravity_(2013_film)\" >Gravity</a></b></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Steve_McQueen_(director)\" >Steve McQueen</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/12_Years_a_Slave_(film)\" >12 Years a Slave</a> </i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Alexander_Payne\" >Alexander Payne</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/Nebraska_(film)\" >Nebraska</a></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/David_O._Russell\" >David O. Russell</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/American_Hustle\" >American Hustle</a></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Martin_Scorsese\" >Martin Scorsese</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/The_Wolf_of_Wall_Street_(2013_film)\" >The Wolf of Wall Street</a></i>"+
"</td></tr>"+
"<tr>"+
"<th scope=\"row\" rowspan=\"5\" style=\"text-align:center;\"><a href=\"https://en.wikipedia.org/wiki/2014_in_film\" >2014</a><br><a href=\"https://en.wikipedia.org/wiki/87th_Academy_Awards\" >(87th)</a>"+
"</th>"+
"<td style=\"background:#FAEB86;\"><b><a href=\"https://en.wikipedia.org/wiki/Alejandro_Gonz%C3%A1lez_I%C3%B1%C3%A1rritu\" >Alejandro González Iñárritu</a></b>"+
"</td>"+
"<td style=\"background:#FAEB86;\"><i><b><a href=\"https://en.wikipedia.org/wiki/Birdman_(film)\" >Birdman</a> </b></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Wes_Anderson\" >Wes Anderson</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/The_Grand_Budapest_Hotel\" >The Grand Budapest Hotel</a></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Richard_Linklater\" >Richard Linklater</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/Boyhood_(film)\"  >Boyhood</a></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Bennett_Miller\" >Bennett Miller</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/Foxcatcher\" >Foxcatcher</a></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Morten_Tyldum\" >Morten Tyldum</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/The_Imitation_Game\" >The Imitation Game</a></i>"+
"</td></tr>"+
"<tr>"+
"<th scope=\"row\" rowspan=\"5\" style=\"text-align:center\"><a href=\"https://en.wikipedia.org/wiki/2015_in_film\" >2015</a> <br><a href=\"https://en.wikipedia.org/wiki/88th_Academy_Awards\" >(88th)</a> "+
"</th>"+
"<td style=\"background:#FAEB86;\"><b><a href=\"https://en.wikipedia.org/wiki/Alejandro_Gonz%C3%A1lez_I%C3%B1%C3%A1rritu\" >Alejandro González Iñárritu</a></b>"+
"</td>"+
"<td style=\"background:#FAEB86;\"><i><b><a href=\"https://en.wikipedia.org/wiki/The_Revenant_(2015_film)\" >The Revenant</a></b></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Lenny_Abrahamson\" >Lenny Abrahamson</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/Room_(2015_film)\" >Room</a></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Tom_McCarthy_(director)\" >Tom McCarthy</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/Spotlight_(film)\" >Spotlight</a> </i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Adam_McKay\" >Adam McKay</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/The_Big_Short_(film)\" >The Big Short</a></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/George_Miller_(filmmaker)\" >George Miller</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/Mad_Max:_Fury_Road\" >Mad Max: Fury Road</a></i>"+
"</td></tr>"+
"<tr>"+
"<th scope=\"row\" rowspan=\"5\" style=\"text-align:center\"><a href=\"https://en.wikipedia.org/wiki/2016_in_film\" >2016</a> <br><a href=\"https://en.wikipedia.org/wiki/89th_Academy_Awards\" >(89th)</a> "+
"</th>"+
"<td style=\"background:#FAEB86;\"><b><a href=\"https://en.wikipedia.org/wiki/Damien_Chazelle\" >Damien Chazelle</a></b>"+
"</td>"+
"<td style=\"background:#FAEB86;\"><i><b><a href=\"https://en.wikipedia.org/wiki/La_La_Land\" >La La Land</a></b></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Mel_Gibson\" >Mel Gibson</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/Hacksaw_Ridge\" >Hacksaw Ridge</a></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Barry_Jenkins\" >Barry Jenkins</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/Moonlight_(2016_film)\" >Moonlight</a> </i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Kenneth_Lonergan\" >Kenneth Lonergan</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/Manchester_by_the_Sea_(film)\" >Manchester by the Sea</a></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Denis_Villeneuve\" >Denis Villeneuve</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/Arrival_(film)\" >Arrival</a></i>"+
"</td></tr>"+
"<tr>"+
"<th scope=\"row\" rowspan=\"5\" style=\"text-align:center\"><a href=\"https://en.wikipedia.org/wiki/2017_in_film\" >2017</a> <br><a href=\"https://en.wikipedia.org/wiki/90th_Academy_Awards\" >(90th)</a> "+
"</th>"+
"<td style=\"background:#FAEB86;\"><b><a href=\"https://en.wikipedia.org/wiki/Guillermo_del_Toro\" >Guillermo del Toro</a></b>"+
"</td>"+
"<td style=\"background:#FAEB86;\"><i><b><a href=\"https://en.wikipedia.org/wiki/The_Shape_of_Water\" >The Shape of Water</a> </b></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Paul_Thomas_Anderson\" >Paul Thomas Anderson</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/Phantom_Thread\" >Phantom Thread</a></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Greta_Gerwig\" >Greta Gerwig</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/Lady_Bird_(film)\" >Lady Bird</a></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Christopher_Nolan\" >Christopher Nolan</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/Dunkirk_(2017_film)\" >Dunkirk</a></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Jordan_Peele\" >Jordan Peele</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/Get_Out\" >Get Out</a></i>"+
"</td></tr>"+
"<tr>"+
"<th scope=\"row\" rowspan=\"5\" style=\"text-align:center\"><a href=\"https://en.wikipedia.org/wiki/2018_in_film\" >2018</a> <br><a href=\"https://en.wikipedia.org/wiki/91st_Academy_Awards\" >(91st)</a> "+
"</th>"+
"<td style=\"background:#FAEB86;\"><b><a href=\"https://en.wikipedia.org/wiki/Alfonso_Cuar%C3%B3n\" >Alfonso Cuarón</a></b>"+
"</td>"+
"<td style=\"background:#FAEB86;\"><i><b><a href=\"https://en.wikipedia.org/wiki/Roma_(2018_film)\" >Roma</a></b></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Yorgos_Lanthimos\" >Yorgos Lanthimos</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/The_Favourite\" >The Favourite</a></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Spike_Lee\" >Spike Lee</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/BlacKkKlansman\" >BlacKkKlansman</a></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Adam_McKay\" >Adam McKay</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/Vice_(2018_film)\" >Vice</a></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Pawe%C5%82_Pawlikowski\" >Paweł Pawlikowski</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/Cold_War_(2018_film)\" >Cold War</a></i>"+
"</td></tr>"+
"<tr>"+
"<th scope=\"row\" rowspan=\"5\" style=\"text-align:center\"><a href=\"https://en.wikipedia.org/wiki/2019_in_film\" >2019</a> <br><small><a href=\"https://en.wikipedia.org/wiki/92nd_Academy_Awards\" >(92nd)</a> </small>"+
"</th>"+
"<td style=\"background:#FAEB86;\"><b><a href=\"https://en.wikipedia.org/wiki/Bong_Joon-ho\" >Bong Joon-ho</a></b>"+
"</td>"+
"<td style=\"background:#FAEB86;\"><i><b><a href=\"https://en.wikipedia.org/wiki/Parasite_(2019_film)\" >Parasite</a> </b></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Sam_Mendes\" >Sam Mendes</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/1917_(2019_film)\" >1917</a></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Todd_Phillips\" >Todd Phillips</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/Joker_(2019_film)\" >Joker</a></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Martin_Scorsese\" >Martin Scorsese</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/The_Irishman\" >The Irishman</a></i>"+
"</td></tr>"+
"<tr>"+
"<td><a href=\"https://en.wikipedia.org/wiki/Quentin_Tarantino\" >Quentin Tarantino</a>"+
"</td>"+
"<td><i><a href=\"https://en.wikipedia.org/wiki/Once_Upon_a_Time_in_Hollywood\" >Once Upon a Time in Hollywood</a></i>"+
"</td></tr></tbody><tfoot></tfoot></table>"+
        "  </body>\n"+
        "</html>"
}, {
    exerciseId: "html-table-4",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: htmlTable4Text
}, {
    exerciseId: "html-div-1",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: htmlDivExerciseText1
}, {
    exerciseId: "html-div-2",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: htmlDivExerciseText2
}, {
    exerciseId: "html-div-3",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: htmlDivExerciseText3
}, {
    exerciseId: "html-div-4",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>
`,
    expectedOutputHtml: htmlDivExerciseText4
}, {
    exerciseId: "html-css-1",
    questionText: `Write html to get the expected output.
    Define style inside head element and use them with class attribute on the elements.
    Do not use style attribute on the individual elements.`,
    initialHtmlText: `
    <!DOCTYPE html>
    <html>
    <head>
        <style>
        </style>
    </head>
    <body>
        <h1>Earth</h1>
        <p>
            Earth is the third planet from the Sun and the only astronomical object known to harbor and support life.
            About 29.2% of Earth's surface is land consisting of continents and islands.
            The remaining 70.8% is covered with water, mostly by oceans, seas, gulfs, and other salt-water bodies.
        </p> 
        <p>
            Earth's atmosphere consists mostly of nitrogen and oxygen. More solar energy is received by tropical
            regions than polar regions and is redistributed by atmospheric and ocean circulation.
            Greenhouse gases also play an important role in regulating the surface temperature.
        </p>
    </body>
    </html>`,
    expectedOutputHtml: `
    <!DOCTYPE html>
    <html>
    <head>
        <style>
            h1 {
                text-align: center
            }
            .greyp {
                text-align:right;
                font-size:18px;
                color:blue
            }
            .redp {
                text-align:left;
                font-size:14px;
                color:green
            }
            body {
                font-family: monospace
            }
        </style>
    </head>
    <body>
        <h1>Earth</h1>
        <p class="greyp">
            Earth is the third planet from the Sun and the only astronomical object known to harbor and support life.
            About 29.2% of Earth's surface is land consisting of continents and islands.
            The remaining 70.8% is covered with water, mostly by oceans, seas, gulfs, and other salt-water bodies.
        </p> 
        <p class="redp">
            Earth's atmosphere consists mostly of nitrogen and oxygen. More solar energy is received by tropical
            regions than polar regions and is redistributed by atmospheric and ocean circulation.
            Greenhouse gases also play an important role in regulating the surface temperature.
        </p>
    </body>
    </html>`
}, {
    exerciseId: "html-css-2",
    questionText: `Write html to get the expected output.
    Define style inside head element and use them with class attribute on the elements.
    Do not use style attribute on the individual elements.`,
    initialHtmlText: `
    <!DOCTYPE html>
    <html>
        <head>
            <style>
            </style>
        </head>
        <body>
        </body>
    </html>`,
    expectedOutputHtml: htmlCssExerciseText2
}, {
    exerciseId: "html-css-3",
    questionText: `Write html to get the expected output.
    Define style inside head element and use them with class attribute on the elements.
    Do not use style attribute on the individual elements.`,
    initialHtmlText: `
    <!DOCTYPE html>
    <html>
        <head>
            <style>
            </style>
        </head>
        <body>
        </body>
    </html>`,
    expectedOutputHtml: htmlCssExerciseText3
}, {
    exerciseId: "html-css-4",
    questionText: `Write html to get the expected output.
    Define style inside head element and use them with class attribute on the elements.
    Do not use style attribute on the individual elements.`,
    initialHtmlText: `
    <!DOCTYPE html>
    <html>
        <head>
            <style>
            </style>
        </head>
        <body>
        </body>
    </html>`,
    expectedOutputHtml: `<!DOCTYPE html>
    <html>
        <head>
            <style>
          .btn {
    font-weight: 400;
    color: #212529;
    text-align: center;
    text-decoration: none;
    background-color: transparent;
    border: 1px solid transparent;
    font-size: 1rem;
    border-radius: .25rem;
}
            .btn-primary {
              color: #fff;
              background-color: #0d6efd;
              border-color: #0d6efd;
          }.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}.btn-success {
    color: #fff;
    background-color: #198754;
    border-color: #198754;
}.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}
            </style>
        </head>
        <body>
            <button type="button" class="btn btn-primary">Primary</button>
            <button type="button" class="btn btn-secondary">Secondary</button>
            <button type="button" class="btn btn-success">Success</button>
            <button type="button" class="btn btn-danger">Danger</button>
        </body>
    </html>`
}, {
    exerciseId: "html-button-1",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
<!DOCTYPE html>
<html>
    <head>
    </head>
<body>
    <p><button style="color:white;background-color:red;border-color:green;
        font-weight:400;
        text-align: center;
        border: 2px solid transparent;
        font-size: 26px;">This</button> is a button with style</p>
</body>
</html>`
}, {
    exerciseId: "html-button-2",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
<!DOCTYPE html>
<html>
<head>
</head>
<body>
<p><button style="background-color: white;
    color: black;
    border: 2px solid green;
    font-size: 26px;">This</button> is a button with style</p>
</body>
</html>`
}, {
    exerciseId: "html-button-3",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
expectedOutputHtml: `
<!DOCTYPE html>
<html>
<head>
</head>
<body>
<p><button style="background-color: black;
    color: white;
    border: 2px solid green;
    font-size: 36px;">This</button> is a button with style</p>
</body>
</html>`
}, {
    exerciseId: "html-button-4",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
<!DOCTYPE html>
<html>
<head>
    <style>            
        .button1 {
            background-color: white; 
            color: black; 
            border: 2px solid green;
            font-size: 19px;
        }
        
        .button2 {
            background-color: white; 
            color: black; 
            border: 2px solid blue;
            font-size: 19px;
        }
        
        .button3 {
            background-color: white; 
            color: black; 
            border: 2px solid red;
            font-size: 19px;
        }
        
        .button4 {
            background-color: white;
            color: black;
            border: 2px solid grey;
            font-size: 19px;
        }
        
        .button5 {
            background-color: white;
            color: black;
            border: 2px solid black;
            font-size: 19px;
        }
    </style>
</head>
<body>
    <button class="button1">Green</button>
    <button class="button2">Blue</button>
    <button class="button3">Red</button>
    <button class="button4">Gray</button>
    <button class="button5">Black</button>
</body>
</html>`
}, {
    exerciseId: "html-css-color-1",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
    <!DOCTYPE html>
    <html>
        <head>
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css" rel="stylesheet"
        integrity="sha384-+0n0xVW2eSR5OomGNYDnhzAbDsOXxcvSN1TPprVMTNDbiYZCxYbOOl7+AMvyTG2x" crossorigin="anonymous">
            <style>
                .para1 {
                    color: rgb(32, 96, 194, 1);
                    font-size: 24px
                }
            </style>
        </head>
        <body>
            <p class="para1">Earth's gravity interacts with other objects in space, especially the Moon, which is Earth's only
             natural satellite. Earth orbits around the Sun in about 365.25 days.
            </p>
        </body>
    </html>`
}, {
    exerciseId: "html-css-color-2",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
    <!DOCTYPE html>
    <html>
        <head>
            <style>
                .button1 {
                    color: #fff;
                    background-color: #0d6efd;
                    border-color: #0d6efd;
                    font-weight: 400;
                    text-align: center;
                    border: 1px solid transparent;
                    padding: .375rem .75rem;
                    font-size: 1rem;
                    border-radius: .25rem;
                    width: 100%;
                    margin-top: 20px;
                }
                .button1 {
                    color: #fff;
                    background-color: #0d6efd;
                    border-color: #0d6efd;
                    font-weight: 400;
                    text-align: center;
                    border: 1px solid transparent;
                    padding: .375rem .75rem;
                    font-size: 1rem;
                    border-radius: .25rem;
                    width: 100%;
                    margin-top: 20px;
                }
                p {
    margin-top: 0;
    margin-bottom: 1rem;
}
.card-body {
    padding: 1rem 1rem;
}

.card-title {
    margin-bottom: .5rem;
}
h5 {
    font-size: 1.25rem;
}
h5, h6 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}
body {
    margin: 0;
    font-family: sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
}

                .card {
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
}

.btn {
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
    }

.btn-primary {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
}
            </style>
        </head>
        
        <body>
          <button type="button" class="btn btn-primary disabled" style="margin: 30px;">Primary</button>
      </body>
    </html>`
}, {
    exerciseId: "html-css-color-3",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
    <!DOCTYPE html>
    <html>
        <head>
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css" rel="stylesheet"
        integrity="sha384-+0n0xVW2eSR5OomGNYDnhzAbDsOXxcvSN1TPprVMTNDbiYZCxYbOOl7+AMvyTG2x" crossorigin="anonymous">
            <style>
                .button1 {
                    position: fixed;
                    left: 10px;
                    top: 10px
                }
                .button2 {
                    position: fixed;
                    right: 10px;
                    bottom: 10px
                }
            </style>
        </head>
        <body>
            <button type="button" class="btn btn-secondary disabled">Secondary</button>
            <button type="button" class="btn btn-success disabled">Success</button>
            <button type="button" class="btn btn-danger disabled">Danger</button>
            <button type="button" class="btn btn-warning disabled">Warning</button>
            <button type="button" class="btn btn-info disabled">Info</button>
            <button type="button" class="btn btn-light disabled">Light</button>
            <button type="button" class="btn btn-dark disabled">Dark</button>
            <button type="button" class="btn btn-link disabled">Link</button>
        </body>
    </html>`
}, {
    exerciseId: "html-css-background-1",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
    <!DOCTYPE html>
    <html>
        <head>
            <style>
                .para {
                    font-size:24px;
                    background-color:grey
                }
            </style>
        </head>
        <body>
            <p class="para">The shape of Earth is nearly spherical. There is a small flattening at the poles and bulging around
                the equator due to Earth's rotation.</p>
        </body>
    </html>`
}, {
    exerciseId: "html-css-background-2",
    questionText: `Use the image at the link
    https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Felix_from_ISS_03_sept_2007_1138Z.jpg/320px-Felix_from_ISS_03_sept_2007_1138Z.jpg
to get the expected output.
 `,
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
    <!DOCTYPE html>
    <html>
        <head>
            <style>
                .para {
                    font-size:24px;
                    color:white;
                    background-image:url(https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Felix_from_ISS_03_sept_2007_1138Z.jpg/320px-Felix_from_ISS_03_sept_2007_1138Z.jpg)
                }
            </style>
        </head>
        <body>
            <p class="para">The shape of Earth is nearly spherical. There is a small flattening at the poles and bulging around
                the equator due to Earth's rotation. The shape of Earth is nearly spherical. There is a small flattening at the poles and bulging around
                the equator due to Earth's rotation.</p>
        </body>
    </html>`
}, {
    exerciseId: "html-css-background-3",
    questionText: `Use the image at the link
 https://upload.wikimedia.org/wikipedia/commons/thumb/9/97/The_Earth_seen_from_Apollo_17.jpg/1024px-The_Earth_seen_from_Apollo_17.jpg
to get the expected output.
 `,
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
    <!DOCTYPE html>
    <html>
        <head>
            <style>
                .para {
                    font-size:24px;
                    color:white;
                    height: 200px;
                    width: 300px;
                    text-align: center;
                    background-image:url(https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Felix_from_ISS_03_sept_2007_1138Z.jpg/320px-Felix_from_ISS_03_sept_2007_1138Z.jpg)
                }
            </style>
        </head>
        <body>
            <h1 class="para">Universe</h1>
        </body>
    </html>`
}, {
    exerciseId: "html-css-background-4",
    questionText: `Use the image at the link
 https://upload.wikimedia.org/wikipedia/commons/thumb/9/97/The_Earth_seen_from_Apollo_17.jpg/1024px-The_Earth_seen_from_Apollo_17.jpg
to get the expected output.
 `,
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
    <!DOCTYPE html>
    <html>
        <head>
            <style>
                .para {
                    color: white;
                    height: 500px;
                    width: 500px;
                    text-align: center;
                    background-image:url(https://upload.wikimedia.org/wikipedia/commons/thumb/9/97/The_Earth_seen_from_Apollo_17.jpg/480px-The_Earth_seen_from_Apollo_17.jpg)
                }
            </style>
        </head>
        <body>
            <p class="para">Earth's interior, like that of the other terrestrial planets, is divided into layers by their
             <a href="https://en.wikipedia.org/wiki/Chemical">chemical</a>)
              properties. The outer layer is a chemically distinct  or physical
               (<a href="https://en.wikipedia.org/wiki/Rheology">rheological</a>
                <a href="https://en.wikipedia.org/wiki/Silicate_minerals">silicate</a> solid crust,
                 which is underlain by a highly <a href="https://en.wikipedia.org/wiki/Viscous">viscous</a> solid mantle.
            </p>
        </body>
    </html>`
}, {
    exerciseId: "html-css-height-width-1",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
    <!DOCTYPE html>
    <html>
        <head>
            <style>
                .para {
                    font-size:24px;
                    background-color:grey;
                    height: 400px;
                    width: 200px;
                    border: 2px solid red 
                }
            </style>
        </head>
        <body>
            <p class="para">The shape of Earth is nearly spherical. There is a small flattening at the poles and bulging around
                the equator due to Earth's rotation.</p>
        </body>
    </html>`
}, {
    exerciseId: "html-css-height-width-2",
    questionText: `Use the image at the 
    url https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Felix_from_ISS_03_sept_2007_1138Z.jpg/320px-Felix_from_ISS_03_sept_2007_1138Z.jpg
     to get the expected output.`,
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
    <!DOCTYPE html>
    <html>
        <head>
            <style>
                .para {
                    width: 300px;
                    font-size:24px;
                    color:red;
                    background-image:url(https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Felix_from_ISS_03_sept_2007_1138Z.jpg/320px-Felix_from_ISS_03_sept_2007_1138Z.jpg)
                }
            </style>
        </head>
        <body>
            <p class="para">The shape of Earth is nearly spherical. There is a small flattening at the poles and bulging around
                the equator due to Earth's rotation. The shape of Earth is nearly spherical. There is a small flattening at the poles and bulging around
                the equator due to Earth's rotation.</p>
        </body>
    </html>`
}, {
    exerciseId: "html-css-height-width-3",
    questionText: `Use the image at the 
    url https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Felix_from_ISS_03_sept_2007_1138Z.jpg/320px-Felix_from_ISS_03_sept_2007_1138Z.jpg
     to get the expected output.`,
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
    <!DOCTYPE html>
    <html>
        <head>
            <style>
                .para {
                    font-size:24px;
                    color:white;
                    height: 300px;
                    width: 300px;
                    text-align: center;
                    background-image:url(https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Felix_from_ISS_03_sept_2007_1138Z.jpg/320px-Felix_from_ISS_03_sept_2007_1138Z.jpg)
                }
            </style>
        </head>
        <body>
            <h1 class="para">Universe</h1>
        </body>
    </html>`
}, {
    exerciseId: "html-css-height-width-4",
    questionText: `Use the image at the 
    url https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Felix_from_ISS_03_sept_2007_1138Z.jpg/320px-Felix_from_ISS_03_sept_2007_1138Z.jpg
     to get the expected output.`,
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
    <!DOCTYPE html>
    <html>
        <head>
            <style>
                .para {
                    color: white;
                    height: 400px;
                    width: 400px;
                    text-align: center;
                    background-image:url(https://upload.wikimedia.org/wikipedia/commons/thumb/9/97/The_Earth_seen_from_Apollo_17.jpg/480px-The_Earth_seen_from_Apollo_17.jpg)
                }
            </style>
        </head>
        <body>
            <p class="para">Earth's interior, like that of the other terrestrial planets, is divided into layers by their
             <a href="https://en.wikipedia.org/wiki/Chemical">chemical</a>)
              properties. The outer layer is a chemically distinct  or physical
               (<a href="https://en.wikipedia.org/wiki/Rheology">rheological</a>
                <a href="https://en.wikipedia.org/wiki/Silicate_minerals">silicate</a> solid crust,
                 which is underlain by a highly <a href="https://en.wikipedia.org/wiki/Viscous">viscous</a> solid mantle.
            </p>
        </body>
    </html>`
}, {
    exerciseId: "html-css-margin-padding-1",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
    <!DOCTYPE html>
    <html>
        <head>
            <style>
                .button1 {
                    font-size:24px;
                    font-weight: 400;
                    color: blue;
                    text-align: center;
                    background-color: transparent;
                    border: 1px solid transparent;
                    font-size: 1rem;
                    color: #fff;
                    background-color: #0d6efd;
                    border-color: #0d6efd;
                    margin: 30px;
                    padding: 30px
                }
            </style>
        </head>
        <body>
            <button class="button1">Button</button>
            <button class="button1">Button</button>
        </body>
    </html>`
}, {
    exerciseId: "html-css-margin-padding-2",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
    <!DOCTYPE html>
    <html>
        <head>
            <style>
                .button1 {
                    color: #fff;
                    background-color: #0d6efd;
                    border-color: #0d6efd;
                    font-weight: 400;
                    text-align: center;
                    border: 1px solid transparent;
                    padding: .375rem .75rem;
                    font-size: 1rem;
                    border-radius: .25rem;
                    width: 100%;
                    margin-top: 20px;
                }
            </style>
        </head>
        <body>
            <button class="button1">Button</button>
            <button class="button1">Button</button>
        </body>
    </html>`
}, {
    exerciseId: "html-css-margin-padding-3",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
    <!DOCTYPE html>
    <html>
        <head>
            <style>
                .button1 {
                    color: #fff;
                    background-color: #0d6efd;
                    border-color: #0d6efd;
                    font-weight: 400;
                    text-align: center;
                    border: 1px solid transparent;
                    padding: .375rem .75rem;
                    font-size: 1rem;
                    border-radius: .25rem;
                    width: 100%;
                    margin-top: 20px;
                }
                p {
    margin-top: 0;
    margin-bottom: 1rem;
}
.card-body {
    padding: 1rem 1rem;
}

.card-title {
    margin-bottom: .5rem;
}
h5 {
    font-size: 1.25rem;
}
h5, h6 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}
body {
    margin: 0;
    font-family: sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
}

                .card {
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
}
            </style>
        </head>
        <body><div class="bd-example">
            <div class="card" style="width: 18rem;">
                <svg class="bd-placeholder-img card-img-top" width="100%" height="180" xmlns="http://www.w3.org/2000/svg" 
                role="img" aria-label="Placeholder: Image cap" preserveAspectRatio="xMidYMid slice" focusable="false">
                <title>Placeholder</title><rect width="100%" height="100%" fill="#868e96"></rect><text x="50%" y="50%" 
                fill="#dee2e6" dy=".3em">Image cap</text></svg>
                
                    <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        <a class="btn btn-primary">Go somewhere</a>
                    </div>
                </div>
            </div>
        </body>
    </html>`
}, {
    exerciseId: "html-css-margin-padding-4",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
    <!DOCTYPE html>
    <html>
        <head>
            <style>
                .button1 {
                    color: #fff;
                    background-color: #0d6efd;
                    border-color: #0d6efd;
                    font-weight: 400;
                    text-align: center;
                    border: 1px solid transparent;
                    padding: .375rem .75rem;
                    font-size: 1rem;
                    border-radius: .25rem;
                    width: 100%;
                    margin-top: 20px;
                }
                .button1 {
                    color: #fff;
                    background-color: #0d6efd;
                    border-color: #0d6efd;
                    font-weight: 400;
                    text-align: center;
                    border: 1px solid transparent;
                    padding: .375rem .75rem;
                    font-size: 1rem;
                    border-radius: .25rem;
                    width: 100%;
                    margin-top: 20px;
                }
                p {
    margin-top: 0;
    margin-bottom: 1rem;
}
.card-body {
    padding: 1rem 1rem;
}

.card-title {
    margin-bottom: .5rem;
}
h5 {
    font-size: 1.25rem;
}
h5, h6 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}
body {
    margin: 0;
    font-family: sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
}

                .card {
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
}

.btn {
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
    }

.btn-primary {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
}
            </style>
        </head>
        <body>
            <div class="bd-example">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">Special title treatment</h5>
                            <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                            <a class="btn btn-primary">Go somewhere</a>
                        </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">Special title treatment</h5>
                            <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                            <a class="btn btn-primary">Go somewhere</a>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </body>
    </html>`
}, {
    exerciseId: "html-css-position-1",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
    <!DOCTYPE html>
    <html>
        <head>
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css" rel="stylesheet"
        integrity="sha384-+0n0xVW2eSR5OomGNYDnhzAbDsOXxcvSN1TPprVMTNDbiYZCxYbOOl7+AMvyTG2x" crossorigin="anonymous">
            <style>
                .button1 {
                    position: absolute;
                    left: 10px;
                    top: 10px
                }
                .button2 {
                    position: absolute;
                    right: 10px;
                    top: 10px
                }
            </style>
        </head>
        <body>
            <div>
                <div class="">
                    <button class="btn btn-primary button1" type="button">Button 1</button>
                    <button class="btn btn-primary button2" type="button">Button 2</button>
                </div>
                <p class="para1">
                Earth's gravity interacts with other objects in space, especially the Moon,
                 which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                 Earth's gravity interacts with other objects in space, especially the Moon,
                  which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                  Earth's gravity interacts with other objects in space, especially the Moon,
                   which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                   Earth's gravity interacts with other objects in space, especially the Moon,
                    which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                    Earth's gravity interacts with other objects in space, especially the Moon,
                     which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                     Earth's gravity interacts with other objects in space, especially the Moon,
                      which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                      Earth's gravity interacts with other objects in space, especially the Moon,
                       which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                       Earth's gravity interacts with other objects in space, especially the Moon,
                        which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                        Earth's gravity interacts with other objects in space, especially the Moon,
                         which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                Earth's gravity interacts with other objects in space, especially the Moon,
                which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                Earth's gravity interacts with other objects in space, especially the Moon,
                which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                Earth's gravity interacts with other objects in space, especially the Moon,
                which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                Earth's gravity interacts with other objects in space, especially the Moon,
                    which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                    Earth's gravity interacts with other objects in space, especially the Moon,
                    which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                    Earth's gravity interacts with other objects in space, especially the Moon,
                    which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                    Earth's gravity interacts with other objects in space, especially the Moon,
                    which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                    Earth's gravity interacts with other objects in space, especially the Moon,
                        which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                        Earth's gravity interacts with other objects in space, especially the Moon,
                        which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                        Earth's gravity interacts with other objects in space, especially the Moon,
                        which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                        Earth's gravity interacts with other objects in space, especially the Moon,
                        which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                </p>
            </div>
        </body>
    </html>`
}, {
    exerciseId: "html-css-position-2",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
    <!DOCTYPE html>
    <html>
        <head>
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css" rel="stylesheet"
        integrity="sha384-+0n0xVW2eSR5OomGNYDnhzAbDsOXxcvSN1TPprVMTNDbiYZCxYbOOl7+AMvyTG2x" crossorigin="anonymous">
            <style>
                .button1 {
                    position: fixed;
                    left: 10px;
                    top: 10px
                }
                .button2 {
                    position: fixed;
                    right: 10px;
                    bottom: 10px
                }
            </style>
        </head>
        <body>
            <div>
                <div class="">
                    <button class="btn btn-primary button1" type="button">Button 1</button>
                    <button class="btn btn-primary button2" type="button">Button 2</button>
                </div>
                <p class="para1">
                Earth's gravity interacts with other objects in space, especially the Moon,
                 which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                 Earth's gravity interacts with other objects in space, especially the Moon,
                  which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                  Earth's gravity interacts with other objects in space, especially the Moon,
                   which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                   Earth's gravity interacts with other objects in space, especially the Moon,
                    which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                    Earth's gravity interacts with other objects in space, especially the Moon,
                     which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                     Earth's gravity interacts with other objects in space, especially the Moon,
                      which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                      Earth's gravity interacts with other objects in space, especially the Moon,
                which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                Earth's gravity interacts with other objects in space, especially the Moon,
                which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                Earth's gravity interacts with other objects in space, especially the Moon,
                    which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                    Earth's gravity interacts with other objects in space, especially the Moon,
                    which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                    Earth's gravity interacts with other objects in space, especially the Moon,
                    which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                    Earth's gravity interacts with other objects in space, especially the Moon,
                    which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                    Earth's gravity interacts with other objects in space, especially the Moon,
                        which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                        Earth's gravity interacts with other objects in space, especially the Moon,
                        which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                        Earth's gravity interacts with other objects in space, especially the Moon,
                        which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                        Earth's gravity interacts with other objects in space, especially the Moon,
                        which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                        Earth's gravity interacts with other objects in space, especially the Moon,
                            which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                            Earth's gravity interacts with other objects in space, especially the Moon,
                            which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                            Earth's gravity interacts with other objects in space, especially the Moon,
                            which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                            Earth's gravity interacts with other objects in space, especially the Moon,
                            which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                </p>
            </div>
        </body>
    </html>`
}, {
    exerciseId: "html-css-position-3",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
    <!DOCTYPE html>
    <html>
        <head>
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css" rel="stylesheet"
        integrity="sha384-+0n0xVW2eSR5OomGNYDnhzAbDsOXxcvSN1TPprVMTNDbiYZCxYbOOl7+AMvyTG2x" crossorigin="anonymous">
            <style>
                .button1 {
                    position: fixed;
                    left: 10px;
                    top: 10px;
                    z-index: -12
                }
                .button2 {
                    position: fixed;
                    right: 10px;
                    bottom: 10px;
                    z-index: -12
                }
                .para1 {
                    z-index: 12
                }
            </style>
        </head>
        <body>
            <div>
                <div class="">
                    <button class="btn btn-primary button1" type="button">Button 1</button>
                    <button class="btn btn-primary button2" type="button">Button 2</button>
                </div>
                <p class="para1">
                Earth's gravity interacts with other objects in space, especially the Moon,
                 which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                 Earth's gravity interacts with other objects in space, especially the Moon,
                  which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                  Earth's gravity interacts with other objects in space, especially the Moon,
                   which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                   Earth's gravity interacts with other objects in space, especially the Moon,
                    which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                    Earth's gravity interacts with other objects in space, especially the Moon,
                     which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                     Earth's gravity interacts with other objects in space, especially the Moon,
                      which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                      Earth's gravity interacts with other objects in space, especially the Moon,
                which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                Earth's gravity interacts with other objects in space, especially the Moon,
                which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                Earth's gravity interacts with other objects in space, especially the Moon,
                    which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                    Earth's gravity interacts with other objects in space, especially the Moon,
                    which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                    Earth's gravity interacts with other objects in space, especially the Moon,
                    which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                    Earth's gravity interacts with other objects in space, especially the Moon,
                    which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                    Earth's gravity interacts with other objects in space, especially the Moon,
                        which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                        Earth's gravity interacts with other objects in space, especially the Moon,
                        which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                        Earth's gravity interacts with other objects in space, especially the Moon,
                        which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                        Earth's gravity interacts with other objects in space, especially the Moon,
                        which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                        Earth's gravity interacts with other objects in space, especially the Moon,
                            which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                            Earth's gravity interacts with other objects in space, especially the Moon,
                            which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                            Earth's gravity interacts with other objects in space, especially the Moon,
                            which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                            Earth's gravity interacts with other objects in space, especially the Moon,
                            which is Earth's only natural satellite. Earth orbits around the Sun in about 365.25 days.
                </p>
            </div>
        </body>
    </html>`
}, {
    exerciseId: "html-css-position-4",
    questionText: `Use the image at the url 
    https://upload.wikimedia.org/wikipedia/commons/thumb/9/9d/MODIS_Map.jpg/1920px-MODIS_Map.jpg to get the expected output.`,
        
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
    <!DOCTYPE html>
    <html>
        <head>
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css" rel="stylesheet"
        integrity="sha384-+0n0xVW2eSR5OomGNYDnhzAbDsOXxcvSN1TPprVMTNDbiYZCxYbOOl7+AMvyTG2x" crossorigin="anonymous">
            <style>
                .img1 {
                    position: absolute;
                    height: 100%;
                    width: 100%
                }
                .button1 {
                    position: absolute;
                    top: 10px;
                    left: 10px
                }
                .button2 {
                    position: absolute;
                    top: 10px;
                    right: 10px
                }
                .button3 {
                    position: absolute;
                    bottom: 10px;
                    right: 10px
                }
                .button4 {
                    position: absolute;
                    bottom: 10px;
                    left: 10px
                }
            </style>
        </head>
        <body>
            <div>
                <img class="img1" src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9d/MODIS_Map.jpg/1920px-MODIS_Map.jpg">
                 <button class="btn btn-primary button1" type="button">Button 1</button>
                 <button class="btn btn-primary button2" type="button">Button 2</button>
                 <button class="btn btn-primary button3" type="button">Button 3</button>
                 <button class="btn btn-primary button4" type="button">Button 4</button>
            </div>
        </body>
    </html>`
}, {
    exerciseId: "html-css-links-1",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
    <!DOCTYPE html>
    <html>
    <head>
        <style>
          .link1 {
              color: red;
              border: 3px solid blue;
              font-size: 16px;
              margin-top: 30px;
              padding: 20px;
              text-decoration: none;
          }
          .link2 {
              color: blue;
              border: 2px solid grey;
              font-size: 8px;
              margin-top: 30px;
              padding: 20px;
              text-decoration: none;
          }
          .link3 {
              color: green;
              border: 1px solid pink;
              font-size: 24px;
              margin-top: 30px;
              padding: 20px;
              text-decoration: none;
          }
          .link4 {
              color: pink;
              border: 8px solid green;
              font-size: 14px;
              margin-top: 30px;
              padding: 20px;
              text-decoration: none;
          }
          .link5 {
              color: magenta;
              border: 4px solid grey;
              font-size: 10px;
              margin-top: 30px;
              padding: 20px;
              text-decoration: none;
          }
          .link1:hover {
              background-color: blue;
              font-size: 24px;
              padding: 30px;
              text-decoration: underline;
          }
          .link2:hover {
              background-color: red;
              font-size: 24px;
              padding: 30px;
              text-decoration: underline;
          }
          .link3:hover {
              background-color: grey;
              font-size: 24px;
              padding: 30px;
              text-decoration: underline;
          }
          .link4:hover {
              background-color: grey;
              font-size: 24px;
              padding: 30px;
              text-decoration: underline;
          }
          .link5:hover {
              background-color: indigo;
              font-size: 24px;
              padding: 30px;
              text-decoration: underline;
          }
          .div1 {
            margin-top: 40px;
          }
        </style>
    </head>
    <body>
      <div class="div1">
      <a class="link1" href="#">Link 1</a>
      <a class="link2" href="#">Link 2</a>
      <a class="link3" href="#">Link 3</a>
      <a class="link4" href="#">Link 4</a>
      <a class="link5" href="#">Link 5</a>
    <div>
    </body>
    </html>`
}, {
    exerciseId: "html-css-links-2",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
<!DOCTYPE html>
<html>
<head>
   <style>
        .button1 {
            position: fixed;
            left: 10px;
            top: 10px
        }
        .button2 {
            position: fixed;
            right: 10px;
            bottom: 10px
        }
        .btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
}.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    opacity: 0.8;
}.btn-success {
    color: #fff;
    background-color: #198754;
    border-color: #198754;
    opacity: 0.8;
}.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    opacity: 0.8;
}.btn-warning {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107;
    opacity: 0.8;
}.btn-info {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0;
    opacity: 0.8;
}.btn-light {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    opacity: 0.8;
}.btn-dark {
    color: #fff;
    background-color: #212529;
    border-color: #212529;
    opacity: 0.8;
}.btn-link {
    font-weight: 400;
    color: #0d6efd;
    text-decoration: underline;
    opacity: 0.8;
}
.btn-secondary:hover {
    opacity: 1;
}.btn-success:hover {
    opacity: 1;
}.btn-danger:hover {
    opacity: 1;
}.btn-warning:hover {
    opacity: 1;
}.btn-info:hover {
    opacity: 1;
}.btn-light:hover {
    opacity: 1;
}.btn-dark:hover {
    opacity: 1;
}.btn-link:hover {
    opacity: 1;
}
    </style>
</head>
<body>
<button type="button" class="btn btn-secondary">Secondary</button>
<button type="button" class="btn btn-success">Success</button>
<button type="button" class="btn btn-danger">Danger</button>
<button type="button" class="btn btn-warning">Warning</button>
<button type="button" class="btn btn-info">Info</button>
<button type="button" class="btn btn-light">Light</button>
<button type="button" class="btn btn-dark">Dark</button>

<button type="button" class="btn btn-link">Link</button>
</body>
</html>`
}, {
    exerciseId: "html-css-links-3",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
    <!DOCTYPE html>
    <html>
    <head>
       <style>
            .button1 {
                position: fixed;
                left: 10px;
                top: 10px
            }
            .button2 {
                position: fixed;
                right: 10px;
                bottom: 10px
            }
            .btn {
        display: inline-block;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        text-align: center;
        text-decoration: none;
        background-color: transparent;
        border: 1px solid transparent;
        padding: .375rem .75rem;
        font-size: 1rem;
        border-radius: .25rem;
    }.btn-secondary {
        color: black;
        border-color: #6c757d;
        background-color: white;
    }.btn-success {
        color: black;
        border-color: #198754;
        background-color: white;
    }.btn-danger {
        color: black;
        border-color: #dc3545;
        background-color: white;
    }.btn-warning {
        color: #000;
        border-color: #ffc107;
        color: black;
        background-color: white;
    }.btn-info {
        color: #000;
        border-color: #0dcaf0;
        color: black;
        background-color: white;
    }.btn-light {
        color: #000;
        border-color: #f8f9fa;
        color: #0d6efd;
        background-color: white;
    }.btn-dark {
        color: #fff;
        border-color: #212529;
        color: #0d6efd;
        background-color: white;
    }.btn-link {
        font-weight: 400;
        color: #0d6efd;
        text-decoration: underline;
        background-color: white;
    }
    .btn-secondary:hover {
        background-color: #6c757d;
        color:white
    }.btn-success:hover {
        background-color: #198754;
        color:white
    }.btn-danger:hover {
        background-color: #dc3545;
        color:white
    }.btn-warning:hover {
        background-color: #ffc107;
        color:white
    }.btn-info:hover {
        background-color: #0dcaf0;
        color:white
    }.btn-light:hover {
        background-color: #f8f9fa;
        color:black
    }.btn-dark:hover {
        background-color: #212529;
        color:white
    }
        </style>
    </head>
    <body>
    <button type="button" class="btn btn-secondary">Secondary</button>
    <button type="button" class="btn btn-success">Success</button>
    <button type="button" class="btn btn-danger">Danger</button>
    <button type="button" class="btn btn-warning">Warning</button>
    <button type="button" class="btn btn-info">Info</button>
    <button type="button" class="btn btn-light">Light</button>
    <button type="button" class="btn btn-dark">Dark</button>
    
    </body>
    </html>`
    
}, {
    exerciseId: "html-css-links-4",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
<!DOCTYPE html>
<html>
<head>
<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css" rel="stylesheet"
integrity="sha384-+0n0xVW2eSR5OomGNYDnhzAbDsOXxcvSN1TPprVMTNDbiYZCxYbOOl7+AMvyTG2x" crossorigin="anonymous">
    <style>
        .button1 {
            position: fixed;
            left: 10px;
            top: 10px
        }
        .accordion-button::after {
            width: 0px;
        }
    </style>
</head>
<body>  <div class="btn-group me-2" role="group" aria-label="First group">
<button type="button" class="btn btn-outline-secondary">1</button>
<button type="button" class="btn btn-outline-secondary">2</button>
<button type="button" class="btn btn-outline-secondary">3</button>
<button type="button" class="btn btn-outline-secondary">4</button>
</div>
</body>
</html>`
}, {
    exerciseId: "html-css-dropdown-1",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
    
<!DOCTYPE html>
<html>
<head>
  <style>
        .button1 {
            position: fixed;
            left: 10px;
            top: 10px
        }
        .button2 {
            position: fixed;
            right: 10px;
            bottom: 10px
        }.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
}.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}
.dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    padding: .5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
  display: none;
}
.dropdown-item {
    display: block;
    /* width: 100%; */
    /* height: 35px; */
    padding: .25rem 1rem;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    background-color: transparent;
    border: 0;
    /* line-height: 20px; */
}
.dropdown:hover .dropdown-menu {
  display: block
}
.dropdown-item:hover {
    background-color:lightgrey;
    /* color: white; */
}
    </style>
</head>
<body>
  <div class="dropdown">
    <button class="btn btn-secondary dropdown-toggle" type="button">
      Dropdown button
    </button>
    <div class="dropdown-menu">
      <a class="dropdown-item" href="#">Action</a>
      <a class="dropdown-item" href="#">Another action</a>
      <a class="dropdown-item" href="#">Something else here</a>
    </div>
  </div>
</body>
</html>`
}, {
    exerciseId: "html-css-dropdown-2",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
    
    <!DOCTYPE html>
    <html>
    <head>
      <style>
            .button1 {
                position: fixed;
                left: 10px;
                top: 10px
            }
            .button2 {
                position: fixed;
                right: 10px;
                bottom: 10px
            }
    .btn {
        display: inline-block;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        text-align: center;
        text-decoration: none;
        user-select: none;
        background-color: transparent;
        border: 1px solid transparent;
        padding: .375rem .75rem;
        font-size: 1rem;
        border-radius: .25rem;
    }
    .btn-secondary {
        color: #fff;
        background-color: #6c757d;
        border-color: #6c757d;
        vertical-align: top;
    }
    .dropdown-menu {
        position: relative;
        z-index: 1000;
        padding: .5rem 0;
        margin: 0;
        font-size: 1rem;
        color: #212529;
        text-align: left;
        list-style: none;
        background-color: #fff;
        border: 1px solid rgba(0,0,0,.15);
        border-radius: .25rem;
        display: none;
    }
    .dropdown-item0 {
        padding: .25rem 1rem;
        font-weight: 400;
        color: #212529;
        text-align: inherit;
        text-decoration: none;
        background-color: transparent;
        border: 0;
        display: block;
    }
    .dropdown-item {
        display: inline-block;
    }
    .dropdown:hover .dropdown-menu0 {
      display: inline-block
    }
    .dropdown1:hover .dropdown-menu1 {
      display: block;
      margin-left: 10px;
    }
    .dropdown2:hover .dropdown-menu2 {
      display: block;
      margin-left: 10px;
    }
    .dropdown3:hover .dropdown-menu3 {
      display: block;
      margin-left: 10px;
    }
    .dropdown-item:hover {
        background-color:lightgrey;
    }
        </style>
    </head>
    <body>
      <div class="dropdown">
        <button class="btn btn-secondary" type="button">
          Dropdown button
        </button>
        <div class="dropdown-menu dropdown-menu0">
          <div class="dropdown1" id="dropdown1">
            <a class="dropdown-item dropdown-item0" href="#">Action</a>
            <div id="dropdown-menu1" class="dropdown-menu dropdown-menu1">
              <a class="dropdown-item0 dropdown-item" href="#">Action 1</a>
              <a class="dropdown-item0 dropdown-item" href="#">Action 2</a>
              <a class="dropdown-item0 dropdown-item" href="#">Action 3</a>
            </div>
          </div>
          <div class="dropdown2" id="dropdown2">
            <a class="dropdown-item dropdown-item0" href="#">Another action</a>
            <div class="dropdown-menu dropdown-menu2">
              <a class="dropdown-item0 dropdown-item" href="#">Another action 1</a>
              <a class="dropdown-item0 dropdown-item" href="#">Another action 2</a>
              <a class="dropdown-item0 dropdown-item" href="#">Another action 3</a>
            </div>
          </div>
          <div class="dropdown3" id="dropdown3">
            <a class="dropdown-item dropdown-item0" href="#">Something else here</a>
            <div class="dropdown-menu dropdown-menu3">
              <a class="dropdown-item0 dropdown-item" href="#">Something else here 1</a>
              <a class="dropdown-item0 dropdown-item" href="#">Something else here 2</a>
              <a class="dropdown-item0 dropdown-item" href="#">Something else here 3</a>
            </div>
          </div>
        </div>
      </div>
    </body>
    </html>`
}, {
    exerciseId: "html-css-dropdown-3",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
    
    
<!DOCTYPE html>
<html>
<head>
  <style>
        .button1 {
            position: fixed;
            left: 10px;
            top: 10px
        }
        .button2 {
            position: fixed;
            right: 10px;
            bottom: 10px
        }
.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
}
.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    vertical-align: top;
}
.dropdown-menu {
    position: fixed;
    right: 10px;
    z-index: 1000;
    padding: .5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    display: none;
}
.dropdown-item {
    padding: .25rem 1rem;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    background-color: transparent;
    border: 1px solid lightgrey;
    display: block;
    margin: 5px
}
.dropdown:hover .dropdown-menu {
  display: block
}
.dropdown {
  position: absolute;
  right: 10px;
}
    </style>
</head>
<body>
  <div class="dropdown">
    <button class="btn btn-secondary" type="button">
      Login
    </button>
    <div class="dropdown-menu">
      <form>
        <input class="dropdown-item" type="text" placeholder="User name"/>
        <input class="dropdown-item" type="password" placeholder="Password"/>
        <button class="dropdown-item btn btn-secondary">Submit</button>
      </form>

    </div>
  </div>
</body>
</html>`
}, {
    exerciseId: "html-css-dropdown-4",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
    
<!DOCTYPE html>
<html>
<head>
  <style>
    .dropdown {
      display: inline-block;
      vertical-align: top;
    }
    .menulink {
      display: inline-block;
      padding: 10px;
      background-color: #06365A;
      color: white;
      text-decoration: none;
    }
    .menupopoutsection {
      display: inline-block;
      width: 195px;
      vertical-align: top;
    }
    .dropdown1 {
      width: 60px;
    }
    .dropdown2 {
      width: 100px;
    }
    .dropdown3 {
      width: 150px;
    }
    .dropdown4 {
      width: 100px;
    }
    .dropdown5 {
      width: 100px;
    }
    .dropdown:hover .menupopout {
      display: block;
    }
    .menupopout {
      background-color: rgb(253, 253, 239);
      border: 1px solid black;
      padding: 10px;
      display: none;
    }
    .menupopout2 {
      /* z-index: 10000; */
      width: 600px;
    }
    .menupopout3 {
      /* z-index: 10000; */
      width: 800px;
    }
    .menupopout5 {
      /* z-index: 10000; */
      width: 400px;
    }
    .menupopoutsectiontitle {
      background-color: #06365A;
      color: white;
      line-height: 25px;
      padding-left: 5px;
    }
    .menupopoutsectionitem {
      display: block;
      border-bottom: 1px dotted black;
      padding: 5px;
      color: #06365A;
      font-size: 11px;
      text-decoration: none;
    }
    .menupopoutsectionitem:hover {
      color: #e8c48c;
    }
  </style>
</head>
  <body>
    <div class="dropdowncontainer">
      <div class="dropdown dropdown1">
        <a class="menulink" href="https://www.iitk.ac.in/">Home</a>
      </div>
      <div class="dropdown dropdown2">
        <a class="menulink">Departments</a>
        <div class="menupopout menupopout2">
          <div class="menupopoutsection">
            <div class="menupopoutsectiontitle">
              Engineering
            </div>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/aero/">
              Aerospace Engineering
            </a>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/bsbe/">
              Biological Sciences & Bioengineering
            </a>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/che/">
              Chemical Engineering
            </a>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/ce/">
              Civil Engineering
            </a>
            <a class="menupopoutsectionitem" href="https://www.cse.iitk.ac.in/">
              Computer Science & Engineering
            </a>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/ee/">
              Electrical Engineering
            </a>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/ime/">
              Industrial & Management Engineering
            </a>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/mse/">
              Materials Science & Engineering
            </a>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/me/">
              Mechanical Engineering
            </a>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/see/">
              Sustainable Energy Engineering
            </a>
          </div>
          <div class="menupopoutsection">
            <div class="menupopoutsectiontitle">
              Humanities
            </div>
            <div class="menupopoutsectionitem" href="http://www.iitk.ac.in/eco/">
              Economic Sciences
            </div>
            <div class="menupopoutsectionitem" href="http://www.iitk.ac.in/hss/">
              Humanities & Social Sciences 
            </div>
          </div>
          <div class="menupopoutsection">
            <div class="menupopoutsectiontitle">
              Sciences
            </div>
            <div class="menupopoutsectionitem" href="https://www.iitk.ac.in/chm/">
              Chemistry
            </div>
            <div class="menupopoutsectionitem" href="https://www.cgs.iitk.ac.in/user/cgs/cgs/index.php">
              Cognitive Science 
            </div>
            <div class="menupopoutsectionitem" href="https://www.iitk.ac.in/es/">
              Earth Sciences
            </div>
            <div class="menupopoutsectionitem" href="https://www.iitk.ac.in/math/">
              Mathematics & Statistics 
            </div>
            <div class="menupopoutsectionitem" href="https://www.iitk.ac.in/phy/">
              Physics
            </div>
          </div>
        </div>
      </div>
      <div class="dropdown dropdown3">
        <a class="menulink">Centres & Facilities
        </a>
        <div class="menupopout menupopout3">
          <div class="menupopoutsection">
            <div class="menupopoutsectiontitle">
              Centres
            </div>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/acms/">
              Advanced Centre for Material Science(ACMS)
            </a>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/ee/">
              Advanced Centre for Electronic Systems(ACES)
            </a>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/imaging/">
              Advanced Imaging Centre
            </a>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/cese/">
              Centre for Environmental Science & Engineering
            </a>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/robotics/">
              Centre for Mechatronics
            </a>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/celp/">
              Centre for Lasers and Photonics
            </a>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/cad/">
              Computer Aided Design Laboratory
            </a>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/nanoscience/">
              Nanoscience and Soft Nanotechnology
            </a>
            <a class="menupopoutsectionitem" href="http://www.security.iitk.ac.in/">
              Prabhu Goel Research Centre for Computer and Internet Security
            </a>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/scdt/">
              Samtel Centre for Display Technologies
            </a>
            <a class="menupopoutsectionitem" href="https://siicincubator.com/">
              SIDBI Innovation and Incubation Centre
            </a>
            <a class="menupopoutsectionitem" href="http://msi.iitk.ac.in/">
              Center for Mathematical Sciences
            </a>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/sbertc/">
              Syndicate Bank Entrepreneurship Research and Training Centre (SBERTC)
            </a>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/cce/">
              Centre for Continuing Education (CCE)
            </a>
            <a class="menupopoutsectionitem" href="https://ict.iitk.ac.in/">
              Electronic and ICT Academy(E&ICT Academy)
            </a>
            <a class="menupopoutsectionitem" href="https://cer.iitk.ac.in/">
              Centre for Energy Regulation (CER)
            </a>
            <a class="menupopoutsectionitem" href="http://cganga.org/">
              Center for Ganga River Basin Management and Studies
            </a>
            <a class="menupopoutsectionitem" href="https://eal.iitk.ac.in/">
              Energy Analytics Lab (EAL)
            </a>
            <a class="menupopoutsectionitem" href="http://iitk.ac.in/rice-iitk/">
              Rice-IITK Collaborative Center
            </a>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/mfcem/">
              Mehta Family Centre for Engineering in Medicine
            </a>
          </div>
          <div class="menupopoutsection">
            <div class="menupopoutsectiontitle">
              Facilities
            </div>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/cc/">
              Computer Centre
            </a>
            <a class="menupopoutsectionitem" href="http://pkklib.iitk.ac.in/">
              P. K. Kelkar Library
            </a>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/#">
              Facility for Ecological & Analytical Testing
            </a>
            <a class="menupopoutsectionitem" href="https://oa.cc.iitk.ac.in/">
              Office Automation
            </a>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/cgbs/">
              Central Glass Blowing Section & Special Water Facility
            </a>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/cw/">
              Central Workshop
            </a>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/#">
              Central Cryogenic Facility
            </a>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/#">
              Liquid Helium Facility
            </a>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/#">
              SQUID Facility
            </a>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/#">
              Virtual Instrument Cell
            </a>
          </div>
          <div class="menupopoutsection">
            <div class="menupopoutsectiontitle">
              National Initiatives
            </div>
            <a class="menupopoutsectionitem" href="">
              National Center for Geodesy (NCG)
            </a>
            <a class="menupopoutsectionitem" href="">
              National Centre for Flexible Electronics
            </a>
            <a class="menupopoutsectionitem" href="">
              National Information Center of Earthquake Engineering
            </a>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/nwtf/">
              National Wind Tunnel Facility
            </a>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/3inetwork/">
              3i Network
            </a>
            <a class="menupopoutsectionitem" href="https://www.nicee.org/npeee/">
              NPEEE
            </a>
          </div>
          <div class="menupopoutsection">
            <div class="menupopoutsectiontitle">
              KNOWLEDGE INCUBATION FOR TEQIP
            </div>
            <a class="menupopoutsectionitem" href="https://www.teqipiitk.in/">
              KIT IIT Kanpur
            </a>
            <div class="menupopoutsectiontitle">
              Quality  Improvement Program (QIP)
            </div>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/qip/">
              Quality  Improvement Program (QIP)
            </a>
          </div>
        </div>
      </div>
      <div class="dropdown dropdown4">
        <a class="menulink" href="https://www.iitk.ac.in/doaa/">Academics
        </a>
      </div>
      <div class="dropdown dropdown5">
        <a class="menulink" href="">Placements
        </a>
        <div class="menupopout menupopout5">
          <div class="menupopoutsection">
            <div class="menupopoutsectiontitle">
              Placements
            </div>
            <a class="menupopoutsectionitem" href="http://spo.iitk.ac.in/">
              Students' Placement Office
            </a>
            <a class="menupopoutsectionitem" href="http://spo.iitk.ac.in/about_iitk.html">
              Why recruit at IITK?
            </a>
            <a class="menupopoutsectionitem" href="http://spo.iitk.ac.in/company_registration.html">
              Company Registration
            </a>
            <a class="menupopoutsectionitem" href="http://spo.iitk.ac.in/procedure_policy.html">
              Procedures and Policy
            </a>
            <a class="menupopoutsectionitem" href="http://spo.iitk.ac.in/facilities.html">
              Facilities
            </a>
            <a class="menupopoutsectionitem" href="http://spo.iitk.ac.in/company_faq.html">
              FAQs
            </a>
          </div>
          <div class="menupopoutsection">
            <div class="menupopoutsectiontitle">
              Downloads
            </div>
            <a class="menupopoutsectionitem" href="">
              Job Announcement Form
            </a>
            <a class="menupopoutsectionitem" href="">
              Internship Offer Format
            </a>
            <a class="menupopoutsectionitem" href="http://spo.iitk.ac.in/assets/docs/IITK_Recruitment_Brochure_2015-16.pdf">
              Campus Recruitment Brochure
            </a>
            <a class="menupopoutsectionitem" href="http://spo.iitk.ac.in/assets/docs/IITK_Recruitment_Guide_2015-16.pdf">
              Campus Recruitment Guide
            </a>
            <a class="menupopoutsectionitem" href="http://spo.iitk.ac.in/assets/docs/IITK_Recruitment_Itinerary_2015-16.pdf">
              Campus Recruitment Schedule
            </a>
            <a class="menupopoutsectionitem" href="https://www.iitk.ac.in/new/brochures.html">
              Departmental Brochures
            </a>
          </div>
        </div>
      </div>
    </div>
  </body>
</html>`
}, {
        exerciseId: "html-form-1",
        initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
        expectedOutputHtml: `
<!DOCTYPE html>
<html>
    <head>
        <style>
        </style>
    </head>
    <body>
        <form>
        <div class="form-group">
            <label for="exampleInputEmail1">Email address</label>
            <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Enter email">
        </div>
        <div class="form-group">
            <label for="exampleInputPassword1">Password</label>
            <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password">
        </div>
        <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" id="exampleCheck1">
            <label class="form-check-label" for="exampleCheck1">Check me out</label>
        </div>
        <button type="submit" class="btn btn-primary">Submit</button>
    </form>
    </body>
</html>`
}, {
        exerciseId: "html-form-2",
        initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
        expectedOutputHtml: `
        <!DOCTYPE html>
        <html>
            <head>
                <style>
                </style>
            </head>
            <body><div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">Email address</label>
          </div>
          <div class="mb-3">
            <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com">
          </div>
          <div class="mb-3">
            <label for="exampleFormControlTextarea1" class="form-label">Example textarea</label>
          </div>
          <div class="mb-3">
            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
          </div>
            </body>
        </html>`
}, {
        exerciseId: "html-form-3",
        initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
        expectedOutputHtml: `
        <!DOCTYPE html>
        <html>
            <head>
                <style>
                </style>
            </head>
            <body><div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">@</span>
            <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1">
          </div>
          
          <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2">
            <span class="input-group-text" id="basic-addon2">@example.com</span>
          </div>
          
          <label for="basic-url" class="form-label">Your vanity URL</label>
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon3">https://example.com/users/</span>
            <input type="text" class="form-control" id="basic-url" aria-describedby="basic-addon3">
          </div>
          
          <div class="input-group mb-3">
            <span class="input-group-text">$</span>
            <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)">
            <span class="input-group-text">.00</span>
          </div>
          
          <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="Username" aria-label="Username">
            <span class="input-group-text">@</span>
            <input type="text" class="form-control" placeholder="Server" aria-label="Server">
          </div>
          
          <div class="input-group">
            <span class="input-group-text">With textarea</span>
            <textarea class="form-control" aria-label="With textarea"></textarea>
          </div>
            </body>
        </html>`
}, {
        exerciseId: "html-form-4",
        initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
        expectedOutputHtml: `
        <!DOCTYPE html>
        <html>
            <head>
                <style>
                </style>
            </head>
            <body><form>
            <div class="form-row row">
              <div class="form-group col-6">
                <label for="inputEmail4">Email</label>
                <input type="email" class="form-control" id="inputEmail4" placeholder="Email">
              </div>
              <div class="form-group col-6">
                <label for="inputPassword4">Password</label>
                <input type="password" class="form-control" id="inputPassword4" placeholder="Password">
              </div>
            </div>
            <div class="form-group">
              <label for="inputAddress">Address</label>
              <input type="text" class="form-control" id="inputAddress" placeholder="1234 Main St">
            </div>
            <div class="form-group">
              <label for="inputAddress2">Address 2</label>
              <input type="text" class="form-control" id="inputAddress2" placeholder="Apartment, studio, or floor">
            </div>
            <div class="form-row  row">
              <div class="form-group col-6">
                <label for="inputCity">City</label>
                <input type="text" class="form-control" id="inputCity">
              </div>
              <div class="form-group col-4">
                <label for="inputState">State</label>
                <select id="inputState" class="form-control">
                  <option selected>Choose...</option>
                  <option>AB</option>
                  <option>CD</option>
                  <option>EF</option>
                  <option>GH</option>
                </select>
              </div>
              <div class="form-group col-2">
                <label for="inputZip">Zip</label>
                <input type="text" class="form-control" id="inputZip">
              </div>
            </div>
            <div class="form-group">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="gridCheck">
                <label class="form-check-label" for="gridCheck">
                  Check me out
                </label>
              </div>
            </div>
            <button type="submit" class="btn btn-primary">Sign in</button>
          </form>
            </body>
        </html>`
}, {
        exerciseId: "html-css-state-element-1",
        initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
        expectedOutputHtml: `
<!DOCTYPE html>
<html>
    <head>
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css" rel="stylesheet"
    integrity="sha384-+0n0xVW2eSR5OomGNYDnhzAbDsOXxcvSN1TPprVMTNDbiYZCxYbOOl7+AMvyTG2x" crossorigin="anonymous">
        <style>
            .para1 {
                color: rgb(32, 96, 194, 1);
                font-size: 24px
            }
        </style>
    </head>
    <body><button type="button" class="btn btn-primary">Primary</button>
    </body>
</html>`
}, {
        exerciseId: "html-css-state-element-2",
        initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
        expectedOutputHtml: `
<!DOCTYPE html>
<html>
    <head>
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css" rel="stylesheet"
    integrity="sha384-+0n0xVW2eSR5OomGNYDnhzAbDsOXxcvSN1TPprVMTNDbiYZCxYbOOl7+AMvyTG2x" crossorigin="anonymous">
        <style>
            .button1 {
                position: fixed;
                left: 10px;
                top: 10px
            }
            .button2 {
                position: fixed;
                right: 10px;
                bottom: 10px
            }
        </style>
    </head>
    <body>
    <button type="button" class="btn btn-secondary">Secondary</button>
    <button type="button" class="btn btn-success">Success</button>
    <button type="button" class="btn btn-danger">Danger</button>
    <button type="button" class="btn btn-warning">Warning</button>
    <button type="button" class="btn btn-info">Info</button>
    <button type="button" class="btn btn-light">Light</button>
    <button type="button" class="btn btn-dark">Dark</button>
    
    <button type="button" class="btn btn-link">Link</button>
    </body>
</html>`
}, {
        exerciseId: "html-css-state-element-3",
        initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
        expectedOutputHtml: `
<!DOCTYPE html>
<html>
    <head>
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css" rel="stylesheet"
    integrity="sha384-+0n0xVW2eSR5OomGNYDnhzAbDsOXxcvSN1TPprVMTNDbiYZCxYbOOl7+AMvyTG2x" crossorigin="anonymous">
        <style>
            .button1 {
                position: fixed;
                left: 10px;
                top: 10px
            }
            .button2 {
                position: fixed;
                right: 10px;
                bottom: 10px
            }
        </style>
    </head>
    <body><button type="button" class="btn btn-outline-primary">Primary</button>
    <button type="button" class="btn btn-outline-secondary">Secondary</button>
    <button type="button" class="btn btn-outline-success">Success</button>
    <button type="button" class="btn btn-outline-danger">Danger</button>
    <button type="button" class="btn btn-outline-warning">Warning</button>
    <button type="button" class="btn btn-outline-info">Info</button>
    <button type="button" class="btn btn-outline-light">Light</button>
    <button type="button" class="btn btn-outline-dark">Dark</button>
    </body>
</html>`
}, {
        exerciseId: "html-css-state-element-4",
        initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
        expectedOutputHtml: `
<!DOCTYPE html>
<html>
    <head>
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css" rel="stylesheet"
    integrity="sha384-+0n0xVW2eSR5OomGNYDnhzAbDsOXxcvSN1TPprVMTNDbiYZCxYbOOl7+AMvyTG2x" crossorigin="anonymous">
        <style>
            .button1 {
                position: fixed;
                left: 10px;
                top: 10px
            }
            .accordion-button::after {
                width: 0px;
            }
        </style>
    </head>
    <body>  <div class="btn-group me-2" role="group" aria-label="First group">
    <button type="button" class="btn btn-outline-secondary">1</button>
    <button type="button" class="btn btn-outline-secondary">2</button>
    <button type="button" class="btn btn-outline-secondary">3</button>
    <button type="button" class="btn btn-outline-secondary">4</button>
  </div>
    </body>
</html>`
}, {
        exerciseId: "html-iframe-1",
        questionText: "Use the webpage at https://en.wikipedia.org to get the expected output.",
        initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
        expectedOutputHtml: `
<!DOCTYPE html>
<html>
    <head>
        <style>
        </style>
    </head>
    <body>
        <p>Below is wikipedia page embedded in an iframe</p>
        <iframe src="https://en.wikipedia.org"  width="700" height="400">
        </iframe>
    </body>
</html>`
}, {
        exerciseId: "html-iframe-2",
        questionText: "Use the webpage at https://en.wikipedia.org to get the expected output.",
        initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
        expectedOutputHtml: `
<!DOCTYPE html>
<html>
    <head>
        <style>
            .iframe1 {
                border: none;
                margin-left: 300px;
            }
        </style>
    </head>
    <body>
        <p>Below is wikipedia page embedded in an iframe</p>
        <iframe src="https://en.wikipedia.org" class="iframe1" width="300" height="200">
        </iframe>
    </body>
</html>`
}, {
        exerciseId: "html-iframe-3",
        questionText: "Use the webpage at https://en.wikipedia.org to get the expected output.",
        initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
        expectedOutputHtml: `
<!DOCTYPE html>
<html>
    <head>
        <style>
            .iframe1 {
                border: none;
                margin-left: 300px;
            }
            .iframe1 {
                display: inline;
                margin-left: unset;
            }
        </style>
    </head>
    <body>
        <p>Below is wikipedia page embedded in an iframe</p>
        <iframe src="https://en.wikipedia.org" class="iframe1" width="300" height="200">
        </iframe>
        <iframe src="https://en.wikipedia.org" class="iframe1 iframe2" width="300" height="200">
        </iframe>
    </body>
</html>`
}, {
        exerciseId: "html-iframe-4",
        questionText: "Use the webpage at https://en.wikipedia.org to get the expected output.",
        initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
        expectedOutputHtml: `
<!DOCTYPE html>
<html>
    <head>
        <style>
            .iframe1 {
                border: none;
                margin-left: 300px;
            }
            .iframe1 {
                display: inline;
                margin-left: unset;
            }
        </style>
    </head>
    <body>
        <h1>Below is wikipedia page embedded in an iframe</h1>
        <iframe src="https://en.wikipedia.org" class="iframe1" width="300" height="200">
        </iframe>
        <iframe src="https://en.wikipedia.org" class="iframe1 iframe2" width="300" height="200">
        </iframe>
        <iframe src="https://en.wikipedia.org" class="iframe1" width="300" height="200">
        </iframe>
        <iframe src="https://en.wikipedia.org" class="iframe1 iframe2" width="300" height="200">
        </iframe>
    </body>
</html>`
}, {
        exerciseId: "html-code-1",
        initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
        expectedOutputHtml: `
<!DOCTYPE html>
<html>
    <head>
        <style>
        </style>
    </head>
    <body>
        <p>The ©
         (a circled capital letter C for copyright), is the symbol used in copyright notices for works other than
          sound recordings.
        </p>
    </body>
</html>`
}, {
        exerciseId: "html-code-2",
        initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
        expectedOutputHtml: `
<!DOCTYPE html>
<html>
    <head>
        <style>
            .iframe1 {
                border: none;
                margin-left: 300px;
            }
        </style>
    </head>
    <body>
        <p>&#8731; 27 = 3</p>
    </body>
</html>`
}, {
        exerciseId: "html-code-3",
        initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
        expectedOutputHtml: `
<!DOCTYPE html>
<html>
    <head>
        <style>
            .iframe1 {
                border: none;
                margin-left: 300px;
            }
            .iframe1 {
                display: inline;
                margin-left: unset;
            }
        </style>
    </head>
    <body>
        <p>For any ε&gt;0, there is a δ&gt;0 so that |f(x)−L|&lt;ε when 0&lt;|x−a|&lt;δ</p>
    </body>
</html>`
}, {
        exerciseId: "html-code-4",
        initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
        expectedOutputHtml: `
<!DOCTYPE html>
<html>
    <head>
        <style>
            .strong1 {
                display: block;
            }
            .div2 {
                display: inline;
            }
            .div3 {
                display: inline
            }
        </style>
    </head>
    <body>
    <p>∀x ∃y y = x+1</p>
    </body>
</html>`
}, {
    exerciseId: "css-length-units-1",
    questionText: "Use percentage length units to get the expected output.",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `

    <!DOCTYPE html>
    <html>
    <head>
      <style>
    .h-100 {
        height: 100%!important;
    }
    .h-25 {
        height: 25%!important;
    }.h-50 {
        height: 50%!important;
    }.h-75 {
        height: 75%!important;
    }.d-inline-block {
        display: inline-block!important;
    }
      </style>
    </head>
      <body>
        <div style="height: 100px; background-color: rgba(255,0,0,0.1);">
          <div class="h-25 d-inline-block" style="width: 120px; background-color: rgba(0,0,255,.1)">Height 25%</div>
          <div class="h-50 d-inline-block" style="width: 120px; background-color: rgba(0,0,255,.1)">Height 50%</div>
          <div class="h-75 d-inline-block" style="width: 120px; background-color: rgba(0,0,255,.1)">Height 75%</div>
          <div class="h-100 d-inline-block" style="width: 120px; background-color: rgba(0,0,255,.1)">Height 100%</div>
        </div>
      </body>
    </html>`
}, {
    exerciseId: "css-length-units-2",
    questionText: "Use percentage length units to get the expected output.",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `

    <!DOCTYPE html>
    <html>
    <head>
      <style>
    .w-100 {
        width: 100%!important;
    }
    .w-25 {
      width: 25%!important;
    }.w-50 {
      width: 50%!important;
    }.w-75 {
      width: 75%!important;
    }.d-inline-block {
        display: inline-block!important;
    }.p-3 {
        padding: 1rem!important;
    }
      </style>
    </head>
      <body><div class="w-25 p-3" style="background-color: #eee;">Width 25%</div>
        <div class="w-50 p-3" style="background-color: #eee;">Width 50%</div>
        <div class="w-75 p-3" style="background-color: #eee;">Width 75%</div>
        <div class="w-100 p-3" style="background-color: #eee;">Width 100%</div>
      </body>
    </html>`
}, {
    exerciseId: "css-length-units-3",
    questionText: "Use em length units to get the expected output.",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `

    <!DOCTYPE html>
    <html>
    <head>
      <style>
    .w-100 {
        width: 100%!important;
    }
    .w-25 {
      width: 25%!important;
    }.w-50 {
      width: 50%!important;
    }.w-75 {
      width: 75%!important;
    }.d-inline-block {
        display: inline-block!important;
    }.p-3 {
        padding: 1rem!important;
    }
    .para1 {
      font-size: 2em;
    }
    .para2 {
      font-size: 1em;
    }
    .para3 {
      font-size: 0.7em;
    }
    .para4 {
      font-size: 5em;
    }
      </style>
    </head>
      <body>
        <p class="para1">
          According to radiometric dating estimation and other evidence, Earth formed over 4.5 billion
           years ago. Within the first billion years of Earth's history, life appeared in the oceans
            and began to affect Earth's atmosphere and surface, leading to the proliferation of
             anaerobic and, later, aerobic organisms.
        </p>
        <p class="para2">
          According to radiometric dating estimation and other evidence, Earth formed over 4.5 billion
           years ago. Within the first billion years of Earth's history, life appeared in the oceans
            and began to affect Earth's atmosphere and surface, leading to the proliferation of
             anaerobic and, later, aerobic organisms.
        </p>
        <p class="para3">
          According to radiometric dating estimation and other evidence, Earth formed over 4.5 billion
           years ago. Within the first billion years of Earth's history, life appeared in the oceans
            and began to affect Earth's atmosphere and surface, leading to the proliferation of
             anaerobic and, later, aerobic organisms.
        </p>
        <p class="para4">
          Earth
        </p>
      </body>
    </html>`
}, {
    exerciseId: "css-length-units-4",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `

    <!DOCTYPE html>
    <html>
    <head>
      <style>
    .w-100 {
        width: 100%!important;
    }
    .w-25 {
      width: 25%!important;
    }.w-50 {
      width: 50%!important;
    }.w-75 {
      width: 75%!important;
    }.d-inline-block {
        display: inline-block!important;
    }.p-3 {
        padding: 1rem!important;
    }
    .form-label {
        margin-bottom: .5rem;
        display: inline-block;
    }
    .form-control {
        display: block;
        width: 100%;
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        color: #212529;
        background-color: #fff;
        border: 1px solid #ced4da;
        appearance: none;
        border-radius: .25rem;
    }
      </style>
    </head>
      <body>
        <form>
          <div class="w-50">
            <label for="inputEmail4" class="form-label">Email</label>
            <input type="email" class="form-control" id="inputEmail4">
          </div>
          <div class="w-50">
            <label for="inputPassword4" class="form-label">Password</label>
            <input type="password" class="form-control" id="inputPassword4">
          </div>
        </form>
      </body>
    </html>`
}, {
    exerciseId: "css-line-height-1",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
    <!DOCTYPE html>
    <html>
    <head>
      <style>
    .para1 {
        line-height: 2;
    }
    .para2 {
        line-height: 3;
    }
      </style>
    </head>
      <body>
        <p class="para1">New continental crust forms as a result of plate tectonics, a process ultimately driven by
           the continuous loss of heat from Earth's interior. Over the period of hundreds of millions of
            years, tectonic forces have caused areas of continental crust to group together to form
             supercontinents that have subsequently broken apart.</p>
        <p class="para2">New continental crust forms as a result of plate tectonics, a process ultimately driven by
          the continuous loss of heat from Earth's interior. Over the period of hundreds of millions of
            years, tectonic forces have caused areas of continental crust to group together to form
            supercontinents that have subsequently broken apart.</p>
      </body>
    </html>`
}, {
    exerciseId: "css-line-height-2",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `
    <!DOCTYPE html>
    <html>
    <head>
      <style>
    .para1 {
        line-height: 1.2;
    }
    .para2 {
        line-height: 3;
    }
      </style>
    </head>
      <body>
        <ul class="para1">
        <li>Sun</li>
        <li>Mars</li>
        <li>Earth</li>
        <li>Jupiter</li>
        <li>Saturn</li>
        <li>Moon</li>
      </ul>  
      <ol class="para2">
      <li>Sun</li>
      <li>Mars</li>
      <li>Earth</li>
      <li>Jupiter</li>
      <li>Saturn</li>
      <li>Moon</li>
    </ol>  
      </body>
    </html>`
}, {
    exerciseId: "css-line-height-3",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `<!DOCTYPE html>
    <html>
        <head>
            <style>
            .btn {
        display: inline-block;
        font-weight: 400;
        line-height: 1.5;
        text-align: center;
        text-decoration: none;
        padding: .375rem .75rem;
        font-size: 1rem;
    }
    [type=button], [type=reset], [type=submit], button {
        -webkit-appearance: button;
    }
    .button1 {
      line-height: 2;
    }
    .button2 {
      line-height: 1;
    }
    .button3 {
      line-height: 3;
    }
    .button4 {
      line-height: 5;
    }
            </style>
        </head>
        <body>
          <button class="btn button1">Button 1</button>
          <button class="btn button2">Button 2</button>
          <button class="btn button3">Button 3</button>
          <button class="btn button4">Button 4</button>
        </body>
    </html>`
}, {
    exerciseId: "css-line-height-4",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: `<!DOCTYPE html>
    <html>
        <head>
            <style>
    .heading1 {
      font-size: 3em;
    }
    .para1 {
      font-size: 1.2em;
      line-height: 2em;
    }
            </style>
        </head>
        <body>
          <h3 class="heading1">Earth</h3>
          <p class="para1">
            Earth is the third planet from the Sun and the only astronomical object known to harbor and
             support life. About 29.2% of Earth's surface is land consisting of continents and islands.
              The remaining 70.8% is covered with water, mostly by oceans, seas, gulfs, and other salt-water bodies,
               but also by lakes, rivers, and other freshwater, which together constitute the hydrosphere.
                Much of Earth's polar regions are covered in ice. Earth's outer layer is divided into
                 several rigid tectonic plates that migrate across the surface over many millions of years, 
                 while its interior remains active with a solid iron inner core, a liquid outer core that
                  generates Earth's magnetic field, and a convective mantle that drives plate tectonics.
          </p>
        </body>
    </html>`
}, {
    exerciseId: "html-wikipedia-1",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: wiki1Html
}, {
    exerciseId: "html-wikipedia-2",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: wiki2Html
}, {
    exerciseId: "html-wikipedia-1",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: wiki1Html
}, {
    exerciseId: "html-wikipedia-3",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: wiki3Html
}, {
    exerciseId: "html-wikipedia-4",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: wiki4Html
}, {
    exerciseId: "html-wikipedia-5",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: wiki5Html
}, {
    exerciseId: "html-wikipedia-6",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: wiki6Html
}, {
    exerciseId: "html-wikipedia-7",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: wiki7Html
}, {
    exerciseId: "html-wikipedia-8",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: wiki8Html
}, {
    exerciseId: "html-wikipedia-9",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: wiki9Html
}, {
    exerciseId: "html-wikipedia-10",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: wiki10Html
}, {
    exerciseId: "html-wikipedia-11",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: wiki11Html
}, {
    exerciseId: "html-dj-1",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: dj1Html
}, {
    exerciseId: "html-dj-2",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: dj2Html
}, {
    exerciseId: "html-dj-3",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: dj3Html
}, {
    exerciseId: "html-dj-4",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: dj4Html
}, {
    exerciseId: "html-dj-5",
    initialHtmlText: `
<!DOCTYPE html>
<html>
    <body>
    </body>
</html>`,
    expectedOutputHtml: dj5Html
}, {
    exerciseId: "html-first-line",
    questionType: "multiple-choice-multiple-answer",
    questionHtml: "What is the first line of every html document?",
    options: {
        option1: {
            text: "html"
        },
        option2: {
            text: "<html>"
        },
        option3: {
            text: "<!DOCTYPE html>"
        },
        option4: {
            text: "Empty line"
        },
    },
    correctOptions: ["option3"]
}, {
    exerciseId: "html-full-form",
    questionType: "multiple-choice-multiple-answer",
    questionHtml: "What is the full form of HTML?",
    options: {
        option1: {
            text: "HyperText Main Language"
        },
        option2: {
            text: "HyperText Markup Language",
        },
        option3: {
            text: "Helvetica Text Markup Language",
        },
        option4: {
            text: "HyperText Modular Language",
        },
    },
    correctOptions: ["option2"]
}, {
    exerciseId: "html-use-for",
    questionType: "multiple-choice-multiple-answer",
    questionHtml: "What is HTML used for?",
    options: {
        option1: {
            text: "To transfer data from server to a client",
        },
        option2: {
            text: "To mine bitcoins and make money",
        },
        option3: {
            text: "To show webpages in the browser",
        },
        option4: {
            text: "To remove virus from a computer",
        },
    },
    correctOptions: ["option3"]
}, {
    exerciseId: "html-root-element",
    questionType: "multiple-choice-multiple-answer",
    questionHtml: "What is the root tag name in a html document?",
    options: {
        option1: {
            text: "!DOCTYPE",
        },
        option2: {
            text: "html",
        },
        option3: {
            text: "body",
        },
        option4: {
            text: "head",
        },
    },
    correctOptions: ["option2"]
}, {
    exerciseId: "html-start-tag",
    questionType: "multiple-choice-multiple-answer",
    questionHtml: "Which of the following is a valid html element start tag?",
    options: {
        option1: {
            text: "<html>",
        },
        option2: {
            text: "</p>",
        },
        option3: {
            text: "<h1>",
        },
        option4: {
            text: "<code>",
        },
        option5: {
            text: "<tag/>",
        },
        option6: {
            text: "</button>",
        },
    },
    correctOptions: ["option1", "option3", "option4"]
}, {
    exerciseId: "html-valid-end-tag",
    questionType: "multiple-choice-multiple-answer",
    questionHtml: "Which of the following is a valid html element end tag?",
    options: {
        option1: {
            text: "<html>",
        },
        option2: {
            text: "</p>",
        },
        option3: {
            text: "<h1>",
        },
        option4: {
            text: "<code>",
        },
        option5: {
            text: "<tag/>",
        },
        option6: {
            text: "</button>",
        },
    },
    correctOptions: ["option2", "option6"]
}, {
    exerciseId: "html-valid-element",
    questionType: "multiple-choice-multiple-answer",
    questionHtml: "Which of the following is a valid html element?",
    options: {
        option1: {
            text: "<p>Hello World</p>",
        },
        option2: {
            text: "<h1>Hello world<h1>",
        },
        option3: {
            text: "</p>Hello world</p>",
        },
        option4: {
            text: "<a>Hello world</a>",
        },
        option5: {
            text: "<p><a>Hello world</a></p>",
        },
        option6: {
            text: "<button><p>Hello world<p></button>",
        },
    },
    correctOptions: ["option1", "option4", "option5"]
}, {
    exerciseId: "html-inside-tag",
    questionType: "multiple-choice-multiple-answer",
    questionHtml: "Which of the following are tags that appear immediately inside a html element?",
    options: {
        option1: {
            text: "<button><p>Hello world<p></button>",
        },
        option2: {
            text: "p",
        },
        option3: {
            text: "body",
        },
        option4: {
            text: "h1",
        },
        option5: {
            text: "DOCTYPE",
        },
        option6: {
            text: "head",
        },
    },
    correctOptions: ["option2", "option5"]
}, {
    exerciseId: "inline-elements",
    questionType: "multiple-choice-multiple-answer",
    questionHtml: "Which of the following are inline elements?",
    options: {
        option1: {
            text: "div",
        },
        option2: {
            text: "p",
        },
        option3: {
            text: "header",
        },
        option4: {
            text: "table",
        },
        option5: {
            text: "form",
        },
        option6: {
            text: "strong",
        },
        option6: {
            text: "img",
        },
        option6: {
            text: "input",
        },
        option6: {
            text: "label",
        },
        option6: {
            text: "sub",
        },
    },
    correctOptions: ["option6", "option7", "option8", "option9", "option10"]
}, {
    exerciseId: "block-elements",
    questionType: "multiple-choice-multiple-answer",
    questionHtml: "Which of the following are block elements?",
    options: {
        option1: {
            text: "div",
        },
        option2: {
            text: "p",
        },
        option3: {
            text: "header",
        },
        option4: {
            text: "table",
        },
        option5: {
            text: "form",
        },
        option6: {
            text: "strong",
        },
        option6: {
            text: "img",
        },
        option6: {
            text: "input",
        },
        option6: {
            text: "label",
        },
        option6: {
            text: "sub",
        },
    },
    correctOptions: ["option1", "option2", "option3", "option4", "option5"]
}]