import React from 'react';
import { HtmlEditor } from '../../../util/htmlEditor';
import { HtmlAnswerQuizLessonPart } from '../../../util/lessonPart';
import { getExerciseWithExerciseId } from '../../../exercises/exercise';

export const HtmlCssBackgroundLessonPartNew = () => {
    return (<div className="container-fluid">
        <h3>Background</h3>
        {/* <HtmlLessonReaderComponent lessonPartContent={htmlCssBackgroundLessonPartContent}></HtmlLessonReaderComponent> */}
    </div>)
}

export const CssBackgroundLessonPart1 = () => {
    return (<div className="container-fluid">
        <h3>Background</h3>
        <ul className="mt-3">
            <li>Background of a html element can be specified as a color or an image.</li>
            <li><code>background-color</code> property is used to specify a background color.</li>
            <li><code>background-image</code> property is used to specify a background image.</li>
            <li><code>background-color</code> value is a color value. The value of <code>background-image</code> is an image url.</li>
        </ul>
        <p>
            See the below example where we show two paragraphs, one with a background color and
             the other with a background image.
        </p>

        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <head>
        <style>
            .para1 {
                background-color:blue;
                border:3px solid green;
                color:white
            }
            .para2 {
                background-image:url(https://upload.wikimedia.org/wikipedia/commons/thumb/9/9d/MODIS_Map.jpg/1920px-MODIS_Map.jpg);
                border:3px solid green;
                color:red
            }
        </style>
    </head>
    <body>
        <p class="para1">Earth is the third planet from the Sun and the only astronomical object known to harbor and support life.</p>
        <p class="para2">Earth is the third planet from the Sun and the only astronomical
                object known to harbor and support life. Earth is the third planet from the Sun and the only astronomical 
                object known to harbor and support life. Earth is the third planet from the Sun and the only astronomical
                object known to harbor and support life. Earth is the third planet from the Sun and the only astronomical
                object known to harbor and support life. Earth is the third planet from the Sun and the only astronomical
                object known to harbor and support life. Earth is the third planet from the Sun and the only astronomical
                object known to harbor and support life. Earth is the third planet from the Sun and the only astronomical
                object known to harbor and support life.</p>
    </body>
</html>`}
            height={"400px"}
            showOutput={true}
        ></HtmlEditor>
    </div>)
}


export const cssBackgroundLessonParts = [{
    component: HtmlCssBackgroundLessonPartNew
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-css-background-1")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-css-background-2")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-css-background-3")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-css-background-4")
    }
}]
