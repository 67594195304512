import { getExerciseWithExerciseId } from "../../../exercises/exercise"
import { HtmlAnswerQuizLessonPart } from "../../../util/lessonPart"

export const htmlWikipediaLessonParts = [{
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-wikipedia-1")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-wikipedia-2")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-wikipedia-3")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-wikipedia-4")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-wikipedia-5")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-wikipedia-6")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-wikipedia-7")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-wikipedia-8")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-wikipedia-9")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-wikipedia-10")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-wikipedia-11")
    }
}]


export const htmlDjangoLessonParts = [{
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-dj-1")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-dj-2")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-dj-3")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-dj-4")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-dj-5")
    }
}]
