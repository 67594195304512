import React from 'react';
import { HtmlAnswerQuizLessonPart } from '../../../util/lessonPart';
import { getExerciseWithExerciseId } from '../../../exercises/exercise';

export const HtmlDivsLessonPart1 = () => {
    return (<div className="container-fluid">
        <h3>Div element</h3>
        <ul className="mt-3">
            <li><code>div</code> tag is used to block different html elements into blocks and
            arrange the blocks in a layout on the page.</li>
            <li><code>div</code> tag is mainly used to set the layout of the page.</li>
            <li>For example, in a article wikipedia article like&nbsp;
             <a href="https://en.wikipedia.org/wiki/Earth" target="_blank">this</a>,
             we would want to put the left side menu links into one <code>div</code> tag, top menu items into one <code>div</code> tag
             and the main article in another <code>div</code> tag.</li>
            <li>Within the main article <code>div</code> tag, we can have mode divs,
             each including article's introduction, content, citations etc.</li>
            <li><code>div</code> elements act as containers for other html elements and they define the layout of the page.</li>
            <li>Below is html using div elements.</li>
        </ul>

        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <body>
        <div>
            <div style="border: 2px dashed red;margin:5px">
                <h1>Earth</h1>
            </div>
            <div style="border:2px dashed green;margin:5px;display:flex;flex-flow:row">
                <div style="border:2px dashed blue;margin:5px;display:flex">
                    <p>The shape of Earth is nearly spherical</p>
                </div>
                <div style="border:2px dashed blue;margin:5px;display:flex">
                    <img style="width:200px" src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/97/The_Earth_seen_from_Apollo_17.jpg/1024px-The_Earth_seen_from_Apollo_17.jpg">
                </div>
            </div>
        </div>         
    </body>
</html>`}
            height={"400px"}
            showOutput={true}
        ></HtmlEditor>
        <p>The outer <code>div</code> holds two div elements. One to hold the header "Earth" and the other to hold the text and the image.
            Div elements by default are arranged one below the other.
            The second <code>div</code> holds two div elements, one to hold the paragraph element and the other to hold
            the image element. Now to arrange divs one beside the other, we used the style property <code>display</code>,
            and set it to <code>flex</code>. We will use flex layouts widely in html.
        </p>
    </div>)
}


export const htmlDivsLessonParts = [{
    component: HtmlDivsLessonPart1,
    lessonPartId: "HtmlDivsLessonPart1"
}]


export const htmlDivs2LessonParts = [{
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-div-1")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-div-2")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-div-3")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-div-4")
    }
}]
