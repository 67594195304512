import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Editor from "@monaco-editor/react"
import { PureComponent } from "react"
import { validateQuestionSaga } from "../../store/common.saga"
import { QuestionTextComponent, getHtmlString, promReduxConnect, shuffle } from "../util/utils"


class MultipleChoiceExerciseComponentInternal extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            selectedOptions: new Set(),
            shuffleSeed: Math.random(),
        }
    }
    
    componentDidMount = () => {
        const { exercise, courseId, isExam } = this.props
        const { exerciseId } = exercise || {}
        this.setSavedOptionsIfExam()
    }

    componentDidUpdate = (prevProps) => {
        const { exercise, courseId, isExam } = this.props
        const { exerciseId } = exercise || {}
        if (prevProps?.exercise?.exerciseId !== this.props?.exercise?.exerciseId) {
            this.setState({
                shuffleSeed: Math.random()
            })
            this.selectedOptions = new Set()
        }

        if (prevProps?.exerciseTask !== this.props.exerciseTask) {
            this.setSavedOptionsIfExam()
        }

        if (this.props?.exerciseTask?.taskStatus !== "passed" &&
            this.props?.exerciseTask?.updateTimestampMillis !==
            prevProps?.exerciseTask?.updateTimestampMillis) {
            this.setState({shuffleSeed: Math.random(), selectedOptions: new Set()})
        }
    }

    setSavedOptionsIfExam = () => {
        const { exercise, courseId, isExam, exerciseTask } = this.props
        const { exerciseId } = exercise || {}
        if (isExam && exerciseTask) {
            const selectedOptions = new Set()
            exerciseTask?.questionSelectedOptions?.forEach(x => selectedOptions.add(x))
            this.setState({selectedOptions})
        }
    }

    saveValidationStatus = () => {
        const { exercise, courseId } = this.props
        const { exerciseId, correctOptions } = exercise || {}

        if (!exerciseId) {
            return
        }
        
        const { selectedOptions } = this.state

        this.props.dispatchFunctionAction(validateQuestionSaga, {
            courseId, exerciseId,
            questionData: {
                questionSelectedOptions: [...selectedOptions]
            }})
    }


    onQuizOptionChanged = (quizOptionKey, event) => {
        const { selectedOptions: existingSelectedOptions } = this.state
        const newSelectedOptions = new Set()
        if (existingSelectedOptions.has(quizOptionKey)) {
            existingSelectedOptions.delete(quizOptionKey)
        } else {
            existingSelectedOptions.add(quizOptionKey)
        }
        existingSelectedOptions.forEach(x => newSelectedOptions.add(x))
        console.log({newSelectedOptions})
        this.setState({selectedOptions: newSelectedOptions})
        if (this.props.onExerciseTaskProgressChanged) {
            this.props.onExerciseTaskProgressChanged({
                questionSelectedOptions: [...newSelectedOptions]
            })
        }
    }

    render = () => {
        const { exercise, exerciseTask, isExam } = this.props
        const { exerciseId, questionType, correctOptions,
            options, questionHtml, questionCodeText } = exercise || {}

        const { shuffleSeed, selectedOptions } = this.state
        if (!correctOptions) {
            return null
        }

        const updateTimestampMillis = exerciseTask?.updateTimestampMillis || 0

        console.log({selectedOptions})

        const quizOptionElements = shuffle(Object.keys(options).map(quizOptionKey => {
            return (<div className="d-flex m-1 quizOption align-items-center" key={quizOptionKey} style={{
                height: "auto"
            }}
            onClick={(event) => this.onQuizOptionChanged(quizOptionKey, event)}>
                <input className="ms-3 quizOption" type="checkbox" style={{
                    width: "20px"
                }} checked={
                    selectedOptions.has(quizOptionKey)}
                    // onClick={(event) => this.onQuizOptionChanged(quizOptionKey, event)}
                    defaultChecked/>
                {options[quizOptionKey].textHtml ?
                <div className="ms-3"
                    dangerouslySetInnerHTML={{__html: options[quizOptionKey].textHtml}}>
                </div>:<div className="ms-3"
                    dangerouslySetInnerHTML={{__html: getHtmlString(options[quizOptionKey].text)}}>
                </div>}
            </div>)
        }), isExam ? 0.756: shuffleSeed)
        return (<div className="d-flex flex-column container-fluid m-5">
            {!isExam ?
            <div className="d-flex align-items-center">
                <div className="ms-5">{exerciseId}</div>

                <div className="d-flex ms-auto align-items-center">
                    <div className="me-3" style={{
                        lineHeight: "normal",
                        fontSize: "10px",
                        color: exerciseTask?.taskStatus === "passed" ? "green": "red"
                    }}>
                        {updateTimestampMillis > 0 ? new Date(updateTimestampMillis).toLocaleString("en-IN"): ""}
                    </div>
                    <div className="primaryActionButton" onClick={this.saveValidationStatus}>Check</div>
                    {exerciseTask?.taskStatus === "passed" ?
                    <FontAwesomeIcon icon={faCheckCircle} color={"green"}
                        size={"lg"}
                        style={{marginLeft: "10px"}}/>:
                    <FontAwesomeIcon icon={faTimesCircle} color={"red"}
                        size={"lg"}
                        style={{marginLeft: "10px"}}/>}
                </div>
            </div>: null}
            <div className="mt-2">
                <QuestionTextComponent exercise={exercise} exerciseTask={exerciseTask}>
                </QuestionTextComponent>
            </div>

            {questionCodeText ?
            <Editor
                height={"300px"}
                defaultLanguage={questionCodeText.language}
                value={questionCodeText.codeText}
                options={{
                    readOnly: true,
                    minimap: {
                        enabled: false
                    }
                }}
            />: null
            }
            <div className="d-flex flex-column" style={{
                fontSize: "20px"
            }}>
                {quizOptionElements}
            </div>
        </div>)
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        email: state.common.user?.email,
        userId: state.common.user?.userId,
    };
};

export const MultipleChoiceExerciseComponent = promReduxConnect(
    MultipleChoiceExerciseComponentInternal,
    mapStateToProps,
)