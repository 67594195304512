import React from 'react';
import { getComponentForQuestionId } from '../../../util/lessonPart';

export const HtmlAudioLessonPart1 = () => {
    return (<div className="container-fluid">
        <h3>Audio</h3>
        <ul className="mt-3">
            <li><code>audio</code> element is used to display audio in a html page.</li>
            <li>Like images, audio file needs to be hosted on a server before it can be included in a html page.</li>
            <li>In the below example we use the audio file at https://upload.wikimedia.org/wikipedia/commons/3/30/La_Marseillaise.ogg</li>
            <li><code>source</code> element inside the audio element is used to specify the source of audio</li>
        </ul>

        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <body>
        <audio controls>
            <source src="https://upload.wikimedia.org/wikipedia/commons/3/30/La_Marseillaise.ogg" type="audio/ogg">
            </source>
        </audio>
    </body>
</html>`}
            height={"400px"}
            showOutput={true}
        ></HtmlEditor>
    </div>)
}

export const HtmlAudioLessonPart2 = () => {
    return (<div className="container-fluid">
        <h3>Audio</h3>
        <ul className="mt-3">
            <li>Supported audio formats are mp3, wav, ogg. Corresponding type attribute values for the source element
                should be <code>audio/mpeg</code>, <code>audio/wav</code> and
                <code>audio/ogg</code> respectively.</li>
            <li>Setting <code>controls</code> attribute enables the controls on the audio element.</li>
            <li>Setting <code>autoplay</code> attribute plays the audio automatically when the page is opened.</li>
            <li>Setting <code>muted</code> attribute plays the audio in mute, when the page is opened.</li>
        </ul>

        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <body>
        <audio controls>
            <source src="https://upload.wikimedia.org/wikipedia/commons/3/30/La_Marseillaise.ogg" type="audio/ogg">
            </source>
        </audio>
    </body>
</html>`}
            height={"400px"}
            showOutput={true}
        ></HtmlEditor>
    </div>)
}

export const HtmlAudioLessonPart3 = () => {
    return (<div className="container-fluid">
        <h3>Video</h3>
        <ul className="mt-3">
            <li>Html <code>video</code> element is used to display video in a html page.</li>
            <li>Like images/audio, video file needs to be hosted on a server before it can be included in a html page.</li>
            <li>In the below example we use the video file at 
                https://upload.wikimedia.org/wikipedia/commons/6/61/La_Marseillaise_%281907%29.webm.</li>
            <li><code>source</code> element inside the video element is used to specify the source of video.</li>
        </ul>

        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <body>
        <video width="300px" height="200px" controls>
            <source src="https://upload.wikimedia.org/wikipedia/commons/6/61/La_Marseillaise_%281907%29.webm" type="video/webm">
            </source>
        </video>
    </body>
</html>`}
            height={"400px"}
            showOutput={true}
        ></HtmlEditor>
    </div>)
}


export const htmlAudioLessonParts = [{
    component: HtmlAudioLessonPart1,
    lessonPartId: "HtmlAudioLessonPart1" 
}, {
    component: HtmlAudioLessonPart2,
    lessonPartId: "HtmlAudioLessonPart2"
}, {
    component: HtmlAudioLessonPart3,
    lessonPartId: "HtmlAudioLessonPart3"
}, getComponentForQuestionId("tag-for-audio")
, getComponentForQuestionId("audio-formats")
, getComponentForQuestionId("tag-for-audio-sources")
, getComponentForQuestionId("attribute-for-audio-controls")
, getComponentForQuestionId("attribute-for-audio-repeat")
]
