import { PureComponent, useRef } from "react"
import React from 'react';


import * as bs from 'bootstrap'
import "../dashboard/course.css"

export class PromSpinnerComponent extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render = () => {
        const { isActive, messageText } = this.props
        if (!isActive) {
            return null
        }
        return <div class="d-flex justify-content-center w-100 h-100 align-items-center position-absolute"
            style={{
                zIndex: 100000,
                backgroundColor: "rgba(255, 255, 255, 0.7)"
            }}>
            <div class="spinner-border" role="status">
            <span class="visually-hidden">{messageText || "Loading..."}</span>
            </div>
        </div>
    }
}

export class PromSpinnerComponent2 extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render = () => {
        const { isActive, messageText } = this.props
        if (!isActive) {
            return null
        }
        return <div class="d-flex"
            style={{
                zIndex: 100000,
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                width: "100vw",
                height: "100vh",
                // border: "1px solid red",
                position: "fixed",
                top: 0,
                left: 0
            }}>
            <div className="d-flex align-items-center justify-content-center w-100">

                <div class="spinner-border" role="status">
                    <span class="visually-hidden">{messageText || "Loading..."}</span>
                </div>
            </div>
        </div>
    }
}

export class PromSpinnerComponent3 extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render = () => {
        const { isActive, messageText } = this.props
        if (!isActive) {
            return null
        }
        return <div class="d-flex"
            style={{
                zIndex: 100000,
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                width: "100vw",
                height: "100vh",
                position: "fixed",
                top: 0,
                left: 0
            }}>
            <div className="d-flex align-items-center justify-content-center w-100">

                <div class="spinner-border text-dark" role="status">
                    <span class="visually-hidden">{messageText || "Loading..."}</span>
                </div>
            </div>
        </div>
    }
}
