import { PureComponent, useRef } from "react"
import React from 'react';

import Editor from "@monaco-editor/react";
import { connect } from "react-redux";
import { EMPTY_ARRAY, getRandomString, isBashShellProject, isMysqlShellProject, promReduxConnect } from "./utils";

import * as bs from 'bootstrap'
import { ProjectEditorComponent } from "./projectEditor/projectEditor";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PromSpinnerComponent } from "./spinner"
import { promAxios } from "../../store/promAxios";
import { checkLoginSaga } from "../../store/common.saga";
import { TASK_TYPE_LESSON_BASH_SHELL, TASK_TYPE_LESSON_JS_REACT, TASK_TYPE_LESSON_JS_REACT_DJANGO_MYSQL, TASK_TYPE_LESSON_MYSQL_SHELL, TASK_TYPE_LESSON_PYTHON_DJANGO, TASK_TYPE_LESSON_PYTHON_DJANGO_MYSQL, TASK_TYPE_LESSON_PYTHON_MODULE } from "./constants";


const STEP_ITEM_PLAY_POLL_INTERVAL_MILLI_SECONDS = 100

class ProjectLessonRecorderComponent extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            text: "",
            textMode: "edit",
            lessonContent: {
                lessonId: getRandomString(16),
                steps: [{}],
            },
            currentStepIndex: 0,
            isSpinnerActive: true,
            processStatuses: {},
            shouldRunProcesses: false,
            tempFiles: {}
        }
        this.inputFile = React.createRef()
    }

    componentDidMount = () => {
        this.props.dispatchFunctionAction(checkLoginSaga, {})
        if (this.props.isReader) {
            this.setState({isReader: this.props.isReader})
        }
        if (this.props.lessonContent) {
            this.setLessonContent(this.props.lessonContent)
        }
        if (this.props.lessonContent?.steps?.[this.state.currentStepIndex]?.editFilePath) {
            this.setState({
                selectedFilePath: this.props.lessonContent?.steps?.[this.state.currentStepIndex]?.editFilePath
            })
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.currentStepIndex !== prevState.currentStepIndex) {
            setTimeout(() => this.pollAndRunProcesses(this.state.currentStepIndex), 100)
        }
        if (this.props.isReader !== prevProps.isReader) {
            this.setState({isReader: this.props.isReader})
        }
        if (this.props.lessonContent !== prevProps.lessonContent) {
            this.setLessonContent(this.props.lessonContent)
        }
        if (this.props.lessonContent?.lessonId !== prevProps?.lessonContent?.lessonId) {
            this.setLessonContent(this.props.lessonContent)
            this.setState({
                currentStepIndex: 0,
                selectedFilePath: this.props.lessonContent?.steps?.[0]?.editFilePath
            })
        }
        if (this.state.lessonContent?.steps?.[this.state.currentStepIndex]?.editFilePath &&
            this.state.lessonContent?.steps?.[this.state.currentStepIndex]?.editFilePath !==
            prevState.lessonContent?.steps?.[prevState.currentStepIndex]?.editFilePath) {
            this.setState({
                selectedFilePath: this.state.lessonContent?.steps?.[this.state.currentStepIndex]?.editFilePath
            })
        }
    }

    getEditFilePath = () => {
        const { lessonContent, currentStepIndex } = this.state
        if (isMysqlShellProject(lessonContent?.lessonType)) {
            return "main.sql"
        } else if (isBashShellProject(lessonContent?.lessonType)) {
            return "main.sh"
        } else {
            lessonContent?.steps?.[currentStepIndex]?.editFilePath
        }
    }

    shouldDisableNextClick = () => {
        const { lessonContent, currentStepIndex } = this.state
        const { isReader } = this.props
        if (isReader) {
            return currentStepIndex >= lessonContent.steps.length - 1
        }
        return false
    }

    onNextClick = () => {
        const { lessonContent, currentStepIndex } = this.state
        const newLessonContent = {...lessonContent}

        if (this.shouldDisableNextClick()) {
            return
        }

        if (currentStepIndex === (newLessonContent.steps.length - 1)) {
            newLessonContent.steps.push(JSON.parse(JSON.stringify(
                newLessonContent.steps[currentStepIndex])))
        }
        this.setState({
            currentStepIndex: currentStepIndex + 1,
            lessonContent: newLessonContent})
    }

    onPreviousClick = () => {
        const { lessonContent, currentStepIndex } = this.state
        const newLessonContent = {...lessonContent}
        if (currentStepIndex > 0) {
            this.setState({
                currentStepIndex: currentStepIndex - 1,
            })
        }
        this.setLessonContent(newLessonContent)
    }

    onAddNewStepLevelText = () => {
        const { lessonContent, currentStepIndex } = this.state
        const newLessonContent = {...lessonContent}

        const textItems = newLessonContent.steps[currentStepIndex]?.textItems || []
        textItems[textItems.length] = {html: ""}
        newLessonContent.steps[currentStepIndex] = {
            ...newLessonContent.steps[currentStepIndex],
            textItems
        }
        this.setLessonContent(newLessonContent)
    }

    onStepLevelTextChange = (index, text) => {
        const { lessonContent, currentStepIndex } = this.state
        const newLessonContent = {...lessonContent}
        const textItems = newLessonContent.steps[currentStepIndex]?.textItems || []
        textItems[index] = {html: text}
        newLessonContent.steps[currentStepIndex] = {
            ...newLessonContent.steps[currentStepIndex],
            textItems
        }
        this.setLessonContent(newLessonContent)
    }

    onRemoveStepLevelText = (index) => {
        const { lessonContent, currentStepIndex } = this.state
        const newLessonContent = {...lessonContent}
        let textItems = newLessonContent.steps[currentStepIndex]?.textItems || []
        textItems = [...textItems.slice(0, index), ...textItems.slice(index+1)]
        newLessonContent.steps[currentStepIndex] = {
            ...newLessonContent.steps[currentStepIndex],
            textItems
        }
        this.setLessonContent(newLessonContent)
    }

    onDeleteStep = () => {
        const { lessonContent, currentStepIndex } = this.state
        const newLessonContent = {...lessonContent}

        if (lessonContent.steps.length <= 1) {
            return
        }
        newLessonContent.steps = [
            ...newLessonContent.steps.slice(0, currentStepIndex),
            ...newLessonContent.steps.slice(currentStepIndex + 1, newLessonContent.steps.length)]
        let newCurrentStepIndex = currentStepIndex

        if (currentStepIndex === newLessonContent.steps.length) {
            newCurrentStepIndex = currentStepIndex - 1
        }
        this.setState({
            currentStepIndex: newCurrentStepIndex,
            lessonContent: newLessonContent})
    }

    onInsertStep = () => {
        const { lessonContent, currentStepIndex } = this.state
        const newLessonContent = {...lessonContent}

        newLessonContent.steps = [
            ...newLessonContent.steps.slice(0, currentStepIndex),
            newLessonContent.steps[currentStepIndex],
            ...newLessonContent.steps.slice(currentStepIndex, newLessonContent.steps.length)]
        this.setLessonContent(newLessonContent)
    }

    getFileText = (lessonContent, currentStepIndex, filePath) => {
        return lessonContent?.steps?.[currentStepIndex]?.files?.find(
            f => f.filePath === filePath)?.fileText
    }

    onAddHighlight2 = (highlightMode) => {
        const { currentSelection, lessonContent, currentStepIndex } = this.state
        const newLessonContent = {...lessonContent}
        if (!currentSelection) {
            return
        }
        const currentHighlights = this.getSelectedFileTextHighlights() || []
        
        const fileText = this.getFileText(newLessonContent, currentStepIndex, currentSelection.filePath)
        
        const highlightIndices = []
        for (let i = 0; i < fileText.length; i++) {
            highlightIndices[i] = false
        }
        currentHighlights.forEach(highlightBlock => {
            const { highlightStartIndex, highlightEndIndex } = highlightBlock
            for (let i = highlightStartIndex; i < highlightEndIndex && i < fileText.length; i++) {
                highlightIndices[i] = true
            }
        })
        const { highlightStartIndex, highlightEndIndex } = currentSelection
        for (let i = highlightStartIndex; i < highlightEndIndex && i < fileText.length; i++) {
            highlightIndices[i] = !!(highlightMode === "highlight")
        }

        const newHighlights = []
        let currentStartIndex = -1
        for (let i = 0; i < highlightIndices.length; i++) {
            if (highlightIndices[i]) {
                if (currentStartIndex === -1) {
                    currentStartIndex = i
                }
            } else {
                if (currentStartIndex !== -1) {
                    newHighlights.push({
                        highlightStartIndex: currentStartIndex,
                        highlightEndIndex: i
                    })
                    currentStartIndex = -1
                }
            }
        }
        if (currentStartIndex !== -1) {
            newHighlights.push({
                highlightStartIndex: currentStartIndex,
                highlightEndIndex: highlightIndices.length
            })
        }

        newLessonContent.steps[currentStepIndex] = {
            ...newLessonContent.steps[currentStepIndex],
            textHighlightFilePath: currentSelection.filePath,
            textHighlights: newHighlights
        }
        this.setLessonContent(newLessonContent)
    }

    // componentWillUpdate(nextProps, nextState) {
    //     const stateKeys = new Set();
    //     const propsKeys = new Set();
    //     Object.keys(this.props).forEach(key => propsKeys.add(key));
    //     Object.keys(nextProps).forEach(key => propsKeys.add(key));
    //     Object.keys(this.state || {}).forEach(key => stateKeys.add(key));
    //     Object.keys(nextState || {}).forEach(key => stateKeys.add(key));

    //     const getDiff = (keys, object1, object2) => {
    //         const diffValues = [];
    //         const keysArray = [...keys];
    //         keysArray.filter(key => object1[key] !== object2[key]).forEach(key => diffValues.push([key, object1[key], object2[key]]));
    //         return [...diffValues];
    //     };

    //     console.log(getDiff(propsKeys, this.props, nextProps), getDiff(stateKeys, this.state || {}, nextState || {}));
    // }

    setLessonContent = (lessonContent) => {
        this.setState({lessonContent})
    }

    onToggleView = () => {
        this.setState({textMode: (this.state.textMode === "edit" ? "view": "edit")})
    }

    onDownloadClick = () => {
        const { lessonContent } = this.state
        const newLessonContent = {...lessonContent}

        this.setLessonContent(newLessonContent)

        const dataString = "data:text/json;charset=utf-8," + encodeURIComponent(
            JSON.stringify(newLessonContent, null, 4));
        const downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", dataString);
        downloadAnchorNode.setAttribute("download", "lessonContent.json");
        document.body.appendChild(downloadAnchorNode);
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }

    onLoadFileClick = () => {
        this.inputFile.current.click();
    }

    onChangeInputFile = (event) => {
        if (!event || !event.target || !event.target.files || event.target.files.length === 0) {
            return
        }

        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            const lessonContent = JSON.parse(e.target.result)
            if (lessonContent.steps?.[0]?.stepItems) {
                const steps = lessonContent.steps || []
                for (let i = 0; i < steps.length; i++) {
                    const stepItems = steps[i]?.stepItems || []
                    
                    let stepItemIndex = stepItems.map(x => x.type).indexOf("showOpenedFile")
                    let stepItem = stepItems?.[stepItemIndex]
                    if (stepItem?.filePath) {
                        steps[i].editFilePath = stepItem?.filePath
                    }
                    
                    stepItemIndex = stepItems.map(x => x.type).indexOf("filePathHighlight")
                    stepItem = stepItems?.[stepItemIndex]
                    if (stepItem?.filePaths) {
                        steps[i].highlightFilePaths = stepItem?.filePaths
                    }
                    
                    stepItemIndex = stepItems.map(x => x.type).indexOf("fileTextHighlight")
                    stepItem = stepItems?.[stepItemIndex]
                    if (stepItem?.textHighlights) {
                        steps[i].textHighlights = stepItem?.textHighlights
                        steps[i].textHighlightFilePath = stepItem?.filePath
                    }
                    
                    stepItemIndex = stepItems.map(x => x.type).indexOf("showProcessOutput")
                    stepItem = stepItems?.[stepItemIndex]
                    if (stepItem?.processId) {
                        steps[i].openProcessOutputProcessId = stepItem?.processId
                    }
                    
                    stepItemIndex = stepItems.map(x => x.type).indexOf("showHelperTab")
                    stepItem = stepItems?.[stepItemIndex]
                    if (stepItem?.tabId) {
                        steps[i].openHelperTabId = stepItem?.tabId
                    }
                    if (stepItem?.httpRequestInfo) {
                        steps[i].httpRequestInfo = stepItem?.httpRequestInfo
                    }

                    if (steps[i]?.stepItems) {
                        delete steps[i]?.stepItems
                    }
                }
            }
            this.setLessonContent(lessonContent)
        };
        fileReader.readAsText(event.target.files[0]);
    }

    onGenerateNewLessonId = () => {
        this.setLessonContent({
            ...this.state.lessonContent,
            lessonId: getRandomString(16)
        })
    }

    onLessonNameChange = (event) => {
        this.setLessonContent({
            ...this.state.lessonContent,
            lessonName: event?.target?.value || ""
        })
    }

    onAddProjectFiles = (fileToBeAdded) => {
        const { lessonContent } = this.state
        const newLessonContent = { lessonContent }

        const newFiles = lessonContent?.steps?.[currentStepIndex]?.files || []
        fileToBeAdded.forEach(f => {
            const existingFile = newFiles.find(f1 => f1.filePath === f.filePath)
            if (existingFile) {
                existingFile.fileText = f.fileText
            } else {
                newFiles.push(f)
            }
        })
        this.onSaveExercise({
            files: newFiles
        })

        if (!newLessonContent.steps) {
            newLessonContent.steps = []
        }
        if (!newLessonContent.steps[currentStepIndex]) {
            newLessonContent.steps[currentStepIndex] = {}
        }

        newLessonContent.steps[currentStepIndex] = {
            ...newLessonContent.steps[currentStepIndex],
            files: newFiles
        }
    }

    onDeleteProjectFile = (filePath) => {
        const {
            lessonContent, currentStepIndex } = this.state
        const newLessonContent = {...lessonContent}
        const currentFiles = newLessonContent.steps[currentStepIndex]?.files || []
        newLessonContent.steps[currentStepIndex] = {
            ...newLessonContent.steps[currentStepIndex],
            files: currentFiles.filter(f => !f.filePath?.startsWith(filePath))
        }

        this.setLessonContent(newLessonContent)
    }

    onRenameProjectFile = (parentPath, oldFileName, newFileName, isFolder) => {
        const {lessonContent,currentStepIndex} = this.state
        let newFiles = lessonContent?.steps?.[currentStepIndex]?.files
        if (!isFolder) {
            let oldFilePath = `${parentPath}/${oldFileName}`
            let newFilePath = `${parentPath}/${newFileName}`
            if (parentPath === "") {
                oldFilePath = oldFileName
                newFilePath = newFileName
            }
            const fileText = newFiles?.find(f => f.filePath === oldFilePath)?.fileText || ""
            newFiles = newFiles.map(f => {
                if (f.filePath === oldFilePath) {
                    return {
                        filePath: newFilePath,
                        fileText: fileText
                    }
                } else {
                    return f
                }
            })
        } else {
            let oldFolderPath = `${parentPath}/${oldFileName}/`
            let newFolderPath = `${parentPath}/${newFileName}/`
            if (parentPath === "") {
                oldFolderPath = `${oldFileName}/`
                newFolderPath = `${newFileName}/`
            }

            newFiles = newFiles.map(f => {
                if (f.filePath === oldFolderPath) {
                    return {
                        filePath: newFolderPath,
                    }
                }
                else if (f.filePath.startsWith(oldFolderPath)) {
                    const oldFilePath = f.filePath
                    let newFilePath = newFolderPath + oldFilePath.split(
                        oldFolderPath).slice(1).join(oldFolderPath)
                    let fileText = null
                    if (!oldFilePath.endsWith("/")) {
                        fileText = f.fileText || ""
                    }
                    return {
                        filePath: newFilePath,
                        fileText
                    }
                } 
                else {
                    return f
                }
            })
        }
        const steps = lessonContent?.steps.map((step)=>{
            return {
                ...step,
                files : newFiles,
            }
        })
        this.setState((prevState)=>({
            ...prevState,
            lessonContent : {
                ...prevState.lessonContent,
                steps : steps
            }
        }))
    }
    
    onFileTextEdited = (filePath, editedFileText) => {
        const {
            lessonId, lessonContent, currentStepIndex } = this.state
        // const newLessonContent = {...lessonContent}
        if (!lessonContent.steps[currentStepIndex]) {
            lessonContent.steps[currentStepIndex] = {}
        }
        if (!lessonContent.steps[currentStepIndex].files) {
            lessonContent.steps[currentStepIndex].files = []
        }
        const currentFile = lessonContent.steps[currentStepIndex]?.files.find(f => f.filePath === filePath)
        if (currentFile) {
            currentFile.fileText = editedFileText
        }
        //  else {
        //     lessonContent.steps[currentStepIndex].files.push({
        //         filePath, fileText: editedFileText
        //     })
        // }

        this.setLessonContent(lessonContent)
    }

    onInit = () => {
        const { initProjectType } = this.state

        promAxios.get(`/api/v1/projectTemplate?templateType=${initProjectType}`).then(response => {
            if (response && response.status === 200) {
                const {currentStepIndex,
                    lessonContent } = this.state
                const processes = response.data.template.processes
                const newLessonContent = {...lessonContent,
                    lessonType: initProjectType,
                    processes
                }
                newLessonContent.steps[currentStepIndex] = {
                    ...newLessonContent.steps[currentStepIndex],
                    files: response.data.template.files,
                    processes
                }
                let editFilePath = ""
                if (isMysqlShellProject(initProjectType)) {
                    editFilePath = "main.sql"
                } else if (isBashShellProject(initProjectType)) {
                    editFilePath = "main.sh"
                }
                newLessonContent.steps.forEach(step => {
                    step.processes = processes
                    step.editFilePath = editFilePath
                })
                this.setLessonContent(newLessonContent)
            }
        })
    }

    toggleShouldRunProcesses = () => {
        this.setState({shouldRunProcesses: !this.state.shouldRunProcesses})
    }

    onInitProcesses = () => {
        const { initProjectType } = this.state

        promAxios.get(`/api/v1/projectTemplate?templateType=${initProjectType}`).then(response => {
            if (response && response.status === 200) {
                const {currentStepIndex,
                    lessonContent } = this.state
                const newLessonContent = {...lessonContent}
                const processes = response.data.template.processes
                // newLessonContent.steps[currentStepIndex] = {
                //     ...newLessonContent.steps[currentStepIndex],
                //     processes
                // }
                newLessonContent.processes = processes
                newLessonContent.steps.forEach(step => {
                    step.processes = processes
                })

                this.setLessonContent(newLessonContent)
            }
        })
    }


    onTextSelectionChanged = (filePath, highlightStartIndex, highlightEndIndex) => {
        this.setState({currentSelection: { filePath, highlightStartIndex, highlightEndIndex }})
    }

    onFileSelectionChanged = (filePath) => {
        this.setState({selectedFilePath: filePath})
    }

    clearHttpRequestInfo = () => {
        const { lessonContent, currentStepIndex } = this.state
        const newLessonContent = {...lessonContent}

        if (newLessonContent?.steps?.[currentStepIndex]) {
            newLessonContent.steps[currentStepIndex].httpRequestInfo = {}
        }
        this.setLessonContent(newLessonContent)
    }

    onHighlightFile = (highlightMode) => {
        const { lessonContent, currentStepIndex, selectedFilePath } = this.state
        if (!selectedFilePath) {
            return
        }
        const newLessonContent = {...lessonContent}
        let highlightFilePaths = newLessonContent.steps[currentStepIndex]?.highlightFilePaths || []
        if (highlightMode === "highlight" && !highlightFilePaths.find(x => x === selectedFilePath)) {
            highlightFilePaths.push(selectedFilePath)
        } else if (highlightMode === "unhighlight" && highlightFilePaths.find(x => x === selectedFilePath)) {
            highlightFilePaths = highlightFilePaths.filter(x => x !== selectedFilePath)
        }
        newLessonContent.steps[currentStepIndex] = {
            ...newLessonContent.steps[currentStepIndex],
            highlightFilePaths: [...highlightFilePaths],
        }
        this.setLessonContent(newLessonContent)
    }
    
    onSetEditFilePath = () => {
        const { lessonContent, currentStepIndex, selectedFilePath } = this.state
        if (!selectedFilePath) {
            return
        }
        const newLessonContent = {...lessonContent}
        newLessonContent.steps[currentStepIndex] = {
            ...newLessonContent.steps[currentStepIndex],
            editFilePath: selectedFilePath,
        }
        this.setLessonContent(newLessonContent)
    }

    getCurrentProcessRunConfigs = () => {
        const { lessonContent, currentStepIndex } = this.state

        const processRunConfigItems = lessonContent?.steps?.[currentStepIndex]?.processRunConfigs || []
        Object.keys(lessonContent?.processes || {}).forEach(processId => {
            if(!processRunConfigItems.find(x => x.processId === processId)) {
                processRunConfigItems.push({
                    processId
                })
            }
        })
        return processRunConfigItems
    }

    onMoveProcessRunConfigItemUp = (runConfigItemIndex) => {
        const { lessonContent, currentStepIndex } = this.state
        const newLessonContent = {...lessonContent}
        if (runConfigItemIndex <= 0) {
            return
        }
        const processRunConfigs = this.getCurrentProcessRunConfigs()
        newLessonContent.steps[currentStepIndex].processRunConfigs = [
            ...processRunConfigs.slice(0, runConfigItemIndex - 1),
            processRunConfigs[runConfigItemIndex],
            processRunConfigs[runConfigItemIndex - 1],
            ...processRunConfigs.slice(runConfigItemIndex + 1),
        ]
        this.setLessonContent(newLessonContent)
    }
    
    setProcessRunConfigStatus = (runConfigItemIndex, status) => {
        const { lessonContent, currentStepIndex } = this.state
        const newLessonContent = {...lessonContent}
        const processRunConfigs = this.getCurrentProcessRunConfigs()
        processRunConfigs[runConfigItemIndex] = {
            ...processRunConfigs[runConfigItemIndex],
            status
        }
        newLessonContent.steps[currentStepIndex].processRunConfigs = processRunConfigs
        this.setLessonContent(newLessonContent)
    }

    onMoveProcessRunConfigItemDown = (runConfigItemIndex) => {
        const { lessonContent, currentStepIndex } = this.state
        const newLessonContent = {...lessonContent}
        const processRunConfigs = this.getCurrentProcessRunConfigs()
        if (runConfigItemIndex >= (processRunConfigs.length - 1)) {
            return
        }
        newLessonContent.steps[currentStepIndex].processRunConfigs = [
            ...processRunConfigs.slice(0, runConfigItemIndex),
            processRunConfigs[runConfigItemIndex + 1],
            processRunConfigs[runConfigItemIndex],
            ...processRunConfigs.slice(runConfigItemIndex + 2),

        ]
        this.setLessonContent(newLessonContent)
    }

    setOpenHelperTabId = (openHelperTabId) => {
        const { lessonContent, currentStepIndex } = this.state
        const newLessonContent = {...lessonContent}
        if (newLessonContent?.steps?.[currentStepIndex]?.openHelperTabId !== openHelperTabId) {
            newLessonContent.steps[currentStepIndex].openHelperTabId = openHelperTabId
            this.setLessonContent(newLessonContent)
        }
    }

    getProjectId = () => {
        const { lessonContent } = this.state
        const { userId } = this.props
        return `${lessonContent?.lessonId}-${userId}`
    }

    onOpenFolderPathsChanged = (newOpenTreeNodes) => {
        const { lessonContent, currentStepIndex } = this.state
        const openFolderPaths = []
        newOpenTreeNodes.forEach(s => {
            openFolderPaths.push(s)
        })
        const newLessonContent = {...lessonContent}
        newLessonContent.steps[currentStepIndex] = {
            ...newLessonContent.steps[currentStepIndex],
            openFolderPaths
        }
        this.setLessonContent(newLessonContent)
    }
    
    onRunProcess = (processId) => {
        const {
            textMode, lessonContent, currentStepIndex } = this.state
        const { userId } = this.props

        const files = lessonContent?.steps?.[currentStepIndex]?.files || []
        const projectFilesForWebsocket = {}
        files.forEach((f) => {
            projectFilesForWebsocket[f.filePath] = f.fileText
        })
        console.log({
            messageType: "runProjectProcess",
            userId: userId,
            processId: processId,
            projectId: this.getProjectId(),
            projectFilesForWebsocket: projectFilesForWebsocket,
            processes: lessonContent?.processes
        })
        this.outputWebSocket.send(JSON.stringify({
            messageType: "runProjectProcess",
            userId: userId,
            processId: processId,
            projectId: this.getProjectId(),
            projectFilesForWebsocket: projectFilesForWebsocket,
            processes: lessonContent?.processes
        }))
    }

    onWebsocketConnect = (ws) => {
        this.outputWebSocket = ws
    }

    onResetProjectProcesses = () => {
    }

    onStopProcess = (processId, shouldRemoveProcess) => {    
        const { userId } = this.props    
        this.outputWebSocket.send(JSON.stringify({
            messageType: "stopProjectProcess",
            projectId: this.getProjectId(),
            processId,
            shouldRemoveProcess,
            userId
        }))
    }

    onProcessStatusChange = (processId, status) => {
        this.setState({
            processStatuses: {
                ...this.state.processStatuses,
                [processId]: status
            }
        })
    }

    pollAndRunProcesses = (stepIndex, runConfigIndex=0, pollConfig={}) => {
        const { lessonContent, currentStepIndex,
            selectedFilePath, shouldRunProcesses, processRuns, processStatuses } = this.state
        if (!shouldRunProcesses || isMysqlShellProject(lessonContent?.lessonType) ||
            isBashShellProject(lessonContent?.lessonType)) {
            return 
        }
        if (stepIndex !== currentStepIndex) {
            return
        }

        const processRunConfigs = this.getCurrentProcessRunConfigs()

        const runConfig = processRunConfigs?.[runConfigIndex]

        if (runConfigIndex >= (processRunConfigs || []).length) {
            this.setState({isRunningProcesses: false})
            return
        } else {
            this.setState({isRunningProcesses: true})
        }

        const processId = runConfig?.processId
        const runConfigStatus = runConfig.status
        const currentProcessStatus = processStatuses?.[processId]
        const pollStatus = pollConfig?.[processId]

        let nextRunConfigIndex = runConfigIndex
        
        if (runConfigStatus === "keepRunning") {
            if (currentProcessStatus === "started") {
                nextRunConfigIndex = runConfigIndex + 1
            } else if (pollStatus !== "asked_to_start") {
                this.onRunProcess(processId)
                pollConfig = {
                    ...pollConfig,
                    [processId]: "asked_to_start"
                }
            } else if (currentProcessStatus === "stopped") {
                nextRunConfigIndex = runConfigIndex + 1
            } else {
                // console.log("waiting to start", runConfigIndex)
            }
        } else if (runConfig.status === "rerun") {
            if (pollStatus !== "asked_to_start") {
                // console.log("starting", runConfigIndex)
                this.onRunProcess(runConfig.processId)
                pollConfig = {
                    ...pollConfig,
                    [processId]: "asked_to_start"
                }
            } else if (currentProcessStatus === "started") {
                // console.log("next step index", runConfigIndex)
                nextRunConfigIndex = runConfigIndex + 1
            } else if (currentProcessStatus === "stopped") {
                // console.log("next step index", runConfigIndex)
                nextRunConfigIndex = runConfigIndex + 1
            } else {
                // console.log("waiting to start", runConfigIndex)
            }
        } else if (runConfig.status === "keepStopped") {
            if (currentProcessStatus === "started" ||
                currentProcessStatus === "starting") {
                // console.log("stopping", runConfigIndex)
                this.onStopProcess(runConfig.processId, false)
            } else {
                // console.log("next step index", runConfigIndex)
                nextRunConfigIndex = runConfigIndex + 1
            }
        } else {
            nextRunConfigIndex = runConfigIndex + 1
        }

        setTimeout(() => this.pollAndRunProcesses(stepIndex, nextRunConfigIndex, pollConfig),
            STEP_ITEM_PLAY_POLL_INTERVAL_MILLI_SECONDS)
    }

    getIsSpinnerActive = () => {
        const { lessonContent, currentStepIndex,
            selectedFilePath, shouldRunProcesses, processRuns, processStatuses, runConfigIndex,
            isRunningProcesses
        } = this.state

        if (!shouldRunProcesses || isMysqlShellProject(lessonContent?.lessonType) || isBashShellProject(lessonContent?.lessonType)) {
            return false
        }
        return isRunningProcesses
    }
    
    onHttpRequestFinished = (httpRequestInfo) => {
        const { lessonContent, currentStepIndex } = this.state
        const newLessonContent = {...lessonContent}

        newLessonContent.steps[currentStepIndex] = {
            ...newLessonContent.steps[currentStepIndex],
            httpRequestInfo
        }
        this.setLessonContent(newLessonContent)
    }

    getSelectedFileTextHighlights = () => {
        const {
            textMode, lessonContent, currentStepIndex, initProjectType,
            selectedFilePath, currentSelection, processRuns,
            processStatuses, isReader } = this.state
        if (selectedFilePath === "" || selectedFilePath?.endsWith("/") ||
         selectedFilePath === lessonContent.steps[currentStepIndex]?.textHighlightFilePath) {
            return lessonContent.steps[currentStepIndex]?.textHighlights
        } else {
            return null
        }
    }

    onAddCode = () => {
        let textItemIndex = this.state?.textItemSelection?.textItemIndex 
        if (textItemIndex !== 0 && !textItemIndex) {
            return
        }

        const {
            selectionStartIndex=0, selectionEndIndex=0
        } = this.state?.textItemSelection || {}


        const { lessonContent, currentStepIndex } = this.state
        const newLessonContent = JSON.parse(JSON.stringify(lessonContent))
        if (!newLessonContent.steps[currentStepIndex]?.textItems ||
            !newLessonContent.steps[currentStepIndex]?.textItems?.[textItemIndex] ||
            !newLessonContent.steps[currentStepIndex]?.textItems?.[textItemIndex]?.html) {
            return
        }
        const text = newLessonContent.steps[currentStepIndex].textItems[textItemIndex].html
        if (selectionStartIndex === selectionEndIndex ||
            selectionStartIndex > text.length ||
            selectionEndIndex > text.length) {
            return
        }
        const replacedText = [
            text.slice(0, selectionStartIndex),
            "<code>",
            text.slice(selectionStartIndex, selectionEndIndex).split("<").join("&lt;").split(">").join("&gt;"),
            "</code>",
            text.slice(selectionEndIndex, text.length)
        ].join("")
        newLessonContent.steps[currentStepIndex].textItems[textItemIndex].html = replacedText
        this.setState({lessonContent: newLessonContent})
    }

    render = () => {
        const {
            textMode, lessonContent, currentStepIndex, initProjectType,
            selectedFilePath, currentSelection, processRuns,
            processStatuses, isReader } = this.state
        const { userId } = this.props
        // console.log({lessonContent, currentStepIndex})
        const stepLevelTextElements = (lessonContent.steps[currentStepIndex]?.textItems || []).map(
            (text, index) => {
            return (<li key={index} className="mt-1">
                
                <div className="input-group flex-grow-1">
                    {textMode === "edit" ?<input type="text" className="form-control"
                        onChange={(event) => this.onStepLevelTextChange(index, event.target.value)}
                        onSelect={(event) => {
                            this.setState({
                                textItemSelection: {
                                    textItemIndex: index,
                                    selectionStartIndex: event.target.selectionStart,
                                    selectionEndIndex: event.target.selectionEnd,
                                }
                            })
                        }}
                        value={text.html}/>:
                    <span className="form-control" dangerouslySetInnerHTML={{__html: text.html}}/>}
                    <button className="col-auto btn btn-primary recorderButton" onClick={() =>
                        this.onRemoveStepLevelText(index)}>Remove</button>
                </div>
                
            </li>)
        })
        const stepLevelTextReaderElements = (lessonContent.steps[currentStepIndex]?.textItems || []).map(
            (text, index) => {
            return (<li key={index} className="mt-1">
                
                <div className="flex-grow-1">
                    <span dangerouslySetInnerHTML={{__html: text.html}}/>
                </div>
                
            </li>)
        })
        
        const processRunConfigs = this.getCurrentProcessRunConfigs()
        const processRunConfigElements = processRunConfigs.map((runConfig, index) => {
            return <li>
                <div className="m-1 b-1" style={{border: "1px solid gray"}}>
                    <span onClick={() => this.setState({
                        initProjectType: "django"})}>
                        {lessonContent?.processes?.[runConfig?.processId]?.processName}
                    </span>
                    <button className="stepButton float-end ms-2"
                        disabled={index <= 0} 
                        onClick={
                        () => this.onMoveProcessRunConfigItemUp(index)}>
                        <FontAwesomeIcon icon={faArrowUp}/>
                    </button>
                    <button className="stepButton float-end ms-2"
                        disabled={index >= (processRunConfigs.length - 1)} 
                        onClick={
                        () => this.onMoveProcessRunConfigItemDown(index)}>
                        <FontAwesomeIcon icon={faArrowDown}/>
                    </button>
                    <div>
                        <div>
                            <input className="form-check-input recorderButton ms-2" type="checkbox" value=""
                                style={{width: "20px"}}
                                checked={runConfig?.status === "keepRunning"}
                                onChange={() => this.setProcessRunConfigStatus(index, "keepRunning")}/>
                            <span>Keep running</span>
                        </div>
                        <div>
                            <input className="form-check-input recorderButton ms-2" type="checkbox" value=""
                                style={{width: "20px"}}
                                checked={runConfig?.status === "rerun"}
                                onChange={() => this.setProcessRunConfigStatus(index, "rerun")}/>
                            <span>Rerun</span>
                        </div>
                        <div>
                            <input className="form-check-input recorderButton ms-2" type="checkbox" value=""
                                style={{width: "20px"}}
                                checked={runConfig?.status === "keepStopped"}
                                onChange={() => this.setProcessRunConfigStatus(index, "keepStopped")}/>
                            <span>Keep Stopped</span>
                        </div>
                    </div>
                </div>
            </li>
        })

        console.log({lessonContent})
        const { lessonType } = lessonContent
        return (<div className="d-flex flex-column" style={{
            // lineHeight: "normal",
            height: "100%",
            minHeight: this.props.isReader ? "800px": "unset"
            }}>
            <PromSpinnerComponent isActive={this.getIsSpinnerActive()}></PromSpinnerComponent>
            <div>
                {!this.props.isReader ? <div className="d-flex">
                    <div className="input-group flex-grow-1">
                        <span className="input-group-text">Lesson Name</span>
                        <input type="text" className="form-control"
                            value={lessonContent.lessonName || ""} onChange={this.onLessonNameChange}/>
                    </div>
                    <div className="input-group flex-grow-1">
                        <span className="input-group-text">Lesson Id</span>
                        <input type="text" className="form-control" value={lessonContent.lessonId || ""} disabled/>
                        <button className="btn btn-secondary recorderButton" onClick={this.onGenerateNewLessonId}>
                            New
                        </button>
                    </div>

                    <div className="input-group dropdown ms-2">
                        <button className="btn btn-secondary recorderButton dropdown-toggle" type="button"
                            id="selectConfigMenuButton"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            {initProjectType}
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="selectConfigMenuButton">
                            <li><a className="dropdown-item" onClick={() => this.setState({
                                initProjectType: TASK_TYPE_LESSON_MYSQL_SHELL})}>
                            {TASK_TYPE_LESSON_MYSQL_SHELL}</a></li>
                            <li><a className="dropdown-item" onClick={() => this.setState({
                                initProjectType: TASK_TYPE_LESSON_BASH_SHELL})}>
                            {TASK_TYPE_LESSON_BASH_SHELL}</a></li>
                            <li><a className="dropdown-item" onClick={() => this.setState({
                                initProjectType: TASK_TYPE_LESSON_PYTHON_DJANGO})}>
                            {TASK_TYPE_LESSON_PYTHON_DJANGO}</a></li>
                            <li><a className="dropdown-item" onClick={() => this.setState({
                                initProjectType: TASK_TYPE_LESSON_PYTHON_MODULE})}>
                            {TASK_TYPE_LESSON_PYTHON_MODULE}</a></li>
                            <li><a className="dropdown-item" onClick={() => this.setState({
                                initProjectType: TASK_TYPE_LESSON_PYTHON_DJANGO_MYSQL})}>
                            {TASK_TYPE_LESSON_PYTHON_DJANGO_MYSQL}</a></li>
                            <li><a className="dropdown-item" onClick={() => this.setState({
                                initProjectType: TASK_TYPE_LESSON_JS_REACT})}>
                            {TASK_TYPE_LESSON_JS_REACT}</a></li>
                            <li><a className="dropdown-item" onClick={() => this.setState({
                                initProjectType: TASK_TYPE_LESSON_JS_REACT_DJANGO_MYSQL})}>
                            {TASK_TYPE_LESSON_JS_REACT_DJANGO_MYSQL}</a></li>
                        </ul>
                        <button className="btn btn-primary recorderButton ms-1" type="button"
                         onClick={this.onInit}>
                            Init
                        </button>
                        <button className="btn btn-primary recorderButton ms-1" type="button"
                         onClick={this.onInitProcesses}>
                            Init Proc
                        </button>
                    </div>
                    <input className="form-check-input recorderButton ms-2" type="checkbox" value=""
                        id="flexCheckDefault146"
                        style={{width: "40px"}}
                        checked={!!this.state.isReader}
                        onChange={() => this.setState({isReader: !this.state.isReader})}/>
                    <span className="recorderButton">Reader</span>
                    <input type='file' id='file' ref={this.inputFile} style={{display: 'none'}}
                        onChange={this.onChangeInputFile}/>

                </div>:
                <div className="d-flex">
                    {/* <h3>{lessonContent.lessonName || ""}</h3> */}
                </div>}
                <div className="d-flex align-items-center">
                    <div><h3 className="m-3">{lessonContent.lessonName}</h3></div>
                    <div className={currentStepIndex <= 0 ? 
                        "primaryActionButtonDisabled primaryActionButton": "primaryActionButton"}
                        style={{marginLeft: "10px"}}
                        onClick={this.onPreviousClick}
                        disabled={currentStepIndex <= 0}
                    >Previous</div>
                    <div className={this.shouldDisableNextClick() ? 
                        "primaryActionButtonDisabled primaryActionButton": "primaryActionButton"}
                        onClick={this.onNextClick} style={{marginLeft: "10px"}}
                        disabled={this.shouldDisableNextClick()}>Next
                    </div>
                    {!isReader && <span>{`${currentStepIndex + 1}/${lessonContent?.steps?.length || 0}`}</span> }
                {/* <div className="m-1">
                    <button className="btn btn-primary recorderButton"
                        disabled={currentStepIndex <= 0}
                        onClick={this.onPreviousClick}>Previous</button>
                    <span className="ms-2 me-2">{currentStepIndex + 1}/{lessonContent.steps?.length || 0}</span>
                    <button className="btn btn-primary recorderButton"
                        disabled={isReader && currentStepIndex === (lessonContent.steps.length - 1)}
                        onClick={this.onNextClick}>
                        Next
                    </button> */}
                </div>
                {!isReader? <div className="d-flex align-items-center">
                    <div className="d-flex ms-auto" style={{height: "28px"}}>
                        <input type="text" className="form-control" value={this.state.loadLessonId || ""}
                            onChange={(e) => {
                                this.setState({loadLessonId: e.target.value})
                            }}/>
                    </div>
                    <button className="primaryActionButton ms-2" style={{}} onClick={(e) => {
                        if (!this.state.loadLessonId) {
                            return
                        }

                        promAxios.get(`/api/v1/lessonContents/${this.state.loadLessonId}`).then(response => {
                            this.setState({lessonContent: response.data})
                        })

                    }}>Load From Server</button>
                    <button className="primaryActionButton recorderButton ms-2" onClick={this.onLoadFileClick}>Load</button>

                    <button className="primaryActionButton recorderButton ms-2" onClick={this.onDownloadClick}
                        disabled={!lessonContent?.lessonId}>Download</button>
                    <button className="primaryActionButton ms-2" onClick={() => {
                        const lessonId = lessonContent?.lessonId || ""
                        if (!lessonId) {
                            return
                        }
    
                        promAxios.post(`/api/v1/lessonContents/${this.state.loadLessonId}`,
                            lessonContent).then(response => {
                        })
                    }}>Save</button>
                </div>: null}
                <div>
                    {!isReader ? <button className="ms-2 btn btn-primary recorderButton" onClick={this.onInsertStep}>
                        Insert</button>: null}
                    {!isReader ? <button className="ms-2 btn btn-primary recorderButton" onClick={this.onDeleteStep}>
                        Delete</button>: null}
                    {!isReader ? <div className="d-inline-block dropdown ms-2">
                        <button className="btn btn-secondary recorderButton dropdown-toggle" type="button"
                            id="processesButton"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="false" aria-expanded="false">
                            Processes
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="processesButton">
                            {processRunConfigElements}
                        </ul>
                    </div>: null}
                    {!isMysqlShellProject(lessonType) &&  !isBashShellProject(lessonType) &&
                    <input className="form-check-input recorderButton ms-2" type="checkbox" value=""
                        id="flexCheckDefault146"
                        checked={!!this.state.shouldRunProcesses}
                        onChange={this.toggleShouldRunProcesses}/>}
                    {!isMysqlShellProject(lessonType) &&  !isBashShellProject(lessonType) &&
                    <span className="recorderButton ms-1">Run Processes</span>}
                </div>
                {isReader ? <div className="m-1">
                    {stepLevelTextReaderElements.length > 0 ? <ul className="mt-3 ms-3" style={{fontSize: "22px", maxWidth: "700px"}} >
                        {stepLevelTextReaderElements}
                    </ul>: null}
                </div>: null}
                {!isReader ? <div className="m-1 border border-primary">
                    <button className="btn btn-primary recorderButton ms-2" 
                        onClick={this.onAddNewStepLevelText}>New Text</button>
                    <button className="btn btn-primary recorderButton ms-2"
                        onClick={this.onToggleView}>Toggle View</button>
                    <button className="btn btn-primary recorderButton ms-2"
                        onClick={this.onAddCode}>Add code</button>
                    
                    {stepLevelTextElements.length > 0 ? <ul className="mt-3">
                        {stepLevelTextElements}
                    </ul>: null}
                </div>: null}
                {!isReader ? 
                <div className="m-1 border border-primary">
                    <div className="d-inline-block">
                        <button className="btn btn-primary recorderButton ms-2" onClick={() =>
                            this.onAddHighlight2("highlight")}
                            disabled={!currentSelection}>
                            Highlight text
                        </button>
                        <button className="btn btn-primary recorderButton ms-2"
                            disabled={!currentSelection}
                            onClick={() => this.onAddHighlight2("unhighlight")}>
                            Unhighlight text
                        </button>
                    </div>
                    <div className="d-inline-block">
                        <button className="btn btn-primary recorderButton ms-2"
                            disabled={!selectedFilePath}
                            onClick={() => this.onHighlightFile("highlight")}>
                            Highlight Filepath
                        </button>
                        <button className="btn btn-primary recorderButton ms-2"
                            disabled={!selectedFilePath}
                            onClick={() => this.onHighlightFile("unhighlight")}>
                            Unhighlight Filepath
                        </button>
                    </div>
                    <div className="d-inline-block">
                        <button className="btn btn-primary recorderButton ms-2"
                            disabled={!selectedFilePath}
                            onClick={this.onSetEditFilePath}>
                            Pin File
                        </button>
                    </div>
                    <div className="d-inline-block">
                        <button className="btn btn-primary recorderButton ms-2"
                            onClick={this.clearHttpRequestInfo}>
                            Clear Http
                        </button>
                    </div>
                </div>: null}
            </div>
            <div className="flex-grow-1 d-flex flex-row">
                <div className={!isReader ? "w-75 h-100": "w-100 h-100"}>
                {lessonContent?.lessonId ? 
                <ProjectEditorComponent
                    projectId={`${lessonContent?.lessonId}-${userId}`}
                    files={lessonContent.steps[currentStepIndex]?.files || EMPTY_ARRAY}
                    userId={userId}
                    resetEditedFilesId={`${lessonContent?.lessonId}-${userId}-${currentStepIndex}`}
                    projectType={lessonContent?.lessonType}
                    onAddProjectFiles={this.onAddProjectFiles}
                    onDeleteProjectFile={this.onDeleteProjectFile}
                    onResetProjectProcesses={this.onResetProjectProcesses}
                    onRenameProjectFile={this.onRenameProjectFile}
                    onFileTextEdited={this.onFileTextEdited}
                    onFileSelectionChanged={this.onFileSelectionChanged}
                    onTextSelectionChanged={this.onTextSelectionChanged}
                    onOpenFolderPathsChanged={this.onOpenFolderPathsChanged}
                    setOpenHelperTabId={this.setOpenHelperTabId}

                    // editFilePath={selectedFilePath}
                    editFilePath={lessonContent.steps[currentStepIndex]?.editFilePath}
                    openFolderPaths={lessonContent.steps?.[currentStepIndex]?.openFolderPaths}
                    openProcessOutputProcessId={lessonContent.steps[currentStepIndex]?.openProcessOutputProcessId}
                    openHelperTabId={lessonContent.steps[currentStepIndex]?.openHelperTabId}
                    httpRequestInfo={lessonContent.steps[currentStepIndex]?.httpRequestInfo}
                    highlightFilePaths={lessonContent.steps[currentStepIndex]?.highlightFilePaths}
                    textHighlights={this.getSelectedFileTextHighlights()}

                    onHttpRequestFinished={this.onHttpRequestFinished}
                    processRuns={processRuns}
                    processes={lessonContent?.processes}
                    onProcessStatusChange={this.onProcessStatusChange}
                    onWebsocketConnect={this.onWebsocketConnect}
                >
                </ProjectEditorComponent>: null}

                </div>
                {!isReader ? <div
                    className="w-25"
                    style={{
                        fontSize: "10px",
                        overflowWrap: "break-word"
                    }}
                >
                    <div>editFilePath: 
                        <span>{lessonContent.steps[currentStepIndex]?.editFilePath}</span>
                    </div>
                    <div>openHelperTabId: 
                        <span>
                            {lessonContent.steps[currentStepIndex]?.openHelperTabId}
                        </span>
                    </div>
                    <div>httpRequestInfo: 
                        <span>
                            {JSON.stringify(lessonContent.steps[currentStepIndex]?.httpRequestInfo)}
                        </span>
                    </div>
                    <div>highlightFilePaths: 
                        <span>
                            {JSON.stringify(lessonContent.steps[currentStepIndex]?.highlightFilePaths)}
                        </span>
                    </div>
                    <div>textHighlights: 
                        <span>
                            {JSON.stringify(lessonContent.steps[currentStepIndex]?.textHighlights)}
                        </span>
                    </div>
                    <div>textHighlightFilePath: 
                        <span>
                            {JSON.stringify(lessonContent.steps[currentStepIndex]?.textHighlightFilePath)}
                        </span>
                    </div>
                    <div>openFolderPaths: 
                        <span>
                            {JSON.stringify(lessonContent.steps[currentStepIndex]?.openFolderPaths)}
                        </span>
                    </div>
                    <div>openProcessOutputProcessId: 
                        <span>
                            {lessonContent.steps[currentStepIndex]?.openProcessOutputProcessId}
                        </span>
                    </div>

                    {/* <div style={{
                        height: "80%",
                        overflowWrap: "break-word",
                        overflow: "scroll"
                        }}>
                        {JSON.stringify(lessonContent.steps[currentStepIndex] || {})}
                    </div> */}
                </div>: null}
            </div>
        </div>)
    }
}


export const ProjectLessonRecorder = promReduxConnect(ProjectLessonRecorderComponent,(state, ownProps) => {
    return {
        email: state.common.user?.email,
        userId: state.common.user?.userId,
    };
})

class ProjectLessonReaderComponent extends PureComponent {
    constructor(props) {
        super(props)
    }

    render = () => {
        console.log({l: this.props.lessonContent})
        return <ProjectLessonRecorder
            lessonContent={this.props.lessonContent}
            isReader={true}
        >
        </ProjectLessonRecorder>

    }
}

const mapStateToPropsProjectLessonReader = (state, ownProps) => {
    return {
        email: state.common.user?.email,
        userId: state.common.user?.userId,
    };
};

const mapDispatchToPropsProjectLessonReader = dispatch => ({
});

export const ProjectLessonReader = connect(
    mapStateToPropsProjectLessonReader,
    mapDispatchToPropsProjectLessonReader,
)(ProjectLessonReaderComponent)

