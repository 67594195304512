import React from 'react';
import { HtmlAnswerQuizLessonPart } from '../../../util/lessonPart';
import { getExerciseWithExerciseId } from '../../../exercises/exercise';

export const HtmlCssLessonPartNew = () => {
    return (<div className="container-fluid">
        <h3>CSS</h3>
        {/* <HtmlLessonReaderComponent lessonPartContent={htmlCssLessonPartContent}></HtmlLessonReaderComponent> */}
    </div>)
}

export const HtmlCssLessonPart1 = () => {
    return (<div className="container-fluid">
        <h3>CSS</h3>
        <ul className="mt-3">
            <li>CSS stands for cascading style sheet.</li>
        </ul>
        <p>
            See the below example where we set some style properties for three different paragraphs.
        </p>

        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <body>
        <p style="font-family:monospace;;text-align:justify;font-size:14px;color:grey">
            Earth is the third planet from the Sun and the only astronomical object known to harbor and support life.
            About 29.2% of Earth's surface is land consisting of continents and islands.
            The remaining 70.8% is covered with water, mostly by oceans, seas, gulfs, and other salt-water bodies.
        </p> 
        <p style="font-family:monospace;;text-align:justify;font-size:14px;color:grey">
            Earth's atmosphere consists mostly of nitrogen and oxygen. More solar energy is received by tropical
            regions than polar regions and is redistributed by atmospheric and ocean circulation.
            Greenhouse gases also play an important role in regulating the surface temperature.
        </p>
        <p style="font-family:monospace;;text-align:justify;font-size:14px;color:grey">
            Earth's gravity interacts with other objects in space, especially the Moon, which is Earth's only natural satellite.
            Earth orbits around the Sun in about 365.25 days. Earth's axis of rotation is tilted with respect to its
            orbital plane, producing seasons on Earth.
        </p>    
    </body>
</html>`}
            height={"400px"}
            showOutput={true}
        ></HtmlEditor>
        <p>
            We can see that all three paragraph elements have same style properties specified.
        </p>
        <p>
            We use CSS to avoid this duplication of stype properties among differet elements of same html document.
        </p>
        <p>In the head element of the html element, we can specify the style of all the paragraph elements in the following way.</p>
        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <head>
        <style>
            p {
                font-family:monospace;
                text-align:justify;
                font-size:14px;
                color:grey
            }
        </style>
    </head>
    <body>
        <p>
            Earth is the third planet from the Sun and the only astronomical object known to harbor and support life.
            About 29.2% of Earth's surface is land consisting of continents and islands.
            The remaining 70.8% is covered with water, mostly by oceans, seas, gulfs, and other salt-water bodies.
        </p> 
        <p>
            Earth's atmosphere consists mostly of nitrogen and oxygen. More solar energy is received by tropical
            regions than polar regions and is redistributed by atmospheric and ocean circulation.
            Greenhouse gases also play an important role in regulating the surface temperature.
        </p>
        <p>
            Earth's gravity interacts with other objects in space, especially the Moon, which is Earth's only natural satellite.
            Earth orbits around the Sun in about 365.25 days. Earth's axis of rotation is tilted with respect to its
            orbital plane, producing seasons on Earth.
        </p>    
    </body>
</html>`}
            height={"400px"}
            showOutput={true}
        ></HtmlEditor>

    </div>)
}

export const HtmlCssLessonPart2 = () => {
    return (<div className="container-fluid">
        <ul className="mt-3">
            <li>CSS is specified in the style element inside the head element.</li>
        </ul>
        <p>
            We can specify the style of all the elements of a particular tag in the following way
        </p>

        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <head>
        <style>
            h1 {
                text-align: center
            }
            p {
                text-align:justify;
                font-size:14px;
                color:grey
            }
            body {
                font-family: monospace
            }
        </style>
    </head>
    <body>
        <h1>Earth</h1>
        <p>
            Earth is the third planet from the Sun and the only astronomical object known to harbor and support life.
            About 29.2% of Earth's surface is land consisting of continents and islands.
            The remaining 70.8% is covered with water, mostly by oceans, seas, gulfs, and other salt-water bodies.
        </p> 
        <p>
            Earth's atmosphere consists mostly of nitrogen and oxygen. More solar energy is received by tropical
            regions than polar regions and is redistributed by atmospheric and ocean circulation.
            Greenhouse gases also play an important role in regulating the surface temperature.
        </p>
    </body>
</html>`}
            height={"400px"}
            showOutput={true}
        ></HtmlEditor>
        <p>
            In the above example, the style set in the head element sets all the paragraph elements to have&nbsp;
            <code>font-size</code> 14px,&nbsp;
            <code>text-align</code> as justify and <code>color</code> as grey.
            We do not need to separately specify these styles on individual <code>p</code> elements.
        </p>
    </div>)
}

export const HtmlCssLessonPart3 = () => {
    return (<div className="container-fluid">
        <ul className="mt-3">
            <li>To specify different styles for the same element type, we use class names like below.</li>
        </ul>

        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <head>
        <style>
            h1 {
                text-align: center
            }
            .greyp {
                text-align:justify;
                font-size:14px;
                color:grey
            }
            .redp {
                text-align:justify;
                font-size:14px;
                color:red
            }
            body {
                font-family: monospace
            }
        </style>
    </head>
    <body>
        <h1>Earth</h1>
        <p class="greyp">
            Earth is the third planet from the Sun and the only astronomical object known to harbor and support life.
            About 29.2% of Earth's surface is land consisting of continents and islands.
            The remaining 70.8% is covered with water, mostly by oceans, seas, gulfs, and other salt-water bodies.
        </p> 
        <p class="redp">
            Earth's atmosphere consists mostly of nitrogen and oxygen. More solar energy is received by tropical
            regions than polar regions and is redistributed by atmospheric and ocean circulation.
            Greenhouse gases also play an important role in regulating the surface temperature.
        </p>
    </body>
</html>`}
            height={"400px"}
            showOutput={true}
        ></HtmlEditor>
        <p>
            In the above example, we defines two css style classes <code>greyp</code> and <code>redp</code> in the head.
            The class attribute on the two paragraphs is used to set the class on the two paragraphs.
        </p>
        <p>Each paragraph will pick the style from the value of the <code>class</code> attribute specified on it.</p>
        <p>Css style classes can also be combined like below. In the below example there is a <code>justp</code> class
            that specifies the text alignment and font size, and the <code>redp</code>, <code>greyp</code> classes specify the color.</p>
        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <head>
        <style>
            h1 {
                text-align: center
            }
            .justp {
                text-align:justify;
                font-size:14px;
            }
            .greyp {
                color:grey
            }
            .redp {
                color:red
            }
            body {
                font-family: monospace
            }
        </style>
    </head>
    <body>
        <h1>Earth</h1>
        <p class="greyp justp">
            Earth is the third planet from the Sun and the only astronomical object known to harbor and support life.
            About 29.2% of Earth's surface is land consisting of continents and islands.
            The remaining 70.8% is covered with water, mostly by oceans, seas, gulfs, and other salt-water bodies.
        </p> 
        <p class="redp justp">
            Earth's atmosphere consists mostly of nitrogen and oxygen. More solar energy is received by tropical
            regions than polar regions and is redistributed by atmospheric and ocean circulation.
            Greenhouse gases also play an important role in regulating the surface temperature.
        </p>
    </body>
</html>`}
            height={"400px"}
            showOutput={true}
        ></HtmlEditor>
        <p>We can see that both the paragraphs now have two classes, from which they will pick the style values</p>
    </div>)
}


export const htmlCssLessonParts = [{
    component: HtmlCssLessonPartNew ,
    lessonPartId: "HtmlCssLessonPart1"
// }, {
//     component: HtmlCssLessonPart2 ,
//     lessonPartId: "HtmlCssLessonPart2"
// }, {
//     component: HtmlCssLessonPart3 ,
//     lessonPartId: "HtmlCssLessonPart3"
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-css-1")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-css-2")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-css-3")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-css-4")
    }
}]
