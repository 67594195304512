import React from 'react';


export const HtmlMetadataLessonPart1 = () => {
    return (<div className="container-fluid">
        <h3>Metadata</h3>
        <ul className="mt-3">
            <li><code>meta</code> elements are used to specify metadata about the html document.</li>
            <li><code>meta</code> elements are specified inside the <code>head</code> element.</li>
            <li>These elements are not displayed in the document, but the information in meta elements is used by browsers 
                and search engines.</li>
            <li><code>meta</code> element has <code>name</code> and <code>content</code> attributes.</li>
        </ul>

        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <head>
    <meta name="keywords" content="earth,ocean,sea"></meta>
    <meta name="description" content="An article on earth's oceans"></meta>
    <meta name="author" content="John Doe"></meta>
    </head>
    <body>
        <p></p>
    </body>
</html>`}
            height={"400px"}
            showOutput={true}
        ></HtmlEditor>
    </div>)
}


export const htmlMetadataLessonParts = [{
    component: HtmlMetadataLessonPart1 
}]
