import React, { Component, PureComponent } from "react"
import { connect } from 'react-redux';
import "./course.css"
import { promAxios } from "../../store/promAxios";
import { ensureLoggedIn, isDevEmail, isValidEmail, isValidPassword, isValidPhoneNumber, promReduxConnect, withRouter } from "../util/utils";
import { checkLoginSaga, loginSaga } from "../../store/common.saga";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import './login.scss'
import { Link } from "react-router-dom";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";

class LoginComponentInternal extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            email: "",
            password: "",
            areValidCredentials: false,
            areValidCredentialsDisplayToggle: false,
            passwordToggle: false,
        }
        window.handleGoogleSignin = this.handleGoogleSignin
    }

    componentDidMount() {
        this.props.dispatchFunctionAction(checkLoginSaga, {})
        this.initializeGoogleSignInButtonScript()
    }

    componentDidUpdate() {
        if (this.props.loginStatus === "loggedIn") {
            this.navigateUser();
       }
    }
    initializeGoogleSignInButtonScript = ()=>{
            if (!document.getElementById("google-signin-script")){
                const script = document.createElement("script");
                script.src = "https://accounts.google.com/gsi/client";
                script.id='google-signin-script'
                script.async = true;
                document.body.appendChild(script);
                window.handleGoogleSignin = this.handleGoogleSignin
            }
    }
    componentWillUnmount(){
        if(document.getElementById("google-signin-script")){
            document.getElementById("google-signin-script")?.remove()
        }
    }
    handleSignUpClick = () => {
        const { navigate } = this.props
        navigate("/app/sign-up")
    }

    navigateUser = () => {
        const { navigate } = this.props;

        navigate('/')
    }
    handleForgotPassword = () => {
        const { navigate } = this.props
        navigate('/app/reset-password')
    }

    handleOnEmailChange = (event) => {
        this.setState({ email: event.target.value })
    }

    handleOnPasswordChange = (event) => {
        this.setState({ password: event.target.value })
    }

    handlePasswordKeyDown = (e) => {
        const { email, password } = this.state
        if (e?.key === "Enter") {
            this.props.dispatchFunctionAction(loginSaga, { email, password })
        }
    }

    login = () => {
        const { email, password, areValidCredentialsDisplayToggle } = this.state
        this.setState({areValidCredentialsDisplayToggle: true})
        if (email && password) {
            this.props.dispatchFunctionAction(loginSaga, { email: email, password: password })
        }
        else {
            this.setState({areValidCredentials: true})
        }
    }

    handleGoogleSignin = (googleResponse) => {
        const {credential: googleCredential} = googleResponse
        // const a = decodeJwtResponse(googleCredential)
        console.log("signin with googleeee", googleResponse)
        this.props.dispatchFunctionAction(loginSaga, {googleCredential})
    }

    render = () => {
        const { passwordToggle } = this.state
//         return <div><div id="g_id_onload"
//         data-client_id="54619666795-9papepe3qgoshe9gha7mmjiurji5ud0e.apps.googleusercontent.com"
//         data-context="signin"
//         data-ux_mode="popup"
//         data-login_uri="http://www.plovi.ai/app/login"
//         data-auto_prompt="false">
//    </div>
   
//    <div class="g_id_signin"
//         data-type="standard"
//         data-shape="rectangular"
//         data-theme="outline"
//         data-text="signin_with"
//         data-size="large"
//         data-logo_alignment="left">
//    </div></div>
        return (<div className="login-container mt-5">
           
            <form className="login-form" >
                <h3>Sign In</h3>

                <div className="form-group login-input">
                    <div className="form-input-row"><label>Email address</label>
                    
                    <div className="redirection-link-container"> New here?&nbsp;&nbsp;
                    <Link to="/app/sign-up">Sign up</Link></div>

                    </div>
                    <input type="email" className="form-control" placeholder="Enter email" required
                        value={this.state.email} onChange={this.handleOnEmailChange} />
                </div>

                <div className="form-group login-input">
                    <label>Password</label>
                    <div className="input-group mb-3">
                        <input type={passwordToggle ? 'text' : 'password'} className="form-control" placeholder="Enter password"
                            value={this.state.password} required onChange={this.handleOnPasswordChange} onKeyDown={this.handlePasswordKeyDown} />
                            <div style={{marginTop:'8px'}}>
                                <FontAwesomeIcon className="input-group-text" icon={passwordToggle ? faEye : faEyeSlash} style={{ height: '24px', width : '18px'}} onClick={() => this.setState({ passwordToggle: !passwordToggle })} />
                            </div>
                    </div>
                </div>

                {/* <div className="form-group login-input">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="customCheck1" />
                        <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                    </div>
                </div> */}
                {this.state.areValidCredentialsDisplayToggle ?
                    (this.props.loginApiHasServerError ? 
                    (<span style={{color:"red"}}>
                        Unable to login! Please try after some time</span>):
                    ((this.state.areValidCredentials || this.props.loginApiAreInvalidCredentials)? 
                    <span style={{color:"red"}}>
                        Invalid Credentials! Please try again</span> 
                    : <span />)) 
                : <span></span>
                }
                <div className="btn btn-primary btn-block login-input" onClick={this.login}>Submit</div>
                {/* <p className="forgot-password text-right">
                    Forgot <a href="#">password?</a>
                </p> */}
                <div className="redirection-link-container" style={{
                     display: "flex", justifyContent: "center", marginTop: "5px" }}>
                    <Link to='/app/reset-password'>Forgot Password?</Link></div>
                {/* <div className="redirection-link-container" style={{
                     display: "flex", justifyContent: "center", marginTop: "5px" }}>
                         Sign up coming soon!</div> */}
                
                <div className="googleButtonDiv">
                    <div id="g_id_onload"
                        data-client_id="54619666795-vsp5timfpkp5a2knc191b71tu3kousf2.apps.googleusercontent.com"
                        data-context="signin"
                        data-ux_mode="popup"
                        data-callback="handleGoogleSignin"
                        data-auto_prompt="false">
                    </div>
                    <div class="g_id_signin"
                        data-type="standard"
                        data-shape="rectangular"
                        data-theme="outline"
                        data-text="signin_with"
                        data-size="large"
                        data-logo_alignment="left"
                        data-width="368"
                    >
                    </div>
                </div>
            </form>
            
        </div>);
    }
}

const mapStateToProps = state => {
    return {
        email: state.common.user?.email,
        userId: state.common.user?.userId,
        loginStatus: state.common.loginStatus,
        loginApiAreInvalidCredentials: state.common.loginApiAreInvalidCredentials,
        loginApiHasServerError: state.common.loginApiHasServerError
    };
};

export const LoginComponent = promReduxConnect(
    LoginComponentInternal,
    mapStateToProps
)

class SignUpComponentInternal extends PureComponent {
    state = {
        email: "",
        fullName: '',
        password: '',
        passwordDidNotMatch: false,
        phoneNumber: '',
        userSignUpSuccessfulFlag: false,
        emailCheckFlag : false,
    };

    handleSubmit = (e) => {
        e.preventDefault()
        const email = (this.state.email || "").trim()
        const fullName = (this.state.fullName || "").trim()
        const password = (this.state.password || "").trim()
        const phoneNumber = (this.state.phoneNumber || "").trim()
        if (!isValidEmail(email)) {
            this.setState({errorText: "Invalid email"})
            return
        }
        if (phoneNumber && !isValidPhoneNumber(phoneNumber)) {
            this.setState({errorText: "Invalid phone number"})
            return
        }
        if (!isValidPassword(password)) {
            this.setState({errorText: `Invalid password. Only alphanumeric passwords 
            with minimum 8 characters for now .`})
            return
        }
        
        promAxios.post('/api/v1/signUp', { email, fullName, password, phoneNumber }).then((response) => {
            if (response?.status === 200) {
                this.setState({
                    signupSuccessful: true,
                })
            }
        }).catch((error)=>{
            console.log(error)
            if (error?.response?.status === 400) {
                this.setState({errorText : error?.response?.data})
            }
        })
    };
    render() {
        const { email, fullName, password,
             passwordDidNotMatch, phoneNumber,emailCheckFlag, signupSuccessful,
             errorText } = this.state;
        const { navigate, loginStatus } = this.props

        if (loginStatus === "notChecked") {
            return null
        } else if (loginStatus === "loggedIn") {
            navigate("/")
        }

        if (signupSuccessful) {
            return <div className="d-flex flex-column m-3 align-items-center">
                <div>Signed up successfully. Click below to login.</div>
                <div className="m-3"><div className="dashboardActionButton" onClick={() => {
                    navigate(`/app/login`)
                }}>Login</div></div>
            </div>
        }
        return (
            <div className="login-container">
                <form className="login-form" onSubmit={this.handleSubmit}>
                    <h3>Sign up</h3>
                    <div className="form-group login-input">
                        <label>Name</label>
                        <input
                            type="name"
                            className="form-control"
                            value={fullName}
                            onChange={(e) => this.setState({ fullName: e?.target?.value })}
                        />
                    </div>
                    <div className="form-group login-input">
                        <label>Email</label>
                        <input
                            type="email"
                            className="form-control"
                            value={email}
                            onChange={(e) => this.setState({ email: e?.target?.value })}
                            required
                        />
                    </div>
                    <div className="form-group login-input">
                        <label>Mobile Number</label>
                        <input
                            type="text"
                            className="form-control"
                            value={phoneNumber}
                            onChange={(e) => this.setState({ phoneNumber: e?.target?.value })}
                        />
                    </div>
                    <div className="form-group login-input">
                        <label>Password</label>
                        <input
                            type="password"
                            className="form-control"
                            value={password}
                            onChange={(e) => this.setState({ password: e?.target?.value })}
                            required
                        />
                    </div>
                    {errorText && <div className="" style={{
                        color: "red",
                        marginTop: "15px",
                    }}>{errorText}</div>}
                    <input type="submit" className="btn btn-primary btn-block login-input" />
                    <div className="redirection-link-container">Already a user?&nbsp;<Link 
                    to={`/app/login`}>Login</Link></div>
                </form>
            </div>
        );
    }
}


export const SignUpComponent = withRouter(promReduxConnect(SignUpComponentInternal, (state) => ({
    user: state.common.user,
    loginStatus: state.common.loginStatus
})))


class MyAccountComponentInternal extends PureComponent {
    state = {
    };

    componentDidMount() {
        this.props.dispatchFunctionAction(checkLoginSaga, {})
    }

    componentDidUpdate(prevProps) {
    }

    updateUser = (e) => {
        e.preventDefault()
        const { editingFieldName } = this.state
        let editingFieldChangedValue = (this.state.editingFieldChangedValue || "").trim()
        if (editingFieldName === "email") {
            if (!isValidEmail(editingFieldChangedValue)) {
                this.setState({errorText: "Invalid email"})
                return
            }
        }
        if (editingFieldName === "phoneNumber") {
            if (!isValidPhoneNumber(editingFieldChangedValue)) {
                this.setState({errorText: "Invalid phone number"})
                return
            }
        }
        if (editingFieldName === "password") {
            if (!isValidPassword(editingFieldChangedValue)) {
                this.setState({errorText: `Invalid password. Only alphanumeric passwords 
                with minimum 8 characters for now .`})
                return
            }
        }
        if (editingFieldName === "fullName" || editingFieldName === "email" ||
            editingFieldName === "password" || editingFieldName === "phoneNumber") {
            promAxios.post('/api/v1/updateUser', { [editingFieldName]:
                editingFieldChangedValue }).then((response) => {
                this.props.dispatchFunctionAction(checkLoginSaga, {})
                this.setState({editingFieldName: null, errorText: ""})
            })
        } else if (editingFieldName === "emailVerificationCode") {
            promAxios.get(`/api/v1/users/verifyEmail?code=${editingFieldChangedValue}`).then((
                response) => {
                this.props.dispatchFunctionAction(checkLoginSaga, {})
                this.setState({editingFieldName: null, errorText: ""})
            }).catch(()=>{
                this.setState({errorText : "Failed to verify email"})
            })
        } else if (editingFieldName === "phoneNumberVerificationCode") {
            promAxios.get(`/api/v1/users/verifyPhoneNumber?code=${
                editingFieldChangedValue}`).then(response => {
                this.props.dispatchFunctionAction(checkLoginSaga, {})
                this.setState({editingFieldName: null, errorText: ""})
            }).catch(()=>{
                this.setState({errorText : "Failed to verify phone number"})
            })
        }
    }

    toggleEditingField = (fieldName) => {
        const { editingFieldName } = this.state;
        const { user } = this.props
        const newEditingFieldName = editingFieldName === fieldName ? null: fieldName
        if (fieldName === "fullName" || fieldName === "email" || fieldName === "phoneNumber" ||
        fieldName === "emailVerificationCode" || fieldName === "phoneNumberVerificationCode") {
            this.setState({
                editingFieldName: newEditingFieldName,
                editingFieldChangedValue: user?.[fieldName],
                errorText: ""
            })
        } else if (fieldName === "password") {
            this.setState({
                editingFieldName: newEditingFieldName,
                editingFieldChangedValue: "",
                errorText: ""
            })
        }
        
        if (newEditingFieldName === "emailVerificationCode") {
            promAxios.post('/api/v1/users/sendEmailVerificationCode').then((response) => {
            })
        }
        if (newEditingFieldName === "phoneNumberVerificationCode") {
            promAxios.post('/api/v1/users/sendPhoneNumberVerificationCode').then((response) => {
            })
        }
    }

    render = () => {
        const { editingFieldChangedValue, errorText, 
              editingFieldName } = this.state;
        const { user, loginStatus, navigate } = this.props
        ensureLoggedIn(loginStatus, navigate)
        if (!user) {
            return null
        }
        const { fullName, email, phoneNumber } = user || {}
        return (
            <>
                <div className="login-container">
                    <form className="login-form" style={{ marginTop: "20px" }}>
                        <h3>My Account</h3>
                        <div className="form-group login-input">
                            <div className="form-input-row">
                                <label>Full Name</label>
                                <FontAwesomeIcon className="edit-icon" onClick={
                                    () => {this.toggleEditingField("fullName")}
                                } beat={editingFieldName === "fullName"} icon={faPenToSquare} />
                            </div>
                            <input
                                type="name"
                                className="form-control"
                                value={editingFieldName === "fullName" ? (editingFieldChangedValue || ""): fullName}
                                onChange={(e) => this.setState({ editingFieldChangedValue: e?.target?.value })}
                                disabled={editingFieldName !== "fullName"}
                            />
                        </div>
                        <div className="form-group login-input">
                            <div className="form-input-row">
                                <label >Email</label>
                                <FontAwesomeIcon className="edit-icon" onClick={
                                    () => this.toggleEditingField("email")
                                } beat={editingFieldName === "email"} icon={faPenToSquare} />
                            </div>
                            <div className="form-input-row-with-button">
                                <input
                                    type="email"
                                    className="form-control"
                                    value={editingFieldName === "email" ? (editingFieldChangedValue || ""): email}
                                    onChange={(e) => this.setState({ editingFieldChangedValue: e?.target?.value })}
                                    disabled={editingFieldName !== "email"}
                                />
                                {(!user?.emailVerified) && 
                                <div className='form-verification' style={{ marginTop: "5px" }} 
                                onClick={() => this.toggleEditingField("emailVerificationCode")}>
                                    Verify Email</div>}
                            </div>
                        </div>
                        {!user?.emailVerified && editingFieldName === "emailVerificationCode" &&
                            <div className="form-group login-input">
                                <label>Enter code</label>
                                <div className="form-input-row">
                                    <input
                                        type="number"
                                        className="form-control"
                                        value={editingFieldName === "emailVerificationCode" ? 
                                        (editingFieldChangedValue || ""): email}
                                        onChange={(e) => this.setState({ editingFieldChangedValue: e?.target?.value })}
                                        disabled={editingFieldName !== "emailVerificationCode"}
                                    />
                                </div>
                            </div>}
                        <div className="form-group login-input">
                            <div className="form-input-row">
                                <label>Phone Number</label>
                                <FontAwesomeIcon className="edit-icon" onClick={
                                    () => this.toggleEditingField("phoneNumber")
                                } beat={editingFieldName === "phoneNumber"} icon={faPenToSquare} />
                            </div>
                            <div className="form-input-row-with-button">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={editingFieldName === "phoneNumber" ? (
                                        editingFieldChangedValue || ""): phoneNumber}
                                    onChange={(e) => this.setState({ editingFieldChangedValue: e?.target?.value })}
                                    disabled={editingFieldName !== "phoneNumber"}
                                />
                                {/* {!user?.tempPhoneNumberVerified && <div className='form-verification'
                                 onClick={() => this.toggleEditingField("phoneNumberVerificationCode")}>
                                     Verify phone</div>} */}
                            </div>
                        </div>
                        {/* {!user?.tempPhoneNumberVerified && editingFieldName === "phoneNumberVerificationCode" && 
                        <div className="form-group login-input">
                            <label>Enter code</label>
                            <div className="form-input-row">
                                <input
                                    type="number"
                                    className="form-control"
                                    value={editingFieldName === "phoneNumberVerificationCode" ? (
                                        editingFieldChangedValue || ""): email}
                                    onChange={(e) => this.setState({ editingFieldChangedValue: e?.target?.value })}
                                    disabled={editingFieldName !== "phoneNumberVerificationCode"}
                                />
                            </div>
                        </div>} */}
                        <div className="form-group login-input">
                            <div className="form-input-row">
                                <label>Password</label>
                                <FontAwesomeIcon className="edit-icon" onClick={
                                    () => this.toggleEditingField("password")
                                } beat={editingFieldName === "password"} icon={faPenToSquare} />
                            </div>
                            <input
                                type="password"
                                className="form-control"
                                value={editingFieldName === "password" ? (
                                    editingFieldChangedValue || ""): "********"}
                                onChange={(e) => this.setState({ editingFieldChangedValue: e?.target?.value })}
                                disabled={editingFieldName !== "password"}
                            />
                        </div>
                        {errorText && <div style={{color: "red"}}>{errorText}</div>}
                        {editingFieldName && <div className="btn btn-primary btn-block login-input"
                         onClick={this.updateUser}>Submit</div>}
                    </form>
                </div>
            </>
        )
    }
}

export const MyAccountComponent = promReduxConnect(MyAccountComponentInternal, (state) => ({
    user: state.common.user,
    loginStatus: state.common.loginStatus
}))


class ResetPasswordInternal extends PureComponent {
    state = {
    }

    sendResetPasswordVerificationCode = (e) => {
        e.preventDefault()
        const email = (this.state.email || {}).trim()
        if (!isValidEmail(email)) {
            this.setState({errorText: "Invalid email"})
            return
        }
        this.setState({errorText: ""})

        promAxios.post("/api/v1/users/sendResetPasswordVerificationCode", { email }).then((response) => {
            this.setState({ verificationCodeSent: true })
        }).catch(() => {
        })
    }

    resetPassword = () => {
        const { email, password, resetPasswordVerificationCode } = this.state
        if (!isValidPassword(password)) {
            this.setState({errorText: `Invalid password. Only alphanumeric passwords 
            with minimum 8 characters for now .`})
            return
        }
        promAxios.post("/api/v1/users/resetPassword", { 
            email, password, resetPasswordVerificationCode }).then((response) => {
            this.setState({
                resetSuccessful: true,
            })
            this.setState({errorText: ""})
        }).catch((error)=>{
            if (error?.response?.status === 400) {
                // this.setState({errorText: error?.response?.data})
                this.setState({errorText: "Failed to reset password"})
            }
        })
    }

    handleRedirectSignUp = () => {
        const { navigate } = this.props
        navigate('/app/sign-up')
    }

    render() {
        const { loginStatus, email, otpInputFlag, resetPasswordVerificationCode,
            emailOtpDisabledFlag, password, confirmPassword, emailSubmittedFlag,
            otpSubmittedFlag, passwordsUpdatedFlag, emailNotFoundFlag,
            otpDidNotMatchFlag, resetSuccessful, verificationCodeSent, errorText } = this.state
        const { navigate } = this.props
        if (loginStatus === "notChecked") {
            return null
        }
        if (loginStatus === "loggedIn") {
            navigate('/')
        }

        if (resetSuccessful) {
            return <div className="d-flex flex-column m-3 align-items-center">
                <div>Password reset successfully. Click below to login.</div>
                <div className="m-3"><div className="dashboardActionButton" onClick={() => {
                    navigate(`/app/login`)
                }}>Login</div></div>
            </div>
        }
        return (
            <div className="login-container">
                <form className="login-form">
                    <h3>Reset Password</h3>
                    <div className="form-group login-input">
                        <label>Email</label>
                        <input
                            type="email"
                            className="form-control"
                            value={email}
                            onChange={(e) => this.setState({ email: e?.target.value })}
                        />
                    </div>

                    
                    {!verificationCodeSent && <input type="submit" className="btn btn-primary btn-block login-input"
                     onClick={this.sendResetPasswordVerificationCode} disabled={!email} />}
                    {verificationCodeSent ?
                        <div className="form-group login-input">
                            <label>Enter Password</label>
                            <input
                                type="password"
                                className="form-control"
                                value={password}
                                onChange={(e) => this.setState({ password: e?.target.value })}
                            />
                            <label className="mt-1">Enter code sent to email</label>
                            <input
                                type="number"
                                className="form-control"
                                value={resetPasswordVerificationCode}
                                onChange={(e) => this.setState({ resetPasswordVerificationCode: e?.target.value })}
                            />
                            <div 
                            className="btn btn-primary btn-block login-input" onClick={this.resetPassword}>
                                Submit</div>
                        </div> : null}
                        {errorText && <div className="" style={{
                            color: "red",
                            marginTop: "15px",
                        }}>{errorText}</div>}
                </form>
            </div>)
    }

}

export const ResetPassword = withRouter(
    promReduxConnect(ResetPasswordInternal, (state, ownProps) => { }))