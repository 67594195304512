import { PureComponent } from "react"
import React from 'react';

import Editor from "@monaco-editor/react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faExpandArrowsAlt, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { faCompressArrowsAlt } from '@fortawesome/free-solid-svg-icons'
import * as monaco from "monaco-editor"
import { getHashCode, getHtmlString } from "./utils";
import "../dashboard/course.css"


export const getHighlightRangeForHighlightIndices = (highlightStartIndex, highlightEndIndex, text) => {
    let startLineNumber = -1
    let startColumnNumber = -1
    let endLineNumber = -1
    let endColumnNumber = -1
    let lineNumber = 1
    let columnNumber = 1
    for (let i = 0; i <= (text || "").length; i++) {
        if (highlightStartIndex === i) {
            startLineNumber = lineNumber
            startColumnNumber = columnNumber
        }
        if (highlightEndIndex === i) {
            endLineNumber = lineNumber
            endColumnNumber = columnNumber
        }
        if (text.charAt(i) === "\n") {
            lineNumber = lineNumber + 1
            columnNumber = 1
        } else {
            columnNumber = columnNumber + 1
        }
    }
    return { startLineNumber, startColumnNumber, endLineNumber, endColumnNumber }
}


export const getHighlightIndicesforRange = (startLineNumber, startColumnNumber, endLineNumber, endColumnNumber, text) => {
    let lineNumber = 1
    let columnNumber = 1
    let highlightStartIndex = -1
    let highlightEndIndex = -1
    for (let i = 0; i <= (text || "").length; i++) {
        if (lineNumber === startLineNumber && columnNumber === startColumnNumber) {
            highlightStartIndex = i
        }
        if (lineNumber === endLineNumber && columnNumber === endColumnNumber) {
            highlightEndIndex = i
        }
        if (text.charAt(i) === "\n") {
            lineNumber = lineNumber + 1
            columnNumber = 1
        } else {
            columnNumber = columnNumber + 1
        }
    }
    return { highlightStartIndex, highlightEndIndex }
}
class JsEditorComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            editedHtmlText: null,
            outputTabName: "output",
            isOutputExpanded: false,
            runOutputText: ""
        }
        this.monacoRef = React.createRef()
        this.monacoEditor = React.createRef()
        this.startTimestampMillis = null
    }

    componentDidMount = () => {
        const { exerciseId } = this.props
        if (exerciseId) {
            this.getSavedExerciseIfNeeded()
        }
        this.nativeConsoleLogFunc = console.log
        console.log = this.onConsoleLog.bind(this)
    }

    componentWillUnmount = () => {
        if (this.nativeConsoleLogFunc) {
            console.log = this.nativeConsoleLogFunc
        }
    }

    componentDidUpdate = (prevProps) => {
        const { exerciseId, initialCodeText, highlightBlocks, initialOutputText } = this.props
        const codeText = this.getCodeText()
        let shouldUpdateEditor = false
        if (this.props.initialCodeText !== prevProps.initialCodeText || 
            this.lastRenderedCodeText !== codeText ||
            this.props.highlightBlocks !== prevProps.highlightBlocks) {
            shouldUpdateEditor = true
        }

        const updateEditorIfRequired = () => {
            if (shouldUpdateEditor) {
                this.updateEditor()
            }
        }
        if (exerciseId !== prevProps.exerciseId || initialOutputText != prevProps.initialOutputText) {
            this.setState({editedHtmlText: null, runId: null}, updateEditorIfRequired)
            this.getSavedExerciseIfNeeded()
            this.startTimestampMillis = performance.now()
        }
        if (initialCodeText !== prevProps.initialCodeText) {
            this.setState({editedHtmlText: null, runOutputText: ""}, updateEditorIfRequired)
        }
        
        if (highlightBlocks !== prevProps.highlightBlocks) {
            this.setState({editedHtmlText: null}, updateEditorIfRequired)
        }
        
        if (prevProps.userId !== this.props.userId) {
            this.setState({editedHtmlText: null}, () => {
                this.getSavedExerciseIfNeeded()
                if (this.monacoEditor.updateOptions) {
                    this.monacoEditor.updateOptions({
                        readOnly: false
                    })
                }
            })
        }
        if (this.lastRenderedOutputText !== this.getOutputText()) {
            this.onOutputTextChanged(this.getOutputText())
        }
        this.lastRenderedinitialCodeText = this.props.initialCodeText
        this.lastRenderedEditedHtmlText = this.state.editedHtmlText
        this.lastRenderedCurrentEditorText = this.getCurrentEditorText()
        this.lastRenderedCodeText = this.getCodeText()
        this.lastRenderedOutputText = this.getOutputText()
    }

    componentWillUnmount() {
        // if (this.saveInterval) {
        //     clearInterval(this.saveInterval)
        // }
    }

    // componentWillUpdate(nextProps, nextState) {
    //     const stateKeys = new Set();
    //     const propsKeys = new Set();
    //     Object.keys(this.props).forEach(key => propsKeys.add(key));
    //     Object.keys(nextProps).forEach(key => propsKeys.add(key));
    //     Object.keys(this.state || {}).forEach(key => stateKeys.add(key));
    //     Object.keys(nextState || {}).forEach(key => stateKeys.add(key));

    //     const getDiff = (keys, object1, object2) => {
    //         const diffValues = [];
    //         const keysArray = [...keys];
    //         keysArray.filter(key => object1[key] !== object2[key]).forEach(key => diffValues.push([key, object1[key], object2[key]]));
    //         return [...diffValues];
    //     };

    //     console.log(getDiff(propsKeys, this.props, nextProps), getDiff(stateKeys, this.state || {}, nextState || {}));
    // }

    toggleOutputExpandMode = () => {
        this.setState({isOutputExpanded: !this.state.isOutputExpanded})
    }

    getSavedExerciseIfNeeded = () => {
        const { isExerciseLoaded, courseId, exerciseId } = this.props
        if (exerciseId && !isExerciseLoaded) {
            this.props.dispatchGetExerciseSagaAction(courseId, exerciseId)
        }
    }

    updateEditor = () => {
        if (!this.monacoEditor || !this.monacoEditor.setValue) {
            return
        }
        this.monacoEditor.setValue(this.getCurrentEditorText())
        this.updateDecorations()
    }

    updateDecorations = () => {
        if (!this.monacoEditor || !this.monacoEditor.setValue) {
            return
        }
        const { editedHtmlText } = this.state
        if (editedHtmlText) {
            this.monacoEditor.deltaDecorations([], []);
        } else {
            const { highlightBlocks=[] } = this.props
            const decorations = []
            highlightBlocks.forEach(highlightBlock => {
                const { highlightStartIndex, highlightEndIndex } = highlightBlock
                if (highlightStartIndex < 0 || highlightEndIndex < 0 || highlightStartIndex === highlightEndIndex) {
                    return
                }
                const { startLineNumber, startColumnNumber, endLineNumber, endColumnNumber } = getHighlightRangeForHighlightIndices(
                    highlightStartIndex, highlightEndIndex, this.getCurrentEditorText())
                decorations.push({ 
                    range: new monaco.Range(startLineNumber, startColumnNumber, endLineNumber, endColumnNumber),
                    options: {
                        inlineClassName: 'myInlineDecoration'
                    }
                })
            })
            this.monacoEditor.deltaDecorations([], decorations);
        }
    }

    getCurrentEditorText = () => {
        const { initialCodeText } = this.props
        const { editedHtmlText } = this.state
        const codeText = this.getCodeText()
        if (!codeText && codeText !== "") {
            return null
        }
        return editedHtmlText || codeText || initialCodeText || ""
    }

    getCodeText = () => {
        const { exercises, courseId, exerciseId } = this.props
        return getCodeText(exercises, courseId, exerciseId)
    }

    getExerciseStatus = () => {
        const { exercises, courseId, exerciseId } = this.props
        return getExerciseStatus(exercises, courseId, exerciseId)
    }

    getExerciseLastUpdatedMillis = () => {
        const { exercises, courseId, exerciseId } = this.props
        return getExerciseLastUpdatedMillis(exercises, courseId, exerciseId)
    }

    onEditorChange = (editorText, event) => {
        const { exerciseId, initialCodeText, highlightBlocks, initialOutputText } = this.props
        this.setState({editedHtmlText: initialCodeText === editorText ? null: editorText}, () => {
            this.updateDecorations()
        })
        if (this.props.onEditorTextChange) {
            this.props.onEditorTextChange(editorText)
        }
    }

    onEditorMount = (editor, monaco) => {
        this.monacoEditor = editor
        this.monacoEditor.updateOptions({
            minimap: {
                enabled: false
            },
            wordWrap: "on",
            readOnly: false
        })
        this.monacoEditor.onDidChangeCursorSelection(this.onDidChangeCursorSelection)
        this.updateEditor()
    }

    onDidChangeCursorSelection = (event) => {
        const selections = this.monacoEditor.getSelections() || []
        if (selections.length < 1) {
            return
        }
        const selection = selections[0]
        const { startLineNumber, startColumn: startColumnNumber, endLineNumber, endColumn: endColumnNumber } = selection
        const { highlightStartIndex, highlightEndIndex } = getHighlightIndicesforRange(
            startLineNumber, startColumnNumber, endLineNumber, endColumnNumber, this.getCurrentEditorText())
        if (this.props.onSelectionChanged && highlightStartIndex !== highlightEndIndex) {
            this.props.onSelectionChanged({ highlightStartIndex, highlightEndIndex })
        }
    }

    setToOutout = () => {
        this.setState({outputTabName: "output"})
    }

    setToExpectedOutout = () => {
        this.setState({outputTabName: "expectedOutput"})
    }

    saveCodeText = () => {
        const { courseId, exerciseId } = this.props
        const editedText = this.getCurrentEditorText()
        if (!this.props.exerciseId) {
            return
        }
        this.props.dispatchSaveExerciseSagaAction(
            courseId, exerciseId, { files: [{filePath: "main.js", fileText: editedText}]})
    }

    runCode = () => {
        this.runOutputText = ""
        this.setState({runOutputText: ""}, () => {
            this.startedRunningCode = true
            const editorText = this.getCurrentEditorText()
            const codeHash = getHashCode(editorText)
            this.setState({runId: codeHash})
            const { expectedOutputText, exerciseId } = this.props
            // console.log("running code js", editorText)
            try {
                const F = new Function(editorText);
                F()
                this.saveCodeText()
            } catch(e) {
                console.log("error", e)
            }

            this.startedRunningCode = false
        })
    }

    onConsoleLog() {
        // this.onConsoleLog(arguments)
        if (this.nativeConsoleLogFunc && this.startedRunningCode) {
            let text = ""
            const a = arguments
            for (let i = 0; i < arguments.length; i++) {
                text = text + arguments[i]
            }
            // this.nativeConsoleLogFunc("whatisthis", this.state.runOutputText, arguments[0])

            this.runOutputText = `${this.runOutputText ? this.runOutputText + "\n": ""}${text}`
            this.setState((state, props) => ({
                runOutputText: this.runOutputText
            }));
            this.nativeConsoleLogFunc(...arguments)
        }
    }

    getOutputDoc = (output) => {
        const a = getHtmlString(output)
        
        return `<code>
            ${a}
        </code>`
    }

    onOutputTextChanged = (outputText) => {
        if (this.props.onOutputTextChanged) {
            this.props.onOutputTextChanged(outputText)
        }
    }

    getOutputText = () => {
        let { runOutputText } = this.state
        let { initialOutputText } = this.props
        // if (runOutput?.output) {
        //     runOutputText = runOutputText + runOutput?.output
        // }
        // if (runOutput?.error) {
        //     runOutputText = runOutputText + "\n\n" + runOutput?.error
        // }
        // console.log({runOutputText})
        return runOutputText || initialOutputText || ""
    }

    // getOutputText = () => {
    //     const { codeRuns, initialOutputText } = this.props
    //     const { runId } = this.state
    //     const runOutput = codeRuns[runId]
    //     console.log({runId})
    //     let runOutputText = ""
    //     if (runOutput?.output) {
    //         runOutputText = runOutputText + runOutput?.output
    //     }
    //     if (runOutput?.error) {
    //         runOutputText = runOutputText + "\n\n" + runOutput?.error
    //     }
    //     return runOutputText || (!runId && initialOutputText) || ""
    // }

    render = () => {
        const { expectedOutputText, exerciseId, userId, codeRuns, initialOutputText, showOutput } = this.props
        const { runId } = this.state
        const height = this.props.height || "150px"
        let htmlTextInputCardClass = "col-12 card"
        if (this.props.showOutput) {
            htmlTextInputCardClass = "col-6 card"
        }
        const runOutput = codeRuns[runId]
        const exerciseStatus = this.getExerciseStatus()

        const currentEditorText = this.getCurrentEditorText()
        const lastUpdateTimestampMillis = this.getExerciseLastUpdatedMillis()
        console.log({a: this.getOutputText()})
        return (<div className="card">
            {currentEditorText !== null ?
            <div className="card-body">
            <div className="container-fluid">
                <div className="row">
                    <div className={htmlTextInputCardClass}>
                        <div className="card-body">
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <div className="linkdiv nav-link active" aria-current="page">Javascript</div>
                                </li>
                                {exerciseId ?
                                    <li className="nav-item ms-auto">
                                        {exerciseId || ""}
                                    </li>: null
                                }
                                <li className="nav-item ms-auto">
                                </li>
                                {(exerciseId && showOutput) ?
                                    <li className="nav-item ms-auto">
                                        <div style={{
                                            lineHeight: "normal",
                                            fontSize: "10px",
                                            color: exerciseStatus === "valid" ? "green": "red"
                                        }}>
                                            {lastUpdateTimestampMillis > 0 ? new Date(lastUpdateTimestampMillis).toLocaleString("en-IN"): ""}
                                        </div>
                                        <button className="btn btn-primary ml-2" onClick={this.runCode}>Run</button>
                                        {exerciseStatus === "valid" ?
                                        <FontAwesomeIcon icon={faCheckCircle} color={"green"}
                                            size={"lg"}
                                            style={{marginLeft: "10px"}}/>:
                                        <FontAwesomeIcon icon={faTimesCircle} color={"red"}
                                            size={"lg"}
                                            style={{marginLeft: "10px"}}/>}
                                    </li>: null
                                }
                                {!exerciseId && showOutput ?
                                    <li className="nav-item ms-auto">
                                        <button className="btn btn-primary ml-2" onClick={this.runCode}>Run</button>
                                    </li>: null
                                }
                                    
                            </ul>
                            <Editor
                                height={height}
                                defaultLanguage="javascript"
                                // defaultValue={this.getCurrentEditorText()}
                                onChange={this.onEditorChange}
                                onMount={this.onEditorMount}
                            />
                        </div>
                    </div>
                    {this.props.showOutput ?
                    <div className={this.state.isOutputExpanded ? " expanded-output card": "col-6 card"}>
                        <div className="card-body d-flex flex-column">
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    {this.state.outputTabName === "output" ?
                                        <div className="linkdiv nav-link active" aria-current="page"
                                         onClick={this.setToOutout}>Output</div> :
                                        <div className="linkdiv nav-link" aria-current="page"
                                         onClick={this.setToOutout}>Output</div>
                                    }
                                </li>
                                {exerciseId ? <li className="nav-item">
                                    {this.state.outputTabName === "expectedOutput" ?
                                        <div className="linkdiv nav-link active"
                                            onClick={this.setToExpectedOutout}>Expected Output</div> :
                                        <div className="linkdiv nav-link"
                                            onClick={this.setToExpectedOutout}>Expected Output</div>
                                    }
                                </li>: null}
                                <li className="nav-item ms-auto">
                                    <button className="btn btn-primary" onClick={this.toggleOutputExpandMode}>
                                        {!this.state.isOutputExpanded  ?
                                        <FontAwesomeIcon icon={faExpandArrowsAlt} />:
                                        <FontAwesomeIcon icon={faCompressArrowsAlt} />}
                                    </button>
                                </li>
                            </ul>
                            {/* <div className="flex-fill d-flex align-items-stretch"> */}
                                {/* <div className="col-12"> */}
                            {this.state.outputTabName === "output" ? 
                                <iframe className="flex-fill" srcDoc={this.getOutputDoc(
                                    this.getOutputText())} title="output"></iframe> :
                                <iframe className="flex-fill" srcDoc={this.getOutputDoc(expectedOutputText)} title="output"></iframe>
                            }
                                {/* </div> */}
                            {/* </div> */}
                        </div>
                    </div>: null}
                </div>
            </div>
            </div>: null}
        </div>)
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        exercises: state.common.exercises,
        email: state.common.user?.email,
        userId: state.common.user?.userId,
        codeRuns: state.common.codeRuns
    };
};

const mapDispatchToProps = dispatch => ({
    dispatchGetExerciseSagaAction: (courseId, exerciseId) => dispatch(getExerciseSagaAction(
        courseId, exerciseId)),
    dispatchSaveExerciseSagaAction: (courseId, exerciseId, exerciseData) => dispatch(
        saveCourseTaskSagaAction(courseId, exerciseId, exerciseData)),
});

export const JsEditor = connect(
    mapStateToProps,
    mapDispatchToProps,
)(JsEditorComponent)