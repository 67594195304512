import { PureComponent } from "react"
import React from 'react';

import { connect } from "react-redux";
import { CSSTransition } from 'react-transition-group';


class HtmlLessonReaderComponent extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            currentStepIndex: 0,
            lessonContent: {
                steps: [{}]
            }
        }
    }

    componentDidMount = (prevProps, prevState) => {
        this.setState({lessonContent: this.props.lessonContent})
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.lessonContent !== this.props.lessonContent) {
            this.setState({
                lessonContent: this.props.lessonContent,
                currentStepIndex: 0
            })
        }
    }

    onNextClick = () => {
        const { lessonContent } = this.state
        const { currentStepIndex } = this.state
        if (currentStepIndex < lessonContent.steps.length) {
            this.setState({
                currentStepIndex: currentStepIndex + 1,
            })
        }
    }

    gettextHighlights = () => {
        const { currentStepIndex, editorHtmlText } = this.state
        const { lessonContent } = this.state
        return lessonContent.steps[currentStepIndex]?.textHighlights || EMPTY_ARRAY
    }

    onPreviousClick = () => {
        const { lessonContent } = this.state
        const { currentStepIndex, editorHtmlText } = this.state
        if (currentStepIndex > 0) {
            this.setState({
                currentStepIndex: currentStepIndex - 1,
            })
        }
    }

    getEditorText = () => {
        const { lessonContent, currentStepIndex, editorHtmlText } = this.state
        const fileText = lessonContent?.steps?.[currentStepIndex]?.projectFiles?.["main.html"]?.fileText
        return editorHtmlText || fileText || ""
    }

    getTextElements = () => {
        const { lessonContent } = this.state
        const { currentStepIndex, editorHtmlText } = this.state
        return lessonContent.steps[currentStepIndex]?.textItems || []
    }

    getOutputText = () => {
        const { lessonContent } = this.state
        const { currentStepIndex, editorHtmlText } = this.state
        return lessonContent.steps[currentStepIndex]?.outputText || ""
    }
    render = () => {
        const { lessonContent } = this.state
        const { currentStepIndex, editorHtmlText } = this.state
        const textHighlights = this.gettextHighlights()
        const textElements = this.getTextElements().map((text, index) => {
            return (<li key={index} className="">
                <div dangerouslySetInnerHTML={{__html: text.html}}/>
            </li>)
        })
        const textElements2 = this.getTextElements().map((text, index) => {
            return (
                <CSSTransition in={true} timeout={200} classNames="my-node" key={`${currentStepIndex}-${index}`}>
                    <div dangerouslySetInnerHTML={{__html: text.html}}/>
                </CSSTransition>
            )
        })
        console.log(this.getOutputText())
        return (<div className="card">
            <div className="card-body">
                <div className="container-fluid">
                    <div className="d-flex">
                        <button className={currentStepIndex > 0 ? "btn btn-primary col-1": "btn btn-primary col-1 disabled"}
                            onClick={this.onPreviousClick}>Previous</button>
                        <div className="col-10 d-flex justify-content-center">
                            {/* <button className="btn btn-primary"
                                onClick={this.applyCodeTransition}><FontAwesomeIcon icon={faRedo} /></button> */}
                        </div>
                        <button className={currentStepIndex < lessonContent.steps.length - 1 ? "btn btn-primary col-1": "btn btn-primary col-1 disabled"}
                            onClick={this.onNextClick}>Next</button>
                    </div>
                    <h3 className="m-3">{lessonContent.lessonName}</h3>

                    <ul className="mt-3">
                        {textElements}
                    </ul>
            
                    <HtmlEditor
                        initialHtmlText={this.getEditorText()}
                        height={"600px"}
                        showOutput={true}
                        highlightBlocks={textHighlights}
                        initialOutputText={this.getOutputText()}
                    ></HtmlEditor>
                </div>
            </div>
        </div>)
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        email: state.common.user?.email,
        userId: state.common.user?.userId,
    };
};

const mapDispatchToProps = dispatch => ({
});


export const HtmlLessonReader2 = connect(
    mapStateToProps,
    mapDispatchToProps,
)(HtmlLessonReaderComponent)