import { PureComponent } from "react";
import { LandingComponent } from "../publicPage/LandingComponent/landingComponent";
import { FeaturesListPanelComponent } from "../publicPage/FeaturesListPanelComponent/featuresListPanelComponent";
import { ContactUs } from "../publicPage/ContactUs/contactUs";
import { ProgramData } from "../publicPage/ProgramDataComponent/programData";
import { CourseData } from "../publicPage/CourseDataComponent/courseData";
import { ExploreLearningPathComponent } from "../publicPage/ExploreLearningPathComponent/exploreLearningPathComponent";
import { promReduxConnect } from "../util/utils";
export class PublicHomeComponent extends PureComponent {
    render = () => {
        return (
            <>
                <LandingComponent />
                <FeaturesListPanelComponent />
                <ExploreLearningPathComponent
                    courseKey="python"
                    courses={this.props.allCourses}
                />
            </>
        );
    };
}

class PublicSingleCourseComponentInternal extends PureComponent {
    render = () => {
        const { course, allSubscriptions } = this.props;
        return (
            <div>
                <CourseData details={course} navigate={this.props.navigate}
                    allSubscriptions={allSubscriptions}
                    dispatchFunctionAction={this.props.dispatchFunctionAction} course={course} />
            </div>
        );
    };
}

export const PublicSingleCourseComponent = promReduxConnect(
    PublicSingleCourseComponentInternal, (state, ownProps) => {
        return {
            allSubscriptions: state.common.allSubscriptions
        };
    })

export class PublicHelpComponent extends PureComponent {
    faqs = [{
        questionHtml: "What is Plovi AI?",
        answerHtml: `We are trying to make use of AI to teach coding to beginners`,
    }];

    render = () => {
        return (
            <div>
                <ContactUs />
                {/* <div>Faqs</div> */}
            </div>
        );
    };
}

