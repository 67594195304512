// import './App.css';
import './components/dashboard/course.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { PureComponent } from 'react';
import { withTheme } from 'styled-components';
import { ProjectLessonRecorder } from './components/util/projectLessonRecorder';
import { FileLessonRecorder } from './components/util/fileLessonRecorder';
import { NewApp, TrialComponent } from './components/dashboard/newApp';
import { CourseComponent } from './components/dashboard/course';
import { promReduxConnect } from './components/util/utils';
import { MysqlLessonRecorder } from './components/util/mysql';



class App extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
        const url = window?.location?.href || ""
        if (url.startsWith("http://") && url.includes("plovi.ai")) {
            window.location.href = `https://${url.slice(7)}`
        }
    }

    componentDidUpdate() {
    }
    render = () => {
        return (
            <div className="App">
                <Routes>
                    <Route path="/app/*" element={<NewApp/>} />
                    
                    <Route path="/projectLessonRecorder" element={<ProjectLessonRecorder/>} />
                    <Route path="/fileLessonRecorder" element={<FileLessonRecorder/>} />
                    <Route path="/mysqlLessonRecorder" element={<MysqlLessonRecorder/>} />
                    <Route path="/courses/*" element={<CourseComponent/>} />
                    <Route path="/" element={<NewApp/>} />
                </Routes>
            </div>
      );
    }
}

const mapStateToProps = state => {
    return {
        email: state.common.user?.email,
        userId: state.common.user?.userId,
    };
};

export default withTheme(promReduxConnect(App, mapStateToProps));