import styles from "./contactUs.module.scss";
import formStyles from "../FormComponent/formComponent.module.scss";
import {FormInputItemTextArea} from "../FormComponent/formComponent";
import ButtonComponent from "../ButtonComponent/buttonComponent";
import { PureComponent } from "react";
import { promAxios } from "../../../store/promAxios";
import React from 'react'
import { isValidEmail, isValidPhoneNumber } from "../../util/utils";

class ContactDetailsContainer extends PureComponent {
    render(){
        const { icon, title, subText, wrapperClassName } = this.props;
        return(
            <div className={`${styles.contactDetailsContainer} ${wrapperClassName}`}>
            <div className={styles.icon}>{icon}</div>
            <div className={styles.details}>
            <div className={styles.title}>{title}</div>
            <div className={styles.subText}>{subText}</div>
        </div>
        </div>
    )
  }
}

class FormInputItem extends PureComponent{
    render() {
        const { icon, type, onChange, placeholder, 
        errors, submitCheck, textIndex } = this.props;
    return (
        <div className={`form-group`}>
        <div className="input-group">
        <div className="input-group-text">{icon}</div>
        <input className={`form-control ${!submitCheck && 
            errors?.[textIndex] ? 'is-invalid' : ''}`}
        placeholder={placeholder} type={type} onChange={onChange} />
        {!submitCheck && errors?.[textIndex] &&
            <div className="invalid-feedback">{errors?.[textIndex]}</div>}
        </div>
        </div>
    );
    };
}

//for submitting the message form
export class ContactUs extends PureComponent {
    constructor(props) {
    super(props)
    this.state = {
        name: "",
        phoneNumber: "",
        email: "",
        contactUsMessageText: "",
        error: {},
        submitCheck: false,
        submitCheckMessage: "",
        submitCheckMessageIsError: false
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({submitCheck : false, submitCheckMessage: "", 
            submitCheckMessageIsError: false})
    
    const isPhoneNumberValid = isValidPhoneNumber(this.state.phoneNumber)
    const isEmailValid = isValidEmail(this.state.email)
    const isMessageValid = (this.state.contactUsMessageText).trim().length>0
    
    if ((isPhoneNumberValid && isMessageValid) ||
       (isEmailValid && isMessageValid) || 
       (isPhoneNumberValid && isEmailValid)) {

    this.setState({submitCheck : true})
    
    const contactUsMessage = {
        name: this.state.name,
        email: this.state.email,
        phoneNumber: this.state.phoneNumber,
        messageText: this.state.contactUsMessageText
    }
    promAxios.post('/api/v1/createContactUsMessages',
        {"contactUsMessage": contactUsMessage})
    .then((response) => {
        this.setState({error:{
          serverSubmitCheck: false
        }})
        this.setState({submitCheckMessage: "Thanks for your message. Please email support@plovi.ai if you need quicker response.",
        submitCheckMessageIsError: false})
      }
    )
    .catch((error) => {
        this.setState({error: {
        serverSubmitCheck: true}
        }
    )
    this.setState({submitCheckMessage: "Message not sent. Please try again.", 
    submitCheckMessageIsError: true})})
    }

    else {
    this.setState({error: {
    phoneNumber: isPhoneNumberValid ? "" : "Please enter valid mobile number.",
    email: isEmailValid ? "" : "Please enter valid email."},
        })}
    }
    render () { 
    return(
    <div className={styles.contactUsContainer}>
        <div className={styles.leftSide}>
        <div className={styles.header}>{"Contact us"}</div>
        <div className={styles.contactList}>
        <ContactDetailsContainer
            icon={
            <svg 
                width="18"
                height="18" 
                viewBox="0 0 18 18" 
                fill="none">
                <path
                  d="M17 12.5C15.8 12.5 14.5 12.3 13.4 11.9H13.1C12.8 11.9 12.6 12 12.4 12.2L10.2 14.4C7.4 12.9 5 
                  10.6 3.6 7.8L5.8 5.6C6.1 5.3 6.2 4.9 6 4.6C5.7 3.5 5.5 2.2 5.5 1C5.5 0.5 5 0 4.5 0H1C0.5 0 0 0.5 
                  0 1C0 10.4 7.6 18 17 18C17.5 18 18 17.5 18 17V13.5C18 13 17.5 12.5 17 12.5ZM2 2H3.5C3.6 2.9 3.8 
                  3.8 4 4.6L2.8 5.8C2.4 4.6 2.1 3.3 2 2ZM16 16C14.7 15.9 13.4 15.6 12.2 15.2L13.4 14C14.2 14.2 15.1
                   14.4 16 14.4V16Z"
                  fill="white"
                />
            </svg>
            }
            title={"Whatsapp us at"}
            subText={"+91 9515656894"}
            wrapperClassName={styles.callUsContainer}
        />
        <ContactDetailsContainer
            icon={
            <svg
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20 2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1
                   20 14V2ZM18 2L10 7L2 2H18ZM18 14H2V4L10 9L18 4V14Z"
                  fill="white"
                />
            </svg>
            }
            title={"Write to us at"}
            subText={"support@plovi.ai"}
            wrapperClassName={styles.writeToUsContainer}
        />
        <ContactDetailsContainer
            icon={
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19 3V21H13V17.5H11V21H5V3H19ZM15 7H17V5H15V7ZM11 7H13V5H11V7ZM7 7H9V5H7V7ZM15 
                  11H17V9H15V11ZM11 11H13V9H11V11ZM7 11H9V9H7V11ZM15 15H17V13H15V15ZM11 15H13V13H11V15ZM7
                   15H9V13H7V15ZM15 19H17V17H15V19ZM7 19H9V17H7V19ZM21 1H3V23H21V1Z"
                  fill="white"
                />
            </svg>
            }
            title={"Office Address"}
            subText={
              "4th floor, Above Jata Fertility Center, Street no. 5, Janardhan Hills, Gachibowli, Hyderabad, India - 500032"
            }
            wrapperClassName={styles.addressContainer}
          />
        </div>
        </div>
        <div className={styles.rightSide}>
        <div className={styles.writeToUsCard}>
        <div className={styles.writeToUsCardHeader}>Write to us</div>
        <div className={formStyles.formContainer}>
        <FormInputItem
            icon={
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8 0C9.06087 0 10.0783 0.421427 10.8284 1.17157C11.5786 1.92172 12 2.93913 12 4C12 5.06087 
              11.5786 6.07828 10.8284 6.82843C10.0783 7.57857 9.06087 8 8 8C6.93913 8 5.92172 7.57857 5.17157 
              6.82843C4.42143 6.07828 4 5.06087 4 4C4 2.93913 4.42143 1.92172 5.17157 1.17157C5.92172 0.421427 
              6.93913 0 8 0ZM8 2C7.46957 2 6.96086 2.21071 6.58579 2.58579C6.21071 2.96086 6 3.46957 6 4C6 4.53043 
              6.21071 5.03914 6.58579 5.41421C6.96086 5.78929 7.46957 6 8 6C8.53043 6 9.03914 5.78929 9.41421 
              5.41421C9.78929 5.03914 10 4.53043 10 4C10 3.46957 9.78929 2.96086 9.41421 2.58579C9.03914 2.21071 
              8.53043 2 8 2ZM8 9C10.67 9 16 10.33 16 13V16H0V13C0 10.33 5.33 9 8 9ZM8 10.9C5.03 10.9 1.9 12.36 1.9 
              13V14.1H14.1V13C14.1 12.36 10.97 10.9 8 10.9Z"
              fill="#525252"
            />
            </svg>
        }
            placeholder={"Name"}
            type="text"
            value = {this.state.name}
            onChange={e => this.setState({name : e.target.value})}
            textIndex = "name"
            submitCheck = {this.state.submitCheck}
        />
        <FormInputItem
            icon={
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20 15.5C18.8 15.5 17.5 15.3 16.4 14.9H16.1C15.8 14.9 15.6 15 15.4 15.2L13.2 17.4C10.4 
              15.9 8 13.6 6.6 10.8L8.8 8.6C9.1 8.3 9.2 7.9 9 7.6C8.7 6.5 8.5 5.2 8.5 4C8.5 3.5 8 3 7.5 
              3H4C3.5 3 3 3.5 3 4C3 13.4 10.6 21 20 21C20.5 21 21 20.5 21 20V16.5C21 16 20.5 15.5 20 
              15.5ZM5 5H6.5C6.6 5.9 6.8 6.8 7 7.6L5.8 8.8C5.4 7.6 5.1 6.3 5 5ZM19 19C17.7 18.9 16.4 18.6 
              15.2 18.2L16.4 17C17.2 17.2 18.1 17.4 19 17.4V19Z"
              fill="#525252"
            />
            </svg>
        }
            placeholder={"Mobile Num: +91 xxxx"}
            type="number"
            value = {this.state.phoneNumber}
            onChange={e => this.setState({phoneNumber:e.target.value})}
            errors = {this.state.error}
            textIndex = "phoneNumber"
            submitCheck = {this.state.submitCheck}/>
        <FormInputItem
            icon={
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
            <path
              d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 
              18V6ZM20 6L12 11L4 6H20ZM20 18H4V8L12 13L20 8V18Z"
              fill="#525252"
            />
            </svg>
        }
            placeholder={"Email"}
            type="email"
            value = {this.state.email}
            onChange={e => this.setState({email:e.target.value})}
            errors = {this.state.error}
            textIndex = "email"
            submitCheck = {this.state.submitCheck}
        />
        <div className={formStyles.formInputItemTextArea}>
            <textarea placeholder="Write your message here" 
            value= {this.state.contactUsMessageText}
            onChange={e=>this.setState({contactUsMessageText: e.target.value})} />
        </div>
        <span style={{
            color: this.state.submitCheckMessageIsError ? "red": "green"}}>
            {this.state.submitCheckMessage}
        </span>
        </div>
        <div className={styles.writeToUsCardButtonContainer}>
        <ButtonComponent type="dark" onClick={this.handleSubmit}>Send Message</ButtonComponent>
        <div className={styles.writeToUsCardNote}>
          We will respond to your message in 1-2 business days.</div>
        </div>
        </div>
        </div>
    </div>
    )}
}