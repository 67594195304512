import styles from "./exploreLearningPathComponent.module.scss";
import ButtonComponent from "../ButtonComponent/buttonComponent";
import { CourseCardComponent } from "../CourseCardComponent/courseCardComponent";
import { useNavigate } from "react-router-dom";

const learningPath = {
  python: {
    title: "Python Developer Internship",
    courseTrack: [
      {
        text: "Start Your Tech Journey",
      },
      {
        text: "Learn from Foundations to Advanced Concepts",
      },
      {
        text: "Learn Databases from Scratch",
      },
      {
        text: "Build Production Scale APIs",
      },
      {
        text: "Take Your Career to Next Step",
      },
    ],
  },
};
export const ExploreLearningPathComponent = (props) => {
  const { courseKey, courses = {} } = props;
  const navigate = useNavigate();
  const courseData = learningPath[courseKey];
  const onHandleRedirection = (id) => {
    const url = `/app/courses?courseId=${id}`;
    navigate(url);
  };

  return (
    <div className={styles.exploreLearningPathComponent}>
      <div className={styles.header}>Explore our Learning Path</div>
      <div className={styles.courseHeader}>{courseData?.title}</div>

      <div className={styles.tracker}>
        {courseData?.courseTrack.length > 0 &&
          courseData?.courseTrack?.map((track, index) => (
            <div key={index} className={styles.trackItem}>
              <div className={styles.trackCircle}>
                <div className={styles.innerCircle}></div>
              </div>
              {track?.text}
            </div>
          ))}
        <div className={styles.timeline}></div>
      </div>
      <div className={styles.innerCoursesCard}>
        {Object.keys(courses)?.length > 0 &&
          Object.keys(courses)?.map((info) => {
            if(courses[info]?.courseName !== "Intermediate Python"){
                return <div className={styles.mainCourseCard}>
                    <CourseCardComponent
                        key={courses[info]?.courseId}
                        title={courses[info]?.courseName}
                        timePeriod={courses[info]?.courseDuration}
                        primaryButtonText={"Explore Course"}
                        onPrimaryClick={() =>
                        onHandleRedirection(courses[info]?.courseId)
                        }
                    />
                </div>
            }
        })}
      </div>

      <div>
        <ButtonComponent
          onClick={() => navigate("/app/programs/python-developer-program")}
          type="primary"
        >
          Explore Program
        </ButtonComponent>
      </div>
    </div>
  );
};
