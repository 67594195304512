import React from 'react';
import htmlIntroLessonContent from "../../../lessonJsons/htmlIntroLessonContent.json"
import htmlElementLessonContent from "../../../lessonJsons/htmlElementLessonContent.json"
import htmlAttributesLessonContent from "../../../lessonJsons/htmlAttributesLessonContent.json"
import htmlHeadingsLessonContent from "../../../lessonJsons/htmlHeadingsLessonContent.json"
import htmlStylesLessonContent from "../../../lessonJsons/htmlStylesLessonContent.json"
import htmlStylesTextSizeLessonContent from "../../../lessonJsons/htmlStylesTextSizeLessonContent.json"
import htmlStylesBackgroundColorLessonContent from "../../../lessonJsons/htmlStylesBackgroundColorLessonContent.json"
import htmlParagraphsLessonContent from "../../../lessonJsons/htmlParagraphsLessonContent.json"
import htmlButtonsLessonContent from "../../../lessonJsons/htmlButtonsLessonContent.json"
import htmlAnchorsLessonContent from "../../../lessonJsons/htmlAnchorsLessonContent.json"
import htmlCssPositionLessonContent from "../../../lessonJsons/htmlCssPositionLessonContent.json"
import htmlCssDisplayLessonContent from "../../../lessonJsons/htmlCssDisplayLessonContent.json"
import htmlCssColorLessonContent from "../../../lessonJsons/htmlCssColorLessonContent.json"
import htmlElement2LessonContent from "../../../lessonJsons/htmlElement2LessonContent.json"
import { HtmlLessonReader2 } from '../../util/htmlLessonRecorder/htmlLessonRecorder2/htmlLessonReader';
import { HtmlAnswerQuizLessonPart, MultipleChoiceAnswerLessonPart } from '../../util/lessonPart';
import { getExerciseWithExerciseId } from '../../exercises/exercise';


export const htmlIntroLessonParts = [{
    component: HtmlLessonReader2,
    props: {
        lessonContent: htmlIntroLessonContent,
    }
}, {
    component: MultipleChoiceAnswerLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-first-line")
    }
}, {
    component: MultipleChoiceAnswerLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-full-form")
    }
},  {
    component: MultipleChoiceAnswerLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-use-for")
    }
}]

export const htmlElementsLessonParts = [{
    component: HtmlLessonReader2,
    props: {
        lessonContent: htmlElementLessonContent,
    }
}, {
    component: HtmlLessonReader2,
    props: {
        lessonContent: htmlElement2LessonContent,
    }
}, {
    component: MultipleChoiceAnswerLessonPart,
    props: {
        exercise: getExerciseWithExerciseId('html-start-tag')
    }
}, {
    component: MultipleChoiceAnswerLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-valid-end-tag")
    }
}, {
    component: MultipleChoiceAnswerLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-valid-element")
    }
}, {
    component: MultipleChoiceAnswerLessonPart,
    props: {
        exercise: getExerciseWithExerciseId('html-root-element')
    }
}, {
    component: MultipleChoiceAnswerLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-inside-tag")
    }
}]

export const htmlAttributesLessonParts = [{
    component: HtmlLessonReader2,
    props: {
        lessonContent: htmlAttributesLessonContent,
    }
}]

export const htmlHeadingsLessonParts = [{
    component: HtmlLessonReader2,
    props: {
        lessonContent: htmlHeadingsLessonContent,
    }
}]

export const htmlStylesLessonParts = [{
    component: HtmlLessonReader2,
    props: {
        lessonContent: htmlStylesLessonContent,
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-color-1")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-color-2")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-color-3")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-color-4")
    }
}]

export const htmlStylesTextSizeLessonParts = [{
    component: HtmlLessonReader2,
    props: {
        lessonContent: htmlStylesTextSizeLessonContent,
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-text-size-1")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-text-size-2")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-text-size-3")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-text-size-4")
    }
}]

export const htmlStylesBackgroundColorLessonParts = [{
    component: HtmlLessonReader2,
    props: {
        lessonContent: htmlStylesBackgroundColorLessonContent,
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-background-color-1")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-background-color-2")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-background-color-3")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-background-color-4")
    }
}]



export const htmlParagraphsLessonParts = [{
    component: HtmlLessonReader2,
    props: {
        lessonContent: htmlParagraphsLessonContent,
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-paragraph-1")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-paragraph-2")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-paragraph-3")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-paragraph-4")
    }
}]


export const htmlButtonLessonParts = [{
    component: HtmlLessonReader2,
    props: {
        lessonContent: htmlButtonsLessonContent,
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-button-1")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-button-2")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-button-3")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-button-4")
    }
}]


export const htmlAnchorLessonParts = [{
    component: HtmlLessonReader2,
    props: {
        lessonContent: htmlAnchorsLessonContent,
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-anchor-1")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-anchor-2")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-anchor-3")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-anchor-4")
    }
}]



export const cssPositionLessonParts = [{
    component: HtmlLessonReader2,
    props: {
        lessonContent: htmlCssPositionLessonContent,
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-css-position-1")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-css-position-2")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-css-position-3")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("html-css-position-4")
    }
}]


export const cssColorsLessonParts = [{
    component: HtmlLessonReader2,
    props: {
        lessonContent: htmlCssColorLessonContent,
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-css-color-1")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-css-color-2")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-css-color-3")
    }
}]

export const cssDisplayLessonParts = [{
    component: HtmlLessonReader2,
    props: {
        lessonContent: htmlCssDisplayLessonContent,
    }
}, {
    component: MultipleChoiceAnswerLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("inline-elements")
    }
}, {
    component: MultipleChoiceAnswerLessonPart,
    props: {
        exercise: getExerciseWithExerciseId("block-elements")
    }
}]
