import React from 'react';
import { HtmlAnswerQuizLessonPart } from '../../../util/lessonPart';
import { getExerciseWithExerciseId } from '../../../exercises/exercise';

export const HtmlListsLessonPart1 = () => {
    return (<div className="container-fluid">
        <h3>Lists</h3>
        <ul className="mt-3">
            <li>Html lists are used to display a set of items in a list.</li>
            <li><code>{"<ul>"}</code> tag is used to display unordered list of items.</li>
            <li><code>{"ol"}</code> tag is used to display ordered list of items.</li>
            <li>Within the <code>ol</code> or <code>ul</code> element tag <code>{"<li>"}</code> is used to display a list item.</li>
            <li>Below is html to display an ordered list of items. </li>
        </ul>

        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <body>
        <ol>
            <li>Size and shape</li>
            <li>Chemical composition</li>
            <li>Internal structure</li>
            <li>Heat</li>
        </ol>
    </body>
</html>`}
            height={"400px"}
            showOutput={true}
        ></HtmlEditor>
    </div>)
}

export const HtmlListsLessonPart2 = () => {
    return (<div className="container-fluid">
        <ul className="mt-3">
            <li>Below is html to display an unordered list of items. </li>
        </ul>

        <HtmlEditor
            initialHtmlText={`
<!DOCTYPE html>
<html>
    <body>
        <ul>
            <li>Size and shape</li>
            <li>Chemical composition</li>
            <li>Internal structure</li>
            <li>Heat</li>
        </ul>
    </body>
</html>`}
            height={"400px"}
            showOutput={true}
        ></HtmlEditor>
    </div>)
}


export const htmlListsLessonParts = [{
    component: HtmlListsLessonPart1 
}, {
    component: HtmlListsLessonPart2,
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-list-1")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-list-2")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-list-3")
    }
}, {
    component: HtmlAnswerQuizLessonPart,
    needsValidation: true,
    props: {
        exercise: getExerciseWithExerciseId("html-list-4")
    }
}]
