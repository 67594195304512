import styles from "./landingComponent.module.scss";
import ButtonComponent from "../ButtonComponent/buttonComponent";
import { GradientTextComponent } from "../GradientTextComponent/gradientTextComponent";
import { useNavigate } from "react-router-dom";

const LandingPageContent = {
  ladingPageSubTextPoints: [
    "Next Generation AI-Powered Learning Experience Platform",
    "High-Quality Content, Live Sessions, Mentoring",
  ],
  landingPageRightImage:
    "https://storage.googleapis.com/38492734832public/web/images/Landing_page_image.svg",
};
export const LandingComponent = (props) => {
  const navigate = useNavigate();
  const onExploreCourse = () => {
    navigate("/app/courses");
  };
  return (
    <div className={styles.landingPage} >
      <div className={styles.leftSide} >
        <div className={styles.landingMainHeader}>
          Learn coding with AI-powered < GradientTextComponent>platform</GradientTextComponent>
        </div>
        <div className={styles.landingSubHeader}>
          <div>Fully guided classroom based programs</div>
          <div>AI-powered courses for self paced online learning</div>
        </div>
        <div style={{width:"100%",display:"flex", justifyContent:"space-between"}}>
            <div>
                <div style={{marginBottom: "16px"}}>
                <ButtonComponent onClick={() => {navigate("/app/internships/python-developer-internship")}} type="primary">
                    Explore Python Developer Internship
                </ButtonComponent>
                </div>
                <div>
                <ButtonComponent onClick={onExploreCourse} type="primary">
                    Explore Courses
                </ButtonComponent>
                </div>
            </div>
               
        </div>
        {/* <div style={{height: "200px"}}></div>
        <div className={styles.landingMainHeader}>
          Build Your Tech Career with
          <GradientTextComponent>{` Interactive `}</GradientTextComponent>{" "}
          Learning
        </div>
        <div className={styles.landingSubHeader}>
          {LandingPageContent.ladingPageSubTextPoints?.map((point, index) => (
            <div key={index}>{point}</div>
          ))}
        </div>

        <div>
          <ButtonComponent onClick={onExploreCourse} type="primary">
            Explore Courses
          </ButtonComponent>
        </div>
      </div>
      <div className={styles.rightSide}>
        <img
          className={styles.rightSideImage}
          src={LandingPageContent?.landingPageRightImage}
          alt={"Image"}
        />*/}
       
      </div> 
      <div className={styles.rightSide}>
          <img
            className={styles.rightSideImage}
            src={LandingPageContent?.landingPageRightImage}
            alt={"Image"}
          />
        </div>
    </div>
  );
};
