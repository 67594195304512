import { PureComponent } from "react"
import React from 'react';
import * as FlexLayout from 'flexlayout-react'
import '../../../node_modules/flexlayout-react/style/light.css'
import { connect } from "react-redux";
import { QuestionTextComponent, promReduxConnect } from "./utils";
import { PythonEditor } from "./pythonEditor";

import "../dashboard/course.css";
import { HtmlEditor } from "./htmlEditor";
import { HTML_QUESTIONS } from "../exercises/html/htmlQuestions";
import { HTML_EXERCISES } from "../exercises/html/htmlExercises";
import { TASK_TYPE_EXERCISE_HTML_FILE, TASK_TYPE_EXERCISE_MULTIPLE_CHOICE_QUESTION } from "./constants";
import { promAxios } from "../../store/promAxios";
import { HTML_LESSONS } from "../courses/html/landing/landing";
import { HtmlLessonReader2 } from "./htmlLessonRecorder/htmlLessonRecorder2/htmlLessonReader";
import { HtmlAnswerQuizLessonPart, MultipleChoiceAnswerLessonPart, SingleAnswerQuizLessonPart } from "./lessonPart";


export class PythonExerciseViewerComponentInternal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            playIntervalSeconds: 10,
            mode: "answer",
            layoutModel: FlexLayout.Model.fromJson({
                global: {
                    rootOrientationVertical: true,
                    tabSetEnableTabStrip: false
                },
                borders: [],
                layout: {
                }
            })
        }
    }

    setLayout = () => {
        this.setState({
            layoutModel: FlexLayout.Model.fromJson({
            global: { rootOrientationVertical: true,
                tabSetEnableTabStrip: false,
                rootOrientationVertical: false
            },
            borders: [],
            layout: {
                type: "row",
                weight: 100,
                children: [{
                    type: "tabset",
                    weight: 25,
                    children: [{
                            type: "tab",
                            name: "",
                            component: "questionHTML",
                            enableClose: false,
                            enableDrag: false
                    }]
                }, {
                    type: "tabset",
                    weight: 75,
                    children: [{
                        type: "tab",
                        name: "",
                        component: "pythonExerciseEditor",
                        enableClose: false,
                        enableDrag: false
                    }]
                }]
            }
        })})
    }

    componentDidMount = () => {
        this.setLayout()
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { playIntervalSeconds } = this.state
    }


    // componentWillUpdate(nextProps, nextState) {
    //     const stateKeys = new Set();
    //     const propsKeys = new Set();
    //     Object.keys(this.props).forEach(key => propsKeys.add(key));
    //     Object.keys(nextProps).forEach(key => propsKeys.add(key));
    //     Object.keys(this.state || {}).forEach(key => stateKeys.add(key));
    //     Object.keys(nextState || {}).forEach(key => stateKeys.add(key));

    //     const getDiff = (keys, object1, object2) => {
    //         const diffValues = [];
    //         const keysArray = [...keys];
    //         keysArray.filter(key => object1[key] !== object2[key]).forEach(key => diffValues.push([key, object1[key], object2[key]]));
    //         return [...diffValues];
    //     };

    //     console.log(getDiff(propsKeys, this.props, nextProps), getDiff(stateKeys,
    // this.state || {}, nextState || {}));
    // }

    onEditorTextChange = (editedText) => {
        if (this.props.onExerciseTaskProgressChanged) {
            this.props.onExerciseTaskProgressChanged({files: [{filePath: "main.py", fileText: editedText}]})
        }
    }


    onValidationResultsReceived = () => {
        // const { exercise } = this.props
        // const {
        //     initialCodeText, expectedOutputText,
        //     exerciseId, questionHtml,
        //     allowCodeCopyPaste } = exercise || {}
        // const { overallError, overallOutput } = validationResults

        // const areOutputsEqual = (!overallOutput && !expectedOutputText) ||
        //     (overallOutput && expectedOutputText && overallOutput.trim() === expectedOutputText.trim())

        // const updatExerciseData = {
        //     files: [{filePath: "main.py", fileText: exerciseCodeText}], validationResults}


        // if (overallError) {
        //     updatExerciseData.taskStatus = "failed"
        // } else if (!areOutputsEqual) {
        //     updatExerciseData.taskStatus = "failed"
        // } else {
        //     updatExerciseData.taskStatus = "passed"
        // }
        // this.props.onSaveExercise(updatExerciseData)
    }

    layoutFactory = (node)  => {
        const { exercise, courseId, exerciseTask, isExam } = this.props
        const {
            initialCodeText, expectedOutputText,
            exerciseId, questionHtml,
            allowCodeCopyPaste } = exercise || {}

        if (!exercise) {
            return null
        }

        if (node.getComponent() === "questionHTML") {
            return <QuestionTextComponent exercise={exercise} exerciseTask={exerciseTask}></QuestionTextComponent>
        }
        else if(node.getComponent() === "pythonExerciseEditor"){
            return (
                <PythonEditor
                    courseId={courseId}
                    editorId={`${exercise?.exerciseId}`}
                    initialCodeText={initialCodeText}
                    highlightBlocks={null}
                    expectedOutputText={expectedOutputText}
                    height={"450px"}
                    isExerciseEditor={true}
                    exerciseId={exerciseId}
                    savedCodeText={exerciseTask?.files?.[0]?.fileText}
                    showOutput={true}
                    exercise={exercise}
                    lastUpdateTimestampMillis={exerciseTask?.updateTimestampMillis}
                    taskStatus={exerciseTask?.taskStatus}
                    timeSpentMillis={exerciseTask?.timeSpentMillis}
                    onEditorTextChange={this.onEditorTextChange}
                    isExam={isExam}
                    onValidationResultsReceived={this.props.onValidationResultsReceived}
                    allowCodeCopyPaste={allowCodeCopyPaste}>
                </PythonEditor>
            )
        }
    }
    
    render () {
        return (
            <div className="flexlayout-container">
                <FlexLayout.Layout
                    model={this.state.layoutModel}
                    factory={this.layoutFactory}
                />
            </div>
        )
    }
}

const mapStateToPropsPythonAnswerQuizLessonPart = (state, ownProps) => {
    return {
        email: state.common.user?.email,
        userId: state.common.user?.userId,
    };
};

const mapDispatchToPropsPythonAnswerQuizLessonPart = dispatch => ({
});

export const PythonExerciseViewerComponent = connect(
    mapStateToPropsPythonAnswerQuizLessonPart,
    mapDispatchToPropsPythonAnswerQuizLessonPart,
)(PythonExerciseViewerComponentInternal)


export class HtmlExerciseViewerComponentInternal extends PureComponent {
    constructor(props) {
        super(props)
        this.selectedOptions = new Set()
    }

    componentDidMount = () => {
        const fields = new Set()
        const allExercises = []
        HTML_EXERCISES.forEach(exercise => {
            Object.keys(exercise).forEach(x => fields.add(x))
            const {
                exerciseId, initialHtmlText, expectedOutputHtml, questionText,
                questionType, questionHtml, options, correctOptions } = exercise
            
            const newExercise = {}
            if (initialHtmlText) {
                newExercise.initialCodeText = initialHtmlText
            }
            if (expectedOutputHtml) {
                newExercise.expectedOutputHtml = expectedOutputHtml
            }
            if (questionText) {
                newExercise.questionHtml = questionText
            }
            if (questionHtml) {
                newExercise.questionHtml = questionHtml
            }
            if (!questionHtml && !questionText) {
                newExercise.questionHtml = "Write html to get the expected output."

            }
            if (correctOptions) {
                newExercise.correctOptions = correctOptions
            }
            if (!questionType) {
                newExercise.exerciseType = TASK_TYPE_EXERCISE_HTML_FILE
                // return
            } else if (questionType === 'multiple-choice-multiple-answer') {
                newExercise.exerciseType = TASK_TYPE_EXERCISE_MULTIPLE_CHOICE_QUESTION
            } else {
                console.log("invalid task?")
            }
            if (!exerciseId) {
                console.log("invalidexercise")
            } else {
                newExercise.exerciseId = exerciseId
            }

            if (options) {
                newExercise.options = {}
                Object.keys(options).forEach(optionKey => {
                    newExercise.options[optionKey] = {
                        text: options[optionKey].text
                    }
                })
            }

            allExercises.push(newExercise)

            
        })

        console.log({allExercises})
        // promAxios.post(`/api/v1/postExercises`, {allExercises})

        const courseModules = []
        const allLessons = []

        HTML_LESSONS.forEach(lesson => {
            const module = {}
            if (lesson.lessonId) {
                module.moduleId = lesson.lessonId
            } else {
                console.log("invalid lesson")
            }
            if (lesson.lessonName) {
                module.moduleName = lesson.lessonName
            } else {
                console.log("invalid lessonName")
            }

            module.moduleDescriptionHtml = "todo"
            module.tasks = []

            lesson.lessonParts.forEach(lessonPart => {
                if (lessonPart.component === HtmlLessonReader2) {
                    module.tasks.push({
                        "taskType": "htmlFileLesson",
                        "taskId": lessonPart.props.lessonContent.lessonId
                    })
                    allLessons.push({
                        ...lessonPart.props.lessonContent,
                        lessonType: "htmlFileLesson"
                    })
                    // console.log("lessonPart1234567", lessonPart)
                } else if (lessonPart.component === MultipleChoiceAnswerLessonPart) {
                    module.tasks.push({
                        "taskType": "multipleChoiceQuestion",
                        "taskId": lessonPart.props.exercise.exerciseId
                    })
                } else if (lessonPart.component === SingleAnswerQuizLessonPart) {
                    module.tasks.push({
                        "taskType": "multipleChoiceQuestion",
                        "taskId": lessonPart.props.questionId
                    })
                } else if (lessonPart.component === HtmlAnswerQuizLessonPart) {
                    module.tasks.push({
                        "taskType": "htmlFileExercise",
                        "taskId": lessonPart.props.exercise.exerciseId
                    })
                } else if (lessonPart?.component?.name?.includes("LessonPart")) {
                    module.tasks.push({
                        "taskType": "htmlFileLesson",
                        "taskId": lessonPart?.component?.name
                    })
                } else {
                    console.log("invalid lesson part", lessonPart?.component?.name, lessonPart)
                    
                }
            })
            courseModules.push(module)
        })

        console.log({courseModules, allLessons})
        // promAxios.post(`/api/v1/postLessons`, {allLessons})
    }

    render = () => {
        const { exercise, courseId, exerciseTask, isExam } = this.props
        const { initialCodeText, expectedOutputHtml, exerciseId, questionText, questionHtml, allowCodeCopyPaste } = exercise || {}

        console.log({exercise, exerciseTask})
        // if (!initialHtmlText) {
        //     return null
        // }
        return (<div className="d-flex flex-column h-100 w-100" style={{}}>
        <QuestionTextComponent exercise={exercise} exerciseTask={exerciseTask}></QuestionTextComponent>

        <HtmlEditor
            initialCodeText={initialCodeText}
            expectedOutputHtml={expectedOutputHtml}
            height={"450px"}
            exerciseId={exerciseId}
            showOutput={true}
            courseId={courseId}
            editorId={`${exercise?.exerciseId}`}
            highlightBlocks={null}
            savedCodeText={exerciseTask?.files?.[0]?.fileText}
            exercise={exercise}
            lastUpdateTimestampMillis={exerciseTask?.updateTimestampMillis}
            taskStatus={exerciseTask?.taskStatus}
            timeSpentMillis={exerciseTask?.timeSpentMillis}
            onEditorTextChange={this.onEditorTextChange}
            isExam={isExam}
            onValidationResultsReceived={this.props.onValidationResultsReceived}
            allowCodeCopyPaste={allowCodeCopyPaste}>
        </HtmlEditor>
    </div>)
        return (<div className="card">
            <div className="card-body">
                <div style={{maxHeight: "400px", overflow: "scroll"}} dangerouslySetInnerHTML={{
                    __html: questionHtml || questionText || "Write html to get the expected output."}}></div>
                {/* <h5 className="card-title">{questionText || "Write html to get the expected output."}</h5> */}
            </div>
        </div>)
    }
}

export const HtmlExerciseViewerComponent = promReduxConnect(
    HtmlExerciseViewerComponentInternal, (state, ownProps) => {
        return {
            email: state.common.user?.email,
            userId: state.common.user?.userId,
        };
})